import React from "react";
import { Form, Button,  Row } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";

export const schema = yup.object({
  allowMultiple: yup.boolean(),
});

const AddedToAutomationForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid
  
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formMultiple">
        <Form.Label>
          {t(
            "locationDashboard.automationShowPage.addedToAutomationForm.multiple.label"
          )}
        </Form.Label>
        <Form.Check
          type="checkbox"
          value={get(values, "allowMultiple")}
          checked={get(values, "allowMultiple")}
          name="allowMultiple"
          onChange={handleChange}
          label={t(
            "locationDashboard.automationShowPage.addedToAutomationForm.multiple.placeholder"
          )}
        />
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.automationShowPage.addedToAutomationForm.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AddedToAutomationForm);
