import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import PipelineTable from "./PipelineTable";
import PipelineContext from "./contexts/PipelineContext";
import PipelineSearchBar from "./PipelineSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const LocationPipelineSettingsPage = () => {
  const { locationId } = useParams();
  useCheckAuth();
  const {
    data: pipelines,
    loading: fetchPipelinesLoading,
    error: fetchPipelinesError,
    getData,
  } = useFetchApi(
    api.locations.pipelines.get,
    {
      locationId,
    },
    { eager: ["stages"] }
  );

  return (
    <PipelineContext.Provider
      value={{
        pipelines,
        reload: getData,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <PipelineSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchPipelinesError && <Error error={fetchPipelinesError} />}
            {fetchPipelinesLoading ? (
              <Loading />
            ) : (
              pipelines && <PipelineTable />
            )}
          </Col>
        </Row>
      </Container>
    </PipelineContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationUser(LocationPipelineSettingsPage)
);
