import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import EmployeeForm from "../EmployeeForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";

const EmployeeCreateModal = ({ handleClose, callback,  show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { userInfo } = useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const { data: account } = await api.accounts.create({
        ...values,
        agencyId: userInfo.agency.id,
        locations: [{ id: locationId }],
      });

      await api.accounts.invite({ accountId: account.id });
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t(
            "locationDashboard.settingsPage.teamManagementPage.createModal.title"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <EmployeeForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmployeeCreateModal;
