export default function (uiData) {
  const triggerKey = Object.keys(uiData).find(
    (key) => uiData[key].type === "trigger"
  );
  const triggerBlock = uiData[triggerKey];
  if (triggerBlock.name === "added_to_automation") {
    return "manual";
  } if (triggerBlock.name === "sms_reply") {
    return "inbound_sms";
  } if (triggerBlock.name === "trigger_link") {
    return "trigger_link";
  }
}
