import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import api from "../../../common/api";
import { useTranslation } from "react-i18next";
import useChat from "../../../hooks/useChat";

export default function MarkAsUnreadButton() {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const { selectedChat } = useChat();
  const markAsRead = async () => {
    try {
      await api.locations.conversations.unread({
        locationId,
        conversationId: selectedChat.id,
      });
    } catch (e) {}
  };
  return (
    <Button variant="light" onClick={markAsRead}>
      {t("locationDashboard.conversationPage.markAsUnread")}
    </Button>
  );
}
