import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Error from "../Error";
import Loading from "../Loading";

const ModalAlert = ({
  handleClose,
  callback,
  text,
  show,
  title = "Confirmation",
}) => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            {text}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          No
        </Button>
        <Button
          variant="dark"
          onClick={async () => {
            setLoading(true);
            setError(null);
            try {
              await callback();
              handleClose();
            } catch (e) {
              setError(e);
            }
            setLoading(false);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ConfirmationModal({
  callback,
  text,
  button,
  title = "Confirmation",
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {React.cloneElement(button, {
        onClick: () => {
          handleShow();
        },
      })}
      <ModalAlert
        title={title}
        show={show}
        handleClose={handleClose}
        callback={callback}
        text={text}
      />
    </>
  );
}
