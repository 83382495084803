import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../../../../common/api";
import EmployeePaginator from "./EmployeePaginator";
import EmployeeTable from "./EmployeeTable";
import EmployeeContext from "./contexts/EmployeeContext";
import EmployeeSearchBar from "./EmployeeSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "Role" ||
      obj[propName] === "Type" ||
      obj[propName] === "In Account"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

const LocationTeamManagementPage = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [nameFilter, setNameFilter] = useState("");
  const [filters, setFilters] = useState({
    role: "",
    type: "",
    page: 1,
    limit: 10,
    locationId: "",
  });
  const {
    data: { results: employees, total },
    loading: fetchEmployeesLoading,
    error: fetchEmployeesError,
    getData,
  } = useFetchApi(
    api.locations.getAccounts,
    {
      locationId,
    },
    clean(filters),
    [filters]
  );
  return (
    <EmployeeContext.Provider
      value={{
        filters,
        setFilters,
        total,
        employees,
        reload: getData,
        nameFilter,
        setNameFilter,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <EmployeeSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted text-left">
              {total}{" "}
              {t("locationDashboard.settingsPage.teamManagementPage.employees")}
            </p>
          </Col>
          <Col className="text-right">
            <EmployeePaginator />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchEmployeesError && <Error error={fetchEmployeesError} />}
            {fetchEmployeesLoading ? (
              <Loading />
            ) : (
              employees && <EmployeeTable />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <EmployeePaginator />
          </Col>
        </Row>
      </Container>
    </EmployeeContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationUser(LocationTeamManagementPage)
);
