import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import MethodInput from "../../../../../../../Form/MethodInput";

export const schema = yup.object({
  url: yup.string().required("validation.executeWehbook.url.required"),
  method: yup.string().required("validation.executeWehbook.method.required"),
});

const ExecuteWebhookForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div>
        <i className="mdi mdi-circle font-13 text-success mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.executeWebhookForm.successHint"
        )}
      </div>

      <div className="mb-3">
        <i className="mdi mdi-circle font-13 text-danger mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.executeWebhookForm.failureHint"
        )}
      </div>
      <MethodInput
        handleChange={handleChange}
        error={get(errors, "method")}
        name="method"
        value={get(values, "method")}
      />
      <Form.Group controlId="formUrl">
        {" "}
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.executeWebhookForm.url.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.executeWebhookForm.url.placeholder"
          )}
          value={get(values, "url") || ""}
          name="url"
          onChange={handleChange}
          isInvalid={!!get(errors, "url")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "url"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.executeWebhookForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(ExecuteWebhookForm);
