import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";

import api from "../../../../../../common/api";
import CustomFieldForm from "../CustomFieldForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import pickCustomField from "../../../../../../common/pickers/pickCustomField";

const CustomFieldCreateModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    console.log(values);
    setLoading(true);
    setError(null);
    try {
      await api.locations.customFields.create(
        { locationId: locationId },
        {
          ...pickCustomField(values),
        }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.settingsPage.customFieldsPage.addModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <CustomFieldForm
              nextExecutionTime={new Date()}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CustomFieldCreateModal;
