import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../AuthorizedRoute";
import * as ROUTES from "../../constants/routes";
import LeftSidebar from "./LeftSidebar";
import LocationsPage from "./LocationsPage";
import TopBar from "./Topbar";

function DashboardPage() {
  return (
    <div className="app">
      <div className="wrapper">
        <LeftSidebar />

        <div className="content-page">
          <div className="content">
            <TopBar />
            <Switch>
              <AuthorizedRoute
                path={ROUTES.ADMIN.href}
                component={LocationsPage}
                exact
              />
              <AuthorizedRoute
                path={ROUTES.ADMIN_LOCATIONS.href}
                component={LocationsPage}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
