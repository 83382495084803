import React from "react";
import { useTranslation } from "react-i18next";
import {
  
  
  Card,
  CardBody,
  CardHeader,
  NavLink,
  UncontrolledCollapse,
} from "reactstrap";
import { Form } from "react-bootstrap";
import get from "lodash/get";

const UsersInfo = ({ handleChange, values, errors }) => {
  const { t } = useTranslation();
  return (
    <div id="accordion" className="mb-4">
      <Card className="mb-0">
        <CardHeader>
          <h5 className="m-0">
            <NavLink
              className="custom-accordion-title d-block pt-2 pb-2"
              id="groups1"
              href="#"
            >
              {t(
                "locationDashboard.settingsPage.teamManagementPage.form.userInfo.title"
              )}
              <span className="float-right">
                <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
              </span>
            </NavLink>
          </h5>
        </CardHeader>
        <UncontrolledCollapse toggler="#groups1">
          <CardBody>
            <Form.Group controlId="formName">
              <Form.Label>
                {" "}
                {t(
                  "locationDashboard.settingsPage.teamManagementPage.form.userInfo.name.label"
                )}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t(
                  "locationDashboard.settingsPage.teamManagementPage.form.userInfo.name.placeholder"
                )}
                value={get(values, "name") || ""}
                name="name"
                onChange={handleChange}
                isInvalid={!!get(errors, "name")}
              />
              <Form.Control.Feedback type="invalid">
                {t(get(errors, "name"))}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>
                {" "}
                {t(
                  "locationDashboard.settingsPage.teamManagementPage.form.userInfo.email.label"
                )}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t(
                  "locationDashboard.settingsPage.teamManagementPage.form.userInfo.email.placeholder"
                )}
                value={get(values, "email") || ""}
                name="email"
                onChange={handleChange}
                isInvalid={!!get(errors, "email")}
              />
              <Form.Control.Feedback type="invalid">
                {t(get(errors, "email"))}
              </Form.Control.Feedback>
            </Form.Group>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </div>
  );
};

export default UsersInfo;
