import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../../../../common/api";
import BootstrapTable from "react-bootstrap-table-next";
import { useCheckAuth } from "../../../../../react-check-auth/index";
import useBulkRequest from "../hooks/useBulkRequest";
import BulkRequestEditModal from "../BulkRequestEditModal";
import ModalButton from "../../../../ModalButton";
import ConfirmationModal from "../../../../ConfirmationModal";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

const BulkRequestTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { userInfo } = useCheckAuth();
  const { requests, reload } = useBulkRequest();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (request) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.bulkRequests.deleteById({
        locationId,
        bulkRequestId: request.id,
      });
      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const ActionColumn = (cell, row) => {
    return (
      <Row>
        <Col>
          {" "}
          <ModalButton
            modal={<BulkRequestEditModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t("locationDashboard.bulkRequestsPage.table.editButton")}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
        <Col>
          <ConfirmationModal
            title={t("locationDashboard.bulkRequestsPage.deleteModal.title")}
            text={t("locationDashboard.bulkRequestsPage.deleteModal.text")}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.bulkRequestsPage.table.deleteButton")}
              </Button>
            }
            callback={() => handleDelete(row)}
          />
        </Col>
      </Row>
    );
  };
  const NameColumn = (cell) => {
    return <React.Fragment>{cell}</React.Fragment>;
  };
  const ScheduledDateColumn = (cell) => {
    return (
      <React.Fragment>
        {format(parseISO(cell), "HH:mm dd.MM.yyyy")}
      </React.Fragment>
    );
  };
  const StatusColumn = (cell) => {
    return <React.Fragment>{cell}</React.Fragment>;
  };
  const TagsColumn = (cell) => {
    return (
      <React.Fragment>
        {" "}
        {cell.map((a) => (
          <span className="bg-light p-1 m-1 d-inline-flex">{`${a.name}`} </span>
        ))}
      </React.Fragment>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: t("locationDashboard.bulkRequestsPage.table.name"),
      sort: true,
      formatter: NameColumn,
    },
    {
      dataField: "tags",
      isDummyColumn: true,
      text: t("locationDashboard.bulkRequestsPage.table.tags"),
      sort: false,
      classes: "table-action",
      formatter: TagsColumn,
    },
    {
      dataField: "status",
      text: t("locationDashboard.bulkRequestsPage.table.status"),
      formatter: StatusColumn,
    },
    {
      dataField: "nextExecutionTime",
      text: t("locationDashboard.bulkRequestsPage.table.scheduledDate"),
      formatter: ScheduledDateColumn,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.bulkRequestsPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={requests}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
        defaultSorted={[{ dataField: "updatedAt", order: "desc" }]}
      />
    </>
  );
};

export default BulkRequestTable;
