import React, { useState } from "react";

import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import format from "date-fns/format";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import useOpportunity from "../hooks/useOpportunity";

const DateTimeRangeDropdown = () => {
  const { dateFilter, setDateFilter } = useOpportunity();
  const handleSelect = (ranges) => {
    setDateFilter({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };
  const [dropdownOpen, setDropdown] = useState(false);
  const toggleDropdown = () => {
    setDropdown(!dropdownOpen);
  };
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="app-search d-none d-lg-block"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="dropdown-toggle btn btn-link"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {dateFilter.startDate && dateFilter.endDate
          ? `${format(dateFilter.startDate, "d MMM")} - ${format(
              dateFilter.endDate,
              "d MMM"
            )}`
          : "Select date range"}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-animated">
        <DateRangePicker
          ranges={[
            {
              startDate: dateFilter.startDate || new Date(),
              endDate: dateFilter.endDate || new Date(),
              key: "selection",
            },
          ]}
          onChange={handleSelect}
        />
      </DropdownMenu>
    </Dropdown>
  );
};

export default DateTimeRangeDropdown;
