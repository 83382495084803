import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const TriggerLinkInput = ({
  onChange,
  value = "",
  error: passedError,
  label = "Trigger Link",
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchTriggerLinksLoading,
    error: fetchTriggerLinksError,
  } = useFetchApi(
    api.locations.triggerLinks.get,
    {
      locationId,
    },
  );
  if (fetchTriggerLinksLoading) return <Loading />;
  return (
    <Form.Group controlId="forTriggerLinkId">
      {fetchTriggerLinksError && <Error error={fetchTriggerLinksError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name="linkId"
        as="select"
        value={value || ""}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>Trigger Link</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TriggerLinkInput;
