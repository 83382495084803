import React from "react";
import timeSelectors from "../../../constants/timeSelectors";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TimeSelectorInput({
  name,
  handleChange,
  value,
  error,
}) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formTimeSelector">
      <Form.Control
        name={name}
        as="select"
        value={value || "placeholder"} // Set this to select the disabled option - react hack
        onChange={handleChange}
        isInvalid={error}
      >
        <option disabled value="placeholder">
          Select
        </option>
        {timeSelectors.map((timeSelector) => (
          <option key={timeSelector} value={timeSelector}>
            {timeSelector}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
