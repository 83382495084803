import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "grapesjs/dist/css/grapes.min.css";
import GrapesJS from "grapesjs";
import gjsBasicBlocks from "grapesjs-blocks-basic";
import gjsPresetNewsLetter from "grapesjs-preset-newsletter";
import de from "grapesjs/locale/de";
import en from "grapesjs/locale/en";
import { apiDomainUrl } from "../../../../../api";

export default function EmailTemplateEditor({
  setConfig,
  setModal,
  config = {},
}) {
  const { locationId } = useParams();
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (!editor) {
      const { html, style, css, components, assets = [] } = config;
      const e = GrapesJS.init({
        container: `#example-editor`,
        plugins: [gjsBasicBlocks, gjsPresetNewsLetter],
        components: components || html,
        style: style || css,
        storageManager: {
          autoload: false,
          autosave: false,
        },
        i18n: {
          locale: "de", // default locale
          detectLocale: true,
          messages: { de, en },
        },
        assetManager: {
          assets,
          autoAdd: 1,
          multiUpload: false,
          upload: `${apiDomainUrl}/v1/locations/${locationId}/email-templates/uploadPhoto`,
          uploadName: "file",
        },
      });
      e.Panels.addButton("options", {
        id: "undo",
        className: "btn-toggle-borders",
        label: "<i class='dripicons-chevron-left'></i>",
        command: "core:undo", // Built-in command
      });
      e.Panels.addButton("options", {
        id: "redo",
        className: "btn-toggle-borders",
        label: "<i class='dripicons-chevron-right'></i>",
        command: "core:redo", // Built-in command
      });
      e.Panels.addButton("options", {
        id: "save",
        className: "btn-toggle-borders",
        label: "<i class='dripicons-clipboard'></i>",
        command: {
          run: function (editor) {
            setConfig({
              html: editor.getHtml(),
              css: editor.getCss(),
              components: editor.getComponents(),
              style: editor.getStyle(),
              assets: editor.AssetManager.getAll().models.map((m) => ({
                type: m.attributes.type,
                src: m.attributes.src,
              })),
            });
            setModal(true);
          },
        },
      });
      setEditor(e);
    }
  }, [config, editor, locationId, setConfig, setModal]);

  return <div id="example-editor" />;
}
