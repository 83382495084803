import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col,  Button } from "react-bootstrap";
import StepHeader from "../StepHeader";
import useImport from "../hooks/useImport";
import duplicateStrategies from "../constants/duplicateStrategies";

export default function FieldMappingPreviewStep({
  next,
  previous,
  handleClose,
}) {
  const { t } = useTranslation();
  const {  strategy, setStrategy } = useImport();
  const handleNext = async () => {
    next();
  };
  return (
    <Container fluid={true} className="text-center">
      <Row>
        <Col>
          <StepHeader
            title={t("locationDashboard.contactsPage.importModal.step4.title")}
            number={4}
            text={t("locationDashboard.contactsPage.importModal.step4.title")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            {duplicateStrategies.map((f) => (
              <div className="custom-control custom-radio text-left m-2">
                <input
                  type="radio"
                  id={f.key}
                  name="strategy"
                  className="custom-control-input"
                  checked={strategy === f.key}
                  onChange={() => {
                    console.log(f);
                    setStrategy(f.key);
                  }}
                />
                <label className="custom-control-label" htmlFor={f.key}>
                  {f.label}
                </label>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={previous}
            className="btn-lg btn-block"
            variant="dark"
          >
            {t("locationDashboard.contactsPage.importModal.step4.previous")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleClose}
            className="btn-lg btn-block"
            variant="light"
          >
            {t("locationDashboard.contactsPage.importModal.step4.cancel")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleNext}
            className="btn-lg btn-block"
            variant="primary"
          >
            {t("locationDashboard.contactsPage.importModal.step4.submit")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
