import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams, withRouter } from "react-router-dom";
import * as ROUTES from "../../../../../../../constants/routes";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInCalendarDays";
import { useTranslation } from "react-i18next";
import api from "../../../../../../../common/api";
import ConfirmationModal from "../../../../../../ConfirmationModal";
import Error from "../../../../../../Error";
import Loading from "../../../../../../Loading";
import { CustomInput } from "reactstrap";

function TaskCard({ task, reload, history }) {
  const { locationId, contactId } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const days = differenceInDays(parseISO(task.dueDate), new Date());
  const handleDeletion = async () => {
    setLoading(true);
    try {
      await api.locations.tasks.deleteById({ taskId: task.id, locationId });
      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const handleComplete = async () => {
    setLoading(true);
    try {
      if (task.status !== "completed") {
        await api.locations.tasks.completeById({ taskId: task.id, locationId });
      } else {
        await api.locations.tasks.openById({ taskId: task.id, locationId });
      }

      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <Row className="mb-2">
        <Col lg={1} md={1} sm={1} xs={1}>
          <CustomInput
            type="checkbox"
            id={`status`}
            checked={task.status === "completed"}
            onClick={handleComplete}
          />
        </Col>
        <Col lg={9} md={9} sm={9} xs={9}>
          <span className="font-weight-bold font-13">{task.title}</span>{" "}
          <span
            className={days > 0 ? "font-11 text-danger" : "font-11 text-red"}
          >
            {days > 0
              ? t("locationDashboard.contactShowPage.tasksPage.card.dueDate", {
                  days,
                })
              : days === 0
              ? t("locationDashboard.contactShowPage.tasksPage.card.dueToday")
              : t("locationDashboard.contactShowPage.tasksPage.card.lateDate", {
                  days: days * -1,
                })}
          </span>
          <br />
          <span className="font-11">{task.description}</span>
          <br />
          <span className="font-11">
            {t("locationDashboard.contactShowPage.tasksPage.card.due")}
          </span>
          <span className="font-11 font-weight-bold">
            {format(parseISO(task.dueDate), "d MMM yyyy, HH:mm a")}
          </span>
          <br />
          <span className="font-11">
            {t("locationDashboard.contactShowPage.tasksPage.card.assigned")}
          </span>
          {task.account && (
            <span className="font-11 font-weight-bold">
              {task.account.name}
            </span>
          )}
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <i
            className="dripicons-document-edit clickable m-1"
            onClick={() =>
              history.push(
                ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_EDIT.as({
                  taskId: task.id,
                  locationId,
                  contactId,
                })
              )
            }
          ></i>
          <ConfirmationModal
            text={t(
              "locationDashboard.contactShowPage.tasksPage.deleteModal.text"
            )}
            title={t(
              "locationDashboard.contactShowPage.tasksPage.deleteModal.title"
            )}
            button={<i className="dripicons-document-delete clickable m-1"></i>}
            callback={handleDeletion}
          />
        </Col>
      </Row>
    </>
  );
}
export default withRouter(TaskCard);
