import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import * as ROUTES from "../../../../constants/routes";
import parseAddress from "../../../../common/functions/parseAddress";

const LocationCard = ({ location }) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body className="p-4">
        <Row>
          <Col lg={3} md={3} sm={12} sx={12}>
            {" "}
            <Link
              to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as({
                locationId: location.id,
              })}
            >
              <img
                src={location.logoUrl}
                alt=""
                style={{ maxHeight: 70, maxWidth: 220 }}
              />
            </Link>
          </Col>
          <Col lg={9} md={9} sm={12} sx={12} className="m-auto">
            <Link
              to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as({
                locationId: location.id,
              })}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h4 className="font-weight-bold">{location.name}</h4>
            </Link>

            <p className="text-muted">{parseAddress(location.address)}</p>
          </Col>{" "}
        </Row>
      </Card.Body>

      <Card.Footer className="text-right">
        {" "}
        <Link
          to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as({ locationId: location.id })}
        >
          {t("locationsPage.locationCard.showLink")}
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default LocationCard;
