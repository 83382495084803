import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../react-check-auth";
import ModalButton from "../../../../ModalButton";
import BulkRequestCreateModal from "../BulkRequestCreateModal";
import useBulkRequest from "../hooks/useBulkRequest";

const BulkRequestSearchBar = () => {
  const { userInfo } = useCheckAuth();
  const { reload } = useBulkRequest();
  const { t } = useTranslation();

  return (
    <Container fluid={true}>
      <Row>
        <Col sm={12}>
          <h2 className="d-inline-block">
            {t("locationDashboard.bulkRequestsPage.title")}
          </h2>

          <ModalButton
            className="m-1 float-right"
            modal={<BulkRequestCreateModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.bulkRequestsPage.addButton")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default BulkRequestSearchBar;
