import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const EmailTemplateInput = ({
  onChange,
  value = "",
  error: passedError,
  label,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchEmailTemplatesLoading,
    error: fetchEmailTemplatesError,
  } = useFetchApi(api.locations.emailTemplates.get, {
    locationId,
  });
  if (fetchEmailTemplatesLoading) return <Loading />;
  return (
    <Form.Group controlId="forEmailTemplateId">
      {fetchEmailTemplatesError && <Error error={fetchEmailTemplatesError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name="emailTemplateId"
        as="select"
        value={value || ""}
        onChange={(e) => {
          onChange(
            e,
            data.find((d) => d.id === e.target.value)
          );
        }}
        isInvalid={!!passedError}
      >
        <option>Email Template</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default EmailTemplateInput;
