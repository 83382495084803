import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import ModalButton from "../../../../../ModalButton";
import useService from "../hooks/useVoiceService";
import ServiceCreateModal from "../VoiceServiceCreateModal";

const ServiceSearchBar = () => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const { reload } = useService();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.settingsPage.smsPage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            modal={<ServiceCreateModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.settingsPage.smsPage.addService")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceSearchBar;
