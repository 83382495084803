import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";

import UserRoles from "./UserRoles";
import UsersInfo from "./UserInfo";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  name: yup.string().required("validation.name.required").max(64,"validation.name.maxLength"),
  email: yup
    .string()
    .email("validation.email.valid")
    .required("validation.email.required").max(64,"validation.name.maxLength"),
  type: yup.string().required("validation.type.required"),
  role: yup.string().required("validation.role.required"),
});

const EmployeeForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <UsersInfo values={values} errors={errors} handleChange={handleChange} />
      <UserRoles
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        handleChange={handleChange}
        extended={values.action === "edit"}
      />
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("settingsPage.agencyTeamManagementPage.employeeForm.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("settingsPage.agencyTeamManagementPage.employeeForm.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(EmployeeForm);
