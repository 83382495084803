import queryString from "query-string";
import api from "../../../api";

export default function updateById({ locationId }, query) {
  return api.get(
    `/locations/${locationId}${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
