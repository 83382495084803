import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import api from "../../../common/api/index";
import MessageForm from "./MessageForm/index";
import useChat from "../../../hooks/useChat";
import Error from "../../Error/index";
import Loading from "../../Loading/index";
import pickConversation from "../../../common/pickers/pickConversation";
import pickMessage from "../../../common/pickers/pickMessage";

const MessageModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { getChats } = useChat();
  const { locationId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      if (callback) callback();
      const { data: conversation } = await api.locations.conversations.create(
        { locationId },
        pickConversation(values)
      );
      const message = await api.locations.conversations.messages.create(
        {
          conversationId: conversation.id,
          locationId,
        },
        {
          ...pickMessage(values),
          senderType: "location",
          contactId: conversation.contactId,
        }
      );
      getChats();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("locationDashboard.conversationPage.messageModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <MessageForm
              {...{ contact: {}, content: {} }}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MessageModal;
