import pick from "lodash/pick";
import pickSource from "./pickSource";
import removeEmptyStringsWithNull from "../functions/removeEmptyStrings";

export default (data) => {
  const result = pick(data, [
    "email",
    "firstName",
    "lastName",
    "phone",
    "tags",
    "birthDate",
    "sourceId",
    "source",
    "company",
    "notes",
    "address",
    "title",
    "academicTitle",
    "customFields",
  ]);
  if (result.source) {
    result.source = { ...pickSource(result.source) };
    if (data.source) {
      if (data.source.id) {
        result.source.id = data.source.id;
      }
    }
  }
  return removeEmptyStringsWithNull(result);
};
