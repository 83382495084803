import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import api from "../../../../../common/api";
import TagTable from "./TagTable";
import TagContext from "./contexts/TagContext";
import TagSearchBar from "./TagSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const LocationTagSettingsPage = () => {
  const { locationId } = useParams();
  const {
    data: tags,
    loading: fetchTagsLoading,
    error: fetchTagsError,
    getData,
  } = useFetchApi(api.locations.tags.get, {
    locationId,
  });
  return (
    <TagContext.Provider
      value={{
        tags,
        reload: getData,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <TagSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchTagsError && <Error error={fetchTagsError} />}
            {fetchTagsLoading ? <Loading /> : tags && <TagTable />}
          </Col>
        </Row>
      </Container>
    </TagContext.Provider>
  );
};
export default withSubscriptionCheck(withLocationUser(LocationTagSettingsPage));
