import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import format from "date-fns/format";
import Loading from "../../Loading/index";
import UserMessage from "./UserMessage/index";
import MessageForm from "./MessageForm";
import get from "lodash/get";
import useChat from "../../../hooks/useChat";
import pickMessage from "../../../common/pickers/pickMessage";
import api from "../../../common/api";
import Error from "../../Error";
import groupByDate from "../../../common/functions/groupByDate";

export default function ChatArea() {
  const { locationId } = useParams();
  const messagesDiv = useRef();
  const [loading] = useState(false);
  const [error, setError] = useState(null);

  const {
    messages,
    selectedChat,

    setReplyMessage,
    replyMessage,
  } = useChat();

  const sendChatMessage = async (values) => {
    setError(null);
    try {
      if (replyMessage) {
        values.content.inReplyTo = replyMessage.meta.messageId;
        if (
          get(replyMessage, "content.subject") &&
          get(replyMessage, "content.subject").includes("Re:")
        ) {
          values.content.subject = `${
            get(replyMessage, "content.subject") || ""
          }`;
        } else {
          values.content.subject = `Re: ${
            get(replyMessage, "content.subject") || ""
          }`;
        }
      }

      await api.locations.conversations.messages.create(
        {
          conversationId: selectedChat.id,
          locationId,
        },
        {
          ...pickMessage(values),
          senderType: "location",
          contactId: selectedChat.contactId,
        }
      );
    } catch (e) {
      setError(e);
    }
    setReplyMessage(null);
  };

  useEffect(() => {
    messagesDiv.current.scrollTo(0, messagesDiv.current.scrollHeight);
  }, [messages]);

  const groupedByDateMessages = groupByDate(messages, "sentAt");

  return (
    <>
      {loading && <Loading />}

      <SimpleBar
        scrollableNodeProps={{ ref: messagesDiv }}
        style={{
          flex: "1",
          minHeight: "0",
        }}
      >
        <ul className="conversation-list">
          {groupedByDateMessages.map(({ items: messages, date }) => (
            <>
              <li className="text-center font-weight-bold">
                <span className="bg-light p-1">
                  {format(date, "dd.MM.yyyy")}
                </span>
              </li>
              {messages.map((message, index) => (
                <UserMessage key={index} message={message} />
              ))}
            </>
          ))}
        </ul>
      </SimpleBar>
      <div className="bg-light p-3 rounded">
        {error && <Error error={error} />}
        <MessageForm
          {...{ content: { text: "" } }}
          onSubmit={sendChatMessage}
        />
      </div>
    </>
  );
}
