import React, { useState } from "react";
import { Table, Col, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import ConfirmationModal from "../../../../../ConfirmationModal";
import EmployeeEditModal from "../EmployeeEditModal";
import ModalButton from "../../../../../ModalButton";
import useEmployee from "../hooks/useEmployee";
import searchEmployees from "../../../../../../common/search/searchEmployees";

const EmployeeTable = () => {
  const { t } = useTranslation();
  const { employees, nameFilter } = useEmployee();
  const data = searchEmployees(employees, nameFilter);
  const { userInfo } = useCheckAuth();
  const { reload } = useEmployee();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (accountId) => {
    setLoading(true);
    try {
      await api.accounts.deleteById({ accountId });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <Table>
        <thead className="thead-light">
          <tr>
            <th>
              {t(
                "locationDashboard.settingsPage.teamManagementPage.table.name"
              )}
            </th>
            <th>
              {" "}
              {t(
                "locationDashboard.settingsPage.teamManagementPage.table.email"
              )}
            </th>
            <th>
              {" "}
              {t(
                "locationDashboard.settingsPage.teamManagementPage.table.role"
              )}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((record) => (
            <tr>
              <td>{record.name}</td>
              <td>{record.email}</td>
              <td>{record.role}</td>
              <td>
                <Row>
                  <Col>
                    <ConfirmationModal
                      text={t(
                        "locationDashboard.settingsPage.teamManagementPage.deleteModal.text"
                      )}
                      title={t(
                        "locationDashboard.settingsPage.teamManagementPage.deleteModal.title"
                      )}
                      button={
                        <Button
                          disabled={
                            record.id === userInfo.id || record.role === "admin"
                          }
                          variant="danger"
                        >
                          {t(
                            "locationDashboard.settingsPage.teamManagementPage.table.delete"
                          )}
                        </Button>
                      }
                      callback={() => handleDelete(record.id)}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <ModalButton
                      modal={<EmployeeEditModal />}
                      button={
                        <Button
                          disabled={
                            record.id === userInfo.id || record.role === "admin"
                          }
                        >
                          {t(
                            "locationDashboard.settingsPage.teamManagementPage.table.edit"
                          )}
                        </Button>
                      }
                      callback={reload}
                      id={record.id}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default EmployeeTable;
