export default [
  { title: "00:00", value: 0 },
  { title: "01:00", value: 3600000 },
  { title: "02:00", value: 7200000 },
  { title: "03:00", value: 10800000 },
  { title: "04:00", value: 14400000 },
  { title: "05:00", value: 18000000 },
  { title: "06:00", value: 21600000 },
  { title: "07:00", value: 25200000 },
  { title: "08:00", value: 28800000 },
  { title: "09:00", value: 32400000 },
  { title: "10:00", value: 36000000 },
  { title: "11:00", value: 39600000 },
  { title: "12:00", value: 43200000 },
  { title: "13:00", value: 46800000 },
  { title: "14:00", value: 50400000 },
  { title: "15:00", value: 54000000 },
  { title: "16:00", value: 57600000 },
  { title: "17:00", value: 61200000 },
  { title: "18:00", value: 64800000 },
  { title: "19:00", value: 68400000 },
  { title: "20:00", value: 72000000 },
  { title: "21:00", value: 75600000 },
  { title: "22:00", value: 79200000 },
  { title: "23:00", value: 82800000 },
  { title: "24:00", value: 86400000 },
];
