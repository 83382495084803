import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useParams, withRouter } from "react-router-dom";
import { CustomInput } from "reactstrap";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import differenceInDays from "date-fns/differenceInCalendarDays";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../../../../constants/routes";
import api from "../../../../../common/api";
import Error from "../../../../Error";
import Loading from "../../../../Loading";

function TaskCard({ task, reload }) {
  const { locationId } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useTranslation();
  const days = differenceInDays(parseISO(task.dueDate), new Date());
  const handleComplete = async () => {
    setLoading(true);
    try {
      if (task.status !== "completed") {
        await api.locations.tasks.completeById({ taskId: task.id, locationId });
      } else {
        await api.locations.tasks.openById({ taskId: task.id, locationId });
      }

      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <Row className="m-1">
        <Col lg={1} md={1} sm={1} xs={1}>
          <CustomInput
            type="checkbox"
            id={`status`}
            checked={task.status === "completed"}
            onClick={handleComplete}
          />
        </Col>
        <Col lg={7} md={7} sm={7} xs={7}>
          <Link
            to={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.as({
              contactId: task.contactId,
              locationId,
            })}
          >
            <span className="font-weight-bold font-14">{task.title}</span>
          </Link>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          {task.contact && (
            <Link
              to={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.as({
                contactId: task.contactId,
                locationId,
              })}
            >
              <span className="font-13">
                <i className="uil uil-user-circle font-16 mr-1"></i>{" "}
                {task.contact.fullName}
              </span>
            </Link>
          )}
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <span className="font-11 font-weight-bold">
            <i className="uil uil-schedule font-16 mr-1"></i>{" "}
            {format(parseISO(task.dueDate), "d MMM yyyy, HH:mm a")}
          </span>
        </Col>
      </Row>
    </>
  );
}
export default withRouter(TaskCard);
