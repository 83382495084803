import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../../../../../common/api";
import TagsForm from "../TagsForm";
import Error from "../../../../../Error/index";
import Loading from "../../../../../Loading/index";

const RemoveTagsModal = ({ handleClose, callback,  show, ids }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    if (!values.tags) {
      handleClose();
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await api.locations.tags.unrelate(
        { locationId },
        { contactIds: ids, tagIds: values.tags.map((v) => v.id) }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.contactsPage.removeTagsModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <p> {t("locationDashboard.contactsPage.removeTagsModal.text")}</p>
            <TagsForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default RemoveTagsModal;
