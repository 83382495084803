import React from "react";
import methods from "../../../constants/methods";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function MethodInput({ name, handleChange, value, error }) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formMethod">
      <Form.Label>{t("formElements.method.label")}</Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value||""}
        onChange={handleChange}
        isInvalid={error}
      >
        <option>{t("formElements.method.option")}</option>
        {methods.map((method) => (
          <option key={method} value={method}>
            {method}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
