import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../AuthorizedRoute";

import * as ROUTES from "../../../constants/routes";
import MainPage from "./MainPage";
import SettingsPage from "./SettingsPage";
import OpportunitiesPage from "./OpportunitiesPage";
import ConversationsPage from "./ConversationsPage";
import BulkRequestsPage from "./BulkRequestsPage";
import TriggerLinksPage from "./TriggerLinksPage";
import TasksRouter from "./TasksRouter";
import ContactRouter from "./ContactRouter";
import AutomationsRouter from "./AutomationsRouter";
import EmailTemplatesRouter from "./EmailTemplatesRouter";
import withLocation from "./hocs/withLocation";

function LocationRouter() {
  return (
    <Switch>
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_DASHBOARD.href}
        component={MainPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_SETTINGS.href}
        component={SettingsPage}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_OPPORTUNITIES.href}
        component={OpportunitiesPage}
      />{" "}
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_BULK_REQUESTS.href}
        component={BulkRequestsPage}
      />{" "}
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_TRIGGER_LINKS.href}
        component={TriggerLinksPage}
      />{" "}
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONVERSATIONS.href}
        component={ConversationsPage}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS.href}
        component={ContactRouter}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS.href}
        component={AutomationsRouter}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_TASKS.href}
        component={TasksRouter}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES.href}
        component={EmailTemplatesRouter}
      />
    </Switch>
  );
}

export default withLocation(LocationRouter);
