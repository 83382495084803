import React, { useState } from "react";
import { Card, Form, Row, Col, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useContact from "../hooks/useContact";
import CampaignFilter from "./CampainFilter";
import CreatedFilter from "./CreatedFilter";
import UpdatedFilter from "./UpdatedFilter";
import PhoneFilter from "./PhoneFilter";
import EmailFilter from "./EmailFilter";
import LastActivityFilter from "./LastActivityFilter";
import SourceFilter from "./SourceFilter";
import TagFilter from "./TagFilter";

export default function FilterBox() {
  const { setFilterBox, setBackendFilters } = useContact();
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useState([]);
  const filters = [
    {
      key: "addFilter",
      title: t("locationDashboard.contactsPage.filterBox.addFilter"),
    },
    {
      key: "campaign",
      title: "Campaign",
      form: (
        <CampaignFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "campaign"));
          }}
        />
      ),
    },
    {
      key: "created",
      title: "Created",
      form: (
        <CreatedFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "created"));
          }}
        />
      ),
    },
    {
      key: "updated",
      title: "Updated",
      form: (
        <UpdatedFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "updated"));
          }}
        />
      ),
    },
    {
      key: "phone",
      title: "Phone",
      form: (
        <PhoneFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "phone"));
          }}
        />
      ),
    },
    {
      key: "email",
      title: "Email",
      form: (
        <EmailFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "email"));
          }}
        />
      ),
    },
    {
      key: "lastActivity",
      title: "Last Activity",
      form: (
        <LastActivityFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "lastActivity"));
          }}
        />
      ),
    },
    {
      key: "source",
      title: "Source",
      form: (
        <SourceFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "source"));
          }}
        />
      ),
    },
    {
      key: "tag",
      title: "Tag",
      form: (
        <TagFilter
          onClose={() => {
            setActiveFilters(activeFilters.filter((f) => f !== "tag"));
          }}
        />
      ),
    },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    if (value !== "addFilter") {
      if (activeFilters.find((f) => f === value)) {
      } else {
        setActiveFilters([...activeFilters, value]);
      }
    }
  };
  return (
    <Card>
      <Card.Header>
        <i
          className="dripicons-cross"
          onClick={() => {
            setFilterBox(false);
            setBackendFilters({});
          }}
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            {" "}
            <Form.Group controlId="formFilters">
              <Form.Label>
                {t("locationDashboard.contactsPage.filterBox.addFilter")}
              </Form.Label>
              <Form.Control
                as="select"
                value={"addFilter"}
                onChange={handleChange}
              >
                {filters.map((filter) => (
                  <option key={filter.key} value={filter.key}>
                    {filter.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion>
              {activeFilters.map(
                (f) => filters.find((filter) => filter.key === f).form
              )}
            </Accordion>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
