import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../../../react-check-auth";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import isAgencyUser from "../../../../../../common/functions/isAgencyUser";
import LocationInput from "../../../../../Form/LocationInput";
import JsonUpload from "../../../../../Form/JsonUpload";
import Error from "../../../../../Error";

const schema = yup.object({
  name: yup.string().required("validation.name.required"),
  enabled: yup.boolean(),
});

const AutomationForm = ({
  onCancel,
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>
          {t("locationDashboard.automationsPage.form.name.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationsPage.form.name.placeholder"
          )}
          value={get(values, "name") || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!get(errors, "name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "name"))}
        </Form.Control.Feedback>
      </Form.Group>
      {isAgencyUser(userInfo) && (
        <LocationInput
          onChange={handleChange}
          value={get(values, "locationId" || "")}
          error={get(errors, "locationId") || ""}
        />
      )}
      {values.action === "import" && (
        <>
          {error && <Error error={error} />}
          <JsonUpload
            onFileUpload={(files) => {
              const file = files[files.length - 1];
              let reader = new FileReader();

              reader.readAsText(file);

              reader.onload = function () {
                try {
                  const automation = JSON.parse(reader.result);
                  setFieldValue("name", automation.name);
                  setFieldValue("uiConfig", automation.uiConfig);
                  setFieldValue("description", automation.description);
                  setFieldValue("enabled", automation.enabled);
                } catch (e) {
                  setError(reader.error);
                }
              };

              reader.onerror = function () {
                setError(reader.error);
              };
            }}
          />
        </>
      )}
      <Form.Group>
        {" "}
        <Form.Check
          type="switch"
          label={t("locationDashboard.automationsPage.form.active.label")}
          id="custom-switch"
          onChange={handleChange}
          name="enabled"
          value={get(values, "enabled")}
          checked={get(values, "enabled")}
        />
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.automationsPage.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.automationsPage.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AutomationForm);
