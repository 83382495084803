import React, { useState } from "react";
import { Form, Col, Container, Row, Card, Button } from "react-bootstrap";
import { useCheckAuth } from "../../../../../../../react-check-auth";
import Loading from "../../../../../../Loading";
import api from "../../../../../../../common/api";
import Error from "../../../../../../Error";
import useFetchApi from "../../../../../../../hooks/useFetchArrayApi";
import parseAddress from "../../../../../../../common/functions/parseAddress";
import { useTranslation } from "react-i18next";

const LocationsInput = ({ setFieldValue, value = [], error: passedError }) => {
  const { t } = useTranslation();
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const { userInfo } = useCheckAuth();
  const { data, loading, error } = useFetchApi(api.agencies.getLocations, {
    agencyId: userInfo.agency.id,
  });
  if (loading) return <Loading />;
  return (
    <>
      <Form.Group controlId="forLocations">
        {error && <Error error={error} />}
        <Form.Label>
          {t(
            "settingsPage.agencyTeamManagementPage.employeeForm.userRoles.locations.label"
          )}
        </Form.Label>
        <Form.Control
          name="location"
          as="select"
          value={selectedLocationId}
          onChange={(e) => {
            if (e.target.value && e.target.value !== "Choose...") {
              setSelectedLocationId(e.target.value);
              if (!value.find((l) => l.id === e.target.value))
                setFieldValue("locations", [
                  ...value,
                  data.find((l) => l.id === e.target.value),
                ]);
            }
          }}
          isInvalid={!!passedError}
        >
          <option>Choose...</option>
          {data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {passedError}
        </Form.Control.Feedback>
      </Form.Group>
      <Container fluid={true}>
        <Row>
          <Col> </Col>
        </Row>
        <Row>
          <Col>
            {value.map((location) => (
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={9} md={9} sx={12} sm={12}>
                      {" "}
                      <h4 className="font-weight-bold">{location.name}</h4>
                      <p className="text-muted">
                        {parseAddress(location.address)}
                      </p>
                    </Col>
                    <Col lg={3} md={3} sx={12} sm={12}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setFieldValue(
                            "locations",
                            value.filter((l) => l.id !== location.id)
                          );
                        }}
                      >
                        {t(
                          "settingsPage.agencyTeamManagementPage.employeeForm.userRoles.locations.delete"
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LocationsInput;
