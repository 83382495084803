import get from "lodash/get";

function searchConversations(
  conversations,
  conversationFilter,
) {
  const result = conversations.filter(
    (e) =>
      (get(e, "contact.fullName") &&
        e.contact.fullName
          .toLowerCase()
          .includes(conversationFilter.toLowerCase())) ||
      (get(e, "contact.phone") &&
        e.contact.phone
          .toLowerCase()
          .includes(conversationFilter.toLowerCase()))
  );
  return result;
}
export default searchConversations;
