import create from "./create";
import updateById from "./updateById";
import deleteById from "./deleteById";
import getById from "./getById";
import get from "./get";
import read from "./read";
import unread from "./unread";
import messages from "./messages";

export default {
  create,
  updateById,
  deleteById,
  getById,
  get,
  messages,
  read,
  unread,
};
