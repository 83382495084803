import pick from "lodash/pick";
import removeEmptyStringsWithNull from "../functions/removeEmptyStrings";

export default function (company) {
  return removeEmptyStringsWithNull(
    pick(company, [
      "id",
      "name",
      "email",
      "phone",
      "websiteUrl",
      "address",
      "timezone",
    ])
  );
}
