import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import CountryInput from "../../../../../Form/CountryInput";
import TimeZoneInput from "../../../../../Form/TimeZoneInput";
import { useCheckAuth } from "../../../../../../react-check-auth";

const schema = yup.object({
  address: yup.object({
    street: yup.string().nullable(),
    city: yup.string().nullable(),
    postcode: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
  }),
  // TODO: Remove nullable when all entries are fixed in DB
  timezone: yup.string().default("Europe/Berlin"),
});

const AddressForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,

  setFieldValue,
}) => {
  useCheckAuth();
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formStreet">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.street.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.street.placeholder"
          )}
          value={get(values, "address.street") || ""}
          name="address.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.street")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.street"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formCity">
        <Col>
          <Form.Label>
            {t(
              "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.city.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.city.placeholder"
            )}
            value={get(values, "address.city") || ""}
            name="address.city"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.city")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.city"))}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.postcode.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.postcode.placeholder"
            )}
            value={get(values, "address.postcode") || ""}
            name="address.postcode"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.postcode")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.postcode"))}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group controlId="formState">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.state.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.state.placeholder"
          )}
          value={get(values, "address.state") || ""}
          name="address.state"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.state")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.state"))}
        </Form.Control.Feedback>
      </Form.Group>
      <CountryInput
        value={get(values, "address.country") || ""}
        name="address.country"
        handleChange={handleChange}
        error={!!get(errors, "address.country")}
      />
      <TimeZoneInput
        value={get(values, "timezone") || "Europe/Berlin"}
        name="timezone"
        handleChange={(c) => {
          // Custom library response
          setFieldValue("timezone", c.value)
        }}
        error={!!get(errors, "timezone")}
      />
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.settingsPage.companySettingsPage.addressDataCard.form.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel"]));
  },
  validationSchema: schema,
})(AddressForm);
