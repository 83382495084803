import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../react-check-auth";
import api from "../../../../common/api";
import TriggerLinkTable from "./TriggerLinkTable";
import TriggerLinkContext from "./contexts/TriggerLinkContext";
import TriggerLinkSearchBar from "./TriggerLinkSearchBar";
import useFetchApi from "../../../../hooks/useFetchArrayApi";
import Error from "../../../Error";
import Loading from "../../../Loading";
import withLocationUser from "../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";

const LocationTriggerLinksPage = () => {
  const { locationId } = useParams();
  useCheckAuth();
  const {
    data: triggerLinks,
    loading: fetchTriggerLinksLoading,
    error: fetchTriggerLinksError,
    getData,
  } = useFetchApi(api.locations.triggerLinks.get, {
    locationId,
  });
  return (
    <TriggerLinkContext.Provider
      value={{
        triggerLinks,
        reload: getData,
      }}
    >
      <Container className="mt-2" fluid={true}>
        <Row>
          <Col>
            <TriggerLinkSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchTriggerLinksError && <Error error={fetchTriggerLinksError} />}
            {fetchTriggerLinksLoading ? (
              <Loading />
            ) : (
              triggerLinks && <TriggerLinkTable />
            )}
          </Col>
        </Row>
      </Container>
    </TriggerLinkContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationUser(LocationTriggerLinksPage)
);
