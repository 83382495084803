import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col,  Button } from "react-bootstrap";
import { useCheckAuth } from "../../../../../../react-check-auth";
import ModalButton from "../../../../../ModalButton";
import usePipeline from "../hooks/usePipeline";
import PipelineCreateModal from "../PipelineCreateModal";

const PipelineSearchBar = () => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const { reload } = usePipeline();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.settingsPage.pipelinePage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            modal={<PipelineCreateModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.settingsPage.pipelinePage.addPipeline")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default PipelineSearchBar;
