import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../../../../../common/api";
import * as ROUTES from "../../../../../../constants/routes";
import automationInstanceStatuses from "../../../../../../constants/automationInstanceStatuses";
import BootstrapTable from "react-bootstrap-table-next";
import fileDownload from "js-file-download";
import { useCheckAuth } from "../../../../../../react-check-auth/index";
import useAutomation from "../hooks/useAutomation";
import AutomationEditModal from "../AutomationEditModal";
import ModalButton from "../../../../../ModalButton";
import ConfirmationModal from "../../../../../ConfirmationModal";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import AutomationDuplicateModal from "../AutomationDuplicateModal";
import format from "date-fns/format";

const AutomationTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { userInfo } = useCheckAuth();
  const { automations, reload } = useAutomation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (automation) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.automations.deleteById({
        locationId,
        automationId: automation.id,
      });
      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const handleExport = async (automation) => {
    fileDownload(
      JSON.stringify(automation),
      `automations-${automation.name}-${format(new Date(), "d-M-y")}.json`
    );
  };
  const ActionColumn = (cell, row) => {
    return (
      <Row>
        <Col>
          <Link
            to={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS_SHOW.as({
              locationId,
              automationId: row.id,
            })}
            className="action-icon"
          >
            {" "}
            <i className="dripicons-pencil"></i>
          </Link>
        </Col>
        <Col>
          {" "}
          <ModalButton
            modal={<AutomationEditModal />}
            button={
              <span className="action-icon">
                <i
                  disabled={
                    userInfo.role === "user" && userInfo.type === "location"
                  }
                  className="action-icon dripicons-gear"
                ></i>
              </span>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
        <Col>
          <Button onClick={() => handleExport(row)}>
            {t("locationDashboard.automationsPage.table.exportButton")}
          </Button>
        </Col>
        <Col>
          {" "}
          <ModalButton
            modal={<AutomationDuplicateModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t("locationDashboard.automationsPage.table.duplicateButton")}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
        <Col>
          <ConfirmationModal
            title={t("locationDashboard.automationsPage.deleteModal.title")}
            text={t("locationDashboard.automationsPage.deleteModal.text")}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.automationsPage.table.deleteButton")}
              </Button>
            }
            callback={() => handleDelete(row)}
          />
        </Col>
      </Row>
    );
  };
  const NameColumn = (cell, row) => {
    return (
      <React.Fragment>
        <Link
          to={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS_SHOW.as({
            locationId,
            automationId: row.id,
          })}
          className="action-icon"
        >
          {cell}
        </Link>
      </React.Fragment>
    );
  };
  const StatusColumn = (cell) => {
    return (
      <div className="text-center">
        {cell === true ? (
          <div>
            <i class="mdi mdi-circle font-13 text-success mr-2"></i>
            {t("locationDashboard.automationsPage.table.active")}
          </div>
        ) : (
          <div>
            <i class="mdi mdi-circle font-13 text-danger mr-2"></i>
            {t("locationDashboard.automationsPage.table.inactive")}
          </div>
        )}
      </div>
    );
  };

  const TotalColumn = (cell, row) => {
    return <div className="text-center">{row.instances.length}</div>;
  };
  const ActiveColumn = (cell, row) => {
    return (
      <div className="text-center">
        {
          row.instances.filter(
            (i) => i.status === automationInstanceStatuses.Runnable
          ).length
        }
      </div>
    );
  };
  const CompletedColumn = (cell, row) => {
    return (
      <div className="text-center">
        {
          row.instances.filter(
            (i) =>
              i.status === automationInstanceStatuses.Complete ||
              i.status === automationInstanceStatuses.Errored ||
              i.status === automationInstanceStatuses.Canceled
          ).length
        }
      </div>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: t("locationDashboard.automationsPage.table.name"),
      sort: true,
      formatter: NameColumn,
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "total",
      isDummyColumn: true,
      text: t("locationDashboard.automationsPage.table.total"),
      sort: false,
      formatter: TotalColumn,
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "active",
      isDummyColumn: true,
      text: t("locationDashboard.automationsPage.table.active"),
      sort: false,
      formatter: ActiveColumn,
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "completed",
      isDummyColumn: true,
      text: t("locationDashboard.automationsPage.table.completed"),
      sort: false,
      formatter: CompletedColumn,
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "enabled",
      text: t("locationDashboard.automationsPage.table.status"),
      formatter: StatusColumn,
      headerStyle: () => {
        return { width: "12%", textAlign: "center" };
      },
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.automationsPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
    {
      dataField: "updatedAt",
      sort: true,
      hidden: true,
    },
  ];
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={automations}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
        defaultSorted={[{ dataField: "updatedAt", order: "desc" }]}
      />
    </>
  );
};

export default AutomationTable;
