import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";

export default function LetterExpressForm({
  handleChange,
  errors,
  values,
  isValid,
}) {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Form.Group controlId="formApiKey">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.directEmailPage.form.apiKey.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.directEmailPage.form.apiKey.placeholder"
          )}
          value={get(values, "config.apiKey") || ""}
          name="config.apiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.apiKey")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.apiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formApiKey">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.directEmailPage.form.username.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.directEmailPage.form.username.placeholder"
          )}
          value={get(values, "config.username") || ""}
          name="config.username"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.username")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.username"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="btn-block"
        variant="primary"
        type="submit"
        disabled={!isValid}
      >
        {t("locationDashboard.settingsPage.directEmailPage.form.submit")}
      </Button>
    </>
  );
}
