import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const SourceInput = ({ onChange, value = "", error: passedError, label }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchSourcesLoading,
    error: fetchSourcesError,
  } = useFetchApi(api.locations.sources.get, {
    locationId,
  });
  if (fetchSourcesLoading) return <Loading />;
  return (
    <Form.Group controlId="forSourceId">
      {fetchSourcesError && <Error error={fetchSourcesError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name="sourceId"
        as="select"
        value={value || ""}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>Source</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default SourceInput;
