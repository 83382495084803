import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import api from "../../../../../common/api";
import SourceTable from "./SourceTable";
import SourceContext from "./contexts/SourceContext";
import SourceSearchBar from "./SourceSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const LocationSourceSettingsPage = () => {
  const { locationId } = useParams();
  const {
    data: sources,
    loading: fetchSourcesLoading,
    error: fetchSourcesError,
    getData,
  } = useFetchApi(api.locations.sources.get, {
    locationId,
  });
  return (
    <SourceContext.Provider
      value={{
        sources,
        reload: getData,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <SourceSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchSourcesError && <Error error={fetchSourcesError} />}
            {fetchSourcesLoading ? <Loading /> : sources && <SourceTable />}
          </Col>
        </Row>
      </Container>
    </SourceContext.Provider>
  );
};
export default withSubscriptionCheck(withLocationUser(LocationSourceSettingsPage));
