import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchArrayApi from "../../../../hooks/useFetchArrayApi";
import DashboardContext from "./contexts/DashboardContext";
import withLocationUser from "../../../../hocs/withLocationUser";
import api from "../../../../common/api";
import OpportunitiesCard from "./OpportunitiesCard";
import PipelineValueCard from "./PipelineValueCard";
import useFetchApi from "../../../../hooks/useFetchApi";
import ContactsCard from "./ReportCard";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";
import ConversionRateCard from "./ConversionRateCard";
import FunnelChartCard from "./FunnelChartCard";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
    if (propName === "startDate") {
      obj[propName] = obj[propName].toISOString();
    }
    if (propName === "endDate") {
      obj[propName] = obj[propName].toISOString();
    }
  }
  return obj;
}

function MainPage() {
  const { locationId } = useParams();
  const [filters, setFilters] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [pipelineId, setPipelineId] = useState("");
  const { data: opportunities  } = useFetchArrayApi(
    api.locations.opportunities.get,
    { locationId },
    { ...clean(filters), eager: ["contact"] },
    [locationId, filters.startDate, filters.endDate]
  );
  const { data: sources  } = useFetchArrayApi(
    api.locations.sources.get,
    { locationId },
    null,
    [locationId]
  );
  const { data: pipeline } = useFetchApi(
    api.locations.pipelines.getById,
    { locationId, pipelineId },
    clean({ ...filters, eager: ["stages.opportunities"] }),
    [pipelineId, filters.startDate, filters.endDate, locationId],
    pipelineId !== ""
  );
  let stages = [];
  if (pipeline.stages) {
    stages = pipeline.stages.reduce((acc, cur) => [...acc, cur], []);
  }
  const { data: events } = useFetchArrayApi(
    api.locations.events.get,
    {
      locationId: locationId,
    },
    {
      stageIds: (stages || []).map((s) => s.id),
      endDate: filters.endDate.toISOString(),
      startDate: filters.startDate.toISOString(),
      eager: ["source"],
      type: "stage-change",
    },
    [filters, pipeline, locationId],
    stages.length > 0
  );
  return (
    <DashboardContext.Provider
      value={{
        filters,
        setFilters,
        opportunities,
        pipelineId,
        pipeline,
        setPipelineId,
        sources,
        stages,
        events,
      }}
    >
      <Container fluid={true} className="">
        <Row>
          <Col lg={4} md={4} sm={12} sx={12}>
            <OpportunitiesCard />
          </Col>
          <Col lg={4} md={4} sm={12} sx={12}>
            <PipelineValueCard />
          </Col>
          <Col lg={4} md={4} sm={12} sx={12}>
            <ConversionRateCard />
          </Col>
        </Row>
        {/* <Col lg={6} md={6} sm={12} sx={12}>
            <PieCard />
          </Col>
          <Col lg={6} md={6} sm={12} sx={12}></Col>*/}{" "}
        <Row>
          <Col>
            <FunnelChartCard />
          </Col>
        </Row>
        <Row>
          <Col>
            <ContactsCard />
          </Col>
        </Row>
      </Container>
    </DashboardContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(MainPage))
);
