import React from "react";
import {  useParams, withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../../../../../constants/routes";
import useAutomation from "../hooks/useAutomation";
import ConfirmationModal from "../../../../../ConfirmationModal";

function AutomationHeader({ history }) {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const {
    automation,
    
    publishAutomation,
    discardAutomation,
    saved,
  } = useAutomation();
  const handleOut = () => {
    history.push(ROUTES.AGENCY_LOCATIONS_AUTOMATIONS.as({ locationId }));
  };
  return (
    <Container fluid={true}>
      <Row>
        <Col className="d-flex">
          {saved ? (
            <Button
              variant="link"
              className="nav-link arrow-none font-24 text-light automation--back"
              onClick={handleOut}
            >
              <i className="dripicons-arrow-left"></i>
            </Button>
          ) : (
            <ConfirmationModal
              title={t(
                "locationDashboard.automationShowPage.unsavedModal.title"
              )}
              text={t("locationDashboard.automationShowPage.unsavedModal.text")}
              button={
                <Button
                  variant="link"
                  className="nav-link arrow-none font-24 text-light automation--back"
                >
                  <i className="dripicons-arrow-left"></i>
                </Button>
              }
              callback={handleOut}
            />
          )}

          <p className="font-20 font-weight-bold mt-2">{automation.name}</p>
        </Col>
        <Col></Col>
        <Col className="text-right">
          <Button className="m-1" variant={"light"} onClick={discardAutomation}>
            {t("locationDashboard.automationShowPage.discardButton")}
          </Button>
          <Button className="m-1" variant={"dark"} onClick={publishAutomation}>
            {t("locationDashboard.automationShowPage.saveButton")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
export default withRouter(AutomationHeader);
