import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import api from "../../../common/api/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

import get from "lodash/get";
import Creatable from "react-select/creatable";

const ContactInput = ({
  values,

  setFieldValue,
  errors,
  fetchSource,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { data: contacts } = useFetchApi(
    api.locations.contacts.get,
    {
      locationId,
    },
    fetchSource ? { eager: ["source"] } : null
  );
  const selectContact = (data, event) => {
    if (event.action === "select-option") {
      setFieldValue("contact", data.value);
    }
    if (event.action === "create-option") {
      setFieldValue("contact", { fullName: data.value });
    }
  };
  const options = contacts.map((c) => ({ value: c, label: c.fullName })) || [];
  return contacts ? (
    <Form.Group controlId="formContactFullName">
      <Form.Label>{t("formElements.contact.label")}</Form.Label>
      <Creatable
        value={options.find((o) => o.value.id === get(values, `contact.id`))}
        options={options}
        onChange={selectContact}
        placeholder={t("formElements.contact.placeholder")}
      />
      {get(errors, "contact") && (
        <div className="text-validation">{t(get(errors, "contact"))}</div>
      )}
    </Form.Group>
  ) : null;
};

export default ContactInput;
