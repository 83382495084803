import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default (WrappedPage) => {
  const WithStripe = ({ ...props }) => {
    return (
      <Elements stripe={stripePromise}>
        <WrappedPage {...props} />
      </Elements>
    );
  };
  return WithStripe;
};
