import React from "react";
import { useTranslation } from "react-i18next";

export default function DirectMailMessage({ message }) {
  const { t } = useTranslation();
  return (
    <>
      {t("locationDashboard.conversationPage.chatArea.message.pdfSent")}
      {"  "}
      <a
        className="link"
        target="_blank"
        href={message.meta.url}
        rel="noopener noreferrer"
      >
        {t("locationDashboard.conversationPage.chatArea.message.pdfLink")}
      </a>
    </>
  );
}
