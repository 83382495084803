import React, {  useState } from "react";
import { Link } from "react-router-dom";
import { useCheckAuth } from "../../react-check-auth/index";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import api from "../../common/api/index";
import * as ROUTES from "../../constants/routes";

const ProfileDropdown = () => {
  const { userInfo, refreshAuth } = useCheckAuth();

  const [dropdownOpen, setDropdown] = useState(false);
  const toggleDropdown = () => {
    setDropdown(!dropdownOpen);
  };
  const logOut = async () => {
    try {
      await api.session.stop();
      refreshAuth();
    } catch (e) {}
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle nav-user arrow-none mr-0"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {/*<span className="account-user-avatar">
          <img src={userInfo.imageUrl} className="rounded-circle" alt="user" />
      </span>*/}

        <span>
          <span className="account-user-name">{userInfo.name}</span>
        </span>
      </DropdownToggle>
      <DropdownMenu
        right
        className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
      >
        <div onClick={toggleDropdown}>
          <Link
            to={ROUTES.AGENCY_SETTINGS_USER.as()}
            className="dropdown-item notify-item"
          >
            <i className={`dripicons-gear mr-1`}></i>
            <span>Account Settings</span>
          </Link>
          <button className="dropdown-item notify-item" onClick={logOut}>
            <i className={`dripicons-exit mr-1`}></i>
            <span>Log out</span>
          </button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
