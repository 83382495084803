function truncateText(str, length, ending) {
  if (length == null) {
    length = 80;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } 
    return str;
  
}

export default truncateText;
