import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import { Row, Col, Card } from "react-bootstrap";
import allElements from "../../constants/elements";

export default function AutomationElements() {
  const [elementFilters, setElementFilters] = useState({ type: "trigger" });
  const { t } = useTranslation();

  const groups = [
    {
      title: t("locationDashboard.automationShowPage.elementsList.triggers"),
      isActive: elementFilters.type === "trigger",
      onChange: () => {
        setElementFilters({ type: "trigger" });
      },
    },
    {
      title: t("locationDashboard.automationShowPage.elementsList.actions"),
      isActive: elementFilters.type === "action",
      onChange: () => {
        setElementFilters({ type: "action" });
      },
    },
    {
      title: t("locationDashboard.automationShowPage.elementsList.conditions"),
      isActive: elementFilters.type === "condition",
      onChange: () => {
        setElementFilters({ type: "condition" });
      },
    },
    {
      title: t("locationDashboard.automationShowPage.elementsList.additional"),
      isActive: elementFilters.type === "additional",
      onChange: () => {
        setElementFilters({ type: "additional" });
      },
    },
  ];

  const elements = allElements.filter((e) => e.type === elementFilters.type);

  function drag(ev) {
    ev.dataTransfer.setData("node", ev.target.getAttribute("data-node"));
  }

  return (
    <Card className="p-0 m-0 shadow-none font-11 h-100">
      <Card.Body className="p-0">
        <ul className="nav nav-tabs nav-bordered">
          {groups.map((group) => (
            <li key={group.title} className="nav-item" onClick={group.onChange}>
              <Link
                className={`nav-link ${group.isActive ? "active" : ""}`}
                to={"#"}
              >
                {group.title}
              </Link>
            </li>
          ))}
        </ul>

        <div className="tab-content">
          <div className="tab-pane show active p-3">
            <SimpleBar style={{ maxHeight: "700px", width: "100%" }}>
              {elements.map((element) => {
                return (
                  <div
                    className="card drag-drawflow bg-light p-2 m-1"
                    draggable={"true"}
                    onDragStart={drag}
                    data-node={element.data}
                  >
                    <Card.Body className="p-0 m-0 ">
                      <Row>
                        <Col
                          lg={2}
                          md={2}
                          sm={12}
                          sx={12}
                          className="pt-1 font-22"
                        >
                          {element.cardIcon()}
                        </Col>
                        <Col lg={10} md={10} sm={12} sx={12}>
                          <p className="font-weight-bold p-0 m-0">
                            {t(`${element.translationKey}.title`)}
                          </p>
                          <p className="text-muted p-0 m-0">
                            {t(`${element.translationKey}.description`)}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </div>
                );
              })}
            </SimpleBar>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
