import React from "react";
import { Container, Row, Col,  Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import ModalButton from "../../../../../ModalButton";
import CustomFieldCreateModal from "../CustomFieldCreateModal";
import useCustomField from "../hooks/useCustomField";

const CustomFieldSearchBar = () => {
  const { userInfo } = useCheckAuth();
  const { reload } = useCustomField();
  const { t } = useTranslation();

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h2>{t("locationDashboard.settingsPage.customFieldsPage.title")}</h2>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            className="m-1"
            modal={<CustomFieldCreateModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                <i className="dripicons-plus"></i> {t("locationDashboard.settingsPage.customFieldsPage.addButton")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CustomFieldSearchBar;
