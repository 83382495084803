import React from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import classnames from "classnames";
import parseISO from "date-fns/parseISO";
import replaceHtml from "../../../../../common/functions/replaceHtml";

const SmsMessage = ({ message }) => {
  useTranslation();
  const sender =
    message.senderType === "location" ? message.location : message.contact;
  return (
    <li
      className={classnames("clearfix", {
        odd: message.senderType !== "contact",
      })}
    >
      <div className="chat-avatar">
        <i className={"user-message dripicons-phone"} />
        <i>{format(parseISO(message.sentAt), "H:mm")}</i>
      </div>
      <div className="conversation-text">
        <div className="ctext-wrap text-left">
          <i>
            {message.senderType === "location" ? sender.name : sender.fullName}
          </i>
          <p>{replaceHtml(message.content.text)}</p>
        </div>
      </div>
    </li>
  );
};

export default SmsMessage;
