import React from "react";
import { Card, CardBody, CardHeader, Row, Col, Container } from "reactstrap";
import { useParams } from "react-router-dom";

import ChatMessages from "../ChatMessageArea/index";
import ChatProfile from "../ChatProfile";
import MarkAsReadButton from "../MarkAsReadButton";
import MarkAsUnreadButton from "../MarkAsUnreadButton";
import useChat from "../../../hooks/useChat";

export default function ChatArea() {
  useParams();
  const { selectedChat } = useChat();

  return (
    <Card className="p-0 m-0 shadow-none h-100 d-flex flex-column">
      <CardHeader className="p-0 m-0 mb-2">
        <Row>
          <Col className="text-left">{selectedChat.fullName}</Col>
          <Col className="text-right">
            {selectedChat.id && !selectedChat.read ? (
              <MarkAsReadButton />
            ) : (
              <MarkAsUnreadButton />
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="position-relative m-0 p-0 flex-fill">
        <Container fluid={true} className="h-100">
          <Row className="h-100">
            <Col
              lg={8}
              sm={8}
              md={8}
              className="h-100 d-flex flex-column"
              style={{
                overflow: "hidden",
              }}
            >
              <ChatMessages />
            </Col>
            <Col lg={4} sm={4} md={4} sx={4} className="h-100">
              <ChatProfile />
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
}
