import React from "react";
import {    Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useContact from "../hooks/useContact";

export default function ContactPaginator() {
  const { t } = useTranslation();
  const {
    setFilters,
    filters,
    total,
    filterChange,
    numberOfPages,
  } = useContact();
  const nextCondition = filters.page * filters.limit < total;
  const previousCondition = filters.page > 1;
  const handleNext = () => {
    if (nextCondition) setFilters({ ...filters, page: filters.page + 1 });
  };
  const handlePrevious = () => {
    if (previousCondition) {
      setFilters({ ...filters, page: filters.page - 1 });
    }
  };
  return (
    <>
      {" "}
      <span style={{ marginRight: 10 }}>
        {t("locationDashboard.contactsPage.table.total", {
          total,
          numberOfPages,
          currentPage: filters.page,
        })}
      </span>
      <div className="btn-group" role="group" aria-label="First group">
        <Button
          disabled={!previousCondition}
          variant={"light"}
          onClick={handlePrevious}
        >
          {t("locationDashboard.contactsPage.table.previous")}
        </Button>{" "}
        <Button
          disabled={!nextCondition}
          variant={"light"}
          onClick={handleNext}
        >
          {t("locationDashboard.contactsPage.table.next")}
        </Button>
        <Form.Control
          name="limit"
          as="select"
          value={filters.limit}
          onChange={filterChange}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
        </Form.Control>
      </div>
    </>
  );
}
