import React from "react";
import parseISO from "date-fns/parseISO";
import DateTime from "react-datetime-picker";

const FormikDateTime = ({ field, form, value }) => {
  const onFieldChange = (value) => {
    form.setFieldValue(field.name, value);
  };
  const date = !value
    ? new Date()
    : value instanceof Date
    ? value
    : parseISO(value);
  return (
    <DateTime
      value={date}
      id={field.name}
      name={field.name}
      onChange={onFieldChange}
      disableClock={true}
    />
  );
};

export default FormikDateTime;
