import queryString from "query-string";
import api from "../../../api";

export default function getAccounts({ locationId }, query) {
  return api.get(
    `/locations/${locationId}/accounts${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
