import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Papa from "papaparse";
import FileUpload from "./FileUpload";
import useImport from "../hooks/useImport";
import StepHeader from "../StepHeader";

export default function UploadFileStep({ next }) {
  const { t } = useTranslation();
  const { setImportedContacts } = useImport();
  const handleUpload = async (file) => {
    const data = await new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          console.log("Complete", results.data.length, "records.");
          resolve(results.data);
        },
      });
    });
    setImportedContacts(data);
    next();
  };
  return (
    <Container fluid={true} className="text-center">
      <Row>
        <Col>
          <StepHeader
            title={t("locationDashboard.contactsPage.importModal.step1.title")}
            number={1}
            text={t("locationDashboard.contactsPage.importModal.step1.text")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          <FileUpload
            showPreview={false}
            onFileUpload={(files) => {
              handleUpload(files[0]);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}
