import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col,  Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import * as ROUTES from "../../../../../constants/routes";

const EmailTemplateSearchBar = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.emailTemplatePage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <Link
            to={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES_CREATE.as({
              locationId,
            })}
          >
            {" "}
            <Button>
              <i className="dripicons-plus"></i>{" "}
              {t("locationDashboard.emailTemplatePage.addEmailTemplate")}
            </Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default EmailTemplateSearchBar;
