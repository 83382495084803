import React from "react";
import { Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import api from "../../../common/api";
import Loading from "../../Loading";

export default function MessageInput({
  name = "content.text",
  values,
  errors,
  setFieldValue,
  type,
  label,
}) {
  const { locationId } = useParams();
  const { data: triggerLinks, loading: fetchTriggerLinksLoading } = useFetchApi(
    api.locations.triggerLinks.get,
    {
      locationId,
    }
  );
  const { data: customFields, loading: fetchCustomFieldsLoading } = useFetchApi(
    api.locations.customFields.get,
    {
      locationId,
    }
  );
  const { t } = useTranslation();
  if (fetchTriggerLinksLoading || fetchCustomFieldsLoading) return <Loading />;

  const init = {
    menu: {
      custom: {
        title: t("formElements.message.menubar.insertPlaceholder"),
        items: "basicitem nesteditem toggleitem triggerLinks customFields",
      },
    },
    height: 200,
    setup: function (editor) {
      editor.ui.registry.addNestedMenuItem("nesteditem", {
        text: t("formElements.message.menubar.contact.header"),
        getSubmenuItems: function () {
          return [
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.salutation"),
              onAction: function () {
                editor.insertContent("{{salutation}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.politeSalutation"),
              onAction: function () {
                editor.insertContent("{{politeSalutation}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.title"),
              onAction: function () {
                editor.insertContent("{{contact.title}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.academicTitle"),
              onAction: function () {
                editor.insertContent("{{contact.academicTitle}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.firstName"),
              onAction: function () {
                editor.insertContent("{{contact.firstName}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.lastName"),
              onAction: function () {
                editor.insertContent("{{contact.lastName}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.email"),
              onAction: function () {
                editor.insertContent("{{contact.email}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.phone"),
              onAction: function () {
                editor.insertContent("{{contact.phone}}");
              },
            },
            {
              type: "menuitem",
              text: t("formElements.message.menubar.contact.company"),
              onAction: function () {
                editor.insertContent("{{contact.company}}");
              },
            },
          ];
        },
      });
      editor.ui.registry.addNestedMenuItem("triggerLinks", {
        text: t("formElements.message.menubar.links.header"),
        getSubmenuItems: function () {
          const links = [];
          triggerLinks.forEach((link) => {
            links.push({
              type: "menuitem",
              text: link.name,
              onAction: function () {
                editor.insertContent(`{{trigger_link.${link.urlCode}}}`);
              },
            });
          });
          return [...links];
        },
      });
      editor.ui.registry.addNestedMenuItem("customFields", {
        text: t("formElements.message.menubar.customFields.header"),
        getSubmenuItems: function () {
          const links = [];
          customFields.forEach((link) => {
            links.push({
              type: "menuitem",
              text: link.name,
              onAction: function () {
                editor.insertContent(`{{custom_field.${link.key}}}`);
              },
            });
          });
          return [...links];
        },
      });
    },
    content_style:
      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
  };
  let outputFormat;
  if (type === "sms") {
    outputFormat = "text";
    init.menubar = "custom";
    init.toolbar = false;
  } else if (type === "call") {
    outputFormat = "text";
    init.menubar = "custom";
    init.toolbar = false;
  } else if (type === "letter") {
    outputFormat = "text";
    init.menubar = "custom";
    init.toolbar = false;
  } else if (type === "email") {
    outputFormat = "html";
    init.valid_children = "+body[style]";
    init.menubar = "view insert format tools custom";
    init.plugins =
      "preview importcss code fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons";
    init.toolbar =
      "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat";
  }
  return (
    <Form.Group controlId="formMessage">
      <Form.Label>{label || t("formElements.message.label")}</Form.Label>
      <Editor
        value={get(values, name)}
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
        outputFormat={outputFormat}
        init={init}
        onEditorChange={(content) => {
          setFieldValue(name, content);
        }}
      />
      <Form.Control.Feedback type="invalid">
        {get(errors, name)}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
