import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import * as ROUTES from "../../../../../constants/routes";

export default function DirectMailCard() {
    const { t } = useTranslation();
    const { locationId } = useParams();
    return (
        <div className="help-box text-white text-center">
            <h5 className="mt-3">
                {t("locationDashboard.leftSidebar.directMailCard.title")}
            </h5>
            <p className="mb-3">
                {t("locationDashboard.leftSidebar.directMailCard.text")}
            </p>
            <Link to={ROUTES.AGENCY_LOCATIONS_SETTINGS_DIRECT_EMAIL.as({ locationId })}>
                <button className="btn btn-outline-light btn-sm">
                    {t("locationDashboard.leftSidebar.directMailCard.button")}
                </button>
            </Link>
        </div>
    );
}
