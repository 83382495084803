import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import ModalButton from "../../../../../ModalButton";
import useTemplate from "../hooks/useTemplate";
import TemplateCreateModal from "../TemplateCreateModal";

const TemplateSearchBar = () => {
  const { t } = useTranslation();
  const { reload } = useTemplate();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.settingsPage.templatePage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            modal={<TemplateCreateModal />}
            button={
              <Button>
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.settingsPage.templatePage.addTemplate")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TemplateSearchBar;
