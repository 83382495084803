import React from "react";
import { Form, Button,  Row } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import AutomationInput from "../../../../../../../Form/AutomationInput";

export const schema = yup.object({
  removeMultiple: yup.boolean(),
  automationId: yup.string().when("removeMultiple", {
    is: false,
    then: yup
      .string()
      .required("validation.cancelAutomation.automationId.required"),
  }),
});

const CancelAutomationForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group>
        {" "}
        <Form.Check
          type="switch"
          label={t(
            "locationDashboard.automationShowPage.cancelAutomationForm.removeMultiple.label"
          )}
          id="custom-switch"
          onChange={handleChange}
          name="removeMultiple"
          value={get(values, "removeMultiple")}
          checked={!!get(values, "removeMultiple")}
        />
      </Form.Group>

      {!get(values, "removeMultiple") && (
        <AutomationInput
          onChange={handleChange}
          error={get(errors, "automationId")}
          value={get(values, "automationId")}
        />
      )}
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.automationShowPage.cancelAutomationForm.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(CancelAutomationForm);
