import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import SmsServiceTable from "./SmsServiceTable";
import SmsServiceContext from "./contexts/SmsServiceContext";
import SmsServiceSearchBar from "./SmsServiceSearchBar";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationAdmin from "../../../../../hocs/withLocationAdmin";
import { useCheckAuth } from "../../../../../react-check-auth";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import api from "../../../../../common/api";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const SmsSettingsPage = () => {
  useCheckAuth();
  const { locationId } = useParams();
  const {
    data: services,
    loading: fetchServicesLoading,
    error: fetchServicesError,
    getData,
  } = useFetchApi(
    api.locations.transports.get,
    { locationId },
    { type: "sms" }
  );
  return (
    <SmsServiceContext.Provider value={{ services, reload: getData }}>
      <Container fluid={true}>
        <Row>
          <Col>
            <SmsServiceSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {fetchServicesError && <Error error={fetchServicesError} />}
                {fetchServicesLoading ? (
                  <Loading />
                ) : (
                  services && <SmsServiceTable />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </SmsServiceContext.Provider>
  );
};
export default withSubscriptionCheck(withLocationAdmin(SmsSettingsPage));
