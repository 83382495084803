import React from "react";
import { useParams, withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../../constants/routes";
import useChat from "../../../hooks/useChat";
import OpportunityListInput from "../../Form/OpportunitiesListInput";
import AutomationsListInput from "../../Form/AutomationInstanceListInput";
import TagListInput from "../../Form/TagListInput";
import getContactLetters from "../../../common/functions/getContactLetters";

function ChatProfile({ history }) {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const { selectedChat } = useChat();
  const contact = selectedChat.contact;
  return (
    <>
      {contact && (
        <SimpleBar
          style={{
            maxHeight: "calc(100vh - 160px)",
          }}
        >
          <Card className="p-0 m-0 shadow-none">
            <CardBody className="">
              <div className="d-flex flex-column text-center align-items-center justify-content-center">
                <div
                  onClick={() => {
                    history.push(
                      ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.as({
                        contactId: contact.id,
                        locationId,
                      })
                    );
                  }}
                  style={{ backgroundColor: contact.color }}
                  className="circle"
                >
                  <p className="circle-content">{getContactLetters(contact)}</p>
                </div>
                <h4>{contact.fullName}</h4>
              </div>

              <div className="mt-3">
                <hr className="" />

                <p className="mt-4 mb-1">
                  <strong>
                    <i className="uil uil-at"></i>{" "}
                    {t("locationDashboard.conversationPage.chatProfile.email")}
                  </strong>
                </p>
                <p>{contact.email}</p>

                <p className="mt-3 mb-1">
                  <strong>
                    <i className="uil uil-phone"></i>{" "}
                    {t("locationDashboard.conversationPage.chatProfile.phone")}
                  </strong>
                </p>
                <p>{contact.phone}</p>

                <p className="mt-4 mb-1">
                  <strong>
                    {t(
                      "locationDashboard.conversationPage.chatProfile.company"
                    )}
                  </strong>
                </p>
                <p>{contact.company}</p>
                <TagListInput contactId={contact.id} />
                <OpportunityListInput contactId={contact.id} />

                <AutomationsListInput contactId={contact.id} />
              </div>
            </CardBody>
          </Card>
        </SimpleBar>
      )}
    </>
  );
}

export default withRouter(ChatProfile);
