import React from "react";
import { Form, Button } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import equalTo from "../../../common/yup/equalTo";
import passwordPattern from "../../../common/yup/passwordPattern";
import CountryInput from "../../Form/CountryInput";

yup.addMethod(yup.string, "equalTo", equalTo);

const schema = yup.object({
  name: yup.string().required("validation.name.required").max(64,"validation.name.maxLength"),
  email: yup.string().email().required("validation.email.required").max(64,"validation.email.maxLength"),
  //planId: yup.string().required(),
  address: yup.object({
    country: yup.string().required("validation.address.country.required"),
  }),
  password: yup
    .string()
    .required("validation.password.required")
    .matches(passwordPattern, "validation.password.pattern"),
  confirmPassword: yup
    .string()
    .equalTo(yup.ref("password"), "validation.password.equal")
    .required("validation.confirmPassword.required"),
});

const SignUpForm = ({
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Control
          type="text"
          placeholder={t("signUpPage.form.name.placeholder")}
          value={values.name || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.name)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Control
          type="email"
          placeholder={t("signUpPage.form.email.placeholder")}
          value={values.email || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.email)}
        </Form.Control.Feedback>
      </Form.Group>
      <CountryInput
        value={get(values, "address.country") || ""}
        name="address.country"
        handleChange={handleChange}
        error={!!get(errors, "address.country")}
      />
      {/*(get(values, "address.country") === "Germany" ||
        get(values, "address.country") === "Austria") && (
        <Form.Group controlId="formVatId">
          <Form.Label>
            Provide a valid EU VAT number, e.g. DE312248579
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={"Vat number"}
            value={values.vatId || ""}
            name="vatId"
            onChange={handleChange}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
      )*/}
      <Form.Group controlId="formPassword">
        <Form.Control
          type="password"
          placeholder={t("signUpPage.form.password.placeholder")}
          value={values.password || ""}
          name="password"
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.password)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Control
          type="password"
          placeholder={t("signUpPage.form.confirmPassword.placeholder")}
          value={values.confirmPassword || ""}
          name="confirmPassword"
          onChange={handleChange}
          isInvalid={!!errors.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.confirmPassword)}
        </Form.Control.Feedback>
      </Form.Group>
      {/*<Form.Group controlId="formCardElement">
        <CardElement />
      </Form.Group>
      <PlanInput
        onChange={handleChange}
        value={values.planId || ""}
        error={errors.planId}
      />*/}
      <Form.Group controlId="formBirthDate">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("signUpPage.form.submit")}
        </Button>
      </Form.Group>
      <p className="text-center text-muted mb-0">
        {" "}
        {t("signUpPage.agree")} <a href="#">{t("signUpPage.terms")}</a>{" "}
        {t("signUpPage.and")} <a href="#">{t("signUpPage.privacyPolicy")}</a>.{" "}
      </p>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit"]));
  },
  validationSchema: schema,
})(SignUpForm);
