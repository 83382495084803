import omit from "lodash/omit";
import get from "lodash/get";
import getDelay from "./getDelay";

function parseAutomationUiConfig(uiConfig) {
  const data = { ...uiConfig.drawflow.Home.data };
  const convertObjectToStep = (key, o) => {
    const result = {
      id: key,
    };
    const config = {
      ...omit(o.data, [
        "task.dueDate", // Temp: This is for old UI configs that still have the hardcoded dueDates, just ignoring them
        "task.duePeriodValue",
        "task.timeSelector",
        "value",
        "timeSelector",
        "window",
      ]),
    };

    // set object type
    if (o.name === "send_sms") {
      result.type = "send_message";
      config.type = "sms";
    } else if (o.name === "send_email") {
      result.type = "send_message";
      config.type = "email";
    } else {
      result.type = o.name;
    }

    // set timeout and delayNext
    if (get(o, "data.value") && get(o, "data.timeSelector")) {
      const timeSelector = get(o, "data.timeSelector");
      const value = get(o, "data.value");
      const delay = getDelay(value, timeSelector);

      // set timeout or delayNext
      if (o.name === "") {
      } else {
        config.timeout = delay;
      }
    }

    // set due date on task only at runtime now, so we send the deadline duration
    // to calculate on automation instance create
    if (o.name === "add_task") {
      const duePeriod = get(o, "data.task.duePeriodValue");

      // The number field will return 0 when empty but touched
      if (duePeriod && duePeriod > 0 && get(o, "data.task.timeSelector")) {
        const timeSelector = get(o, "data.task.timeSelector");

        const delay = getDelay(duePeriod, timeSelector);

        config.task.duePeriod = delay;
      }
    }

    if (get(o, "data.windows")) {
      const window = get(o, "data.windows");
      const newWindow = {};
      Object.keys(window).forEach((key) => {
        if (window[key])
          newWindow[key] = window[key].map((v) => Number.parseInt(v));
      });
      config.windows = newWindow;
    }

    // Set connections
    const outputs = Object.keys(o.outputs).map((key) => o.outputs[key]);
    if (outputs.length > 0) {
      if (o.name === "input_contains") {
        config.passStepId = get(outputs[0], "connections[0].node");
      } else if (o.name === "has_tag") {
        config.passStepId = get(outputs[0], "connections[0].node");
      } else {
        config.nextStepId = get(outputs[0], "connections[0].node");
      }
      if (outputs.length > 1) {
        if (
          o.name === "execute_webhook" ||
          o.name === "input_contains" ||
          o.name === "outgoing_call_end" ||
          o.name === "has_tag"
        ) {
          config.failStepId = get(outputs[1], "connections[0].node");
        } else {
          config.timeoutStepId = get(outputs[1], "connections[0].node");
        }
      }
    }

    result.config = config;
    return result;
  };

  const triggerKey = Object.keys(data).find(
    (key) => data[key].type === "trigger"
  );
  console.log(data);
  const highestId = parseInt(
    Object.keys(data).reduce((acc, cur) =>
      parseInt(acc) > parseInt(cur) ? acc : cur
    )
  );
  Object.keys(data).forEach((key) => {
    if (data[key].name === "send_call") {
      const id = (highestId + 1).toString();
      data[id] = {
        name: "outgoing_call_end",
        outputs: data[key].outputs,
        config: {
          wait: true,
        },
      };
      data[key] = {
        ...data[key],
        name: data[key].name,
        outputs: [{ connections: [{ node: id }] }],
      };
    }
  });

  // Convert all steps
  let steps = Object.keys(data)
    .filter((key) => data[key].name !== "comment")
    .map((key) => convertObjectToStep(key, data[key]));

  const result = {};
  const triggerBlock = steps.find((step) => step.id === triggerKey);
  const initialStepId = triggerBlock.config.nextStepId;

  if (triggerBlock.config.linkId) {
    result.linkId = triggerBlock.config.linkId;
  }

  steps = steps.filter((step) => step.id !== triggerKey);

  result.initialStepId = initialStepId;
  result.steps = steps;

  return result;
}
export default parseAutomationUiConfig;
