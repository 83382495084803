import api from "../../../../api";

export default function uploadPhoto({ locationId, emailTemplateId }, file) {
  const formData = new FormData();
  formData.append("file", file);
  return api.post(
    `/locations/${locationId}/email-templates/${emailTemplateId}/uploadPhoto`,
    formData
  );
}
