import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useCheckAuth } from "../../../react-check-auth";
import { useTranslation, withTranslation } from "react-i18next";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import * as ROUTES from "../../../constants/routes";

import searchLocations from "../../../common/search/searchLocations";

const AccountsDropdown = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [locationFilter, setLocationFilter] = useState("");
  const { userInfo, refreshAuth } = useCheckAuth();
  const locations = userInfo.locations;
  const [dropdownOpen, setDropdown] = useState(false);
  const toggleDropdown = () => {
    setDropdown(!dropdownOpen);
  };
  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
      className="app-search"
    >
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle mr-0 nav-select-account text-dark"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        {locationId && locations.find((l) => l.id === locationId)
          ? locations.find((l) => l.id === locationId).name
          : t("locationDropdown.title")}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-animated topbar-dropdown-menu dropdown-lg">
        <div className="dropdown-header noti-title">
          {" "}
          <form>
            <input
              onChange={(e) => setLocationFilter(e.target.value)}
              value={locationFilter}
              type="text"
              className="form-control"
              placeholder="Search ..."
            />
          </form>
        </div>
        {locations &&
          searchLocations(locations, locationFilter)
            .slice(0, 5)
            .map((location) => (
              <Link
                key={location.id}
                to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as({
                  locationId: location.id,
                })}
                className={"dropdown-item notify-item"}
                onClick={refreshAuth}
              >
                <span>{location.name}</span>
              </Link>
            ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default withTranslation()(AccountsDropdown);
