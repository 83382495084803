import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import EmailTemplateEditor from "../EmailTemplateEditor";
import EmailTemplateEditModal from "../EmailTemplateEditModal";
import useFetchApi from "../../../../../hooks/useFetchApi";
import api from "../../../../../common/api";
import Loading from "../../../../Loading";
import Error from "../../../../Error";

export default function EmailTemplateCreatePage() {
  const { emailTemplateId, locationId } = useParams();
  const [show, setShow] = useState(false);
  const [config, setConfig] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    data,
    error: fetchError,
    loading: fetchLoading,
  } = useFetchApi(api.locations.emailTemplates.getById, {
    emailTemplateId,
    locationId,
  });
  if (fetchLoading) {
    return <Loading />;
  }
  return (
    <Container fluid={true} className="mt-2">
      {fetchError && <Error error={fetchError} />}
      {show && (
        <EmailTemplateEditModal
          id={emailTemplateId}
          config={config}
          handleClose={handleClose}
          show={show}
        />
      )}
      <EmailTemplateEditor
        setModal={handleShow}
        setConfig={setConfig}
        config={data.config}
      />
    </Container>
  );
}
