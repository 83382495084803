import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";

import BootstrapTable from "react-bootstrap-table-next";
import useLocation from "../hooks/useLocation";

import * as ROUTES from "../../../../constants/routes";
import api from "../../../../common/api";
import Error from "../../../Error";
import ConfirmationModal from "../../../ConfirmationModal";
import Loading from "../../../Loading";

const LocationTable = () => {
  const { t } = useTranslation();
  const { locations, reload } = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (locationId) => {
    setLoading(true);
    try {
      await api.admin.locations.deleteById({ locationId });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };
  if (loading) {
    return <Loading />;
  }
  console.log(locations);
  const ViewColumn = (cell, row) => {
    return (
      <React.Fragment>
        <Link
          to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as({
            locationId: row.id,
          })}
          className="action-icon"
        >
          <Button>{t("adminDashboard.locationsPage.table.view")}</Button>
        </Link>
      </React.Fragment>
    );
  };
  const CreatedAtColumn = (cell) => {
    return (
      <React.Fragment>{format(parseISO(cell), "dd.MM.yyyy")}</React.Fragment>
    );
  };
  const BelongsToAgencyColumn = (cell) => {
    return <React.Fragment>{cell.name}</React.Fragment>;
  };
  const DeleteColumn = (cell, row) => {
    return (
      <React.Fragment>
        <ConfirmationModal
          text={t("adminDashboard.locationsPage.deleteModal.text")}
          title={t("adminDashboard.locationsPage.deleteModal.title")}
          button={
            <Button variant="danger">
              {t("adminDashboard.locationsPage.table.delete")}
            </Button>
          }
          callback={() => handleDelete(row.id)}
        />
      </React.Fragment>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: t("adminDashboard.locationsPage.table.name"),
      sort: true,
    },
    {
      dataField: "createdAt",
      isDummyColumn: true,
      text: t("adminDashboard.locationsPage.table.createdAt"),
      sort: false,
      classes: "table-action",
      formatter: CreatedAtColumn,
    },
    {
      dataField: "agency",
      isDummyColumn: true,
      text: t("adminDashboard.locationsPage.table.agency"),
      sort: false,
      classes: "table-action",
      formatter: BelongsToAgencyColumn,
    },
    {
      dataField: "view",
      isDummyColumn: true,
      text: t("adminDashboard.locationsPage.table.view"),
      sort: false,
      classes: "table-action",
      formatter: ViewColumn,
    },
    {
      dataField: "delete",
      isDummyColumn: true,
      text: t("adminDashboard.locationsPage.table.delete"),
      sort: false,
      classes: "table-action",
      formatter: DeleteColumn,
    },
  ];
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={locations}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
      />
    </>
  );
};

export default LocationTable;
