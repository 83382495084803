import React from "react";
import { useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import api from "../../../../../../../../common/api";
import pickLetter from "../../../../../../../../common/pickers/pickLetter";
import MessageInput from "../../../../../../../Form/MessageInput";

export const schema = yup.object({
  content: yup.object({
    text: yup.string().required("validation.sendLetter.message.required"),
    subject: yup.string().required("validation.sendLetter.subject.required"),
    information_block: yup.string(),
  }),
  c4: yup.boolean(),
  sender: yup.object({
    name: yup.string(),
    street: yup.string(),
    postcode: yup.string(),
    city: yup.string(),
    country: yup.string(),
  }),
  recipient: yup.object({
    name: yup.string(),
    companyName: yup.string(),
    street: yup.string(),
    postcode: yup.string(),
    city: yup.string(),
    country: yup.string(),
  }),
});

const SendLetterForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const onGenerate = async () => {
    try {
      const {
        data: { fileUrl },
      } = await api.locations.generateLetter(
        { locationId },
        pickLetter(values)
      );
      var win = window.open(fileUrl, "_blank");
      win.focus();
    } catch (e) {}
  };
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formSubject">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.subject.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.subject.placeholder"
          )}
          value={get(values, "content.subject") || ""}
          name="content.subject"
          onChange={handleChange}
          isInvalid={!!get(errors, "content.subject")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "content.subject"))}
        </Form.Control.Feedback>
      </Form.Group>
      <MessageInput
        name={"content.information_block"}
        label={t(
          "locationDashboard.automationShowPage.sendLetterForm.information_block.label"
        )}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        type={"letter"}
      />
      <MessageInput
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        type={"letter"}
      />
      <Form.Group controlId="formC4">
        <Form.Check
          type="checkbox"
          value={get(values, "c4")}
          checked={get(values, "c4")}
          name="c4"
          onChange={handleChange}
          label={t("locationDashboard.automationShowPage.sendLetterForm.c4")}
        />
      </Form.Group>
      <p>{t("locationDashboard.automationShowPage.sendLetterForm.hint")}</p>
      <p>
        {t("locationDashboard.automationShowPage.sendLetterForm.sender.title")}
      </p>
      <Form.Group controlId="formSenderName">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.name.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.name.placeholder"
          )}
          value={get(values, "sender.name") || ""}
          name="sender.name"
          onChange={handleChange}
          isInvalid={!!get(errors, "sender.name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "sender.name"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSenderStreet">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.street.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.street.placeholder"
          )}
          value={get(values, "sender.street") || ""}
          name="sender.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "sender.street")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "sender.street"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSenderPostcode">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.postcode.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.postcode.placeholder"
          )}
          value={get(values, "sender.postcode") || ""}
          name="sender.postcode"
          onChange={handleChange}
          isInvalid={!!get(errors, "sender.postcode")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "sender.postcode"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSenderCity">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.city.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.city.placeholder"
          )}
          value={get(values, "sender.city") || ""}
          name="sender.city"
          onChange={handleChange}
          isInvalid={!!get(errors, "sender.city")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "sender.city"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSenderCountry">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.country.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.sender.country.placeholder"
          )}
          value={get(values, "sender.country") || ""}
          name="sender.country"
          onChange={handleChange}
          isInvalid={!!get(errors, "sender.country")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "sender.country"))}
        </Form.Control.Feedback>
      </Form.Group>
      <p>
        {t(
          "locationDashboard.automationShowPage.sendLetterForm.recipient.title"
        )}
      </p>
      <Form.Group controlId="formRecipientCompanyName">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.companyName.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.companyName.placeholder"
          )}
          value={get(values, "recipient.companyName") || ""}
          name="recipient.companyName"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.companyName")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.companyName"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formRecipientName">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.name.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.name.placeholder"
          )}
          value={get(values, "recipient.name") || ""}
          name="recipient.name"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.name"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formRecipientStreet">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.street.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.street.placeholder"
          )}
          value={get(values, "recipient.street") || ""}
          name="recipient.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.street")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.street"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formRecipientPostcode">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.postcode.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.postcode.placeholder"
          )}
          value={get(values, "recipient.postcode") || ""}
          name="recipient.postcode"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.postcode")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.postcode"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formRecipientCity">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.city.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.city.placeholder"
          )}
          value={get(values, "recipient.city") || ""}
          name="recipient.city"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.city")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.city"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formRecipientCountry">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.country.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendLetterForm.recipient.country.placeholder"
          )}
          value={get(values, "recipient.country") || ""}
          name="recipient.country"
          onChange={handleChange}
          isInvalid={!!get(errors, "recipient.country")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "recipient.country"))}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formButton">
        <Button
          onClick={onGenerate}
          className="btn-lg btn-block"
          variant="primary"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.sendLetterForm.generate")}
        </Button>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.sendLetterForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    console.log(values);
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      omit(values, ["error", "onSubmit", "onCancel", "onGenerate", "action"])
    );
  },
  validationSchema: schema,
})(SendLetterForm);
