import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../react-check-auth";
import api from "../../../../common/api";
import EmployeePaginator from "./EmployeePaginator";
import EmployeeTable from "./EmployeeTable";
import EmployeeContext from "./contexts/EmployeeContext";
import EmployeeSearchBar from "./EmployeeSearchBar";
import useFetchApi from "../../../../hooks/useFetchArrayApi";
import Error from "../../../Error";
import Loading from "../../../Loading";

import withAgencyUser from "../../../../hocs/withAgencyUser";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";
import { useTranslation } from "react-i18next";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "Role" ||
      obj[propName] === "Type" ||
      obj[propName] === "In Account"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

const AgencyTeamManagementPage = () => {
  const { t } = useTranslation();
  const [nameFilter, setNameFilter] = useState("");
  const [filters, setFilters] = useState({
    role: "",
    type: "",
    page: 1,
    limit: 10,
    locationId: "",
  });
  const { userInfo } = useCheckAuth();
  const {
    data: { results: employees, total },
    loading: fetchEmployeesLoading,
    error: fetchEmployeesError,
    getData,
  } = useFetchApi(
    api.agencies.getAccounts,
    {
      agencyId: userInfo.agency.id,
    },
    clean(filters),
    [filters]
  );
  return (
    <EmployeeContext.Provider
      value={{
        filters,
        setFilters,
        total,
        employees,
        reload: getData,
        nameFilter,
        setNameFilter,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <EmployeeSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted text-left">
              {total} {t("settingsPage.agencyTeamManagementPage.employees")}
            </p>
          </Col>
          <Col className="text-right">
            <EmployeePaginator />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchEmployeesError && <Error error={fetchEmployeesError} />}
            {fetchEmployeesLoading ? (
              <Loading />
            ) : (
              employees && <EmployeeTable />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <EmployeePaginator />
          </Col>
        </Row>
      </Container>
    </EmployeeContext.Provider>
  );
};
export default withSubscriptionCheck(withAgencyUser(AgencyTeamManagementPage));
