import React, { useState } from "react";
import { useCheckAuth } from "../../../../react-check-auth";
import AgencyForm from "./AgencyForm";
import AddressForm from "./AddressForm";
import DomainForm from "./DomainForm";
import { Container, Row, Card, Col } from "react-bootstrap";
import api from "../../../../common/api";
import pickAgency from "../../../../common/pickers/pickAgency";
import Error from "../../../Error";
import Loading from "../../../Loading";
import withAgencyUser from "../../../../hocs/withAgencyUser";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";
import { useTranslation } from "react-i18next";
import useFetchApi from "../../../../hooks/useFetchApi";
import pickDomain from "../../../../common/pickers/pickDomain";

const AgencySettingsPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState(null);
  const [domainLoading, setDomainLoading] = useState(false);
  const [domainError, setDomainError] = useState(null);
  const { userInfo, refreshAuth } = useCheckAuth();
  const {
    data: agency,
    loading: fetchAgencyLoading,
    error: fetchAgencyError
    ,
  } = useFetchApi(
    api.agencies.getById,
    { agencyId: userInfo.agency.id },
    { eager: ["domain"] }
  );
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const promises = [];
      if (data.photo) {
        promises.push(api.agencies.updatePhoto(agency.id, data.photo));
      }
      promises.push(api.agencies.updateById(agency.id, pickAgency(data)));
      await Promise.all(promises);
      refreshAuth();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const onAddressSubmit = async (data) => {
    setAddressLoading(true);
    try {
      await api.agencies.updateById(agency.id, pickAgency(data));
      refreshAuth();
    } catch (e) {
      setAddressError(e);
    }
    setAddressLoading(false);
  };
  const onDomainSubmit = async (data) => {
    setDomainLoading(true);
    try {
      await api.agencies.upsertDomain(agency.id, pickDomain(data));
      refreshAuth();
    } catch (e) {
      setDomainError(e);
    }
    setDomainLoading(false);
  };
  if (fetchAgencyLoading) return <Loading />;
  return (
    <Container fluid={true}>
      {error && <Error error={error} />}
      {fetchAgencyError && <Error error={fetchAgencyError} />}
      {addressError && <Error error={addressError} />}
      {domainError && <Error error={domainError} />}
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>
                {t("settingsPage.agencySettingsPage.companyDataCard.title")}
              </h3>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <Loading />
              ) : (
                <AgencyForm {...agency} onSubmit={onSubmit} />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h3> {t("settingsPage.agencySettingsPage.domainCard.title")}</h3>
            </Card.Header>
            <Card.Body>
              {domainLoading ? (
                <Loading />
              ) : (
                <DomainForm {...agency.domain} onSubmit={onDomainSubmit} />
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h3>
                {" "}
                {t("settingsPage.agencySettingsPage.addressDataCard.title")}
              </h3>
            </Card.Header>
            <Card.Body>
              {addressLoading ? (
                <Loading />
              ) : (
                <AddressForm {...agency} onSubmit={onAddressSubmit} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default withSubscriptionCheck(withAgencyUser(AgencySettingsPage));
