import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import {  withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import TimeSelectorInput from "../../../../../../../Form/TimeSelectorInput";
// import DateTimeInput from "../../../../../../../Form/DateTimeInput";
import AccountInput from "../../../../../../../Form/AccountInput";

export const schema = yup.object({
  task: yup
    .object({
      title: yup.string().required("validation.title.required"),
      description: yup.string().nullable(),
      accountId: yup.string().nullable(), // .required("validation.accountId.required"),
      dueDate: yup.date(), // .required("validation.dueDate.required"),
      duePeriodValue: yup.number().positive().min(0),
      timeSelector: yup.string().when("duePeriodValue", {
        is: (v) => {
          // console.log(v);
          return v !== undefined && v > 0;
        },
        then: yup.string().required("validation.dueDate.timeType.required"),
      }),
    })
    .required(),
});

const AddTaskForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formTitle">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.automationShowPage.addTaskForm.title.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.addTaskForm.title.placeholder"
          )}
          value={get(values, "task.title") || ""}
          name="task.title"
          onChange={handleChange}
          isInvalid={!!get(errors, "task.title")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "task.title"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDescription">
        {" "}
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.addTaskForm.description.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          as={"textarea"}
          rows={3}
          placeholder={t(
            "locationDashboard.automationShowPage.addTaskForm.description.placeholder"
          )}
          value={get(values, "task.description") || ""}
          name="task.description"
          onChange={handleChange}
          isInvalid={!!get(errors, "task.description")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "task.description"))}
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group controlId="formDueDate">
        <Form.Label>
          {t("locationDashboard.automationShowPage.addTaskForm.dueDate.label")}
        </Form.Label>
        <div
          className={
            "form-control " +
            (get(errors, "task.dueDate") || "" ? "is-invalid" : "")
          }
        >
          <Field
            name="task.dueDate"
            value={get(values, "task.dueDate") || ""}
            component={DateTimeInput}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "task.dueDate"))}
          </Form.Control.Feedback>
        </div>
      </Form.Group> */}
      <Form.Group as={Row} controlId="formDuePeriod">
        <Col xs={12}>
          <Form.Label>
            {t(
              "locationDashboard.automationShowPage.addTaskForm.dueDate.label"
            )}
          </Form.Label>
        </Col>
        <Col xs={6}>
          <Form.Group controlId="formValue">
            <Form.Control
              type="number"
              value={get(values, "task.duePeriodValue") || ""}
              name="task.duePeriodValue"
              onChange={handleChange}
              isInvalid={!!get(errors, "task.duePeriodValue")}
            />
            <Form.Control.Feedback type="invalid">
              {t(get(errors, "task.duePeriodValue"))}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={6}>
          {" "}
          <TimeSelectorInput
            handleChange={handleChange}
            error={get(errors, "task.timeSelector")}
            name="task.timeSelector"
            value={get(values, "task.timeSelector")}
          />
        </Col>
      </Form.Group>
      <AccountInput
        onChange={handleChange}
        name={"task.accountId"}
        value={get(values, "task.accountId") || ""}
        label={t(
          "locationDashboard.automationShowPage.addTaskForm.accountId.label"
        )}
        error={get(errors, "task.accountId")}
      />
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.addTaskForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AddTaskForm);
