import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import enFlag from "./flags/us.jpg";
import germanyFlag from "./flags/germany.jpg";
import franceFlag from "./flags/france.jpg";

const languages = [
  {
    name: "English",
    flag: enFlag,
    key: "en",
  },
  {
    name: "Deutsch",
    flag: germanyFlag,
    key: "de",
  },
  {
    name: "Français",
    flag: franceFlag,
    key: "fr",
  },
];

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const selectLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const selectedLanguage = languages.find((l) => l.key === i18n.language);
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        data-toggle="dropdown"
        tag="button"
        className="nav-link dropdown-toggle arrow-none btn btn-link"
        onClick={toggleDropdown}
        aria-expanded={dropdownOpen}
      >
        <img
          src={selectedLanguage.flag}
          alt={selectedLanguage.name}
          className="mr-1"
          height="12"
        />{" "}
        <span className="align-middle">{selectedLanguage.name}</span>
        <i className="mdi mdi-chevron-down align-middle"></i>
      </DropdownToggle>
      <DropdownMenu
        right
        className="dropdown-menu-animated topbar-dropdown-menu"
      >
        <div onClick={toggleDropdown}>
          {languages.map((lang, i) => {
            return (
              <div
                className="dropdown-item notify-item"
                key={i + "-lang"}
                onClick={() => selectLanguage(lang.key)}
              >
                <img
                  src={lang.flag}
                  alt={lang.name}
                  className="mr-1"
                  height="12"
                />{" "}
                <span className="align-middle">{lang.name}</span>
              </div>
            );
          })}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
