import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import EmployeeForm from "../EmployeeForm";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import useFetchApi from "../../../../../hooks/useFetchApi";
import { useTranslation } from "react-i18next";
import pickEmployee from "../../../../../common/pickers/pickEmployee";

const EmployeeEditModal = ({ handleClose, callback, show, id }) => {
  const { t } = useTranslation();
  useCheckAuth();
  const {
    data,
    error: fetchError,
    loading: fetchLoading,
  } = useFetchApi(
    api.accounts.getById,
    { accountId: id },
    { eager: ["locations"] }
  );
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      // console.log(values);
      await api.accounts.updateById(
        { accountId: id },
        pickEmployee({
          ...values,
          locations: values.locations.map((l) => ({ id: l.id })),
        })
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("settingsPage.agencyTeamManagementPage.editModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || fetchLoading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            {fetchError && <Error error={fetchError} />}
            <EmployeeForm
              {...data}
              action={"edit"}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmployeeEditModal;
