import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import TagsInput from "../../../../../../../Form/TagsInput";

import SourceCreatableInput from "../../../../../../../Form/SourceCreatableInput";

export const schema = yup.object({
  contact: yup
    .object({
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      email: yup
        .string()
        .email("validation.updateContact.email.valid")
        .nullable(),
      phone: yup.string().nullable(),
      birthDate: yup.string().nullable(),
    })
    .required(),
});

const UpdateContactForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formTags">
        <Form.Label>{t("formElements.tags.label")}</Form.Label>
        <TagsInput
          setFieldValue={setFieldValue}
          name={"contact.tags"}
          value={get(values, "contact.tags")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "contact.tags")}
        </Form.Control.Feedback>
      </Form.Group>
      <SourceCreatableInput
        name="contact.source"
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        values={values}
        errors={errors}
      />
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.updateContactForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(UpdateContactForm);
