import React from "react";
import { Form, Button } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import PipelineInput from "../../../../../../../Form/PipelineInput";
import StageInput from "../../../../../../../Form/StageInput";
import opportunityStatuses from "../../../../../../../../constants/opportunityStatuses";

export const schema = yup.object({
  opportunity: yup
    .object({
      leadValue: yup
        .number()
        .integer("validation.addUpdateOpportunity.leadValue.number"),
      pipelineId: yup
        .string()
        .required("validation.addUpdateOpportunity.pipelineId.required"),
      stageId: yup
        .string()
        .required("validation.addUpdateOpportunity.stageId.required"),
      status: yup
        .string()
        .required("validation.addUpdateOpportunity.status.required"),
    })
    .required(),
});

const AddUpdateOpportunityForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <PipelineInput
        value={get(values, "opportunity.pipelineId")}
        onChange={handleChange}
        error={get(errors, "opportunity.pipelineId")}
        name={"opportunity.pipelineId"}
      />
      <StageInput
        pipelineId={get(values, "opportunity.pipelineId")}
        value={get(values, "opportunity.stageId")}
        onChange={handleChange}
        error={get(errors, "opportunity.stageId")}
        name={"opportunity.stageId"}
      />
      <Form.Group>
        <Form.Label> {t("formElements.status.label")}</Form.Label>
        <Form.Control
          name="opportunity.status"
          as="select"
          value={get(values, "opportunity.status") || ""}
          onChange={handleChange}
          isInvalid={!!get(errors, "opportunity.status")}
        >
          <option> {t("formElements.status.option")}</option>
          {opportunityStatuses.map((status) => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "opportunity.status"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formLeadValue">
        <Form.Label>{t("formElements.leadValue.label")}</Form.Label>
        <Form.Control
          type="number"
          placeholder={t("formElements.leadValue.placeholder")}
          value={get(values, "opportunity.leadValue") || ""}
          name="opportunity.leadValue"
          onChange={handleChange}
          isInvalid={!!get(errors, "opportunity.leadValue")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "opportunity.leadValue"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.automationShowPage.addUpdateOpportunityForm.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AddUpdateOpportunityForm);
