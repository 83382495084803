import React from "react";
import { Button, Col,  Form, Row } from "react-bootstrap";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import spacetime from "spacetime";

import { useCheckAuth } from "../../../../react-check-auth";

import AutomationInput from "../../../Form/AutomationInput";
import TimezonedDateTimeInput from "../../../Form/TimezonedDateTimeInput";

const schema = yup.object({
  automationId: yup.string().required(),
  contactId: yup.string().required(),
  nextExecutionTime: yup.string(),
});

const AutomationInstanceForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();

    // Get user's current active location timezone
    const { userInfo } = useCheckAuth();
    // console.log(JSON.stringify(userInfo.locations))
    
    const { locationId } = useParams();
    let tzName;

    if(locationId !== undefined) {
      const currLocation = userInfo.locations.find((l) => l.id === locationId)
      if(currLocation && currLocation.timezone) {
        tzName = currLocation.timezone
      }
    }
    if(!tzName) {
      tzName = 'Europe/Berlin'
    }

    const date = get(values, "nextExecutionTime") || new Date()
    const remoteDate = spacetime(date.getTime(), tzName);
  
    const remoteTz = remoteDate.timezone()
  
    const mins = remoteTz.current.offset * 60
    const hr = `${(mins / 60) ^ 0}:` + (mins % 60 === 0 ? "00" : Math.abs(mins % 60))
    const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${tzName}`

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formAutomation">
        <AutomationInput
          value={get(values, "automationId") || ""}
          onChange={handleChange}
          error={get(errors, "automationId")}
        />
      </Form.Group>
      <Form.Group controlId="formAutomation">
        <Form.Label>
          {t("formElements.automationInstanceList.form.scheduledDate.label")}
        </Form.Label>
        <div className="mb-1">{t("formElements.automationInstanceList.form.scheduledDate.notice")} <b>{prefix}</b></div>
        <div
          className={
            "form-control " +
            (get(errors, "nextExecutionTime") || "" ? "is-invalid" : "")
          }
        >
          <Field
            name="nextExecutionTime"
            value={get(values, "nextExecutionTime") || ""}
            tzName={tzName}
            component={TimezonedDateTimeInput}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "nextExecutionTime"))}
          </Form.Control.Feedback>
        </div>
      </Form.Group>

      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("formElements.automationInstanceList.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("formElements.automationInstanceList.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AutomationInstanceForm);
