import queryString from "query-string";
import api from "../../../../api";

export default function get(params, query) {
  return api.get(
    `/admin/locations${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
