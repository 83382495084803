export default function parseUiConfig(data) {
  const processBlock = (data) => {
    if (data.name === "comment") {
      data.html = `<div class="title-box">${data.data.comment}</div>`;
    }
    return data;
  };
  return Object.keys(data).reduce((acc, key) => {
    return { ...acc, [key]: processBlock(data[key]) };
  }, {});
}
