import React from "react";
import { Form,     InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import Sms77Form from "./Sms77Form";
import TwilioForm from "./TwilioForm";
import SmsServiceNameInput from "./SmsServiceNameInput";
import getSmsWebhook from "../../../../../../common/functions/getSmsWebhook";
import getCallWebhook from "../../../../../../common/functions/getCallWebhook";

const schema = yup.object({
  config: yup.object({
    apiKey: yup.string().required("validation.apiKey.required"),
    phone: yup.string().required("validation.phone.required"),
  }),
});

const SmsServiceForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      {values.action === "edit" && (
        <>
          <Form.Group>
            <Form.Label>
              {t(
                "locationDashboard.settingsPage.smsPage.form.leadkrakenWebhook.label"
              )}
            </Form.Label>
            <InputGroup>
              <Form.Control
                id={"webhook"}
                type="text"
                value={getSmsWebhook(values.name, locationId)}
              ></Form.Control>

              <InputGroup.Append
                onClick={() => {
                  document.execCommand(
                    "copy",
                    false,
                    document.getElementById("webhook").select()
                  );
                }}
              >
                <InputGroup.Text>
                  <i className="dripicons-clipboard"></i>
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          {values.name === "twilio" && (
            <Form.Group>
              <Form.Label>
                {t(
                  "locationDashboard.settingsPage.smsPage.form.leadkrakenCallWebhook.label"
                )}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  id={"call-webhook"}
                  type="text"
                  value={getCallWebhook(values.name, locationId)}
                ></Form.Control>

                <InputGroup.Append
                  onClick={() => {
                    document.execCommand(
                      "copy",
                      false,
                      document.getElementById("call-webhook").select()
                    );
                  }}
                >
                  <InputGroup.Text>
                    <i className="dripicons-clipboard"></i>
                  </InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          )}
        </>
      )}

      {values.action !== "edit" && (
        <SmsServiceNameInput
          value={get(values, "name") || ""}
          name="name"
          handleChange={handleChange}
          error={!!get(errors, "name")}
        />
      )}

      {get(values, "name") === "sms77" ? (
        <Sms77Form
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : get(values, "name") === "twilio" ? (
        <TwilioForm
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : null}
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(SmsServiceForm);
