import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { withFormik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import omit from "lodash/omit";

import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../react-check-auth";
import useChat from "../../../../hooks/useChat";
import SmsForm from "./SmsForm";
import EmailForm from "./EmailForm";
import MessageTemplateSelectModal from "../../MessageTemplateSelectModal";
import ModalButton from "../../../ModalButton";

const schema = yup.object({
  content: yup.object({ text: yup.string().required() }),
});

const MessageForm = ({
  handleSubmit,
  handleChange,

  values,

  errors,
  setFieldValue,
}) => {
  useCheckAuth();
  const { location } = useChat();
  const { t } = useTranslation();
  const groups = [
    {
      enabled:
        location.defaultSmsTransport && location.defaultSmsTransport.active,
      title: t(
        "locationDashboard.conversationPage.chatArea.messageForm.smsForm.title"
      ),
      value: "sms",
    },
    {
      enabled:
        location.defaultEmailTransport && location.defaultEmailTransport.active,
      title: t(
        "locationDashboard.conversationPage.chatArea.messageForm.emailForm.title"
      ),
      value: "email",
    },
  ];

  // This is used in the modal and also the contact view screen - so needs more throught and design
  // if (groups.length === 0) {
  //   // TODO: Add to translations
  //   return <Error error={{ message: "You have no default transports" }} />;
  // }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row>
        <Col className="mb-2 text-right">
          <ul className="nav nav-tabs nav-bordered">
            {groups.map((group) => (
              <li
                key={group.title}
                className="nav-item"
                onClick={() => {
                  if (!group.enabled) return;
                  setFieldValue("type", group.value);
                }}
              >
                <Link
                  className={`nav-link 
                  ${group.value === values.type ? "active" : ""}
                  ${group.enabled ? "" : "disabled"}`}
                  to={"#"}
                >
                  {group.title}
                </Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          {values.type === "sms" ? (
            <SmsForm
              handleChange={handleChange}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          ) : values.type === "email" ? (
            <EmailForm
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              errors={errors}
              values={values}
            />
          ) : null}
        </Col>
      </Row>
      <Row className="">
        <Col>
          {values.type === "sms" && (
            <ModalButton
              className="mt-1"
              modal={<MessageTemplateSelectModal />}
              button={
                <Button variant="primary">
                  {t(
                    "locationDashboard.conversationPage.chatArea.messageForm.smsForm.useTemplate"
                  )}
                </Button>
              }
              callback={(template) => {
                if (template) setFieldValue("content.text", template);
              }}
            />
          )}{" "}
          <button
            type="submit"
            className="btn btn-success chat-send mt-1 float-right"
          >
            SEND <i className="uil uil-message"></i>
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, options) => {
    options.props.onSubmit(
      omit(values, ["error", "onSubmit", "onCancel"]),
      options
    );
    options.resetForm();
    options.setStatus({ success: true });
    options.setFieldValue("type", values.type);
  },
  validationSchema: schema,
})(MessageForm);
