import React, { useState } from "react";
import { Card, Accordion,  Form } from "react-bootstrap";
import omit from "lodash/omit";
import useContact from "../../hooks/useContact";
import SourceInput from "../../../../../../Form/SouceInput";
import { useTranslation } from "react-i18next";

export default function SourceFilter({ onClose }) {
  const {
    backendFilters: filters,
    setBackendFilters: setFilters,
  } = useContact();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState({});
  const options = [
    {
      key: "1",
      title: t(
        "locationDashboard.contactsPage.filterBox.sourceFilter.notEmpty"
      ),
      onSelect: () => {
        setFilters({ ...omitFilters(filters), isSourceEmpty: false });
      },
    },
    {
      key: "2",
      title: t("locationDashboard.contactsPage.filterBox.sourceFilter.empty"),
      onSelect: () => {
        setFilters({ ...omitFilters(filters), isSourceEmpty: true });
      },
    },
    {
      key: "3",
      title: t("locationDashboard.contactsPage.filterBox.sourceFilter.is"),
      onSelect: () => {
        setFilters({ ...omitFilters(filters) });
      },
    },
    {
      key: "4",
      title: t("locationDashboard.contactsPage.filterBox.sourceFilter.not"),
      onSelect: () => {
        setFilters({ ...omitFilters(filters) });
      },
    },
  ];
  const omitFilters = (filters) =>
    omit(filters, ["isSourceEmpty", "sourceId", "notSourceId"]);
  const handleClose = () => {
    setFilters(omitFilters(filters));
    onClose();
  };
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="6">
        {selectedOption.title ||
          t(
            "locationDashboard.contactsPage.filterBox.sourceFilter.setSourceFilter"
          )}{" "}
        <i className="dripicons-cross" onClick={handleClose}></i>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="6">
        <Card.Body>
          {options.map((option) => (
            <>
              {" "}
              <Form.Check
                type="radio"
                label={option.title}
                name="sourceRadios"
                id="formHorizontalRadios1"
                onChange={() => {
                  setSelectedOption(option);
                  option.onSelect();
                }}
              />
              {option.key === "3" && selectedOption.key === "3" && (
                <SourceInput
                  label={t(
                    "locationDashboard.contactsPage.filterBox.sourceFilter.searchSource"
                  )}
                  value={filters.sourceId}
                  onChange={(e) => {
                    setFilters({
                      ...omitFilters(filters),
                      sourceId: e.target.value,
                    });
                  }}
                />
              )}
              {option.key === "4" && selectedOption.key === "4" && (
                <SourceInput
                  label={t(
                    "locationDashboard.contactsPage.filterBox.sourceFilter.searchSource"
                  )}
                  value={filters.notSourceId}
                  onChange={(e) => {
                    setFilters({
                      ...omitFilters(filters),
                      notSourceId: e.target.value,
                    });
                  }}
                />
              )}
            </>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
