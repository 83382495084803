import React from "react";
import { Form, Button,  Row } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import AutomationInput from "../../../../../../../Form/AutomationInput";

export const schema = yup.object({
  automationId: yup
    .string()
    .required('validation.addToAutomation.automationId.required'),
});

const AddToAutomationForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <AutomationInput
        onChange={handleChange}
        error={get(errors, "automationId")}
        value={get(values, "automationId")}
      />
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.addToAutomationForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(AddToAutomationForm);
