import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {  Col, Button, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import ConfirmationModal from "../../../../ConfirmationModal";
import TriggerLinkEditModal from "../TriggerLinkEditModal";
import ModalButton from "../../../../ModalButton";
import useTriggerLink from "../hooks/useTriggerLink";

const TriggerLinkTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { triggerLinks } = useTriggerLink();
  const { userInfo } = useCheckAuth();
  const { reload } = useTriggerLink();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (triggerLinkId) => {
    setLoading(true);
    try {
      await api.locations.triggerLinks.deleteById({
        triggerLinkId,
        locationId,
      });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };
  if (loading) {
    return <Loading />;
  }
  const ActionColumn = (cell, row  ) => {
    return (
      <Row>
        <Col>
          <ConfirmationModal
            text={t("locationDashboard.triggerLinkPage.deleteModal.text")}
            title={t("locationDashboard.triggerLinkPage.deleteModal.title")}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.triggerLinkPage.table.delete")}
              </Button>
            }
            callback={() => handleDelete(row.id)}
          />
        </Col>
        <Col>
          {" "}
          <ModalButton
            modal={<TriggerLinkEditModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t("locationDashboard.triggerLinkPage.table.edit")}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: t("locationDashboard.triggerLinkPage.table.name"),
      sort: true,
      headerStyle: ( ) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "shortUrl",
      text: t("locationDashboard.triggerLinkPage.table.shortUrl"),
      sort: true,
    },
    {
      dataField: "clickCount",
      text: t("locationDashboard.triggerLinkPage.table.clickCount"),
      sort: true,headerStyle: ( ) => {
        return { width: "150px" };
      },
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.triggerLinkPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={triggerLinks}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
      />
    </>
  );
};

export default TriggerLinkTable;
