import React from "react";
import { useTranslation } from "react-i18next";

export default function PortDescription() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <i className="mdi mdi-circle font-13 text-success mr-2"></i>
        {t("locationDashboard.automationShowPage.portDescription.successHint")}
      </div>

      <div className="mb-3">
        <i className="mdi mdi-circle font-13 text-danger mr-2"> </i>
        {t("locationDashboard.automationShowPage.portDescription.failureHint")}
      </div>
    </>
  );
}
