import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import ServiceForm from "../EmailServiceForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import pickService from "../../../../../../common/pickers/pickEmailService";
import emailServices from "../../../../../../constants/emailServices";
import hosts from "../../../../../../constants/hosts";

const ServiceCreateModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.transports.create(
        { locationId },
        {
          ...pickService(values),
          type: "email",
        }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.createModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <ServiceForm
              name={emailServices[0]}
              locationId={locationId}
              config={{ host: hosts[0] }}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ServiceCreateModal;
