import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";

import MessageInput from "../../../../../../../Form/MessageInput";

export const schema = yup.object({
  comment: yup.string().required("validation.addToAutomation.comment.required"),
});

const CommentForm = ({
  handleSubmit,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <MessageInput
        name={"comment"}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        type={"email"}
      />
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.commentForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(CommentForm);
