import React from "react";
import academicTitles from "../../../constants/academicTitles";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function AcademicTitleInput({ name, handleChange, value, error }) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formAcademicTitle">
      <Form.Label>{t("formElements.academicTitle.label")}</Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value || ""}
        onChange={handleChange}
        isInvalid={error}
      >
        <option value={""}>{t("formElements.academicTitle.option")}</option>
        {academicTitles.map((academicTitle) => (
          <option key={academicTitle} value={academicTitle}>
            {academicTitle}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
