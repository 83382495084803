import React, { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { Container, Col, Row, Card, Alert } from "react-bootstrap";
import Loading from "../Loading";
import EmailForm from "./EmailForm";
import AuthLayout from "../../Layout/Auth";

import Error from "../Error";
import * as ROUTES from "../../constants/routes";
import useFirebase from "../../hooks/useFirebase";
import withFirebase from "../../hocs/withFirebase";

const ForgetPassword = () => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await firebase.auth.sendPasswordResetEmail(values.email);
      setSent(true);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };
  if (loading) return <Loading />;
  return (
    <div className=" account-pages mt-5 mb-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={5}>
            <Card>
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <span></span>
              </div>
              <Card.Body className="p-4 position-relative">
                <div className="text-center w-75 m-auto">
                  <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                    {t("forgotPasswordPage.title")}
                  </h4>
                  <p className="text-muted mb-4">
                    {t("forgotPasswordPage.hint")}
                  </p>
                </div>
                {sent && (
                  <Alert variant="success">
                    {t("forgotPasswordPage.checkYourEmail")}
                  </Alert>
                )}
                {error && <Error error={error} />}
                <EmailForm onSubmit={onSubmit} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col className="col-12 text-center">
            <p className="text-muted">
              Don't have an account?{" "}
              <NavLink to={ROUTES.SIGN_IN.as()} className="text-muted ml-1">
                <b>{t("forgotPasswordPage.signIn")}</b>
              </NavLink>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const TranslatedForgotPassword = withTranslation()(ForgetPassword);

const ForgotPassordPage = () => {
  return (
    <AuthLayout>
      <TranslatedForgotPassword />
    </AuthLayout>
  );
};

export default withRouter(withFirebase(ForgotPassordPage));
