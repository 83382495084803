import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import ChatUsers from "../../../Chat/ChatLists";
import ChatArea from "../../../Chat/ChatArea";
import withChat from "../../../../hocs/withChat";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";
import withLocationUser from "../../../../hocs/withLocationUser";

function ConversationsPage() {
  return (
    <Container className="" fluid={true}>
      <Row className="h-100">
        <Col
          lg={4}
          sm={4}
          md={4}
          sx={4}
          className="order-lg-1 order-xl-1 h-100"
        >
          <ChatUsers />
        </Col>
        <Col className="order-lg-1 order-xl-1 h-100">
          <ChatArea />
        </Col>
      </Row>
    </Container>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withChat(withTranslation()(ConversationsPage)))
);
