import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import get from "lodash/get";
import { v4 as uuidv4 } from "uuid";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function StagesInput({
  values,
  setFieldValue,
  errors,
  handleChange,
}) {
  const { t } = useTranslation();
  const stages = get(values, "stages") || [];
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      stages,
      result.source.index,
      result.destination.index
    );
    setFieldValue("stages", items);
  };

  return (
    <Container>
      <Row>
        <Col lg={8} md={8} sm={8} sx={8}>
          <Form.Label>
            {t(
              "locationDashboard.settingsPage.pipelinePage.form.stages.name.label"
            )}
          </Form.Label>
        </Col>{" "}
        <Col lg={4} md={4} sm={4} sx={4}>
          <Form.Label>
            {t(
              "locationDashboard.settingsPage.pipelinePage.form.stages.action.label"
            )}
          </Form.Label>
        </Col>{" "}
      </Row>
      <Row>
        <Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided ) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {stages.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided ) => (
                        <Row
                          className="p-2 m-2 bg-light"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Col>
                            <Form.Group controlId={`formStages[${index}].name`}>
                              <Form.Control
                                type="text"
                                placeholder={t(
                                  "locationDashboard.settingsPage.pipelinePage.form.stages.name.placeholder"
                                )}
                                value={
                                  get(values, `stages[${index}].name`) || ""
                                }
                                name={`stages[${index}].name`}
                                onChange={handleChange}
                                isInvalid={
                                  !!get(errors, `stages[${index}].name`)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {t(get(errors, `stages[${index}].name`))}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Button
                              variant="danger"
                              onClick={() => {
                                setFieldValue(
                                  "stages",
                                  stages.filter((stage) => stage.id !== item.id)
                                );
                              }}
                            >
                              {t(
                                "locationDashboard.settingsPage.pipelinePage.form.stages.delete"
                              )}
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            variant="light"
            onClick={() => {
              setFieldValue("stages", [...stages, { id: uuidv4() }]);
            }}
          >
            {t(
              "locationDashboard.settingsPage.pipelinePage.form.stages.addStage"
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
