import React from "react";
import { Card } from "react-bootstrap";
import useAutomation from "../../hooks/useAutomation";
import { useTranslation } from "react-i18next";
import allElements from "../../constants/elements";

export default function AutomationDiagram() {
  const { onDrawflowDivRender, editor } = useAutomation();
  const { t } = useTranslation();

  const zoomIn = () => {
    editor.zoom_in();
  };
  const zoomOut = () => {
    editor.zoom_out();
  };
  const zoomReset = () => {
    editor.zoom_reset();
  };
  function drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("node");
    addNodeToDrawFlow(data, ev.clientX, ev.clientY);
  }

  function allowDrop(ev) {
    ev.preventDefault();
  }

  function addNodeToDrawFlow(name, pos_x, pos_y) {
    if (editor.editor_mode === "fixed") {
      return false;
    }
    pos_x =
      pos_x *
        (editor.precanvas.clientWidth /
          (editor.precanvas.clientWidth * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().x *
        (editor.precanvas.clientWidth /
          (editor.precanvas.clientWidth * editor.zoom));
    pos_y =
      pos_y *
        (editor.precanvas.clientHeight /
          (editor.precanvas.clientHeight * editor.zoom)) -
      editor.precanvas.getBoundingClientRect().y *
        (editor.precanvas.clientHeight /
          (editor.precanvas.clientHeight * editor.zoom));
    var block = allElements.find((b) => b.data === name);
    editor.addNode(
      block.data,
      block.inputs,
      block.outputs,
      pos_x,
      pos_y,
      block.data,
      {},
      // block.getElement(), // This doesn't translate at all
      t(block.getElement(), { ...t(block.translationKey, { returnObjects: true }), key: `${block.translationKey}.title` }),
      false,
      block.type
    );
    console.log(editor);
  }

  return (
    <Card className="m-0 p-0 shadow-none h-100">
      <Card.Body>
        <div
          style={{ height: "100%" }}
          id="drawflow"
          onDrop={drop}
          onDragOver={allowDrop}
          ref={onDrawflowDivRender}
        >
          <div className="bar-zoom">
            <i className="btn" onClick={zoomOut}>
              -
            </i>
            <i className="btn dripicons-search" onClick={zoomReset}></i>
            <i className="btn" onClick={zoomIn}>
              +
            </i>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
