import React from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import EmailForm from "./EmailForm";
import AuthLayout from "../../Layout/Auth";

const SignIn = () => {
  const { t } = useTranslation();
  return (
    <div className=" account-pages mt-5 mb-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={5}>
            <Card>
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <span>
                </span>
              </div>
              <Card.Body className="p-4 position-relative">
                <div className="text-center w-75 m-auto">
                  <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                    {t("signInPage.title")}
                  </h4>
                  <p className="text-muted mb-4">{t("signInPage.hint")}</p>
                </div>
                <EmailForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const TranslatedSignIn = withTranslation()(SignIn);

const SignInPage = () => {
  return (
    <AuthLayout>
      <TranslatedSignIn />
    </AuthLayout>
  );
};

export default SignInPage;
