import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import get from "lodash/get";
import MapFieldsForm from "./Form";
import StepHeader from "../StepHeader";
import useImport from "../hooks/useImport";
import isNill from "../../../../../../../common/functions/isNill";

export default function UploadFileStep({ next, handleClose }) {
  const { t } = useTranslation();
  const { setContacts, importedContacts, mappings, setMappings, customFields } =
    useImport();
  const handleSubmit = async (mappings) => {
    setContacts(
      importedContacts
        .map((contact) => {
          const c = { customFields: {} };
          Object.keys(mappings).forEach((key) => {
            if (customFields.some((f) => f.key === key)) {
              c.customFields[key] = contact[mappings[key]];
            } else {
              c[key] = contact[mappings[key]];
            }
          });
          return c;
        })
        .filter((c) => {
          if (
            !(
              (!isNill(c.firstName) ||
                !isNill(c.lastName) ||
                !isNill(c.fullName)) &&
              (!isNill(c.email) || !isNill(c.phone))
            )
          )
            return false;
          try {
            const schema = yup.object({
              email: yup.string().email().nullable(),
            });
            schema.validateSync(c);
            return true;
          } catch (e) {
            return false;
          }
        })
    );
    setMappings(mappings);
    next();
  };
  const fields = Object.keys(get(importedContacts, "[0]")) || [];
  return (
    <Container fluid={true} className="text-center">
      <Row>
        <Col>
          <StepHeader
            title={t("locationDashboard.contactsPage.importModal.step2.title")}
            number={2}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <MapFieldsForm
            fields={fields}
            {...mappings}
            onCancel={handleClose}
            onSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Container>
  );
}
