import React, { useState } from "react";
import { Container, Row, Col,  Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchApi from "../../../../hooks/useFetchArrayApi";
import api from "../../../../common/api";
import Error from "../../../Error";
import Loading from "../../../Loading";

import AccountInput from "../../../Form/AgencyAccountInput";
import get from "lodash/get";
import taskDates from "../../../../constants/taskDates";
import taskStatuses from "../../../../constants/taskStatuses";
import TaskSection from "./TaskSection";
import differenceInDays from "date-fns/differenceInCalendarDays";
import parseISO from "date-fns/parseISO";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "All" ||
      obj[propName] === "All accounts"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export default function TaskListPage() {
  const { /* contactId,*/ locationId } = useParams();
  const [filters, setFilters] = useState({ order: "desc" });
  const { t } = useTranslation();
  const {
    data,
    loading: fetchTasksLoading,
    error: fetchTasksError,
    getData,
  } = useFetchApi(
    api.locations.tasks.get,
    {
      locationId: locationId,
      // contactId: contactId, // Not sure why contactId is here, I think it was a mistake
    },
    { eager: ["account", "contact"], ...clean(filters) },
    [filters /* , contactId */]
  );
  const lateTasks = data.filter(
    (task) => differenceInDays(parseISO(task.dueDate), new Date()) < 0
  );
  const todayTasks = data.filter(
    (task) => differenceInDays(parseISO(task.dueDate), new Date()) === 0
  );
  const dueTasks = data.filter(
    (task) => differenceInDays(parseISO(task.dueDate), new Date()) > 0
  );
  return (
    <Container fluid={true} className="mt-4">
      {fetchTasksError && <Error error={fetchTasksError} />}
      <Row>
        <Col>
          <h2>{t("locationDashboard.tasksPage.title")}</h2>
        </Col>
        <Col>
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <AccountInput
                onChange={(e) => {
                  setFilters({ ...filters, accountId: e.target.value });
                }}
                value={get(filters, "accountId") || ""}
              />
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group controlId="forOrder">
                <Form.Control
                  name="order"
                  as="select"
                  value={filters.order}
                  onChange={(e) =>
                    setFilters({ ...filters, order: e.target.value })
                  }
                >
                  {taskDates.map((date) => (
                    <option key={date.order} value={date.order}>
                      {date.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Form.Group controlId="forStatus">
                <Form.Control
                  name="status"
                  as="select"
                  value={filters.status}
                  onChange={(e) =>
                    setFilters({ ...filters, status: e.target.value })
                  }
                >
                  <option>All</option>
                  {taskStatuses.map((date) => (
                    <option key={date.value} value={date.value}>
                      {date.title}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      {fetchTasksLoading ? (
        <Loading />
      ) : data.length === 0 ? (
        <>
          <Row>
            <Col> {t("locationDashboard.tasksPage.list.hint")}</Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-2">
            <Col>
              <TaskSection
                reload={getData}
                tasks={lateTasks}
                title={t("locationDashboard.tasksPage.lateTasks.title")}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <TaskSection
                reload={getData}
                tasks={todayTasks}
                title={t("locationDashboard.tasksPage.todayTasks.title")}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <TaskSection
                reload={getData}
                tasks={dueTasks}
                title={t("locationDashboard.tasksPage.dueTasks.title")}
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
