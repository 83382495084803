import React from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import AutomationContext from "./contexts/AutomationContext";
import AutomationSearchBar from "./AutomationSearchBar";
import AutomationTable from "./AutomationTable/index";
import api from "../../../../../common/api/index";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error/index";
import Loading from "../../../../Loading/index";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

function AutomationsPage() {
  const { locationId } = useParams();
  const {
    data: automations,
    loading: fetchAutomationsLoading,
    error: fetchAutomationsError,
    getData,
  } = useFetchApi(
    api.locations.automations.get,
    {
      locationId: locationId,
    },
    { eager: ["instances"] }
  );

  return (
    <AutomationContext.Provider
      value={{
        automations,
        reload: () => {
          getData();
        },
      }}
    >
      <Container className="" fluid={true}>
        <Row>
          <Col>
            <AutomationSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchAutomationsError && <Error error={fetchAutomationsError} />}
            {fetchAutomationsLoading ? (
              <Loading />
            ) : (
              automations && <AutomationTable />
            )}
          </Col>
        </Row>
      </Container>
    </AutomationContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(AutomationsPage))
);
