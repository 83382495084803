import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, withRouter, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import get from "lodash/get";
import SmsCard from "./SmsCard";
import EmailCard from "./EmailCard";
import DirectMailCard from "./DirectMailCard";
import { useCheckAuth } from "../../../../react-check-auth";
import * as ROUTES from "../../../../constants/routes";
import logo from "../../../../assets/images/logo-with-text.png";
import useFetchApi from "../../../../hooks/useFetchApi";
import api from "../../../../common/api";

const SideBarContent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { userInfo } = useCheckAuth();

  // Don't show setup prompts to location users
  const isNotLocationUser = userInfo
    ? userInfo.type !== "location" && userInfo.role !== "user"
    : false;

  return (
    <div className="left-side-menu">
      <Link to="/" className="logo text-center">
        <span className="logo-lg">
          <img src={get(userInfo, "agency.photoUrl") || logo} alt="logo" />
        </span>
      </Link>
      <SimpleBar style={{ maxHeight: "100%" }}>
        <div className="h-100" id="left-side-menu-container" data-simplebar>
          <ul className="metismenu side-nav" style={{ listStyleType: "none" }}>
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_DASHBOARD.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-folder-open"></i>
                <span>{t("locationDashboard.leftSidebar.dashboard")}</span>
              </NavLink>
            </li>
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_TASKS.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-clipboard"></i>
                <span>{t("locationDashboard.leftSidebar.tasks")}</span>
              </NavLink>
            </li>
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_CONVERSATIONS.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-inbox"></i>
                <span>{t("locationDashboard.leftSidebar.conversations")}</span>
              </NavLink>
            </li>
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_OPPORTUNITIES.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-graph-bar"></i>
                <span>{t("locationDashboard.leftSidebar.opportunities")}</span>
              </NavLink>
            </li>
            {isNotLocationUser && (
              <li className="side-nav-item">
                <NavLink
                  to={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS.as(params)}
                  className="side-nav-link"
                >
                  <i className="dripicons-network-2"></i>
                  <span>{t("locationDashboard.leftSidebar.automations")}</span>
                </NavLink>
              </li>
            )}
            {isNotLocationUser && (
              <li className="side-nav-item">
                <NavLink
                  to={ROUTES.AGENCY_LOCATIONS_BULK_REQUESTS.as(params)}
                  className="side-nav-link"
                >
                  <i className="dripicons-broadcast"></i>
                  <span>{t("locationDashboard.leftSidebar.bulkRequests")}</span>
                </NavLink>
              </li>
            )}
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_CONTACTS.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-user"></i>
                <span>{t("locationDashboard.leftSidebar.contacts")}</span>
              </NavLink>
            </li>
            {isNotLocationUser && (
              <li className="side-nav-item">
                <NavLink
                  to={ROUTES.AGENCY_LOCATIONS_TRIGGER_LINKS.as(params)}
                  className="side-nav-link"
                >
                  <i className="dripicons-link"></i>
                  <span>{t("locationDashboard.leftSidebar.triggerLinks")}</span>
                </NavLink>
              </li>
            )}
            {isNotLocationUser && (
              <li className="side-nav-item">
                <NavLink
                  to={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES.as(params)}
                  className="side-nav-link"
                >
                  <i className="dripicons-document"></i>
                  <span>
                    {t("locationDashboard.leftSidebar.emailTemplates")}
                  </span>
                </NavLink>
              </li>
            )}
            <li className="side-nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS.as(params)}
                className="side-nav-link"
              >
                <i className="dripicons-gear"></i>
                <span>{t("locationDashboard.leftSidebar.settings")}</span>
              </NavLink>
            </li>
          </ul>
          {isNotLocationUser && <SetupPrompts />}
        </div>{" "}
      </SimpleBar>
    </div>
  );
};

const SetupPrompts = () => {
  const params = useParams();
  const { locationId } = params;
  const {
    data: location,
    loading: fetchLocationLoading,
    error: fetchLocationError,
  } = useFetchApi(
    api.locations.getById,
    {
      locationId,
    },
    {
      eager: [
        "defaultSmsTransport",
        "defaultVoiceTransport",
        "defaultEmailTransport",
        "defaultDirectMailTransport",
      ],
    },
    []
  );

  // early return
  if (fetchLocationError || fetchLocationLoading || !location || !location.id) {
    return null;
  }

  return (
    <>
      {!location.defaultSmsTransport && <SmsCard />}
      {!location.defaultEmailTransport && <EmailCard />}
      {!location.defaultDirectMailTransport && <DirectMailCard />}
    </>
  );
};

export default withRouter(SideBarContent);
