import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../../AuthorizedRoute";
import * as ROUTES from "../../../../constants/routes";
import AutomationsPage from "./AutomationsPage";
import AutomationsShowPage from "./AutomationsShowPage";

function ContactRouter() {
  return (
    <Switch>
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS.href}
        component={AutomationsPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_AUTOMATIONS_SHOW.href}
        component={AutomationsShowPage}
      />
    </Switch>
  );
}

export default ContactRouter;
