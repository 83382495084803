import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import VoiceServiceForm from "../VoiceServiceForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";

import useFetchApi from "../../../../../../hooks/useFetchApi";

import pickEditSmsService from "../../../../../../common/pickers/pickEditSmsService";

const VoiceServiceEditModal = ({ handleClose, callback, show, id }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const {
    data,
    error: fetchError,
    loading: fetchLoading,
  } = useFetchApi(api.locations.transports.getById, {
    transportId: id,
    locationId,
  });

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.transports.updateById(
        { transportId: id, locationId: locationId },
        { ...pickEditSmsService(values) }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.settingsPage.voicePage.editModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || fetchLoading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            {fetchError && <Error error={fetchError} />}
            <VoiceServiceForm
              {...data}
              action={"edit"}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VoiceServiceEditModal;
