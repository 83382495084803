import removeEmptyStringsWithNull from "../functions/removeEmptyStrings";

export default (values) => {
  const result = {};
  if (values.sender) {
    result.sender = removeEmptyStringsWithNull(values.sender);
  }
  if (values.recipient) {
    result.recipient = removeEmptyStringsWithNull(values.recipient);
  }
  if (values.content) {
    result.content = removeEmptyStringsWithNull(values.content);
  }
  console.log(result);
  return result;
};
