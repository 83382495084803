import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";

const schema = yup.object({
  name: yup.string().required("validation.name.required"),
  longUrl: yup
    .string()
    .required("validation.longUrl.required"),
});

const TriggerLinkForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>
          {t("locationDashboard.triggerLinkPage.form.name.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.triggerLinkPage.form.name.placeholder"
          )}
          value={get(values, "name") || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!get(errors, "name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "name"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formLongUrl">
        <Form.Label>
          {t("locationDashboard.triggerLinkPage.form.longUrl.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.triggerLinkPage.form.longUrl.placeholder"
          )}
          value={get(values, "longUrl") || ""}
          name="longUrl"
          onChange={handleChange}
          isInvalid={!!get(errors, "longUrl")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "longUrl"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.triggerLinkPage.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.triggerLinkPage.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(TriggerLinkForm);
