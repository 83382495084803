export default [
  {
    key: "upsert-null",
    label:
      "Update them with the data in the document, but only for fields that are empty (recommended)",
  },
  {
    key: "upsert",
    label:
      "Update them with the data in the document, including the fields that already have a value",
  },
  { key: "miss", label: "Don`t modify them" },
];
