import React, { useState } from "react";
import { withRouter, NavLink, useParams } from "react-router-dom";
import { Container, Col, Row, Card } from "react-bootstrap";
import Loading from "../Loading";
import EmailForm from "./EmailForm";
import api from "../../common/api";
import AuthLayout from "../../Layout/Auth";

import Error from "../Error";
import * as ROUTES from "../../constants/routes";

const ResetPassordPage = ({ history }) => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    setError(null);
    setLoading(true);
    try {
      await api.auth.resetPassword(token, { password: values.password });
      history.push(ROUTES.SIGN_IN.as())
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) return <Loading />;
  return (
    <AuthLayout>
      <div className=" account-pages mt-5 mb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={5}>
              <Card>
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <span>
                  </span>
                </div>
                <Card.Body className="p-4 position-relative">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                      Reset Password
                    </h4>
                    <p className="text-muted mb-4">Change your password</p>
                  </div>
                  {error && <Error error={error} />}
                  <EmailForm onSubmit={onSubmit} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col className="col-12 text-center">
              <p className="text-muted">
                Don't have an account?{" "}
                <NavLink to={ROUTES.SIGN_IN.as()} className="text-muted ml-1">
                  <b>Back to log In</b>
                </NavLink>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </AuthLayout>
  );
};

export default withRouter(ResetPassordPage);
