import queryString from "query-string";
import api from "../../../../api";

export default function get({ locationId }, query) {
  return api.get(
    `/locations/${locationId}/custom-fields${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
