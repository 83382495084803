import React from "react";
import { Form,     InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import MailgunForm from "./MailgunForm";
import MailjetForm from "./MailjetForm";
import SmtpForm from "./SmtpForm";
import EmailServiceNameInput from "./EmailServiceNameInput";
import getEmailWebhook from "../../../../../../common/functions/getEmailWebhook";

const schema = yup.object({
  name: yup.string(),
  config: yup.object({
    apiKey: yup.string(),
    email: yup.string().required("validation.email.required"),
    host: yup.string(),
    domain: yup.string(),
    publicApiKey: yup.string(),
    privateApiKey: yup.string(),
    password: yup.string(),
    imapHost: yup.string(),
    imapPost: yup.string(),
    smtpHost: yup.string(),
    smtpPort: yup.string(),
  }),
});

const EmailServiceForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      {values.action === "edit" && values.name !== "smtp" && (
        <Form.Group>
          <Form.Label>
            {t(
              "locationDashboard.settingsPage.emailPage.form.leadkrakenWebhook.label"
            )}
          </Form.Label>
          <InputGroup>
            <Form.Control
              id={"webhook"}
              type="text"
              value={getEmailWebhook(values.name, locationId)}
            ></Form.Control>

            <InputGroup.Append
              onClick={() => {
                document.execCommand(
                  "copy",
                  false,
                  document.getElementById("webhook").select()
                );
              }}
            >
              <InputGroup.Text>
                <i className="dripicons-clipboard"></i>
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      )}
      {values.action !== "edit" && (
        <EmailServiceNameInput
          value={get(values, "name") || ""}
          name="name"
          handleChange={handleChange}
          error={!!get(errors, "name")}
        />
      )}

      {get(values, "name") === "mailgun" ? (
        <MailgunForm
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : null}
      {get(values, "name") === "mailjet" ? (
        <MailjetForm
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : null}
      {get(values, "name") === "smtp" ? (
        <SmtpForm
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : null}
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(EmailServiceForm);
