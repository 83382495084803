import React from "react";
import {
  
  
  Card,
  CardBody,
  CardHeader,
  NavLink,
  UncontrolledCollapse,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import { useCheckAuth } from "../../../../../../../react-check-auth";
import roles from "../../../../../../../constants/roles";

import limitedRoles from "../../../../../../../constants/limitedRoles";

const UsersRoles = ({
  handleChange,
  values,
  errors
  
  ,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const limited = userInfo.role === "user";
  return (
    <div id="accordion" className="mb-4">
      <Card className="mb-0">
        <CardHeader>
          <h5 className="m-0">
            <NavLink
              className="custom-accordion-title d-block pt-2 pb-2"
              id="groups2"
              href="#"
            >
              {t(
                "locationDashboard.settingsPage.teamManagementPage.form.userRoles.title"
              )}
              <span className="float-right">
                <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
              </span>
            </NavLink>
          </h5>
        </CardHeader>
        <UncontrolledCollapse toggler="#groups2">
          <CardBody>
            <Form.Group>
              <Form.Label>
                {" "}
                {t(
                  "locationDashboard.settingsPage.teamManagementPage.form.userRoles.role.label"
                )}
              </Form.Label>
              <Form.Control
                name="role"
                as="select"
                value={get(values, "role")}
                onChange={handleChange}
                isInvalid={!!get(errors, "role")}
              >
                <option>Role</option>
                {(limited ? limitedRoles : roles).map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {t(get(errors, "role"))}
              </Form.Control.Feedback>
            </Form.Group>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </div>
  );
};

export default UsersRoles;
