import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../react-check-auth";
import api from "../../../common/api";
import * as ROUTES from "../../../constants/routes";
import { Form, Button } from "react-bootstrap";
import Loading from "../../Loading";
import Error from "../../Error";
import useFirebase from "../../../hooks/useFirebase";
import withFirebase from "../../../hocs/withFirebase";
import isSystemAdmin from "../../../common/functions/isSystemAdmin";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};
const SignInFormBase = ({ history }) => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(INITIAL_STATE);
  const { refreshAuth } = useCheckAuth();

  const onSubmit = async (event) => {
    setLoading(true);
    try {
      const authResult = await firebase.doSignInWithEmailAndPassword(
        user.email,
        user.password
      );
      const idToken = await authResult.user.getIdToken();
      const { data } = await api.session.start({ idToken });
      setUser(INITIAL_STATE);
      refreshAuth();
      setLoading(false);
      if (isSystemAdmin(data)) {
        history.push(ROUTES.ADMIN.as());
      } else {
        history.push(ROUTES.AGENCY.as());
      }
    } catch (e) {
      setError(e);
      setLoading(false);
    }

    event.preventDefault();
  };

  const onChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Form onSubmit={onSubmit}>
        {error && <Error error={error} />}
        <Form.Group controlId="formEmail">
          <Form.Control
            name="email"
            value={user.email}
            onChange={onChange}
            type="text"
            placeholder={t("signInPage.form.email.placeholder")}
          />
        </Form.Group>
        <Form.Group controlId="formPassword1">
          <Form.Control
            name="password"
            value={user.password}
            onChange={onChange}
            autoComplete="current-password"
            type="password"
            placeholder={t("signInPage.form.password.placeholder")}
          />
        </Form.Group>
        <Form.Group controlId="formSignIn">
          <Button
            className="btn btn-lg btn-block"
            variant="primary"
            disabled={user.password === "" || user.email === ""}
            type="submit"
          >
            {t("signInPage.form.submit")}
          </Button>
        </Form.Group>
        <div className="form-group text-center">
          <div className="custom-control custom-control-inline custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="remember-me"
            />{" "}
            <label className="custom-control-label" htmlFor="remember-me">
              {t("signInPage.form.keepSignIn")}
            </label>
          </div>
        </div>

        <div className="text-center pt-3">
          <Link to={ROUTES.FORGOT_PASSWORD.as()} className="">
            {t("signInPage.forgotPassword")}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default withRouter(withFirebase(SignInFormBase));
