import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useCheckAuth } from "../../../../../../react-check-auth";
import FileUpload from "../../../../../Form/FileUpload";
import ImagePreview from "../../../../../Form/ImagePreview";

const schema = yup.object({
  name: yup.string().nullable(),
  phone: yup.string().nullable(),
  email: yup.string().email("validation.email.valid").nullable(),
  websiteUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "validation.url.pattern"
    )
    .nullable(),
});

const CompanyForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  useCheckAuth();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group as={Row} controlId="formName">
        <Col>
          <ImagePreview url={values.logoUrl} file={values.photo} />{" "}
        </Col>
        <Col>
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.logo.label"
            )}
          </Form.Label>
          <FileUpload
            showPreview={false}
            onFileUpload={(files) => {
              setFieldValue("photo", files[0]);
            }}
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="formName">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.name.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.name.placeholder"
          )}
          value={get(values, "name") || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!get(errors, "name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "name"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.email.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.email.placeholder"
          )}
          value={get(values, "email") || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!get(errors, "email")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "email"))}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formPhone">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.phone.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.phone.placeholder"
          )}
          value={get(values, "phone") || ""}
          name="phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "phone")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formWebsiteUrl">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.websiteUrl.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.websiteUrl.placeholder"
          )}
          value={get(values, "websiteUrl") || ""}
          name="websiteUrl"
          onChange={handleChange}
          isInvalid={!!get(errors, "websiteUrl")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "websiteUrl"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formLeadkrakenApiKey">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.leadkrakenApiKey.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          disabled
          placeholder={t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.leadkrakenApiKey.placeholder"
          )}
          value={get(values, "leadkrakenApiKey") || ""}
          name="leadkrakenApiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "leadkrakenApiKey")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "leadkrakenApiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.settingsPage.companySettingsPage.companyDataCard.form.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel"]));
  },
  validationSchema: schema,
})(CompanyForm);
