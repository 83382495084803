import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import api from "../../../../../common/api";
import TemplateTable from "./TemplateTable";
import TemplateContext from "./contexts/TemplateContext";
import TemplateSearchBar from "./TemplateSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const LocationTemplateSettingsPage = () => {
  const { locationId } = useParams();
  const {
    data: templates,
    loading: fetchTemplatesLoading,
    error: fetchTemplatesError,
    getData,
  } = useFetchApi(api.locations.messageTemplates.get, {
    locationId,
  });
  return (
    <TemplateContext.Provider
      value={{
        templates,
        reload: getData,
      }}
    >
      <Container fluid={true}>
        <Row>
          <Col>
            <TemplateSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchTemplatesError && <Error error={fetchTemplatesError} />}
            {fetchTemplatesLoading ? (
              <Loading />
            ) : (
              templates && <TemplateTable />
            )}
          </Col>
        </Row>
      </Container>
    </TemplateContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationUser(LocationTemplateSettingsPage)
);
