import create from "./create";
import updateById from "./updateById";
import deleteById from "./deleteById";
import getById from "./getById";
import get from "./get";
import completeById from "./complete";
import openById from "./open";

export default {
  create,
  updateById,
  deleteById,
  getById,
  get,
  completeById,
  openById,
};
