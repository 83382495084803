import React, {  useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OpportunityContext from "./contexts/OpportunityContext";
import OpportunitySearchBar from "./OpportunitySearchBar";
import useFetchApi from "../../../../hooks/useFetchApi";
import api from "../../../../common/api";
import Error from "../../../Error";
import Loading from "../../../Loading";
// import opportunitySorts from "../../../../constants/opportunitySorts";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";
import withLocationUser from "../../../../hocs/withLocationUser";
import searchOpportunities from "../../../../common/search/searchOpportunities";
import ModalButton from "../../../ModalButton";
import OpportunityEditModal from "./OpportunityEditModal";
import * as ROUTES from "../../../../constants/routes";
import pickOpportunity from "../../../../common/pickers/pickOpportunity";
import formatGermanCurrency from "../../../../common/functions/formatGermanCurrency";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function OpportunitiesPage() {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pipelineId, setPipelineId] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: null,
  });
  const [order, setOrder] = useState("desc");
  const [orderField, setOrderField] = useState("createdAt");
  const [filters, setFilters] = useState({
    automation: "",
    // order: opportunitySorts[0],
    eager: ["stages.opportunities.contact.source"],
  });
  const {
    data: pipeline,
    loading: fetchPipelineLoading,
    error: fetchPipelineError,
    getData,
  } = useFetchApi(
    api.locations.pipelines.getById,
    { locationId, pipelineId },
    clean(filters),
    [...pipelineId, filters],
    pipelineId !== "" && pipelineId !== "Pipeline"
  );

  const onDragEnd = async (result) => {
    const { destination, draggableId, source } = result;
    const { droppableId: stageId } = destination;
    const stage = pipeline.stages.find((s) => s.id === source.droppableId);
    const opportunity = stage.opportunities.find((o) => o.id === draggableId);
    setLoading(true);
    setError(null);
    try {
      await api.locations.opportunities.updateById(
        { locationId, opportunityId: draggableId },
        pickOpportunity({ ...opportunity, stageId })
      );
      getData();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <OpportunityContext.Provider
      value={{
        filters,
        pipeline,
        setFilters,
        setPipelineId,
        pipelineId,
        nameFilter,
        setNameFilter,
        reload: getData,
        dateFilter,
        setDateFilter,
        order,
        setOrder,
        orderField,
        setOrderField,
      }}
    >
      <Container fluid={true}>
        <Row className="mt-2">
          <Col>
            <OpportunitySearchBar />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {fetchPipelineError ? (
              <Error error={fetchPipelineError} />
            ) : fetchPipelineLoading ? (
              <Loading />
            ) : loading ? (
              <Loading />
            ) : pipeline && pipeline.stages && pipelineId !== "Pipeline" ? (
              <>
                {fetchPipelineError && <Error error={fetchPipelineError} />}
                {error && <Error error={error} />}

                <DragDropContext onDragEnd={onDragEnd}>
                  <div className="board">
                    {pipeline.stages.map((stage) => {
                      const visibleOpportunities = stage.opportunities
                        ? searchOpportunities(
                            stage.opportunities,
                            nameFilter,
                            dateFilter,
                            order,
                            orderField
                          )
                        : [];

                      return (
                        <Droppable droppableId={stage.id}>
                          {(provided ) => (
                            <div className="tasks" ref={provided.innerRef}>
                              <h5 className="mt-0 task-header">
                                {stage.name}
                                <p className="mt-2 text-muted">
                                  {t(
                                    "locationDashboard.opportunitiesPage.deals"
                                  )}{" "}
                                  {visibleOpportunities.length}
                                </p>
                                <p className="text-muted">
                                  {formatGermanCurrency(
                                    visibleOpportunities.reduce(
                                      (prev, curr) => prev + curr.leadValue,
                                      0
                                    )
                                  )}
                                </p>
                              </h5>
                              {visibleOpportunities.length === 0 && (
                                <p className="text-center text-muted pt-2 mb-0">
                                  {t(
                                    "locationDashboard.opportunitiesPage.noOpportunities"
                                  )}{" "}
                                </p>
                              )}
                              {visibleOpportunities.map(
                                (opportunity, index) => (
                                  <Draggable
                                    key={opportunity.id}
                                    draggableId={opportunity.id}
                                    index={index}
                                  >
                                    {(provided ) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <ModalButton
                                          modal={<OpportunityEditModal />}
                                          id={opportunity.id}
                                          button={
                                            <Card>
                                              <Card.Body>
                                                <Link
                                                  to={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.as(
                                                    {
                                                      locationId,
                                                      contactId:
                                                        opportunity.contact.id,
                                                    }
                                                  )}
                                                >
                                                  <h5>
                                                    {
                                                      opportunity.contact
                                                        .fullName
                                                    }
                                                  </h5>
                                                </Link>
                                                <p>
                                                  {opportunity.contact.source &&
                                                    opportunity.contact.source
                                                      .name}
                                                </p>
                                                <p>
                                                  {`${
                                                    opportunity.leadValue || 0
                                                  }€`}
                                                </p>
                                              </Card.Body>
                                            </Card>
                                          }
                                          modalProps={{
                                            pipelineId: stage.pipelineId,
                                          }}
                                          callback={getData}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                            </div>
                          )}
                        </Droppable>
                      );
                    })}
                  </div>
                </DragDropContext>
              </>
            ) : null}{" "}
          </Col>
        </Row>
      </Container>
    </OpportunityContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(OpportunitiesPage))
);
