import React from "react";
import {  useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";

import api from "../../../common/api/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import searchSources from "../../../common/search/searchSources";

import Error from "../../Error";
import Loading from "../../Loading";

const SourceInput = ({ values,  setFieldValue, errors, name }) => {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const { data: sources, loading, error  } = useFetchApi(
    api.locations.sources.get,
    {
      locationId,
    }
  );
  const selectSource = (data, event) => {
    if (event.action === "select-option") {
      setFieldValue(`${name}`, data.value);
    }
    if (event.action === "create-option") {
      setFieldValue(`${name}`, { name: data.value });
    }
  };
  const options = sources
    ? searchSources(sources, get(values, `${name}.name`) || "").map((c) => ({
        value: c,
        label: c.name,
      }))
    : [];
  return (
    <>
      {error && <Error error={error} />}
      {loading ? (
        <Loading />
      ) : (
        sources && (
          <Form.Group controlId="formSource">
            <Form.Label>{t("formElements.source.label")}</Form.Label>
            <Creatable
              value={options.find(
                (o) => o.value.id === get(values, `${name}.id`)
              )}
              options={options}
              onChange={selectSource}
              placeholder={t("formElements.source.placeholder")}
            />
            {get(errors, name) && (
              <div className="text-validation">
                {t("validation.sourceId.required")}
              </div>
            )}
          </Form.Group>
        )
      )}
    </>
  );
};

export default SourceInput;
