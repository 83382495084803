import pick from "lodash/pick";
import pickContact from "./pickContact";

export default (data) => {
  const result = pick(data, ["contact", "locationId", "contactId"]);
  if (result.contact) {
    result.contact = { ...pickContact(data.contact) };
    if (data.contact.id) {
      result.contact.id = data.contact.id;
    }
  }

  return result;
};
