import React from "react";
import SmsMessage from "./SmsMessage";
import LetterMessage from "./LetterMessage";
import EmailMessage from "./EmailMessage";
import CallMessage from "./CallMessage";

const UserMessage = ({ message }) => {
  if (message.type === "direct-mail") {
    return <LetterMessage message={message} />;
  } else if (message.type === "email") {
    return <EmailMessage message={message} />;
  } else if (message.type === "call") {
    return <CallMessage message={message} />;
  } else {
    return <SmsMessage message={message} />;
  }
};

export default UserMessage;
