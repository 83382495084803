import React from "react";
import { useTranslation } from "react-i18next";

export default function StepHeader({ number, title, text }) {
  const { t } = useTranslation();
  return (
    <>
      <p className="font-18">
        <strong>
          {" "}
          {t("locationDashboard.contactsPage.importModal.step")} {number}:
        </strong>
        {title}
      </p>
      <p className="font-14">{text}</p>
    </>
  );
}
