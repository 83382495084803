import React, { useState } from "react";
import { Card, Accordion,  Form } from "react-bootstrap";
import omit from "lodash/omit";
import useContact from "../../hooks/useContact";
import { useTranslation } from "react-i18next";

export default function PhoneFilter({ onClose }) {
  const {
    backendFilters: filters,
    setBackendFilters: setFilters,
  } = useContact();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState({});
  const options = [
    {
      key: "1",
      title: t("locationDashboard.contactsPage.filterBox.phoneFilter.notEmpty"),
      onSelect: () => {
        setFilters({ ...omitFilters(filters), isPhoneEmpty: false });
      },
    },
    {
      key: "2",
      title: t("locationDashboard.contactsPage.filterBox.phoneFilter.empty"),
      onSelect: () => {
        setFilters({ ...omitFilters(filters), isPhoneEmpty: true });
      },
    },
  ];
  const omitFilters = (filters) => omit(filters, ["isPhoneEmpty"]);
  const handleClose = () => {
    setFilters(omitFilters(filters));
    onClose();
  };
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="5">
        {selectedOption.title ||
          t(
            "locationDashboard.contactsPage.filterBox.phoneFilter.setPhoneFilter"
          )}{" "}
        <i className="dripicons-cross" onClick={handleClose}></i>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="5">
        <Card.Body>
          {options.map((option) => (
            <>
              {" "}
              <Form.Check
                type="radio"
                label={option.title}
                name="phoneRadios"
                id="formHorizontalRadios1"
                onChange={() => {
                  setSelectedOption(option);
                  option.onSelect();
                }}
              />
            </>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
