import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";
import api from "../../../common/api";
import Error from "../../Error";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const CustomFieldInput = ({ onChange, value = "" }) => {
  useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchCustomFieldsLoading,
    error: fetchCustomFieldsError,
  } = useFetchApi(api.locations.customFields.get, {
    locationId,
  });
  if (fetchCustomFieldsLoading) return <Loading />;
  return (
    <>
      {fetchCustomFieldsError && <Error error={fetchCustomFieldsError} />}
      {data.map((field) => (
        <Form.Group key={`kuniq${field.key}`} controlId={`for${field.key}`}>
          <Form.Label>{field.name}</Form.Label>
          <Form.Control
            type="text"
            placeholder={field.placeholder}
            name={`customFields.${field.key}`}
            value={get(value, field.key)}
            onChange={onChange}
          />
        </Form.Group>
      ))}
    </>
  );
};

export default CustomFieldInput;
