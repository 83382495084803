import React from "react";
import { useCheckAuth } from "../react-check-auth";
import UnauthorizedPage from "../components/UnauthorizedPage";
import isSystemAdmin from "../common/functions/isSystemAdmin";

export default (WrappedPage) => {
  const WithSystemAdmin = ({ ...props }) => {
    const { userInfo } = useCheckAuth();
    if (isSystemAdmin(userInfo)) {
      return <WrappedPage {...props} />;
    } else {
      return <UnauthorizedPage />;
    }
  };
  return WithSystemAdmin;
};
