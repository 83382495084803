import React, { useState } from "react";

export default function ModalButton({
  callback,
  text,
  button,
  modal,
  id,
  className,
  modalProps,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {React.cloneElement(button, {
        className,
        onClick: () => {
          handleShow();
        },
      })}
      {show &&
        React.cloneElement(modal, {
          show: show,
          handleClose: handleClose,
          callback: callback,
          text: text,
          id,
          ...modalProps,
        })}
    </>
  );
}
