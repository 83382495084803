import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import { useCheckAuth } from "../react-check-auth";
import ChatContext from "../contexts/ChatContext";
import api from "../common/api";
import useFetchArrayApi from "../hooks/useFetchArrayApi";
import useFetchApi from "../hooks/useFetchApi";
import searchConversations from "../common/search/searchConversations";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      propName === "status"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export default (WrappedPage) => {
  const WithChat = ({ ...props }) => {
    const { locationId, contactId } = useParams();
    const { current: socket } = useRef(
      process.env.NODE_ENV === "development"
        ? io(process.env.REACT_APP_SOCKET_DOMAIN)
        : io()
    );
    const { userInfo } = useCheckAuth();

    const [error, setError] = useState(null);
    const [replyMessage, setReplyMessage] = useState(null);
    const [updateChatId, setUpdateChatId] = useState(null);
    const [chatFilters, setChatFilters] = useState(
      contactId
        ? { status: "all" }
        : {
            status: "unread",
          }
    );
    const [chatApiFilters, setChatApiFilters] = useState(
      contactId
        ? {}
        : {
            sort: "lastMessageAt",
            read: false,
          }
    );
    const [selectedChat, setSelectedChat] = useState({});
    const [initialized, setInitialized] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(1);

    const {
      data: { results: chats, total: totalChats },

      getData: getChats,
    } = useFetchArrayApi(
      api.locations.conversations.get,
      {
        locationId,
      },
      {
        eager: ["lastMessage", "contact.[opportunities, tags]"],
        order: "desc",
        sort: "createdAt",
        limit: 30 * numberOfPages,
        page: 1,
        contactId,
        ...clean(chatApiFilters),
      },
      [chatApiFilters, numberOfPages],
      true,
      { results: [], total: 0 }
    );

    const { data: location } = useFetchApi(
      api.locations.getById,
      {
        locationId,
      },
      {
        eager: ["defaultSmsTransport", "defaultEmailTransport"],
      },
      []
    );

    const {
      data: messages,

      getData: getMessages,
    } = useFetchArrayApi(
      api.locations.conversations.messages.get,
      {
        locationId,
        conversationId: selectedChat.id,
      },
      {
        eager: ["location", "contact"],
      },
      [selectedChat.id, numberOfPages],
      !!selectedChat.id
    );
    //initializing
    useEffect(() => {
      if (!initialized) {
        socket.open();

        socket.on("update-chat-messages", async ({ chatId }) => {
          setUpdateChatId(chatId);
        });

        socket.on("exception", async (error) => {
          setError(error);
        });

        socket.emit("join-chats", {
          userId: userInfo.id,
        });

        setInitialized(true);
      }
      return () => {
        socket.close();
      };
    }, []);

    //selecting chat if it is not selected
    useEffect(() => {
      if (chats && chats.length > 0) {
        if (!get(selectedChat, "id")) {
          setSelectedChat(chats[0] || {});
        } else {
          setSelectedChat(chats.find((c) => c.id === selectedChat.id) || {});
        }
      } else {
        setSelectedChat({});
      }
    }, [chats]);

    useEffect(() => {
      if (updateChatId) {
        if (updateChatId === selectedChat.id) getMessages();
        getChats();
        setUpdateChatId(null);
      }
    }, [updateChatId]);

    const filteredChats = searchConversations(
      chats,
      chatFilters.searchFilter || ""
    );
    return (
      <ChatContext.Provider
        value={{
          chatFilters,
          setChatFilters,
          chatApiFilters,
          setChatApiFilters,
          selectedChat,
          setSelectedChat,
          messages,
          chats: filteredChats,
          getChats,
          setNumberOfPages,
          numberOfPages,
          location,
          totalChats,
          replyMessage,
          setReplyMessage,
        }}
      >
        <WrappedPage {...props} />
      </ChatContext.Provider>
    );
  };
  return WithChat;
};
