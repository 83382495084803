import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../../AuthorizedRoute";
import * as ROUTES from "../../../../constants/routes";
import ContactsPage from "./ContactsPage";
import ContactShowPage from "./ContactShowPage";

function ContactRouter() {
  return (
    <Switch>
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS.href}
        component={ContactsPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.href}
        component={ContactShowPage}
      />
    </Switch>
  );
}

export default ContactRouter;
