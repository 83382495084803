import React from "react";
import { useTranslation } from "react-i18next";
import countries from "../../../constants/countries";
import { Form } from "react-bootstrap";

export default function CountryInput({
  name,
  handleChange,
  value,
  error,
  defaultOption = false,
}) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formCountry">
      <Form.Label> {t("formElements.country.label")}</Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value}
        onChange={handleChange}
        isInvalid={error}
      >
        {defaultOption && <option>Country</option>}
        {countries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
