import React from "react";
import { Redirect, useParams } from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import LocationContext from "../contexts/LocationContext";
import useFetchApi from "../../../../hooks/useFetchApi";
import api from "../../../../common/api";
import Error from "../../../Error";
import Loading from "../../../Loading";

export default (WrappedPage) => {
  const WithLocation = ({ ...props }) => {
    const { locationId } = useParams();
    const {
      data: location,
      loading,
      error,
    } = useFetchApi(api.locations.getById, { locationId });
    if (loading) {
      return (
        <div className="w-100">
          <Loading />
        </div>
      );
    }
    if (error) return <Error error={error} />;
    if (location) {
      return (
        <LocationContext.Provider value={{ location }}>
          <WrappedPage {...props} />
        </LocationContext.Provider>
      );
    } else {
      return <Redirect to={ROUTES.SIGN_IN.as()} />;
    }
  };
  return WithLocation;
};
