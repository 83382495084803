import parseISO from "date-fns/parseISO";
import searchContacts from "./searchContacts";

function searchOpportunities(
  opportunities,
  opportunityFilter,
  dateFilter,
  order,
  orderField = "createdAt"
) {
  const result = opportunities.filter(
    (e) =>
      searchContacts([e.contact], opportunityFilter, -1).length > 0 &&
      (dateFilter.startDate && dateFilter.endDate
        ? (parseISO(e.createdAt) > dateFilter.startDate &&
            parseISO(e.createdAt) < dateFilter.endDate) ||
          (parseISO(e.updatedAt) > dateFilter.startDate &&
            parseISO(e.updatedAt) < dateFilter.endDate)
        : true)
  );
  // console.log(`sorting by ${orderField} ${order}`, result);
  result.sort((o1, o2) => {
    // Add here if you're wanting more fields to do Date comparison
    if (orderField === "createdAt") {
      // Equal date objects
      if (parseISO(o1[orderField]) === parseISO(o2[orderField])) {
        console.log("fields equal");
        return 0;
      }

      if (order === "desc") {
        return parseISO(o1[orderField]) < parseISO(o2[orderField]) ? 1 : -1;
      } 
        return parseISO(o1[orderField]) > parseISO(o2[orderField]) ? 1 : -1;
      
    }

    if (order === "desc") {
      return o2[orderField] - o1[orderField];
    }
    return o1[orderField] - o2[orderField];

    // Note: Not used for now
    // String compare:
    // if (order === "desc") {
    //   return o1[orderField].localeCompare(o2[orderField], "en", {
    //     sensitivity: "base",
    //   });
    // }
    // return o2[orderField].localeCompare(o1[orderField], "en", {
    //   sensitivity: "base",
    // });
  });
  return result;
}
export default searchOpportunities;
