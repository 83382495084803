import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import isNaN from "lodash/isNaN";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";

function isNill(value) {
  return (
    isUndefined(value) ||
    isNull(value) ||
    isNaN(value) ||
    (isString(value) && isEmpty(value))
  );
}

export default isNill;
