import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import MessageInput from "../../../../../../../Form/MessageInput";

export const schema = yup.object({
  content: yup.object({
    text: yup.string(),
  }),
  phone: yup.string().required(),
});

const CallForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div>
        <i className="mdi mdi-circle font-13 text-success mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.callForm.portDescription.successHint"
        )}
      </div>

      <div className="mb-3">
        <i className="mdi mdi-circle font-13 text-danger mr-2"> </i>
        {t(
          "locationDashboard.automationShowPage.callForm.portDescription.failureHint"
        )}
      </div>
      <Form.Group controlId="formPhone">
        <Form.Label>
          {t("locationDashboard.automationShowPage.callForm.phone.label")}
          <br />
          {t("locationDashboard.automationShowPage.callForm.phone.hint")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.callForm.phone.placeholder"
          )}
          value={get(values, "phone") || ""}
          name="phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "phone")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <MessageInput
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        type={"call"}
      />
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.callForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(CallForm);
