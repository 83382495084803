import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFetchApi from "../../../../../../../hooks/useFetchArrayApi";
import api from "../../../../../../../common/api";
import Error from "../../../../../../Error";
import Loading from "../../../../../../Loading";
import { useTranslation } from "react-i18next";
import TaskCard from "../TaskCard";
import AccountInput from "../../../../../../Form/AccountInput";
import get from "lodash/get";
import taskDates from "../../../../../../../constants/taskDates";
import taskStatuses from "../../../../../../../constants/taskStatuses";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "All" ||
      obj[propName] === "All accounts"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export default function TaskListPage() {
  const { contactId, locationId } = useParams();
  const [filters, setFilters] = useState({ order: "desc" });
  const { t } = useTranslation();
  const {
    data,
    loading: fetchTasksLoading,
    error: fetchTasksError,
    getData,
  } = useFetchApi(
    api.locations.tasks.get,
    {
      locationId: locationId,
    },
    { eager: ["account"], contactId: contactId, ...clean(filters) },
    [filters, contactId]
  );
  return (
    <>
      {fetchTasksError && <Error error={fetchTasksError} />}
      <Row>
        <Col lg={4} md={4} sm={12} xs={12}>
          <AccountInput
            onChange={(e) => {
              setFilters({ ...filters, accountId: e.target.value });
            }}
            value={get(filters, "accountId") || ""}
          />
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <Form.Group controlId="forOrder">
            <Form.Control
              name="order"
              as="select"
              value={filters.order}
              onChange={(e) =>
                setFilters({ ...filters, order: e.target.value })
              }
            >
              {taskDates.map((date) => (
                <option key={date.order} value={date.order}>
                  {date.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <Form.Group controlId="forStatus">
            <Form.Control
              name="status"
              as="select"
              value={filters.status}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
            >
              <option>All</option>
              {taskStatuses.map((date) => (
                <option key={date.value} value={date.value}>
                  {date.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      {fetchTasksLoading ? (
        <Loading />
      ) : data.length === 0 ? (
        <>
          <Row>
            <Col>
              {" "}
              {t("locationDashboard.contactShowPage.tasksPage.list.hint")}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="task-list">
            <Col>
              {data.map((d) => (
                <TaskCard task={d} reload={getData} key={d.id} />
              ))}
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
