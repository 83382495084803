import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, withRouter } from "react-router-dom";
import * as ROUTES from "../../../../../../../constants/routes";
import api from "../../../../../../../common/api";
import pickTask from "../../../../../../../common/pickers/pickTask";
import Loading from "../../../../../../Loading";
import Error from "../../../../../../Error";
import TaskForm from "../TaskForm";
import useFetchApi from "../../../../../../../hooks/useFetchArrayApi";

function TaskEditPage({ history }) {
  const { locationId, contactId, taskId } = useParams();
  const {
    data: task,
    loading: fetchTaskLoading,
    error: fetchTaskError,
  } = useFetchApi(api.locations.tasks.getById, {
    locationId: locationId,
    contactId: contactId,
    taskId: taskId,
  });
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    history.push(
      ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS.as({ locationId, contactId })
    );
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      const {} = await api.locations.tasks.updateById(
        { locationId: locationId, taskId },
        {
          ...pickTask(values),
        }
      );
      history.push(
        ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS.as({
          locationId,
          contactId,
        })
      );
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Container>
      {error && <Error error={error} />}
      {fetchTaskError && <Error error={fetchTaskError} />}
      <Row>
        <Col>
          {loading ? (
            <Loading />
          ) : fetchTaskLoading ? (
            <Loading />
          ) : (
            <TaskForm
              onSubmit={onSubmit}
              {...task}
              onCancel={onCancel}
              contactId={contactId}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(TaskEditPage);
