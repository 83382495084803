function getDelay(value, timeSelector) {
  let delay;
  if (timeSelector === "seconds") {
    delay = value * 1000;
  }
  if (timeSelector === "minutes") {
    delay = value * 60 * 1000;
  }
  if (timeSelector === "hours") {
    delay = value * 60 * 60 * 1000;
  }
  if (timeSelector === "days") {
    delay = value * 24 * 60 * 60 * 1000;
  }
  return delay;
}

export default getDelay;
