import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import withStripe from "../../../../../hocs/withStripe";
import Error from "../../../../Error";
import Loading from "../../../../Loading";

const PaymentMethodEdit = () => {
  const { userInfo } = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const savePaymentMethod = async () => {
    const cardElement = elements.getElement(CardElement);
    if (!stripe || !elements) {
      setError({ message: "Try one more time" });
    }

    const { error, token } = await stripe.createToken(cardElement);
    if (error) setError(error);
    setLoading(true);
    try {
      await api.agencies.updatedSourceById(userInfo.agency.id, {
        token: token.id,
      });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) return <Loading />;
  return (
    <Form>
      {error && <Error error={error} />}
      <Form.Group controlId="formPassword">
        <CardElement />
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Button
          onClick={savePaymentMethod}
          variant={"success"}
          disabled={userInfo.role === "user"}
        >
          Save
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withStripe(PaymentMethodEdit);
