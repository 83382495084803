import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../react-check-auth";
import useOpportunity from "../hooks/useOpportunity";
import opportunitySorts from "../../../../../constants/opportunitySorts";
import PipelineInput from "../../../../Form/PipelineInput";
import ModalButton from "../../../../ModalButton";
import OpportunityCreateModal from "../OpportunityCreateModal";
import DateTimeRangeDropdown from "../DateTimeRangeDropdown";

const OpportunitySearchBar = () => {
  const { userInfo } = useCheckAuth();
  const { t } = useTranslation();
  const {
    filters,
    setFilters,
    pipelineId,
    setPipelineId,
    reload,
    nameFilter,
    setNameFilter,
    setOrder,
    order,
    orderField,
    setOrderField,
  } = useOpportunity();
  const handleChange = (e) => {
    if (e.target.name === "pipelineId") {
      setPipelineId(e.target.value);
    } else if (e.target.name === "name") {
      setNameFilter(e.target.value);
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={3} md={3} sx={12} sm={12}>
          <Form.Control
            name="order"
            as="select"
            value={`${orderField},${order}`}
            onChange={(e) => {
              // Set the ordering direction and field to order on - see searchOpportunities function
              const [field, order] = e.target.value.split(",");
              setOrder(order);
              setOrderField(field);
            }}
          >
            {opportunitySorts.map((sort) => (
              <option
                key={`${sort.field},${sort.order}`}
                value={`${sort.field},${sort.order}`}
              >
                {sort.title}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col lg={2} md={2} sx={12} sm={12}>
          <PipelineInput
            onChange={handleChange}
            value={pipelineId}
            setInitialValue={true}
          />
        </Col>

        <Col lg={2} md={2} sx={12} sm={12}>
          <Form.Control
            name="name"
            onChange={handleChange}
            value={nameFilter}
            type="text"
            placeholder={t("locationDashboard.opportunitiesPage.search")}
          />
        </Col>
        <Col lg={2} md={2} sx={12} sm={12}>
          <DateTimeRangeDropdown />
        </Col>
        <Col lg={3} md={3} sx={12} sm={12}>
          {" "}
          <div className="btn-group">
            <ModalButton
              className="m-1"
              modal={<OpportunityCreateModal />}
              button={
                <Button
                  disabled={
                    userInfo.role === "user" && userInfo.type === "location"
                  }
                >
                  <i className="dripicons-plus"></i>
                  {t("locationDashboard.opportunitiesPage.addNewButton")}
                </Button>
              }
              callback={reload}
            />
            {/*
            <Button className="m-1">
              <i className="dripicons-download"></i>
            </Button>*/}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default OpportunitySearchBar;
