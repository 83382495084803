import React from "react";
import { useParams } from "react-router-dom";
import { useCheckAuth } from "../react-check-auth";
import UnauthorizedPage from "../components/UnauthorizedPage";
import isLocationAdmin from "../common/functions/isLocationAdmin";

export default (WrappedPage) => {
  const WithLocationAdmin = ({ ...props }) => {
    const { locationId } = useParams();
    const { userInfo } = useCheckAuth();
    if (isLocationAdmin(userInfo, locationId)) {
      return <WrappedPage {...props} />;
    } else {
      return <UnauthorizedPage />;
    }
  };
  return WithLocationAdmin;
};
