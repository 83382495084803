import React from "react";
import { useTranslation } from "react-i18next";
import hosts from "../../../../../../../constants/hosts";
import { Form } from "react-bootstrap";

export default function MailgunHostInput({ name, handleChange, value, error }) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formHost">
      <Form.Label>
        {t("locationDashboard.settingsPage.emailPage.form.host.label")}
      </Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value}
        onChange={handleChange}
        isInvalid={error}
      >
        {hosts.map((host) => (
          <option key={host} value={host}>
            {host}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
