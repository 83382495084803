import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";

import equalTo from "../../../common/yup/equalTo";
import passwordPattern from "../../../common/yup/passwordPattern";

yup.addMethod(yup.string, "equalTo", equalTo);

const schema = yup.object({
  oldPassword: yup
    .string()
    .required("validation.password.required")
    .matches(passwordPattern, "validation.password.pattern"),
  password: yup
    .string()
    .required("validation.password.required")
    .matches(passwordPattern, "validation.password.pattern"),
  confirmPassword: yup
    .string()
    .equalTo(yup.ref("password"), "validation.password.equal")
    .required("validation.confirmPassword.required"),
});

const SignUpForm = ({
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formOldPassword">
        <Form.Label>{t("passwordDataCard.form.oldPassword.label")}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("passwordDataCard.form.oldPassword.placeholder")}
          value={values.oldPassword || ""}
          name="oldPassword"
          onChange={handleChange}
          isInvalid={!!errors.oldPassword}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.oldPassword)}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formNewPassword">
        <Form.Label>{t("passwordDataCard.form.newPassword.label")}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("passwordDataCard.form.newPassword.placeholder")}
          value={values.password || ""}
          name="password"
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.password)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Label>
          {t("passwordDataCard.form.confirmPassword.label")}
        </Form.Label>
        <Form.Control
          type="password"
          placeholder={t("passwordDataCard.form.confirmPassword.placeholder")}
          value={values.confirmPassword || ""}
          name="confirmPassword"
          onChange={handleChange}
          isInvalid={!!errors.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.confirmPassword)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBirthDate">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("passwordDataCard.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit"]));
  },
  validationSchema: schema,
})(SignUpForm);
