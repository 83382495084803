import React from "react";
import { useParams } from "react-router-dom";
import get from "lodash/get";
import { Container, Row, Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import allElements from "../../constants/elements";
import useAutomation from "../../hooks/useAutomation";
import pickSource from "../../../../../../../common/pickers/pickSource";
import api from "../../../../../../../common/api";
import { useTranslation } from "react-i18next";
import pickTag from "../../../../../../../common/pickers/pickTag";

export default function AutomationElementForm() {
  const { locationId } = useParams();
  const { selectedNode, setSelectedNode, setSaved } = useAutomation();
  const { t } = useTranslation();

  const element = allElements.find((e) => e.data === selectedNode.name);
  const handleSubmit = async (values) => {
    // Visual feedback on save
    NotificationManager.success("Changes saved");

    // Creating the new tags on the server and updating our contact tags array to have just existing tags with IDs
    // TODO: This should be server protected
    if (element.data === "update_contact") {
      if (get(values, "contact.tags")) {
        const newTags = get(values, "contact.tags").filter(
          (t) => t.name === t.id
        );
        if (newTags.length > 0) {
          const { data: tags } = await api.locations.tags.createMultiple(
            { locationId },
            { tags: newTags.map((t) => ({ name: t.name })) }
          );
          values.contact.tags = [...tags, ...get(values, "contact.tags")]
            .filter((t) => t.name !== t.id)
            .map((t) => pickTag(t));
        } else {
          values.contact.tags = get(values, "contact.tags").map((t) =>
            pickTag(t)
          );
        }
      }
      if (get(values, "contact.source")) {
        if (!get(values, "contact.source.id")) {
          const { data: s } = await api.locations.sources.create(
            { locationId },
            get(values, "contact.source")
          );
          values.contact.source = pickSource(s);
        } else {
          values.contact.source = pickSource(values.contact.source);
        }
      }
    }

    selectedNode.data = values;

    setSaved(false);
  };
  return (
    <Container>
      <Row>
        <Col>
          <h4>{t(`${element.translationKey}.title`)}</h4>
        </Col>
        <Col className="text-right">
          <i
            className="dripicons-cross"
            onClick={() => setSelectedNode(null)}
          ></i>
        </Col>
      </Row>
      <Row>
        <Col>
          {element.getFormComponent({
            ...selectedNode.data,
            onSubmit: handleSubmit,
          })}
        </Col>
      </Row>
    </Container>
  );
}
