import React from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import CustomFieldContext from "./contexts/CustomFieldContext";
import CustomFieldSearchBar from "./CustomFieldSearchBar";
import CustomFieldTable from "./CustomFieldTable";
import api from "../../../../../common/api";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

function CustomFieldsPage() {
  const { locationId } = useParams();
  const {
    data: requests,
    loading: fetchCustomFieldsLoading,
    error: fetchCustomFieldsError,
    getData,
  } = useFetchApi(api.locations.customFields.get, {
    locationId: locationId,
  });

  return (
    <CustomFieldContext.Provider
      value={{
        requests,
        reload: () => {
          getData();
        },
      }}
    >
      <Container className="mt-3" fluid={true}>
        <Row>
          <Col>
            <CustomFieldSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchCustomFieldsError && <Error error={fetchCustomFieldsError} />}
            {fetchCustomFieldsLoading ? (
              <Loading />
            ) : (
              requests && <CustomFieldTable />
            )}
          </Col>
        </Row>
      </Container>
    </CustomFieldContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(CustomFieldsPage))
);
