import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import weeks from "../../../constants/weeks";
import times from "../../../constants/times";

export default function TimeWindowInput({
  name,
  handleChange,
  value,
  error,
  setFieldValue,
}) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  return (
    <>
      <p>{t("formElements.inputDays.hint")}</p>
      <Form.Group>
        <Form.Label>{t("formElements.inputDays.label")}</Form.Label>
        <Row>
          {" "}
          {weeks.map((week) => (
            <Col
              style={{ cursor: "pointer" }}
              className={`m-1 p-2 ${
                selected === week.value
                  ? "bg-success"
                  : value[week.value]
                  ? "bg-success"
                  : "bg-light"
              }`}
              onClick={() => {
                if (week.value !== null && selected === week.value) {
                  setSelected(null);
                  setFieldValue(name, {
                    ...value,
                    [week.value]: null,
                  });
                } else {
                  setSelected(week.value);
                  if (
                    value[week.value] === null ||
                    value[week.value] === undefined
                  ) {
                    setFieldValue(name, {
                      ...value,
                      [week.value]: [
                        times[0].value,
                        times[times.length - 1].value,
                      ],
                    });
                  }
                }
              }}
            >
              {week.title}
            </Col>
          ))}
        </Row>
      </Form.Group>
      {selected !== null && (
        <>
          <p>
            {t("formElements.inputDays.day.label")}{" "}
            <strong>{weeks.find((w) => w.value === selected).title}</strong>
          </p>
          <Row>
            <Col>{t("formElements.inputDays.day.startTime.label")}</Col>
            <Col>
              <Form.Group controlId="formTimeSelector">
                <Form.Control
                  name={`${name}.${selected}[0]`}
                  as="select"
                  value={get(value, `${selected}[0]`)}
                  onChange={handleChange}
                  isInvalid={error}
                >
                  <option>Select</option>
                  {times.map((time) => (
                    <option key={time.value} value={time.value}>
                      {time.title}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>{t("formElements.inputDays.day.endTime.label")}</Col>
            <Col>
              <Form.Group controlId="formTimeSelector">
                <Form.Control
                  name={`${name}.${selected}[1]`}
                  as="select"
                  value={get(value, `${selected}[1]`)}
                  onChange={handleChange}
                  isInvalid={error}
                >
                  <option>Select</option>
                  {times.map((time) => (
                    <option key={time.value} value={time.value}>
                      {time.title}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
