export const HOME = {
  href: "/",
  as: () => `/`,
};
export const FORGOT_PASSWORD = {
  href: "/forgot-password",
  as: () => `/forgot-password`,
};
export const RESET_PASSWORD = {
  href: "/reset-password/:token",
  as: (params) => `/reset-password/${params.token}`,
};
export const SIGN_IN = {
  href: "/sign_in",
  as: () => `/sign_in`,
};
export const INVITATIONS_SIGN_UP = {
  href: "/invites/:inviteId/sign_up",
  as: ({ inviteId }) => `/invites/${inviteId}/sign_up`,
};
export const SIGN_UP = {
  href: "/sign_up",
  as: () => `/sign_up`,
};
export const ADMIN = {
  href: "/admin",
  as: () => `/admin`,
};
export const ADMIN_LOCATIONS = {
  href: "/admin/locations",
  as: () => `/admin/locations`,
};
export const AGENCY = {
  href: "/",
  as: () => `/`,
};
export const AGENCY_LOCATIONS = {
  href: "/locations",
  as: () => `/locations`,
};
export const AGENCY_LOCATIONS_DASHBOARD = {
  href: "/locations/:locationId",
  as: (params) => `/locations/${params.locationId}`,
};
export const AGENCY_LOCATIONS_CONVERSATIONS = {
  href: "/locations/:locationId/conversations",
  as: (params) => `/locations/${params.locationId}/conversations`,
};
export const AGENCY_LOCATIONS_OPPORTUNITIES = {
  href: "/locations/:locationId/opportunities",
  as: (params) => `/locations/${params.locationId}/opportunities`,
};
export const AGENCY_LOCATIONS_BULK_REQUESTS = {
  href: "/locations/:locationId/bulk-requests",
  as: (params) => `/locations/${params.locationId}/bulk-requests`,
};
export const AGENCY_LOCATIONS_TRIGGER_LINKS = {
  href: "/locations/:locationId/trigger-links",
  as: (params) => `/locations/${params.locationId}/trigger-links`,
};
export const AGENCY_LOCATIONS_CONTACTS = {
  href: "/locations/:locationId/contacts",
  as: (params) => `/locations/${params.locationId}/contacts`,
};
export const AGENCY_LOCATIONS_CONTACTS_SHOW = {
  href: "/locations/:locationId/contacts/:contactId/show",
  as: (params) =>
    `/locations/${params.locationId}/contacts/${params.contactId}/show`,
};

export const AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS = {
  href: "/locations/:locationId/contacts/:contactId/show/tasks",
  as: (params) =>
    `/locations/${params.locationId}/contacts/${params.contactId}/show/tasks`,
};
export const AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_CREATE = {
  href: "/locations/:locationId/contacts/:contactId/show/tasks/create",
  as: (params) =>
    `/locations/${params.locationId}/contacts/${params.contactId}/show/tasks/create`,
};
export const AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_EDIT = {
  href: "/locations/:locationId/contacts/:contactId/show/tasks/:taskId/edit",
  as: (params) =>
    `/locations/${params.locationId}/contacts/${params.contactId}/show/tasks/${params.taskId}/edit`,
};
export const AGENCY_LOCATIONS_SETTINGS = {
  href: "/locations/:locationId/settings",
  as: (params) => `/locations/${params.locationId}/settings`,
};
export const AGENCY_LOCATIONS_AUTOMATIONS = {
  href: "/locations/:locationId/automations",
  as: (params) => `/locations/${params.locationId}/automations`,
};
export const AGENCY_LOCATIONS_TASKS = {
  href: "/locations/:locationId/tasks",
  as: (params) => `/locations/${params.locationId}/tasks`,
};
export const AGENCY_LOCATIONS_AUTOMATIONS_SHOW = {
  href: "/locations/:locationId/automations/:automationId/show",
  as: (params) =>
    `/locations/${params.locationId}/automations/${params.automationId}/show`,
};
export const AGENCY_LOCATIONS_EMAIL_TEMPLATES = {
  href: "/locations/:locationId/email-templates",
  as: (params) => `/locations/${params.locationId}/email-templates`,
};
export const AGENCY_LOCATIONS_EMAIL_TEMPLATES_CREATE = {
  href: "/locations/:locationId/email-templates/create",
  as: (params) => `/locations/${params.locationId}/email-templates/create`,
};
export const AGENCY_LOCATIONS_EMAIL_TEMPLATES_EDIT = {
  href: "/locations/:locationId/email-templates/edit/:emailTemplateId",
  as: (params) =>
    `/locations/${params.locationId}/email-templates/edit/${params.emailTemplateId}`,
};
export const AGENCY_LOCATIONS_SETTINGS_TEAM_MANAGEMENT = {
  href: "/locations/:locationId/settings/team-management",
  as: (params) => `/locations/${params.locationId}/settings/team-management`,
};
export const AGENCY_LOCATIONS_SETTINGS_TAGS = {
  href: "/locations/:locationId/settings/tags",
  as: (params) => `/locations/${params.locationId}/settings/tags`,
};
export const AGENCY_LOCATIONS_SETTINGS_SMS = {
  href: "/locations/:locationId/settings/sms",
  as: (params) => `/locations/${params.locationId}/settings/sms`,
};
export const AGENCY_LOCATIONS_SETTINGS_VOICE = {
  href: "/locations/:locationId/settings/voice",
  as: (params) => `/locations/${params.locationId}/settings/voice`,
};
export const AGENCY_LOCATIONS_SETTINGS_EMAIL = {
  href: "/locations/:locationId/settings/email",
  as: (params) => `/locations/${params.locationId}/settings/email`,
};
export const AGENCY_LOCATIONS_SETTINGS_DIRECT_EMAIL = {
  href: "/locations/:locationId/settings/direct-email",
  as: (params) => `/locations/${params.locationId}/settings/direct-email`,
};
export const AGENCY_LOCATIONS_SETTINGS_PROFILE = {
  href: "/locations/:locationId/settings/profile",
  as: (params) => `/locations/${params.locationId}/settings/profile`,
};
export const AGENCY_LOCATIONS_SETTINGS_SOURCES = {
  href: "/locations/:locationId/settings/sources",
  as: (params) => `/locations/${params.locationId}/settings/sources`,
};
export const AGENCY_LOCATIONS_SETTINGS_TEMPLATES = {
  href: "/locations/:locationId/settings/templates",
  as: (params) => `/locations/${params.locationId}/settings/templates`,
};
export const AGENCY_LOCATIONS_SETTINGS_PIPELINES = {
  href: "/locations/:locationId/settings/pipelines",
  as: (params) => `/locations/${params.locationId}/settings/pipelines`,
};
export const AGENCY_LOCATIONS_SETTINGS_CUSTOM_FIELDS = {
  href: "/locations/:locationId/settings/custom-fields",
  as: (params) => `/locations/${params.locationId}/settings/custom-fields`,
};
export const AGENCY_LOCATIONS_SETTINGS_COMPANY = {
  href: "/locations/:locationId/settings/company",
  as: (params) => `/locations/${params.locationId}/settings/company`,
};

export const AGENCY_HELP = {
  href: "/help",
  as: () => `/help`,
};
export const AGENCY_SETTINGS = {
  href: "/settings",
  as: () => `/settings`,
};
export const AGENCY_SETTINGS_AGENCY = {
  href: "/settings/agency",
  as: () => `/settings/agency`,
};
export const AGENCY_SETTINGS_USER = {
  href: "/settings/user",
  as: () => `/settings/user`,
};
export const AGENCY_SETTINGS_AGENCY_TEAM_MANAGEMENT = {
  href: "/settings/agency-team-management",
  as: () => `/settings/agency-team-management`,
};
export const AGENCY_SETTINGS_AGENCY_BILLING = {
  href: "/settings/agency-billing",
  as: () => `/settings/agency-billing`,
};
