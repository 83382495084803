import React, { useState } from "react";
import { withRouter, NavLink, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import Loading from "../Loading";
import SignUpForm from "./SignUpForm";
import AuthLayout from "../../Layout/Auth";
import api from "../../common/api";
import * as ROUTES from "../../constants/routes";
import useFetchApi from "../../hooks/useFetchApi";
import Error from "../Error";
import pickUser from "./pickUser";
import { useCheckAuth } from "../../react-check-auth";
import useFirebase from "../../hooks/useFirebase";
import withFirebase from "../../hocs/withFirebase";

const InvitationSignUp = ({ history }) => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const { refreshAuth } = useCheckAuth();
  const { inviteId } = useParams();
  const {
    data: invite,
    error: fetchError,
    loading: fetchLoading,
  } = useFetchApi(api.invites.getById, { inviteId }, { eager: ["account"] });
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  if (loading || fetchLoading) return <Loading />;
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await api.invites.acceptById({ inviteId }, pickUser(data));
      const authResult = await firebase.doSignInWithEmailAndPassword(
        data.email,
        data.password
      );
      const idToken = await authResult.user.getIdToken();
      await api.session.start({ idToken });
      refreshAuth();
      setLoading(false);
      history.push(ROUTES.AGENCY.as());
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <AuthLayout>
      <div className="account-pages mt-5 mb-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-header pt-4 pb-4 text-center bg-primary">
                  <span>
                  </span>
                </div>

                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                      {t("signUpInvite.title")}
                    </h4>
                    <p className="text-muted mb-4">{t("signUpInvite.hint")}</p>
                  </div>
                  {fetchError && <Error error={fetchError} />}
                  {error && <Error error={error} />}
                  <SignUpForm onSubmit={onSubmit} {...invite.account} />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <p className="text-muted">
                    {t("signUpInvite.haveAccount")}{" "}
                    <NavLink
                      to={ROUTES.SIGN_IN.as()}
                      className="text-muted ml-1"
                    >
                      <b>{t("signUpInvite.signIn")}</b>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default withRouter(withFirebase(withTranslation()(InvitationSignUp)));
