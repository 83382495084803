import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../react-check-auth";
import api from "../../../../common/api";
import LocationForm from "../LocationForm";
import Error from "../../../Error";
import Loading from "../../../Loading";

const LocationCreateModal = ({ handleClose, callback,  show }) => {
  const { t } = useTranslation();
  const {  refreshAuth } = useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.create(values);
      if (callback) callback();
      refreshAuth();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("locationsPage.createModal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <LocationForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default LocationCreateModal;
