import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useLocation from "../../hooks/useLocation";
import api from "../../../../../common/api";
import { useCheckAuth } from "../../../../../react-check-auth";
import Loading from "../../../../Loading";
import AddressForm from "./AddressForm";
import CompanyForm from "./CompanyForm";
import Error from "../../../../Error";
import pickCompany from "../../../../../common/pickers/pickLocation";
import withLocationAdmin from "../../../../../hocs/withLocationAdmin";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const CompanySettingsPage = () => {
  const { t } = useTranslation();
  const { location } = useLocation();
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyError, setCompanyError] = useState(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressError, setAddressError] = useState(null);
  const {  refreshAuth } = useCheckAuth();
  const onCompanySubmit = async (data) => {
    setCompanyLoading(true);
    try {
      const promises = [];
      if (data.photo) {
        promises.push(api.locations.updatePhoto(location.id, data.photo));
      }
      promises.push(api.locations.updateById(location.id, pickCompany(data)));
      await Promise.all(promises);
      refreshAuth();
    } catch (e) {
      setCompanyError(e);
    }
    setCompanyLoading(false);
  };
  const onAddressSubmit = async (data) => {
    setAddressLoading(true);
    try {
      await api.locations.updateById(location.id, pickCompany(data));
      refreshAuth();
    } catch (e) {
      setAddressError(e);
    }
    setAddressLoading(false);
  };
  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>
                {" "}
                {t(
                  "locationDashboard.settingsPage.companySettingsPage.companyDataCard.title"
                )}
              </h3>
            </Card.Header>
            <Card.Body>
              {companyError && <Error error={companyError} />}
              {companyLoading ? (
                <Loading />
              ) : (
                <CompanyForm {...location} onSubmit={onCompanySubmit} />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h3>
                {" "}
                {t(
                  "locationDashboard.settingsPage.companySettingsPage.addressDataCard.title"
                )}
              </h3>
            </Card.Header>
            <Card.Body>
              {addressError && <Error error={addressError} />}
              {addressLoading ? (
                <Loading />
              ) : (
                <AddressForm {...location} onSubmit={onAddressSubmit} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default withSubscriptionCheck(withLocationAdmin(CompanySettingsPage));
