import React from "react";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";
import useChat from "../../../../../hooks/useChat";

const EmailForm = ({ handleChange, values, errors, setFieldValue }) => {
  const { t } = useTranslation();
  const { location } = useChat();
  return (
    <>
      <Form.Group controlId="formSubject">
        <Form.Control
          type="text"
          name="from"
          value={get(location, "defaultEmailTransport.config.email")}
          onChange={handleChange}
          readOnly
        />
      </Form.Group>{" "}
      <Form.Group controlId="formFromName">
        <Form.Control
          type="text"
          name="content.fromName"
          placeholder={t("formElements.fromName.placeholder")}
          value={get(values, "content.fromName")}
          onChange={handleChange}
          isInvalid={!!get(errors, "content.fromName")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "content.fromName")}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formSubject">
        <Form.Control
          type="text"
          name="content.subject"
          placeholder={t("formElements.subject.placeholder")}
          value={get(values, "content.subject")}
          onChange={handleChange}
          isInvalid={!!get(errors, "content.subject")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "content.subject")}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Editor
        value={get(values, "content.text")}
        apiKey={process.env.REACT_APP_TINYMCE_KEY}
        init={{
          height: 200,
          menubar: false,
          toolbar:
            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
        }}
        onEditorChange={(content) => setFieldValue("content.text", content)}
      />
    </>
  );
};

export default EmailForm;
