import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import allElements from "./constants/elements";
import Drawflow from "../../../../../modules/drawflow";
import AutomationContext from "./contexts/AutomationContext";
import AutomationHeader from "./AutomationHeader";
import AutomationTool from "./AutomationTool";
import api from "../../../../../common/api/index";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error/index";
import Loading from "../../../../Loading/index";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";
import pickAutomation from "../../../../../common/pickers/pickAutomation";

function AutomationsPage() {
  const { t } = useTranslation();

  const [saved, setSaved] = useState(true);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editor, setEditor] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const { locationId, automationId } = useParams();
  const {
    data: automation,
    error: fetchAutomationError,
    loading: fetchAutomationLoading,
    getData,
  } = useFetchApi(
    api.locations.automations.getById,
    {
      automationId,
      locationId,
    },
    null,
    [locationId, automationId, update]
  );

  const onDrawflowDivRender = useCallback(
    (node) => {
      // ref value changed to node
      if (node === null) {
        // node is null, if DOM node of ref had been unmounted before
      } else {
        console.log('creating new drawflow')
        setEditor(new Drawflow(node));
      }
    },
    [automation]
  );
  useEffect(() => {
    if (editor) {
      editor.reroute = true;
      editor.reroute_fix_curvature = true;
      editor.force_first_input = false;
      if (automation.uiConfig && editor) {

        // Run translations to update stored html translation to current viewing language
        Object.keys(automation.uiConfig.drawflow.Home.data).forEach(key => {
          const temp = automation.uiConfig.drawflow.Home.data[key].html
          let replacedSomething = false;

          const doc = new DOMParser().parseFromString(temp, "text/html")

          const els = doc.querySelectorAll('span[data-i18n]')

          els.forEach(el => {
            const attrVal = el.getAttribute('data-i18n')
            if(attrVal) {
              replacedSomething = true;
              el.innerHTML = t(attrVal)
            }
          })
          if(replacedSomething) {
            automation.uiConfig.drawflow.Home.data[key].html = doc.documentElement.outerHTML
          }

        })

        editor.drawflow = automation.uiConfig;
        console.log('set editor.drawflow')
        console.log(automation.uiConfig);
      }
      console.log('calling editor start')
      editor.start();
      editor.on("nodeCreated", function (id) {
        console.log("Node created " + id);
      });

      editor.on("nodeRemoved", function (id) {
        console.log("Node removed " + id);
      });

      editor.on("nodeSelected", function (id) {
        setSelectedNode(editor.drawflow.drawflow.Home.data[id]);
      });

      editor.on("moduleCreated", function (name) {
        console.log("Module Created " + name);
      });

      editor.on("moduleChanged", function (name) {
        console.log("Module Changed " + name);
      });

      editor.on("connectionCreated", function (connection) {
        const outputNode =
          editor.drawflow.drawflow.Home.data[connection.output_id];
        const inputNode =
          editor.drawflow.drawflow.Home.data[connection.input_id];
        if (inputNode.name === "input_contains") {
          if (
            outputNode.name !== "wait_sms" &&
            outputNode.name !== "wait_email" &&
            outputNode.name !== "input_contains" &&
            outputNode.name !== "sms_reply"
          ) {
            editor.removeSingleConnection(
              connection.output_id,
              connection.input_id,
              connection.output_class,
              connection.input_class
            );
          }
        }
        const outputConnections =
          outputNode.outputs[connection.output_class].connections;
        if (outputConnections.length > 1) {
          editor.removeSingleConnection(
            connection.output_id,
            connection.input_id,
            connection.output_class,
            connection.input_class
          );
        }
        console.log("Connection created");
      });

      editor.on("connectionRemoved", function (connection) {
        console.log("Connection removed");
        console.log(editor);
        console.log(connection);
      });

      editor.on("nodeMoved", function (id) {
        console.log("Node moved " + id);
      });

      editor.on("zoom", function (zoom) {
        console.log("Zoom level " + zoom);
      });

      editor.on("translate", function (position) {
        console.log("Translate x:" + position.x + " y:" + position.y);
      });

      editor.on("addReroute", function (id) {
        console.log("Reroute added " + id);
      });

      editor.on("removeReroute", function (id) {
        console.log("Reroute removed " + id);
      });
    }
  }, [editor]);

  const publishAutomation = async () => {
    setError(null);
    setLoading(true);
    try {
      const uiConfig = editor.export();
      const uiData = uiConfig.drawflow.Home.data;
      Object.keys(uiData).forEach((key) =>
        allElements
          .find((e) => e.data === uiData[key].name)
          .schema.validateSync(uiData[key].data)
      );

      const newAutomation = pickAutomation({
        ...automation,
        uiConfig,
      });

      console.log(newAutomation);
      await api.locations.automations.updateById(
        { automationId, locationId },
        newAutomation
      );
      setUpdate(!update);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    setSaved(true);
  };
  const discardAutomation = async () => {
    setError(null);
    setLoading(true);
    try {
      setUpdate(!update);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <AutomationContext.Provider
      value={{
        automation,
        reload: () => {
          getData();
        },
        onDrawflowDivRender,
        editor,
        selectedNode,
        setSelectedNode,
        publishAutomation,
        discardAutomation,
        saved,
        setSaved,
      }}
    >
      <Container fluid={true} className="mt-2">
        {error && <Error error={error} />}
        {loading && <Loading />}
        <Row>
          <Col>
            <AutomationHeader />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchAutomationError && <Error error={fetchAutomationError} />}
            {fetchAutomationLoading ? (
              <Loading />
            ) : (
              automation && <AutomationTool />
            )}
          </Col>
        </Row>
      </Container>
    </AutomationContext.Provider>
  );
}
export default withSubscriptionCheck(withLocationUser(AutomationsPage));
