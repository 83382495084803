import React from "react";
import { Link } from "react-router-dom";
import {  withTranslation } from "react-i18next";
import { useCheckAuth } from "../../../react-check-auth";
import { Button } from "react-bootstrap";
import LanguageDropdown from "../../LanguageDropdown";
import ProfileDropdown from "../../ProfileDropdown";
import AccountsDropdown from "../LocationsDropdown";
import * as ROUTES from "../../../constants/routes";

const TopBar = () => {
  const { userInfo } = useCheckAuth();
  return (
    <div className={`navbar-custom `}>
      <div className="container-fluid">
        <ul className="list-unstyled topbar-right-menu float-right mb-0">
          <li className="notification-list mt-3">
            <LanguageDropdown />
          </li>
          {userInfo.type === "agency" && (
            <li className='notification-list mt-2'>
              <Link to={ROUTES.AGENCY_HELP.as()}>
                <Button style={{ borderRadius: "50%" }}>?</Button>
              </Link>
            </li>
          )}
          <li className="notification-list">
            <ProfileDropdown />
          </li>
        </ul>
        <AccountsDropdown />
      </div>
    </div>
  );
};

export default withTranslation()(TopBar);
