import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const AutomationInput = ({
  onChange,
  value = "",
  error: passedError,
  label,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchAutomationsLoading,
    error: fetchAutomationsError,
  } = useFetchApi(
    api.locations.automations.get,
    {
      locationId,
    },
    { enabled: true }
  );
  if (fetchAutomationsLoading) return <Loading />;
  return (
    <Form.Group controlId="forAutomationId">
      {fetchAutomationsError && <Error error={fetchAutomationsError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name="automationId"
        as="select"
        value={value || ""}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>Automation</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default AutomationInput;
