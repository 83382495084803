import queryString from "query-string";
import api from "../../../../../api";

export default function getAutomations({ locationId, automationId }, query) {
  return api.get(
    `/locations/${locationId}/automations/${automationId}/instances${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
