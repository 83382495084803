import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

export const schema = yup.object({
  includeTerm: yup.string().nullable(),
  excludeTerm: yup.string().nullable(),
  includeTermMatchOptions: yup.object(),
  excludeTermMatchOptions: yup.object(),
});

const ResponseContainsForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const includeTermMatchOptions = [
    {
      key: "includeTermMatchOptions_any_false",
      value: false,
      label: t(
        "locationDashboard.automationShowPage.responseContainsForm.matchOptions.full.label"
      ),
    },
    {
      key: "includeTermMatchOptions_any_true",
      value: true,
      label: t(
        "locationDashboard.automationShowPage.responseContainsForm.matchOptions.any.label"
      ),
    },
  ];
  const excludeTermMatchOptions = [
    {
      key: "excludeTermMatchOptions_any_false",
      value: false,
      label: t(
        "locationDashboard.automationShowPage.responseContainsForm.matchOptions.full.label"
      ),
    },
    {
      key: "excludeTermMatchOptions_any_true",
      value: true,
      label: t(
        "locationDashboard.automationShowPage.responseContainsForm.matchOptions.any.label"
      ),
    },
  ];
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div>
        <i className="mdi mdi-circle font-13 text-success mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.responseContainsForm.successHint"
        )}
      </div>

      <div className="mb-3">
        <i className="mdi mdi-circle font-13 text-danger mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.responseContainsForm.failureHint"
        )}
      </div>
      {includeTermMatchOptions.map((f) => (
        <div className="custom-control custom-radio text-left m-2">
          <input
            type="radio"
            id={f.key}
            name="includeTermMatchOptions.any"
            className="custom-control-input"
            checked={get(values, "includeTermMatchOptions.any") === f.value}
            onChange={() => {
              setFieldValue("includeTermMatchOptions.any", f.value);
            }}
          />
          <label className="custom-control-label" htmlFor={f.key}>
            {f.label}
          </label>
        </div>
      ))}

      <Form.Group controlId="formContains">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.responseContainsForm.messageContains.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.responseContainsForm.messageContains.placeholder"
          )}
          value={get(values, "includeTerm") || ""}
          name="includeTerm"
          onChange={handleChange}
          isInvalid={!!get(errors, "includeTerm")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "includeTerm")}
        </Form.Control.Feedback>
      </Form.Group>
      {excludeTermMatchOptions.map((f) => (
        <div className="custom-control custom-radio text-left m-2">
          <input
            type="radio"
            id={f.key}
            name="excludeTermMatchOptions.any"
            className="custom-control-input"
            checked={get(values, "excludeTermMatchOptions.any") === f.value}
            onChange={() => {
              setFieldValue("excludeTermMatchOptions.any", f.value);
            }}
          />
          <label className="custom-control-label" htmlFor={f.key}>
            {f.label}
          </label>
        </div>
      ))}
      <Form.Group controlId="formDoesNotContains">
        {" "}
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.responseContainsForm.doesNotContain.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.responseContainsForm.doesNotContain.placeholder"
          )}
          value={get(values, "excludeTerm") || ""}
          name="excludeTerm"
          onChange={handleChange}
          isInvalid={!!get(errors, "excludeTerm")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "excludeTerm")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.automationShowPage.responseContainsForm.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(ResponseContainsForm);
