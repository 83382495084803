import updateById from "./updateById";
import get from "./get";
import getById from "./getById";
import create from "./create";
import deleteById from "./deleteById";
import makeSmsDefault from "./sms-default";
import makeVoiceDefault from "./voice-default";
import makeEmailDefault from "./email-default";
import makeDirectEmailDefault from "./direct-email-default";

export default {
  updateById,
  get,
  deleteById,
  create,
  getById,
  makeSmsDefault,
  makeVoiceDefault,
  makeEmailDefault,
  makeDirectEmailDefault,
};
