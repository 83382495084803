import pick from "lodash/pick";
import removeEmptyStringsWithNull from "../functions/removeEmptyStrings";

export default function (agency) {
  return removeEmptyStringsWithNull(
    pick(agency, [
      "id",
      "name",
      "email",
      "phone",
      "whitelabelDomain",
      "privatePolicyUrl",
      "websiteUrl",
      "address",
      "vatId",
    ])
  );
}
