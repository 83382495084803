import create from "./create";
import updateById from "./updateById";
import updatePhoto from "./updatePhoto";
import getAccounts from "./getAccounts";
import getById from "./getById";
import getLetter from "./getLetter";
import generateLetter from "./generateLetter";

import pipelines from "./pipelines";
import conversations from "./conversations";
import tags from "./tags";
import sources from "./sources";
import contacts from "./contacts";
import transports from "./transports";
import opportunities from "./opportunities";
import events from "./events";
import automations from "./automations";
import automationInstances from "./automation-instances";
import bulkRequests from "./bulk-request";
import triggerLinks from "./trigger-links";
import customFields from "./custom-fields";
import tasks from "./tasks";
import emailTemplates from "./email-templates";
import messageTemplates from "./message-templates";

export default {
  create,
  updateById,
  updatePhoto,
  getAccounts,
  pipelines,
  tags,
  contacts,
  transports,
  opportunities,
  sources,
  conversations,
  events,
  automations,
  automationInstances,
  getById,
  bulkRequests,
  getLetter,
  generateLetter,
  triggerLinks,
  customFields,
  tasks,
  emailTemplates,
  messageTemplates,
};
