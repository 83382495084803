import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import EmailTemplateContext from "../contexts/EmailTemplateContext";
import EmailTemplateTable from "../EmailTemplateTable";
import EmailTemplateSearchBar from "../EmailTemplateSearchBar";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const LocationEmailTemplatesPage = () => {
  const { locationId } = useParams();
  useCheckAuth();
  const {
    data: emailTemplates,
    loading: fetchEmailTemplatesLoading,
    error: fetchEmailTemplatesError,
    getData,
  } = useFetchApi(
    api.locations.emailTemplates.get,
    {
      locationId,
    },
    { order: "desc", sort: "updatedAt" }
  );
  return (
    <EmailTemplateContext.Provider
      value={{
        emailTemplates,
        reload: getData,
      }}
    >
      <Container className="mt-2" fluid={true}>
        <Row>
          <Col>
            <EmailTemplateSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchEmailTemplatesError && (
              <Error error={fetchEmailTemplatesError} />
            )}
            {fetchEmailTemplatesLoading ? (
              <Loading />
            ) : (
              emailTemplates && <EmailTemplateTable />
            )}
          </Col>
        </Row>
      </Container>
    </EmailTemplateContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationUser(LocationEmailTemplatesPage)
);
