import React from "react";
import { useCheckAuth } from "../react-check-auth";
import InactiveSubscriptionPage from "../components/AgencyDashboard/InactiveSubscriptionPage";
import isSystemUser from "../common/functions/isSystemUser";

export default (WrappedPage) => {
  const WithSubscriptionCheck = ({ ...props }) => {
    const { userInfo } = useCheckAuth();
    if (isSystemUser(userInfo) || userInfo.agency.status === "active") {
      return <WrappedPage {...props} />;
    } else {
      return <InactiveSubscriptionPage />;
    }
  };
  return WithSubscriptionCheck;
};
