import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FunnelGraph from "funnel-graph-js";
import { useParams } from "react-router-dom";
import DateTimeRange from "../DateTimeRangeDropdown";
import useDashboard from "../hooks/useDashboard";
import PipelineInput from "../../../../Form/PipelineInput";

import Error from "../../../../Error";
import Loading from "../../../../Loading";

export default function FunnelChartCard() {
  useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    setPipelineId,
    pipelineId,
    pipeline,
    sources,

    events,
    stages,
  } = useDashboard();

  const drawChart = async () => {
    setError(null);
    setLoading(true);
    try {
      document.getElementById("funnel-chart").innerHTML = "";
      if (sources.length > 0 && stages.length > 0 && events.length > 0) {
        const filteredStages = stages.filter((s) =>
          events.some((e) => e.stageId === s.id)
        );
        const filteredSources = sources.filter((s) =>
          events.some((e) => e.sourceId === s.id)
        );
        const graphData = filteredStages.map((s) => {
          const possibleEvents = events.filter((e) => e.stageId === s.id);
          const sortBySource = filteredSources.map(
            (source) =>
              possibleEvents.filter((e) => e.sourceId === source.id).length
          );
          return sortBySource;
        });
        console.log({
          labels: filteredStages.map((s) => s.name),
          subLabels: filteredSources.map((s) => s.name),
          values: graphData,
        });
        const g = new FunnelGraph({
          container: ".funnel-chart",
          gradientDirection: "horizontal",
          data: {
            labels: filteredStages.map((s) => s.name),
            subLabels: filteredSources.map((s) => s.name),
            values: graphData,
          },
          displayPercent: true,
          direction: "horizontal",
        });
        g.draw();
      }
    } catch (e) {
      console.log(e);
      setError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    drawChart();
  }, [pipeline, sources, events, drawChart]);
  return (
    <Card className="p-2">
      <Card.Header>
        <Container>
          <Row>
            <Col>
              <h4>{t("locationDashboard.mainPage.funnelCard.title")}</h4>
            </Col>
            <Col>
              <PipelineInput
                setInitialValue={true}
                value={pipelineId}
                onChange={(e) => {
                  setPipelineId(e.target.value);
                }}
              />
            </Col>{" "}
            <Col>
              <DateTimeRange />
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {loading ? <Loading /> : error ? <Error error={error} /> : null}
        <div
          style={{ height: 400, width: "100%" }}
          className="funnel-chart"
          id="funnel-chart"
        ></div>
      </Card.Body>
    </Card>
  );
}
