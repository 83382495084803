import React, { useState } from "react";
import { Card, Accordion,  Form } from "react-bootstrap";
import omit from "lodash/omit";
import useContact from "../../hooks/useContact";
import AutomationInput from "../../../../../../Form/AutomationInput";
import { useTranslation } from "react-i18next";

export default function CampaignFilter({ onClose }) {
  const {
    backendFilters: filters,
    setBackendFilters: setFilters,
  } = useContact();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState({});
  const options = [
    {
      key: "1",
      title: t(
        "locationDashboard.contactsPage.filterBox.campaignFilter.hasActive"
      ),
      onSelect: () => {
        setFilters({ ...omitFilters(filters), hasActiveAutomation: true });
      },
    },
    {
      key: "2",
      title: t(
        "locationDashboard.contactsPage.filterBox.campaignFilter.inActive"
      ),
      onSelect: () => {
        setFilters(omitFilters(filters));
      },
    },
    {
      key: "3",
      title: t(
        "locationDashboard.contactsPage.filterBox.campaignFilter.inFinished"
      ),
      onSelect: () => {
        setFilters(omitFilters(filters));
      },
    },
  ];
  const omitFilters = (filters) =>
    omit(filters, [
      "activeAutomationId",
      "finishedAutomationId",
      "hasActiveAutomation",
    ]);
  const handleClose = () => {
    setFilters(omitFilters(filters));
    onClose();
  };
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="1">
        {selectedOption.title ||
          t(
            "locationDashboard.contactsPage.filterBox.campaignFilter.setCampaignFilter"
          )}{" "}
        <i className="dripicons-cross" onClick={handleClose}></i>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <Card.Body>
          {options.map((option) => (
            <>
              {" "}
              <Form.Check
                type="radio"
                label={option.title}
                name="campaignRadios"
                id="formHorizontalRadios1"
                onChange={() => {
                  setSelectedOption(option);
                  option.onSelect();
                }}
              />
              {option.key === "2" && selectedOption.key === "2" && (
                <AutomationInput
                  label={t(
                    "locationDashboard.contactsPage.filterBox.campaignFilter.searchCampaign"
                  )}
                  value={filters.activeAutomationId}
                  onChange={(e) => {
                    setFilters({
                      ...omitFilters(filters),
                      activeAutomationId: e.target.value,
                    });
                  }}
                />
              )}
              {option.key === "3" && selectedOption.key === "3" && (
                <AutomationInput
                  label={t(
                    "locationDashboard.contactsPage.filterBox.campaignFilter.searchCampaign"
                  )}
                  value={filters.finishedAutomationId}
                  onChange={(e) => {
                    setFilters({
                      ...omitFilters(filters),
                      finishedAutomationId: e.target.value,
                    });
                  }}
                />
              )}
            </>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
