import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import AutomationDiagram from "./AutomationDiagram";
import AutomationElementForm from "./AutomationElementForm";
import AutomationElements from "./AutomationElements";
import useAutomation from "../hooks/useAutomation";

export default function AutomationTool() {
  const { selectedNode } = useAutomation();
  return (
    <Container fluid={true}>
      <Row
        style={{
          maxHeight: "calc(200vh - 100px)",
          minHeight: "calc(100vh - 200px)",
        }}
      >
        <Col className="m-0 p-0" lg={3} md={3} sx={3} sm={3}>
          <AutomationElements />
        </Col>
        <Col className="m-0 p-0">
          <AutomationDiagram />
        </Col>
        {selectedNode && (
          <Col className="m-0 p-1" lg={3} md={3} sx={3} sm={3}>
            <div
              style={{
                maxHeight: "calc(200vh - 100px)",
                overflowY: "auto",
                width: "100%",
              }}
            >
              <AutomationElementForm />
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
}
