import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../../AuthorizedRoute";
import * as ROUTES from "../../../../constants/routes";
import EmailTemplateListPage from "./EmailTemplateListPage";
import EmailTemplateCreatePage from "./EmailTemplateCreatePage";
import EmailTemplateEditPage from "./EmailTemplateEditPage";

function EmailTemplateRouter() {
  return (
    <Switch>
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES.href}
        component={EmailTemplateListPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES_CREATE.href}
        component={EmailTemplateCreatePage}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES_EDIT.href}
        component={EmailTemplateEditPage}
      />
    </Switch>
  );
}

export default EmailTemplateRouter;
