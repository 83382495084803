import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import MessageInput from "../../../../../../../Form/MessageInput";

export const schema = yup.object({
  phone: yup.string().nullable(),
  content: yup.object({
    text: yup.string().required("validation.sendSms.message.required"),
  }),
});

const SendSmsForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formPhone">
        <Form.Label>
          {t("locationDashboard.automationShowPage.sendSmsForm.phone.label")}
          <br />
          {t("locationDashboard.automationShowPage.sendSmsForm.phone.hint")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendSmsForm.phone.placeholder"
          )}
          value={get(values, "phone") || ""}
          name="phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "phone")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <MessageInput
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        type={"sms"}
      />
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.sendSmsForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(SendSmsForm);
