import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import api from "../../../../../../common/api/index";
import ContactForm from "../ContactForm/index";
import Error from "../../../../../Error/index";
import Loading from "../../../../../Loading/index";
import pickContact from "../../../../../../common/pickers/pickContact";

const ContactCreateModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.contacts.create(
        { locationId: locationId },
        {
          ...pickContact(values),
        }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.contactsPage.createModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <ContactForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ContactCreateModal;
