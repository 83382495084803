import queryString from "query-string";
import api from "../../../../../api";

export default function getById(params, query) {
  return api.get(
    `/locations/${params.locationId}/automations/${
      params.automationId
    }/instances/${params.instanceId}${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
