import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import VoiceServiceTable from "./VoiceServiceTable";
import VoiceServiceContext from "./contexts/VoiceServiceContext";
import VoiceServiceSearchBar from "./VoiceServiceSearchBar";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationAdmin from "../../../../../hocs/withLocationAdmin";
import { useCheckAuth } from "../../../../../react-check-auth";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import api from "../../../../../common/api";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const VoiceSettingsPage = () => {
  useCheckAuth();
  const { locationId } = useParams();
  // const [,] = useState(false);
  // const [,] = useState(null);
  const {
    data: services,
    loading: fetchServicesLoading,
    error: fetchServicesError,
    getData,
  } = useFetchApi(
    api.locations.transports.get,
    { locationId },
    { type: "sms", name: "twilio" } // Can't use voice here, it'll break the duplicated view, just show twilio
  );
  return (
    <VoiceServiceContext.Provider value={{ services, reload: getData }}>
      <Container fluid={true}>
        <Row>
          <Col>
            <VoiceServiceSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {fetchServicesError && <Error error={fetchServicesError} />}
                {fetchServicesLoading ? (
                  <Loading />
                ) : (
                  services && <VoiceServiceTable />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </VoiceServiceContext.Provider>
  );
};
export default withSubscriptionCheck(withLocationAdmin(VoiceSettingsPage));
