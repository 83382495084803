import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import get from "lodash/get";
import PaymentMethodEdit from "./PaymentMethodEdit";
import useFetchApi from "../../../../hooks/useFetchApi";
import api from "../../../../common/api";
import { useCheckAuth } from "../../../../react-check-auth";
import Error from "../../../Error";
import Loading from "../../../Loading";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import withAgencyAdmin from "../../../../hocs/withAgencyAdmin";

const AgencyBillingPage = () => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const { data, error, loading: fetchLoading } = useFetchApi(
    api.agencies.getById,
    {
      agencyId: userInfo.agency.id,
    },
    { eager: ["subscriptions.plan", "invoices"] }
  );
  if (fetchLoading) return <Loading />;
  return (
    <Container fluid={true}>
      <Row className="mt-2">
        <Col>
          {" "}
          <h2>{t("settingsPage.agencyBillingPage.header")}</h2>
        </Col>
      </Row>
      {error ? (
        <Error />
      ) : (
        <Row>
          <Col lg={6} md={6} sm={12} sx={12}>
            {get(data, "subscriptions[0].plan") && (
              <Card>
                <Card.Body className="m-0 p-0">
                  <Container fluid={true}>
                    {" "}
                    <Row className="bg-success p-5">
                      <Col className="text-center">
                        <h4 className="text-light">
                          Beta Tester Account
                          {/*data.subscriptions[0].plan.name.toUpperCase()*/}
                        </h4>
                        {/* <h3 className="text-light">
                          {data.subscriptions[0].plan.price}/
                          {data.subscriptions[0].plan.currency}</h3>*/}
                      </Col>
                    </Row>
                    {/* <Row className="m-3">
                      <Col className="text-center">
                        <p>Get 2 month free</p>
                        <Button variant={"success"}>Claim 2 month free</Button>
                      </Col>
                    </Row>*/}
                  </Container>
                </Card.Body>
              </Card>
            )}
            {/*get(data, "subscriptions[0]") && (
              <Card>
                <Card.Body className="m-0 p-0">
                  <Container fluid={true}>
                    {" "}
                    <Row className="p-5">
                      <Col className="text-center">
                        <p>
                          {t(
                            "settingsPage.agencyBillingPage.nextInvoiceCard.text"
                          )}
                          <span className="font-weight-bold">
                            {" "}
                            {format(
                              parseISO(data.subscriptions[0].currentPeriodEnd),
                              "MMM d, yyyy"
                            )}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            )*/}
          </Col>

          <Col lg={6} md={6} sm={12} sx={12}>
            <Card>
              <Card.Header>
                <h3>
                  {" "}
                  {t("settingsPage.agencyBillingPage.paymentMethodCard.title")}
                </h3>
              </Card.Header>

              <Card.Body>
                <Container fluid={true}>
                  {" "}
                  <Row>
                    <Col>
                      <PaymentMethodEdit />{" "}
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
            {get(data, "invoices") && (
              <Card>
                <Card.Header>
                  <h3>
                    {t(
                      "settingsPage.agencyBillingPage.billingHistoryCard.title"
                    )}
                  </h3>
                </Card.Header>
                <Card.Body>
                  <Container fluid={true}>
                    {" "}
                    {data.invoices.map((invoice) => (
                      <>
                        <Row>
                          <Col
                            onClick={() => {
                              window.location = invoice.externalUrl;
                            }}
                          >
                            <p className="btn-link">Invoice {invoice.id}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {invoice.createdAt &&
                              format(
                                parseISO(invoice.createdAt),
                                "MMM d, yyyy"
                              )}
                          </Col>
                          <Col>
                            {invoice.amount}/{invoice.currency}
                          </Col>
                        </Row>
                      </>
                    ))}
                  </Container>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};
export default withAgencyAdmin(AgencyBillingPage);
