import React from "react";
import parseISO from "date-fns/parseISO";
import DateTime from "react-datetime-picker";
import spacetime from "spacetime";

const FormikDateTime = ({ field, form, value, tzName}) => {
  
  const onFieldChange = (value) => {
    form.setFieldValue(field.name, value);
  };
  
  // Parse passed date or set now()
  // console.log(`Incoming date value: ${value}`)
  const date = !value
    ? new Date()
    : value instanceof Date
    ? value
    : parseISO(value);

  // console.log(`Initial date value: ${date.toISOString()}`)
  
  const remoteDate = spacetime(date.getTime(), tzName);
  const localDate = spacetime(date.getTime());

  // console.log(`Remote date value: ${remoteDate.toNativeDate().toISOString()}`);
  // console.log(`Local date value: ${localDate.toNativeDate().toISOString()}`);

  const remoteTz = remoteDate.timezone()
  const localTz = localDate.timezone()
  
  const localOffsetToUTC = localTz.current.offset * 60

  const mins = remoteTz.current.offset * 60
  const hr = `${(mins / 60) ^ 0}:` + (mins % 60 === 0 ? "00" : Math.abs(mins % 60))
  const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${tzName}`

  console.log(`Remote timezone offsets: ${mins}mins   ${hr}hr   ${prefix} prefix`)
  console.log(`Local timezone offsets: ${localOffsetToUTC}mins`)

  // value: remoteTz.name
  // offset: remoteTz.current.offset

  function convertToVisualDate(date) {
    // Real run time as UTC integer
    const utcTime = date.getTime()

    // Adjust UTC real time to fake a visual time (add remote offset and remove local offset)
    const visualFakeTimestamp = (utcTime + (mins * 60 * 1000)) - (localOffsetToUTC * 60 * 1000)

    // visualDate
    return new Date(visualFakeTimestamp)
  }

  function convertToRealDate(date) {
    // Real run time as UTC integer
    const utcTime = date.getTime()

    // Adjust fake date to real time - and reverse the above date changes
    const realTimestamp = (utcTime - (mins * 60 * 1000)) + (localOffsetToUTC * 60 * 1000)

    // visualDate
    return new Date(realTimestamp)
  }

  return (
    <>
      <DateTime
        value={convertToVisualDate(date)}
        // value={date}
        id={field.name}
        name={field.name}
        // onChange={onFieldChange}
        onChange={date => onFieldChange(convertToRealDate(date))}
        disableClock={true}
      />
    </>
  );
};

export default FormikDateTime;
