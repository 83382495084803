import create from "./create";
import updateById from "./updateById";
import deleteById from "./deleteById";
import getById from "./getById";
import deleteContacts from "./delete";
import get from "./get";
import tags from "./tags";
import importContacts from "./import";

export default {
  create,
  updateById,
  deleteById,
  getById,
  deleteContacts,
  get,
  tags,
  importContacts,
};
