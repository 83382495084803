import pick from "lodash/pick";
import pickContact from "./pickContact";
import removeEmptyStringsWithNull from "../functions/removeEmptyStrings";

export default (data) => {
  const result = pick(data, [
    "contact",
    "stageId",
    "status",
    "leadValue",
    "contactId",
    "pipelineId",
  ]);
  if (result.contact) {
    result.contact = { ...pickContact(data.contact) };
    if (data.contact.id) {
      result.contact.id = data.contact.id;
    }
  }

  return removeEmptyStringsWithNull(result);
};
