import pick from "lodash/pick";
import get from "lodash/get";
import parseAutomationUiConfig from "../functions/parseAutomationUiConfig";
import setAutomationTrigger from "../functions/setAutomationTrigger";
import removeNilValues from "../functions/removeNilValues";
import parseUiConfig from "../functions/parseUiConfig";

const pickAutomation = (data) => {
  const automation = pick(data, ["name", "description", "uiConfig", "enabled"]);

  if (automation.uiConfig) {
    const uiData = get(automation, "uiConfig.drawflow.Home.data");
    if (uiData) {
      if (Object.keys(uiData).some((key) => uiData[key].data.allowMultiple)) {
        automation.allowMultiple = true;
      }
      automation.trigger = setAutomationTrigger(uiData);
    }
    automation.uiConfig.drawflow.Home.data = parseUiConfig(uiData);
    automation.config = removeNilValues(
      parseAutomationUiConfig(automation.uiConfig)
    );
  }

  return automation;
};

export default pickAutomation;
