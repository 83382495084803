import queryString from "query-string";
import api from "../../../../api";

export default function getById(params, query) {
  return api.get(
    `/locations/${params.locationId}/message-templates/${
      params.messageTemplateId
    }${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
