import isLocationAdmin from "./isLocationAdmin";

export default function isLocationUser(userInfo, locationId) {
  return (
    (userInfo.type === "location" &&
      userInfo.role === "user" &&
      userInfo.locations.some((l) => l.id === locationId)) ||
    isLocationAdmin(userInfo, locationId)
  );
}
