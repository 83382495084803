import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../react-check-auth";
import LanguageDropdown from "../../../LanguageDropdown";
import ProfileDropdown from "../../../ProfileDropdown";
import AccountsDropdown from "../../LocationsDropdown";
import * as ROUTES from "../../../../constants/routes";

const TopBar = () => {
  useCheckAuth();
  return (
    <div className={`navbar-custom `}>
      <div className="container-fluid">
        <ul className="list-unstyled topbar-right-menu float-right mb-0">
          <li className="notification-list mt-3">
            <LanguageDropdown />
          </li>
          <li className="notification-list">
            <NavLink
              className="nav-link dropdown-toggle arrow-none btn btn-link"
              to={ROUTES.AGENCY_LOCATIONS.as()}
            >
              <i className="dripicons-swap noti-icon"></i>
            </NavLink>
          </li>
          <li className="notification-list">
            <ProfileDropdown />
          </li>
        </ul>
        <AccountsDropdown />
      </div>
    </div>
  );
};

export default withTranslation()(TopBar);
