import create from "./create";
import updateById from "./updateById";
import deleteById from "./deleteById";
import getById from "./getById";
import get from "./get";
import createMultiple from "./createMultiple";
import relate from "./relate";
import unrelate from "./unrelate";

export default {
  create,
  updateById,
  deleteById,
  getById,
  get,
  createMultiple,
  relate,
  unrelate,
};
