import React, { useState } from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../react-check-auth";
import Loading from "../../Loading";
import searchLocations from "../../../common/search/searchLocations";
import Error from "../../Error";
import LocationCard from "./LocationCard";
import LocationCreateModal from "./LocationCreateModal";
import ModalButton from "../../ModalButton";
import withSubscriptionCheck from "../../../hocs/withSubscriptionCheck";

function LocationsPage() {
  const { t } = useTranslation();
  const { refreshAuth } = useCheckAuth();
  const [error, ] = useState(null);
  const [loading, ] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const { userInfo } = useCheckAuth();
  const locations = userInfo.locations;
  if (loading) return <Loading />;
  return (
    <Container fluid={true}>
      {error && <Error error={error} />}
      <Row className="align-items-center">
        <Col>
          <h2>{t("locationsPage.title")}</h2>
        </Col>
        <Col>
          <Row className="align-items-center">
            <Col>
              {" "}
              <form className="p-3">
                <input
                  onChange={(e) => setLocationFilter(e.target.value)}
                  value={locationFilter}
                  type="text"
                  className="form-control"
                  placeholder={t("locationsPage.search")}
                />
              </form>
            </Col>
            <Col>
              {" "}
              <ModalButton
                callback={() => {
                  refreshAuth();
                }}
                modal={<LocationCreateModal />}
                button={
                  <Button disabled={userInfo.role === "user"}>
                    <i className="dripicons-plus"></i>
                    {t("locationsPage.addAccountButton")}
                  </Button>
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          {searchLocations(locations, locationFilter).map((location) => (
            <LocationCard key={location.id} location={location} />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default withSubscriptionCheck(withTranslation()(LocationsPage));
