import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";

const schema = yup.object({
  email: yup
    .string()
    .email("validation.email.valid")
    .max(64, "validation.name.maxLength"),
  name: yup.string().max(64, "validation.name.maxLength"),
  password: yup.string().required("validation.password.required"),
});

const PersonalDataForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formEmail">
        <Form.Label> {t("personalDataCard.form.email.label")}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t("personalDataCard.form.email.placeholder")}
          value={values.email || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.email)}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formName">
        <Form.Label> {t("personalDataCard.form.name.label")}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t("personalDataCard.form.name.placeholder")}
          value={values.name || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.name)}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formPassword">
        <Form.Label> {t("personalDataCard.form.password.label")}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t("personalDataCard.form.password.label")}
          value={values.password || ""}
          name="password"
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.password)}
        </Form.Control.Feedback>
      </Form.Group>{" "}
      <Form.Group controlId="formBirthDate">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("personalDataCard.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withTranslation()(
  withFormik({
    mapPropsToValues: (values) => {
      return values;
    },
    handleSubmit: (values, { props }) => {
      props.onSubmit(omit(values, ["error", "onSubmit"]));
    },

    validationSchema: schema,
  })(PersonalDataForm)
);
