import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useCheckAuth } from "../../../../react-check-auth";
import ModalButton from "../../../ModalButton";
import LocationCreateModal from "../../LocationsPage/LocationCreateModal";

export default function OnboardingCard() {
  const { userInfo, refreshAuth } = useCheckAuth();
  const { t } = useTranslation();
  return (
    <div className="help-box text-white text-center">
      <h5 className="mt-3">{t("leftSidebar.onboardingCard.title")}</h5>
      <p className="mb-3">
        {t("leftSidebar.onboardingCard.text")}
        <br />
        <br />
        {t("leftSidebar.onboardingCard.hint")}
      </p>
      <ModalButton
        callback={() => {
          refreshAuth();
        }}
        modal={<LocationCreateModal />}
        className="btn btn-outline-light btn-sm"
        button={
          <Button disabled={userInfo.role === "user"}>
            {t("leftSidebar.onboardingCard.addAccount")}
          </Button>
        }
      />
    </div>
  );
}
