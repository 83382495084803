import React from "react";
import { useParams } from "react-router-dom";
import { useCheckAuth } from "../react-check-auth";
import UnauthorizedPage from "../components/UnauthorizedPage";
import isLocationUser from "../common/functions/isLocationUser";

export default (WrappedPage) => {
  const WithLocationUser = ({ ...props }) => {
    const { locationId } = useParams();
    const { userInfo } = useCheckAuth();
    if (isLocationUser(userInfo, locationId)) {
      return <WrappedPage {...props} />;
    } else {
      return <UnauthorizedPage />;
    }
  };
  return WithLocationUser;
};
