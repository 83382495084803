import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import TimeSelectorInput from "../../../../../../../Form/TimeSelectorInput";
import TimeWindowInput from "../../../../../../../Form/TimeWindowInput";
import PortDescription from "../PortDescription";

export const schema = yup.object({
  value: yup.number().required("validation.waitForSms.value.required"),
  timeSelector: yup
    .string()
    .required("validation.waitForSms.timeType.required"),
});

const WaitForResponseForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <PortDescription />
      <Form.Group as={Row} controlId="formTimeout">
        <Col>
          <Form.Group controlId="formValue">
            <Form.Control
              type="number"
              value={get(values, "value") || ""}
              name="value"
              onChange={handleChange}
              isInvalid={!!get(errors, "value")}
            />
            <Form.Control.Feedback type="invalid">
              {t(get(errors, "value"))}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          {" "}
          <TimeSelectorInput
            handleChange={handleChange}
            error={get(errors, "timeSelector")}
            name="timeSelector"
            value={get(values, "timeSelector")}
          />
        </Col>
      </Form.Group>
      <Form.Group>
        {" "}
        <Form.Check
          type="switch"
          label={t("formElements.waitWindow.label")}
          id="custom-switch"
          onChange={handleChange}
          name="useWindows"
          value={get(values, "useWindows")}
          checked={!!get(values, "useWindows")}
        />
      </Form.Group>

      {get(values, "useWindows") && (
        <TimeWindowInput
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          error={get(errors, "windows")}
          name="windows"
          value={get(values, "windows") || {}}
        />
      )}
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.waitForSmsForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(WaitForResponseForm);
