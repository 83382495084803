import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import api from "../../../../../../common/api";
import BootstrapTable from "react-bootstrap-table-next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import useCustomField from "../hooks/useCustomField";
import CustomFieldEditModal from "../CustomFieldEditModal";
import ModalButton from "../../../../../ModalButton";
import ConfirmationModal from "../../../../../ConfirmationModal";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";

const CustomFieldTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { userInfo } = useCheckAuth();
  const { requests, reload } = useCustomField();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (request) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.customFields.deleteById({
        locationId,
        customFieldId: request.id,
      });
      reload();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const ActionColumn = (cell, row) => {
    return (
      <Row>
        <Col>
          {" "}
          <ModalButton
            modal={<CustomFieldEditModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t(
                  "locationDashboard.settingsPage.customFieldsPage.table.editButton"
                )}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
        <Col>
          <ConfirmationModal
            title={t(
              "locationDashboard.settingsPage.customFieldsPage.deleteModal.title"
            )}
            text={t(
              "locationDashboard.settingsPage.customFieldsPage.deleteModal.text"
            )}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t(
                  "locationDashboard.settingsPage.customFieldsPage.table.deleteButton"
                )}
              </Button>
            }
            callback={() => handleDelete(row)}
          />
        </Col>
      </Row>
    );
  };
  const KeyColumn = (cell) => {
    return <React.Fragment>{cell}</React.Fragment>;
  };
  const NameColumn = (cell) => {
    return <React.Fragment>{cell}</React.Fragment>;
  };
  const PlaceholderColumn = (cell) => {
    return <React.Fragment>{cell}</React.Fragment>;
  };
  const columns = [
    {
      dataField: "key",
      text: "Key",
      // text: t("locationDashboard.settingsPage.customFieldsPage.table.name"),
      sort: true,
      formatter: KeyColumn,
    },
    {
      dataField: "name",
      text: t("locationDashboard.settingsPage.customFieldsPage.table.name"),
      sort: true,
      formatter: NameColumn,
    },
    {
      dataField: "placeholder",
      text: t(
        "locationDashboard.settingsPage.customFieldsPage.table.placeholder"
      ),
      sort: true,
      formatter: PlaceholderColumn,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.settingsPage.customFieldsPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={requests}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
        defaultSorted={[{ dataField: "createdAt", order: "desc" }]}
      />
    </>
  );
};

export default CustomFieldTable;
