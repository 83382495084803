import React from "react";
import {    Button } from "react-bootstrap";
import useEmployee from "../hooks/useEmployee";
import { useTranslation } from "react-i18next";

export default function EmployeePaginator() {
  const { t } = useTranslation();
  const { setFilters, filters, total } = useEmployee();
  const nextCondition = filters.page * filters.limit < total;
  const previousCondition = filters.page > 1;
  const handleNext = () => {
    if (nextCondition) setFilters({ ...filters, page: filters.page + 1 });
  };
  const handlePrevious = () => {
    if (previousCondition) {
      setFilters({ ...filters, page: filters.page - 1 });
    }
  };
  return (
    <div className="btn-group" role="group" aria-label="First group">
      <Button
        disabled={!previousCondition}
        variant={"light"}
        onClick={handlePrevious}
      >
        {t("settingsPage.agencyTeamManagementPage.table.previous")}
      </Button>{" "}
      <Button disabled={!nextCondition} variant={"light"} onClick={handleNext}>
        {t("settingsPage.agencyTeamManagementPage.table.next")}
      </Button>
    </div>
  );
}
