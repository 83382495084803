import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  address: yup.object({
    street: yup.string().nullable(),
    city: yup.string().nullable(),
    postcode: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
  }),
});

const AddressForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formStreet">
        <Form.Label>
          {" "}
          {t(
            "settingsPage.agencySettingsPage.addressDataCard.form.street.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "settingsPage.agencySettingsPage.addressDataCard.form.street.placeholder"
          )}
          value={get(values, "address.street") || ""}
          name="address.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.street")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "address.street")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formCity">
        <Col>
          <Form.Label>
            {t(
              "settingsPage.agencySettingsPage.addressDataCard.form.city.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "settingsPage.agencySettingsPage.addressDataCard.form.city.placeholder"
            )}
            value={get(values, "address.city") || ""}
            name="address.city"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.city")}
          />
          <Form.Control.Feedback type="invalid">
            {get(errors, "address.city")}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>
            {t(
              "settingsPage.agencySettingsPage.addressDataCard.form.postcode.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "settingsPage.agencySettingsPage.addressDataCard.form.postcode.placeholder"
            )}
            value={get(values, "address.postcode") || ""}
            name="address.postcode"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.postcode")}
          />
          <Form.Control.Feedback type="invalid">
            {get(errors, "address.postcode")}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group controlId="formState">
        <Form.Label>
          {t(
            "settingsPage.agencySettingsPage.addressDataCard.form.state.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "settingsPage.agencySettingsPage.addressDataCard.form.state.placeholder"
          )}
          value={get(values, "address.state") || ""}
          name="address.state"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.state")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "address.state")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formCountry">
        <Form.Label>
          {t(
            "settingsPage.agencySettingsPage.addressDataCard.form.country.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "settingsPage.agencySettingsPage.addressDataCard.form.country.placeholder"
          )}
          value={get(values, "address.country") || ""}
          name="address.country"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.country")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "address.country")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formVatId">
        <Form.Label>
          {t(
            "settingsPage.agencySettingsPage.addressDataCard.form.vatId.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "settingsPage.agencySettingsPage.addressDataCard.form.vatId.placeholder"
          )}
          value={get(values, "vatId") || ""}
          name="vatId"
          onChange={handleChange}
          isInvalid={!!get(errors, "vatId")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "vatId")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("settingsPage.agencySettingsPage.addressDataCard.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel"]));
  },
  validationSchema: schema,
})(AddressForm);
