import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const StageInput = ({
  onChange,
  value = "",
  error: passedError,
  pipelineId,
  name = "stageId",
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchStagesLoading,
    error: fetchStagesError
    ,
  } = useFetchApi(
    api.locations.pipelines.stages.get,
    {
      locationId,
      pipelineId,
    },
    null,
    [pipelineId, locationId],
    pipelineId ? true : false
  );

  if (fetchStagesLoading) return <Loading />;
  return (
    <Form.Group controlId="forStageId">
      {fetchStagesError && <Error error={fetchStagesError} />}
      <Form.Control
        name={name}
        as="select"
        value={value||""}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>{t("formElements.stage.option")}</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default StageInput;
