import React from "react";
import { NavLink, Switch } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../react-check-auth";
import AuthorizedRoute from "../../AuthorizedRoute";
import * as ROUTES from "../../../constants/routes";
import AgencySettingsPage from "./AgencySettingsPage";
import UserSettingsPage from "./UserSettingsPage";
import AgencyBillingPage from "./AgencyBillingPage";
import AgencyTeamManagementPage from "./AgencyTeamManagement";
import isSystemUser from "../../../common/functions/isSystemUser";

function SettingsPage() {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  return (
    <Container fluid={true}>
      <Row className="align-items-center">
        <Col>
          <h2>{t("settingsPage.title")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className="nav nav-tabs mb-3">
            {!isSystemUser(userInfo) && (
              <li className="nav-item">
                <NavLink
                  to={ROUTES.AGENCY_SETTINGS_USER.as()}
                  className="nav-link"
                >
                  <i className="mdi mdi-home-variant d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    {t("settingsPage.userSettingsPage.title")}
                  </span>
                </NavLink>
              </li>
            )}

            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_SETTINGS_AGENCY.as()}
                className="nav-link"
              >
                <i className="mdi mdi-account-circle d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("settingsPage.agencySettingsPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_SETTINGS_AGENCY_TEAM_MANAGEMENT.as()}
                className="nav-link"
              >
                <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {t("settingsPage.agencyTeamManagementPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_SETTINGS_AGENCY_BILLING.as()}
                className="nav-link"
              >
                <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {t("settingsPage.agencyBillingPage.title")}
                </span>
              </NavLink>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch>
            <AuthorizedRoute
              path={ROUTES.AGENCY_SETTINGS.href}
              component={UserSettingsPage}
              exact
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_SETTINGS_AGENCY.href}
              component={AgencySettingsPage}
              exact
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_SETTINGS_USER.href}
              component={UserSettingsPage}
              exact
            />{" "}
            <AuthorizedRoute
              path={ROUTES.AGENCY_SETTINGS_AGENCY_BILLING.href}
              component={AgencyBillingPage}
              exact
            />{" "}
            <AuthorizedRoute
              path={ROUTES.AGENCY_SETTINGS_AGENCY_TEAM_MANAGEMENT.href}
              component={AgencyTeamManagementPage}
              exact
            />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(SettingsPage);
