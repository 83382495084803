import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../../../../../AuthorizedRoute";
import * as ROUTES from "../../../../../../constants/routes";
import TaskCreatePage from "./TaskCreatePage";
import TaskEditPage from "./TaskEditPage";
import TaskListPage from "./TaskListPage";

export default function TasksRouter() {
  return (
    <Switch>
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.href}
        component={TaskListPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS.href}
        component={TaskListPage}
        exact
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_CREATE.href}
        component={TaskCreatePage}
      />
      <AuthorizedRoute
        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_EDIT.href}
        component={TaskEditPage}
      />
    </Switch>
  );
}
