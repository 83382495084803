import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card } from "react-bootstrap";
import ChangePassword from "../../../../ChangePassword";
import PersonalDataEdit from "../../../../PersonalDataEdit";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const UserSettingsPage = () => {
  const { t } = useTranslation();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sm={12} sx={12}>
          <Card>
            <Card.Header>
              <h3>
                {" "}
                {t(
                  "locationDashboard.settingsPage.userSettingsPage.personalDataCard.title"
                )}
              </h3>
            </Card.Header>
            <Card.Body>
              <PersonalDataEdit />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={12} sx={12}>
          {" "}
          <Card>
            <Card.Header>
              <h3>
                {" "}
                {t(
                  "locationDashboard.settingsPage.userSettingsPage.passwordDataCard.title"
                )}
              </h3>
            </Card.Header>
            <Card.Body>
              <ChangePassword />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default withSubscriptionCheck(withLocationUser(UserSettingsPage));
