import api from "../../../../../api";

export default function updateById(
  { automationId, locationId, instanceId },
  body
) {
  return api.put(
    `/locations/${locationId}/automations/${automationId}/instances/${instanceId}`,
    body
  );
}
