import React, { useState } from "react";
import { Card, Accordion,  Form, Row, Col } from "react-bootstrap";
import omit from "lodash/omit";
import useContact from "../../hooks/useContact";
import get from "lodash/get";
import subMilliseconds from "date-fns/subMilliseconds";
import TimeSelectorInput from "../../../../../../Form/TimeSelectorInput";
import getDelay from "../../../../../../../common/functions/getDelay";
import { useTranslation } from "react-i18next";

export default function CreatedFilter({ onClose }) {
  const [values, setValues] = useState({});
  const { t } = useTranslation();
  const {
    backendFilters: filters,
    setBackendFilters: setFilters,
  } = useContact();
  const [selectedOption, setSelectedOption] = useState({});
  const options = [
    {
      key: "1",
      title: t(
        "locationDashboard.contactsPage.filterBox.createdFilter.moreThan"
      ),
      onSelect: () => {
        setFilters(omitFilters(filters));
        setValues({});
      },
    },
    {
      key: "2",
      title: t(
        "locationDashboard.contactsPage.filterBox.createdFilter.lessThan"
      ),
      onSelect: () => {
        setFilters(omitFilters(filters));
        setValues({});
      },
    },
  ];
  const omitFilters = (filters) =>
    omit(filters, ["startCreatedAt", "endCreatedAt"]);
  const handleClose = () => {
    setFilters(omitFilters(filters));
    onClose();
  };
  const handleChange = (e) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    if (newValues.value && newValues.timeSelector) {
      const delay = getDelay(newValues.value, newValues.timeSelector);
      if (selectedOption.key === "1") {
        setFilters({
          ...filters,
          endCreatedAt: subMilliseconds(new Date(), delay).toISOString(),
        });
      } else {
        setFilters({
          ...filters,
          startCreatedAt: subMilliseconds(new Date(), delay).toISOString(),
        });
      }
    }
    setValues(newValues);
  };
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="2">
        {values.timeSelector && values.value
          ? `Created ${selectedOption.title} ${
              values.value && values.timeSelector
                ? `${values.value} ${values.timeSelector}`
                : ""
            } ago`
          : "Set created filter"}{" "}
        <i className="dripicons-cross" onClick={handleClose}></i>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <Card.Body>
          {options.map((option) => (
            <>
              {" "}
              <Form.Check
                type="radio"
                label={option.title}
                name="createdAtRadios"
                id="formHorizontalRadios1"
                onChange={() => {
                  setSelectedOption(option);
                  option.onSelect();
                }}
              />
              {option.key === "1" && selectedOption.key === "1" && (
                <Row>
                  <Col>
                    <Form.Group controlId="formValue">
                      <Form.Control
                        type="number"
                        value={get(values, "value") || ""}
                        name="value"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {" "}
                    <TimeSelectorInput
                      handleChange={handleChange}
                      name="timeSelector"
                      value={get(values, "timeSelector")}
                    />
                  </Col>
                </Row>
              )}
              {option.key === "2" && selectedOption.key === "2" && (
                <Row>
                  <Col>
                    <Form.Group controlId="formValue">
                      <Form.Control
                        type="number"
                        value={get(values, "value") || ""}
                        name="value"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    {" "}
                    <TimeSelectorInput
                      handleChange={handleChange}
                      name="timeSelector"
                      value={get(values, "timeSelector")}
                    />
                  </Col>
                </Row>
              )}
            </>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
