import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateTimeRange from "../DateTimeRangeDropdown";
import useDashboard from "../hooks/useDashboard";

export default function OpportunitiesCard() {
  const { opportunities } = useDashboard();
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Container>
          <Row>
            <Col>
              <h4>{t("locationDashboard.mainPage.opportunitiesCard.title")}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <DateTimeRange />
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {" "}
        <Container fluid={true}>
          {" "}
          <Row>
            <Col className="text-center">
              <p className="font-24">{opportunities.length}</p>
            </Col>
          </Row>
          <Row className="text-success">
            <Col className="text-left">
              {t("locationDashboard.mainPage.opportunitiesCard.closed")}
            </Col>
            <Col className="text-right ">
              {opportunities.filter((o) => o.status === "won").length}
            </Col>
          </Row>
          <Row className="text-primary">
            <Col className="text-left">
              {t("locationDashboard.mainPage.opportunitiesCard.open")}
            </Col>
            <Col className="text-right ">
              {opportunities.filter((o) => o.status === "open").length}
            </Col>
          </Row>
          <Row className="text-red">
            <Col className="text-left">
              {t("locationDashboard.mainPage.opportunitiesCard.lost")}
            </Col>
            <Col className="text-right">
              {opportunities.filter((o) => o.status === "lost").length}
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
