import React from "react";
import { Container } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";

import Loading from "../../Loading";
import Error from "../../Error";
import LocationTable from "./LocationTable";
import LocationContext from "./contexts/LocationContext";
import api from "../../../common/api";
import withSystemAdmin from "../../../hocs/withSystemAdmin";
import useFetchArrayApi from "../../../hooks/useFetchArrayApi";

function LocationsPage() {
  const { t } = useTranslation();
  const {
    error,
    loading,
    data: locations,
    getData,
  } = useFetchArrayApi(api.admin.locations.get, {}, { eager: ["agency"] });

  if (loading)
    return (
      <div className="found it w-100">
        <Loading />
      </div>
    );

  return (
    <LocationContext.Provider
      value={{
        error,
        locations,
        loading,
        reload: () => {
          getData();
        },
      }}
    >
      <Container fluid={true} className="mt-2">
        {error && <Error error={error} />}
        <h3>{t("adminDashboard.locationsPage.title")}</h3>
        <LocationTable />
      </Container>
    </LocationContext.Provider>
  );
}

export default withSystemAdmin(withTranslation()(LocationsPage));
