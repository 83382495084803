import queryString from "query-string";
import api from "../../../../../api";

export default function getById(params, query) {
  return api.get(
    `/locations/${params.locationId}/conversations/${
      params.conversationId
    }/messages/${params.messageId}${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
