import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalButton from "../../ModalButton/index";
import api from "../../../common/api/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import TagCreateModal from "./TagRelateModal";
import Error from "../../Error";
import ConfirmationModal from "../../ConfirmationModal";

export default function TagsListInput({ contactId: cId }) {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const { locationId, contactId } = useParams();
  const {
    data: { tags = [] },
    
    error: fetchError,
    getData,
  } = useFetchApi(
    api.locations.contacts.getById,
    {
      locationId,
      contactId: contactId || cId,
    },
    { eager: ["tags"] },
    [contactId, cId]
  );
  const handleUnrelate = async (id) => {
    setError(null);
    try {
      await api.locations.contacts.tags.unrelate({
        locationId,
        tagId: id,
        contactId: contactId || cId,
      });
      getData();
    } catch (e) {
      setError(true);
    }
  };
  return (
    <>
      {error && <Error error={error} />}{" "}
      {fetchError && <Error error={fetchError} />}{" "}
      <p className="mt-3 mb-1">
        <strong> {t("formElements.tagList.label")}</strong>
      </p>
      {tags.map((a) => (
        <span className="bg-light p-1 m-1 d-inline-flex">
          {`${a.name}`}{" "}
          <ConfirmationModal
            title={t("formElements.tagList.unrelateModal.title")}
            text={t("formElements.tagList.unrelateModal.text")}
            button={<i className="dripicons-cross" />}
            callback={() => handleUnrelate(a.id)}
          />
        </span>
      ))}
      <ModalButton
        className="m-1"
        modal={<TagCreateModal contactId={contactId || cId} />}
        button={
          <Button variant="light">
            <i className="dripicons-plus"></i>
          </Button>
        }
        callback={() => {
          getData();
        }}
      />
    </>
  );
}
