function searchSources(sources, sourceFilter, length = 5) {
  const result = [];
  result.push(
    ...sources.filter((e) =>
      `${e.name ? e.name : ""}`
        .toLowerCase()
        .includes(sourceFilter.toLowerCase())
    )
  );
  if (result.length < length) {
    result.push(
      ...sources
        .filter((c) => !result.some((source) => source.id === c.id))
        .slice(0, length - result.length)
    );
  }
  return result;
}
export default searchSources;
