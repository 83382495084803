import React from "react";
import { NavLink, Switch, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../../../react-check-auth";
import { withTranslation, useTranslation } from "react-i18next";
import AuthorizedRoute from "../../../AuthorizedRoute";
import * as ROUTES from "../../../../constants/routes";
import TeamManagementSettingsPage from "./TeamManagementSettingsPage";
import TagsSettingsPage from "./TagsSettingsPage";
import SourcesSettingsPage from "./SourcesSettingsPage";
import SmsSettingsPage from "./SmsSettingsPage";
import VoiceSettingsPage from "./VoiceSettingsPage";
import EmailSettingsPage from "./EmailSettingsPage";
import DirectEmailSettingsPage from "./DirectEmailSettingsPage";
import ProfileSettingsPage from "./ProfileSettingsPage";
import PipelinesSettingsPage from "./PipelinesSettingsPage";
import CustomFieldsSettingsPage from "./CustomFieldsSettingsPage";
import TemplatesSettingsPage from "./TemplatesSettingsPage";
import CompanySettingsPage from "./CompanySettingsPage";
import isSystemUser from "../../../../common/functions/isSystemUser";

function SettingsPage() {
  const params = useParams();
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  return (
    <Container fluid={true}>
      <Row className="align-items-center">
        <Col>
          <h2>{t("locationDashboard.settingsPage.title")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul className="nav nav-tabs mb-3">
            {!isSystemUser(userInfo) && (
              <li className="nav-item">
                <NavLink
                  to={ROUTES.AGENCY_LOCATIONS_SETTINGS_PROFILE.as(params)}
                  className="nav-link"
                >
                  <i className="mdi mdi-home-variant d-md-none d-block"></i>
                  <span className="d-none d-md-block">
                    {t("locationDashboard.settingsPage.userSettingsPage.title")}
                  </span>
                </NavLink>
              </li>
            )}

            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_COMPANY.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t(
                    "locationDashboard.settingsPage.companySettingsPage.title"
                  )}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_TEAM_MANAGEMENT.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.teamManagementPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_SMS.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.smsPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_VOICE.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.voicePage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_EMAIL.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.emailPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_DIRECT_EMAIL.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.directEmailPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_PIPELINES.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.pipelinePage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_CUSTOM_FIELDS.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.customFieldsPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_TAGS.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.tagPage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_SOURCES.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.sourcePage.title")}
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={ROUTES.AGENCY_LOCATIONS_SETTINGS_TEMPLATES.as(params)}
                className="nav-link"
              >
                <i className="mdi mdi-home-variant d-md-none d-block"></i>
                <span className="d-none d-md-block">
                  {" "}
                  {t("locationDashboard.settingsPage.templatePage.title")}
                </span>
              </NavLink>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch>
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS.href}
              component={ProfileSettingsPage}
              exact
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_PROFILE.href}
              component={ProfileSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_COMPANY.href}
              component={CompanySettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_TEAM_MANAGEMENT.href}
              component={TeamManagementSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_SMS.href}
              component={SmsSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_VOICE.href}
              component={VoiceSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_EMAIL.href}
              component={EmailSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_DIRECT_EMAIL.href}
              component={DirectEmailSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_PIPELINES.href}
              component={PipelinesSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_TAGS.href}
              component={TagsSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_SOURCES.href}
              component={SourcesSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_CUSTOM_FIELDS.href}
              component={CustomFieldsSettingsPage}
            />
            <AuthorizedRoute
              path={ROUTES.AGENCY_LOCATIONS_SETTINGS_TEMPLATES.href}
              component={TemplatesSettingsPage}
            />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(SettingsPage);
