import React from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import DateInput from "../../../../../Form/DateInput";
import TagsInput from "../../../../../Form/TagsInput";
import OpportunityListInput from "../../../../../Form/OpportunitiesListInput";
import SourceCreatableInput from "../../../../../Form/SourceCreatableInput";
import AutomationsListInput from "../../../../../Form/AutomationInstanceListInput";
import CountryInput from "../../../../../Form/CountryInput";
import TitleInput from "../../../../../Form/TitleInput";
import pickContact from "../../../../../../common/pickers/pickContact";
import AcademicTitleInput from "../../../../../Form/AcademicTitleInput";
import CustomFieldInput from "../../../../../Form/CustomFieldInput";

const schema = yup.object({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  company: yup.string().nullable(),
  email: yup.string().email("validation.email.valid").nullable(),
  phone: yup.string().nullable(),
  birthDate: yup.string().nullable(),
  address: yup
    .object({
      street: yup.string().nullable(),
      city: yup.string().nullable(),
      postcode: yup.string().nullable(),
      country: yup.string().nullable(),
      state: yup.string().nullable(),
    })
    .nullable(),
  notes: yup.string().nullable(),
  title: yup.string().nullable(),
});

const ContactForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { contactId } = useParams();
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TitleInput
        handleChange={handleChange}
        name={"title"}
        error={get(errors, "title")}
        value={get(values, "title") || ""}
      />
      <AcademicTitleInput
        handleChange={handleChange}
        name={"academicTitle"}
        error={get(errors, "academicTitle")}
        value={get(values, "academicTitle") || ""}
      />
      <Form.Group controlId="formFirstName">
        <Form.Label>
          {t("locationDashboard.contactShowPage.form.firstName.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.firstName.placeholder"
          )}
          value={get(values, "firstName") || ""}
          name="firstName"
          onChange={handleChange}
          isInvalid={!!get(errors, "firstName")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "firstName"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formLastName">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.lastName.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.lastName.placeholder"
          )}
          value={get(values, "lastName") || ""}
          name="lastName"
          onChange={handleChange}
          isInvalid={!!get(errors, "lastName")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "lastName"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.email.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.email.placeholder"
          )}
          value={get(values, "email") || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!get(errors, "email")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "email"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formPhone">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.phone.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.phone.placeholder"
          )}
          value={get(values, "phone") || ""}
          name="phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "phone")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formCompany">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.company.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.company.placeholder"
          )}
          value={get(values, "company") || ""}
          name="company"
          onChange={handleChange}
          isInvalid={!!get(errors, "company")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "company"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBirthDate">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.birthDate.label")}
        </Form.Label>
        <DateInput
          name="birthDate"
          value={get(values, "birthDate") || ""}
          onChange={setFieldValue}
          isInvalid={!!get(errors, "birthDate")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "birthDate"))}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formStreet">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.street.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.street.placeholder"
          )}
          value={get(values, "address.street") || ""}
          name="address.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.street")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.street"))}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group as={Row} controlId="formCity">
        <Col>
          <Form.Label>
            {t("locationDashboard.contactShowPage.form.city.label")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.contactShowPage.form.city.placeholder"
            )}
            value={get(values, "address.city") || ""}
            name="address.city"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.city")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.city"))}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>
            {" "}
            {t("locationDashboard.contactShowPage.form.postcode.label")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.contactShowPage.form.postcode.placeholder"
            )}
            value={get(values, "address.postcode") || ""}
            name="address.postcode"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.postcode")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.postcode"))}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group controlId="formState">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.state.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.form.state.placeholder"
          )}
          value={get(values, "address.state") || ""}
          name="address.state"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.state")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.state"))}
        </Form.Control.Feedback>
      </Form.Group>
      <CountryInput
        value={get(values, "address.country") || ""}
        name="address.country"
        handleChange={handleChange}
        error={!!get(errors, "address.country")}
        defaultOption={true}
      />
      <Form.Group controlId="formNotes">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.notes.label")}
        </Form.Label>
        <Form.Control
          type="text"
          name="notes"
          as="textarea"
          value={get(values, "notes")}
          style={{ minHeight: 80 }}
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "notes"))}
        </Form.Control.Feedback>
      </Form.Group>
      <CustomFieldInput
        value={get(values, "customFields")}
        onChange={handleChange}
      />
      <Form.Group controlId="formTags">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.tags.label")}
        </Form.Label>
        <TagsInput
          setFieldValue={setFieldValue}
          name={"tags"}
          value={get(values, "tags")}
          error={get(errors, "tags")}
        />
      </Form.Group>
      <Form.Group>
        <OpportunityListInput />
      </Form.Group>
      <Form.Group>
        <AutomationsListInput contactId={contactId} />
      </Form.Group>
      <SourceCreatableInput
        name="source"
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        values={values}
        errors={errors}
      />
      <Form.Group>
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.form.contactCreatedAt")}
        </Form.Label>
        <p className="text-muted">
          {values.createdAt && format(parseISO(values.createdAt), "d MMM YYY")}
        </p>
      </Form.Group>

      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.contactShowPage.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {
    const result = { ...values };
    if (result.tags) {
      result.tags = result.tags.map((t) =>
        t.id === t.name ? { name: t.name } : { id: t.id, name: t.name }
      );
    }
    props.onSubmit(
      omit(pickContact(result), ["error", "onSubmit", "onCancel", "action"])
    );
  },
  validationSchema: schema,
})(ContactForm);
