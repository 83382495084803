import React from "react";
import { Button, Col,  Form, Row } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import PipelineInput from "../../PipelineInput/index";
import StageInput from "../../StageInput/index";
import opportunityStatuses from "../../../../constants/opportunityStatuses";

const schema = yup.object({
  fullName: yup.string(),
  leadValue: yup.number().integer(),
  pipelineId: yup.string().required(),
  stageId: yup.string().required(),
  contactId: yup.string().required(),
});

const OpportunityForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group as={Row} controlId="formContact">
        <Col>
          <PipelineInput
            value={get(values, "pipelineId") || ""}
            onChange={handleChange}
            error={get(errors, "pipelineId")}
          />
        </Col>
        <Col>
          <StageInput
            pipelineId={get(values, "pipelineId")}
            value={get(values, "stageId") || ""}
            onChange={handleChange}
            error={get(errors, "stageId")}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="formContact">
        <Col>
          <Form.Group>
            <Form.Label>{t("formElements.status.label")}</Form.Label>
            <Form.Control
              name="status"
              as="select"
              value={get(values, "status")}
              onChange={handleChange}
              isInvalid={!!get(errors, "status")}
            >
              <option>{t("formElements.status.option")}</option>
              {opportunityStatuses.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {get(errors, "status")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formLeadValue">
            <Form.Label>{t("formElements.leadValue.label")}</Form.Label>
            <Form.Control
              type="number"
              placeholder={t("formElements.leadValue.placeholder")}
              value={get(values, "leadValue") || ""}
              name="leadValue"
              onChange={handleChange}
              isInvalid={!!get(errors, "leadValue")}
            />
            <Form.Control.Feedback type="invalid">
              {get(errors, "leadValue")}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("formElements.opportunityList.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("formElements.opportunityList.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(OpportunityForm);
