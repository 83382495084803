import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import TriggerLinkInput from "../../../../../../../Form/TriggerLinkInput";

export const schema = yup.object({
  linkId: yup.string().required("validation.linkId.required"),
  allowMultiple: yup.boolean(),
});

const TriggerLinkClickForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formMultiple">
        <Form.Label>
          {t(
            "locationDashboard.automationShowPage.triggerLinkClickForm.multiple.label"
          )}
        </Form.Label>
        <Form.Check
          type="checkbox"
          value={get(values, "allowMultiple")}
          checked={get(values, "allowMultiple")}
          name="allowMultiple"
          onChange={handleChange}
          label={t(
            "locationDashboard.automationShowPage.triggerLinkClickForm.multiple.placeholder"
          )}
        />
      </Form.Group>
      <TriggerLinkInput
        error={get(errors, "linkId")}
        onChange={handleChange}
        value={get(values, "linkId")}
        label={t(
          "locationDashboard.automationShowPage.triggerLinkClickForm.linkId.label"
        )}
      />
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t(
            "locationDashboard.automationShowPage.triggerLinkClickForm.submit"
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(TriggerLinkClickForm);
