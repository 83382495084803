import pick from "lodash/pick";

export default (data) => {
  const result = pick(data, [
    "contactId",
    "transportId",
    "content",
    "senderType",
    "type",
    "meta",
  ]);
  return result;
};
