import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import TimezoneSelect from "react-timezone-select";

const customStyles = {
  input: (provided) => ({
    ...provided,
    display: 'flex', // Bug in safari, shows as 2px due to being display: inline-grid
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
}

export default function TimeZoneInput({
  name,
  handleChange,
  value,
  error,
}) {
  const { t } = useTranslation();

  return (
    <Form.Group controlId="formTimezone">
      <Form.Label> {t("formElements.timezone.label")}</Form.Label>
      
      <TimezoneSelect
        value={value}
        onChange={handleChange}
        className=""
        styles={customStyles}
      />
      
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
