import React from "react";
import { useTranslation } from "react-i18next";
import emailServices from "../../../../../../../constants/emailServices";
import { Form } from "react-bootstrap";

export default function EmailServiceNameInput({
  name,
  handleChange,
  value,
  error,
}) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formEmailServiceName">
      <Form.Label>
        {" "}
        {t("locationDashboard.settingsPage.emailPage.form.emailService.label")}
      </Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value}
        onChange={handleChange}
        isInvalid={error}
      >
        {emailServices.map((emailService) => (
          <option key={emailService} value={emailService}>
            {emailService}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{t(error)}</Form.Control.Feedback>
    </Form.Group>
  );
}
