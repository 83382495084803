import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import api from "../../../../common/api";
import TagForm from "../TagForm";
import Error from "../../../Error";
import Loading from "../../../Loading";
// import pickTag from "../../../../common/pickers/pickTag";

const TagCreateModal = ({ handleClose, callback, show, contactId }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.contacts.tags.relate({
        locationId,
        contactId,
        tagId: values.tagId,
      });
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> {t("formElements.tagList.modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <TagForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TagCreateModal;
