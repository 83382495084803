import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import { Field, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import DateTimeInput from "../../../../../../Form/DateTimeInput";
import AccountInput from "../../../../../../Form/AgencyAccountInput";

const schema = yup.object({
  title: yup.string().required("validation.title.required"),
  description: yup.string().nullable(),
  accountId: yup.string(), // .required("validation.accountId.required"),
  dueDate: yup.date().required("validation.dueDate.required"),
});

const ContactForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formTitle">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactShowPage.tasksPage.form.title.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactShowPage.tasksPage.form.title.placeholder"
          )}
          value={get(values, "title") || ""}
          name="title"
          onChange={handleChange}
          isInvalid={!!get(errors, "title")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "title"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDescription">
        {" "}
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.contactShowPage.tasksPage.form.description.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          as={"textarea"}
          rows={3}
          placeholder={t(
            "locationDashboard.contactShowPage.tasksPage.form.description.placeholder"
          )}
          value={get(values, "description") || ""}
          name="description"
          onChange={handleChange}
          isInvalid={!!get(errors, "description")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "description"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDueDate">
        <Form.Label>
          {t("locationDashboard.contactShowPage.tasksPage.form.dueDate.label")}
        </Form.Label>
        <div
          className={
            "form-control " + (get(errors, "dueDate") || "" ? "is-invalid" : "")
          }
        >
          <Field
            name="dueDate"
            value={get(values, "dueDate") || ""}
            component={DateTimeInput}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "dueDate"))}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
      <AccountInput
        onChange={handleChange}
        value={get(values, "accountId") || ""}
        label={t(
          "locationDashboard.contactShowPage.tasksPage.form.accountId.label"
        )}
        error={get(errors, "accountId")}
      />
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.contactsPage.createModal.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.contactsPage.createModal.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(ContactForm);
