import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateTimeRange from "../DateTimeRangeDropdown";
import useDashboard from "../hooks/useDashboard";

export default function ConversionRateCard() {
  const { opportunities } = useDashboard();
  const { t } = useTranslation();
  return (
    <Card className="conversion-rate">
      <Card.Header>
        <Container>
          <Row>
            <Col>
              <h4>
                {t("locationDashboard.mainPage.conversionRateCard.title")}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <DateTimeRange />
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {" "}
        <Container fluid={true}>
          {" "}
          <Row>
            <Col className="text-center">
              <p className="font-24">
                {(
                  (opportunities.filter((o) => o.status === "won").length /
                    opportunities.length) *
                    100 || 0
                ).toFixed(2)}
                %
              </p>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
