import React from "react";

import Iframe from "react-iframe";

export default function HelpPage() {
  return (
    <div className="mt-3 embed-responsive embed-responsive-21by9">
      <Iframe
        className="embed-responsive-item"
        frameBorder="0"
        width="100%"
        height="100%"
        url="https://leadkraken.tawk.help/"
        id="myId"
      />
    </div>
  );
}
