import React, { useState } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { useCheckAuth } from "../../react-check-auth";
import Loading from "../Loading";
import SignUpForm from "./SignUpForm";
import AuthLayout from "../../Layout/Auth";
import api from "../../common/api";
import * as ROUTES from "../../constants/routes";
import logo from "../../assets/images/logo-with-text.png";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import withStripe from "../../hocs/withStripe";
import Error from "../Error";
import withFirebase from "../../hocs/withFirebase";
import useFirebase from "../../hooks/useFirebase";

const SignUp = ({ history }) => {
  const { t } = useTranslation();
  const { refreshAuth } = useCheckAuth();
  const firebase = useFirebase();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  if (loading) return <Loading />;
  const onSubmit = async (data) => {
    /* const cardElement = elements.getElement(CardElement);
        if (!stripe || !elements) {
          setError({ message: "Try one more time" });
        }
    
        const { error, token } = await stripe.createToken(cardElement);*/
    if (error) setError(error);
    setLoading(true);
    try {
      //Delete when adding paid plans
      const { data: plans } = await api.plans.get();
      data.planId = plans.find((p) => p.price === 0).id;
      await api.checkout.agencies.createSubscription({
        planId: data.planId,
        email: data.email,
        password: data.password,
        name: data.name,
        // token: token.id,
        address: data.address,
        vatId: data.vatId,
      });
      const authResult = await firebase.doSignInWithEmailAndPassword(
        data.email,
        data.password
      );
      const idToken = await authResult.user.getIdToken();

      await api.session.start({ idToken });
      refreshAuth();
      setLoading(false);
      history.push(ROUTES.AGENCY.as());
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="account-pages mt-5 mb-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card">
              <div className="card-header pt-4 pb-4 text-center bg-primary">
                <span>
                  <img src={logo} alt="" height="25" />
                </span>
              </div>

              <div className="card-body p-4">
                <div className="text-center w-75 m-auto">
                  <h4 className="text-dark-50 text-center mt-0 font-weight-bold">
                    {t("signUpPage.title")}
                  </h4>
                  <p className="text-muted mb-4">{t("signUpPage.hint")}</p>
                </div>
                {error && <Error error={error} />}
                <SignUpForm
                  address={{ country: "Germany" }}
                  onSubmit={onSubmit}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12 text-center">
                <p className="text-muted">
                  {t("signUpPage.haveAccount")}{" "}
                  <NavLink to={ROUTES.SIGN_IN.as()} className="text-muted ml-1">
                    <b>{t("signUpPage.signIn")}</b>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TranslatedSignUp = withRouter(
  withStripe(withFirebase(withTranslation()(SignUp)))
);

const SignUpPage = () => {
  return (
    <AuthLayout>
      <TranslatedSignUp />
    </AuthLayout>
  );
};

export default SignUpPage;
