import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import TagsInput from "../../../../../../../Form/TagsInput";

export const schema = yup.object({
  tags: yup.array().required(),
});

const HasTagForm = ({
  handleSubmit,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div>
        <i className="mdi mdi-circle font-13 text-success mr-2"></i>
        {t(
          "locationDashboard.automationShowPage.hasTagForm.portDescription.successHint"
        )}
      </div>
      <div className="mb-3">
        <i className="mdi mdi-circle font-13 text-danger mr-2"> </i>
        {t(
          "locationDashboard.automationShowPage.hasTagForm.portDescription.failureHint"
        )}
      </div>
      <Row className="mb-2 mt-2">
        <Col>
          <TagsInput
            creatable={false}
            setFieldValue={setFieldValue}
            value={get(values, "tags")}
            error={get(errors, "tags")}
            name={"tags"}
          />
        </Col>
      </Row>

      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.hasTagForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(HasTagForm);
