export default [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Full Name", key: "fullName" },
  { label: "Title", key: "title" },
  { label: "Academic Title", key: "academicTitle" },
  { label: "Company", key: "company" },
];
