import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import LetterExpressForm from "./LetterExpressForm";
import EmailServiceNameInput from "./DirectEmailServiceNameInput";

const schema = yup.object({
  name: yup.string(),
  config: yup.object({
    apiKey: yup.string(),
    username: yup.string(),
  }),
});

const EmailServiceForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  useTranslation();
  useParams();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      {values.action !== "edit" && (
        <EmailServiceNameInput
          value={get(values, "name") || ""}
          name="name"
          handleChange={handleChange}
          error={!!get(errors, "name")}
        />
      )}

      {get(values, "name") === "letter-express" ? (
        <LetterExpressForm
          handleChange={handleChange}
          errors={errors}
          values={values}
          isValid={isValid}
        />
      ) : null}
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(EmailServiceForm);
