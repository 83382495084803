export default [
  "Deutschland",
  "Österreich",
  "Schweiz",
  "Luxemburg",
  "Frankreich",
  "Australia",
  "United States",
  "United Kingdom",
  "Spanien",
];
