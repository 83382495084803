import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EmailServiceTable from "./DirectEmailServiceTable";
import DirectEmailServiceContext from "./contexts/DirectEmailServiceContext";
import EmailServiceSearchBar from "./DirectEmailServiceSearchBar";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationAdmin from "../../../../../hocs/withLocationAdmin";
import { useCheckAuth } from "../../../../../react-check-auth";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import api from "../../../../../common/api";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const DirectEmailSettingsPage = () => {
  useCheckAuth();
  const { locationId } = useParams();
  const [,] = useState(false);
  const [,] = useState(null);
  const {
    data: services,
    loading: fetchServicesLoading,
    error: fetchServicesError,
    getData,
  } = useFetchApi(
    api.locations.transports.get,
    { locationId },
    { type: "direct-mail" }
  );
  return (
    <DirectEmailServiceContext.Provider value={{ services, reload: getData }}>
      <Container fluid={true}>
        <Row>
          <Col>
            <EmailServiceSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {fetchServicesError && <Error error={fetchServicesError} />}
                {fetchServicesLoading ? (
                  <Loading />
                ) : (
                  services && <EmailServiceTable />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </DirectEmailServiceContext.Provider>
  );
};
export default withSubscriptionCheck(
  withLocationAdmin(DirectEmailSettingsPage)
);
