import get from "lodash/get";

export default function getContactLetters(contact) {
    let result = "";
    if (get(contact, "firstName")) {
        result += contact.firstName[0].toUpperCase();
    }
    if (get(contact, "lastName")) {
        result += contact.lastName[0].toUpperCase();
    }
    return result;
}