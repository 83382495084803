import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import * as ROUTES from "../../../constants/routes";

export default function InactiveSubscriptionPage() {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h2>Account deactivated</h2>
          <p>Subscribe to new plan to activate your account</p>
          <Link to={ROUTES.AGENCY_SETTINGS_AGENCY_BILLING.as()}>
            <Button>Choose plan</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}
