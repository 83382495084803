import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WithContext as ReactTags } from "react-tag-input";
import Loading from "../../Loading";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import api from "../../../common/api";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const TagsInput = ({
  setFieldValue,
  value = [],
  name,
  creatable = true,
  error: validationError,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const tags = value.map((tag) => {
    return { text: tag.name, id: tag.id };
  });
  const { data, loading, error } = useFetchApi(api.locations.tags.get, {
    locationId,
  });
  const suggestions = data.map((d) => ({ id: d.id, text: d.name }));
  const handleDelete = (i) => {
    const value = tags.filter((tag, index) => index !== i);
    handleInputChange(value);
  };

  const handleAddition = (tag) => {
    if (!creatable) {
      if (!suggestions.find((s) => s === tag)) {
        return;
      }
    }
    const value = [...tags, tag];
    handleInputChange(value);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    handleInputChange(newTags);
  };
  const handleInputChange = (tags) => {
    if (setFieldValue) {
      setFieldValue(
        name,
        tags.map((t) => ({ id: t.id, name: t.text }))
      );
    }
  };
  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;
  console.log(validationError);
  return (
    <div>
      <ReactTags
        suggestions={suggestions}
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
      />
      {validationError && (
        <div className="text-validation">{t(validationError)}</div>
      )}
    </div>
  );
};

export default TagsInput;
