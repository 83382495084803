import React from "react";

import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const AccountInput = ({
  onChange,
  value = "",
  error: passedError,
  label,
  name = "accountId",
}) => {
  const { t } = useTranslation();
  const {
    data,
    loading: fetchAccountsLoading,
    error: fetchAccountsError,
  } = useFetchApi(api.accounts.get);
  if (fetchAccountsLoading) return <Loading />;
  return (
    <Form.Group controlId="forAccountId">
      {fetchAccountsError && <Error error={fetchAccountsError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name={name}
        as="select"
        value={value || ""}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>All accounts</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default AccountInput;
