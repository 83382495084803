import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";


const schema = yup.object({
  email: yup
    .string()
    .email("validation.email.valid")
    .required("validation.email.required"),
});

const EmailForm = ({
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formEmail">
        <Form.Control
          name="email"
          value={values.email || ""}
          onChange={handleChange}
          type="text"
          placeholder={t("forgotPasswordPage.form.email.placeholder")}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.email)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Button
          className="btn btn-lg btn-block"
          variant="primary"
          disabled={!isValid}
          type="submit"
        >
          {t("forgotPasswordPage.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit"]));
  },
  validationSchema: schema,
})(EmailForm);
