import React from "react";
import AddedToAutomationForm, {
  schema as AddedToAutomationSchema,
} from "../AutomationTool/AutomationElementForm/AddedToAutomationForm/index";
import AddToAutomationForm, {
  schema as AddToAutomationSchema,
} from "../AutomationTool/AutomationElementForm/AddToAutomationForm/index";
import AddUpdateOpportunityForm, {
  schema as AddUpdateOpportunitySchema,
} from "../AutomationTool/AutomationElementForm/AddUpdateOpportunityForm/index";
import ExecuteWebhookForm, {
  schema as ExecuteWebhookSchema,
} from "../AutomationTool/AutomationElementForm/ExecuteWebhookForm/index";
import ResponseContainsForm, {
  schema as ResponseContainsSchema,
} from "../AutomationTool/AutomationElementForm/ResponseContainsForm/index";
import SendSmsForm, {
  schema as SendSmsSchema,
} from "../AutomationTool/AutomationElementForm/SendSmsForm/index";
import SendEmailForm, {
  schema as SendEmailSchema,
} from "../AutomationTool/AutomationElementForm/SendEmailForm/index";
import SmsReplyForm, {
  schema as SmsReplySchema,
} from "../AutomationTool/AutomationElementForm/SmsReplyForm/index";
import UpdateContactForm, {
  schema as UpdateContactSchema,
} from "../AutomationTool/AutomationElementForm/UpdateContactForm/index";
import WaitForForm, {
  schema as WaitForSchema,
} from "../AutomationTool/AutomationElementForm/WaitForForm/index";
import WaitForSmsForm, {
  schema as WaitForSmsSchema,
} from "../AutomationTool/AutomationElementForm/WaitForSmsForm/index";
import WaitForEmailForm, {
  schema as WaitForEmailSchema,
} from "../AutomationTool/AutomationElementForm/WaitForEmailForm";

import CancelAutomationForm, {
  schema as CancelAutomationSchema,
} from "../AutomationTool/AutomationElementForm/CancelAutomationForm";
import SendLetterForm, {
  schema as SendLetterSchema,
} from "../AutomationTool/AutomationElementForm/SendLetterForm";
import TriggerLinkClickForm, {
  schema as TriggerLinkClickSchema,
} from "../AutomationTool/AutomationElementForm/TriggerLinkClickForm";
import SendCallForm, {
  schema as SendCallSchema,
} from "../AutomationTool/AutomationElementForm/SendCallForm";
import CommentForm, {
  schema as CommentSchema,
} from "../AutomationTool/AutomationElementForm/CommentForm";
import AddTaskForm, {
  schema as AddTaskSchema,
} from "../AutomationTool/AutomationElementForm/AddTaskForm";
import HasTagForm, {
  schema as HasTagSchema,
} from "../AutomationTool/AutomationElementForm/HasTagForm";

export default [
  {
    // title: "Zu Automation hinzugefügt",
    data: "added_to_automation",
    translationKey: 'locationDashboard.automationShowPage.addedToAutomationForm',
    type: "trigger",
    //description: "Ein Kontakt wurde dieser Automation hinzugefügt",
    inputs: [],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-media-play icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-media-play"></i>,
    getFormComponent: (props) => <AddedToAutomationForm {...props} />,
    schema: AddedToAutomationSchema,
  },
  {
    // title: "Klick auf Trigger Link",
    data: "trigger_link",
    translationKey: 'locationDashboard.automationShowPage.triggerLinkClickForm',
    type: "trigger",
    //description: "Ein Kontakt hat auf einen Trigger Link geklickt",
    inputs: [],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-link icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-link"></i>,
    getFormComponent: (props) => <TriggerLinkClickForm {...props} />,
    schema: TriggerLinkClickSchema,
  },
  {
    // title: "Antwort per SMS",
    data: "sms_reply",
    translationKey: 'locationDashboard.automationShowPage.smsReplyForm',
    type: "trigger",
    //description: "Ein Kontakt hat per SMS geantwortet",
    inputs: [],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-phone icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-phone"></i>,
    getFormComponent: (props) => <SmsReplyForm {...props} />,
    schema: SmsReplySchema,
  },
  {
    // title: "SMS senden",
    data: "send_sms",
    translationKey: 'locationDashboard.automationShowPage.sendSmsForm',
    type: "action",
    //description: "Eine SMS an den Kontakt in dieser Automation schicken",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-phone icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-phone"></i>,
    getFormComponent: (props) => <SendSmsForm {...props} />,
    schema: SendSmsSchema,
  },
  {
    // title: "Email senden",
    data: "send_email",
    translationKey: 'locationDashboard.automationShowPage.sendEmailForm',
    type: "action",
    //description: "Eine Email an den Kontakt in dieser Automation schicken",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-mail icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-mail"></i>,
    getFormComponent: (props) => <SendEmailForm {...props} />,
    schema: SendEmailSchema,
  },
  {
    // title: "Brief senden",
    data: "send_letter",
    translationKey: 'locationDashboard.automationShowPage.sendLetterForm',
    type: "action",
    //description: "Einen Brief an den Kontakt in dieser Automation schicken",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-print icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-print"></i>,
    getFormComponent: (props) => <SendLetterForm {...props} />,
    schema: SendLetterSchema,
  },
  {
    // title: "Deal hinzufügen/aktualisieren",
    data: "update_opportunity",
    translationKey: 'locationDashboard.automationShowPage.addUpdateOpportunityForm',
    type: "action",
    //description: "Änderungen an Deal vornehmen",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-trophy icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-trophy"></i>,
    getFormComponent: (props) => (
      <AddUpdateOpportunityForm opportunity={{}} {...props} />
    ),
    schema: AddUpdateOpportunitySchema,
  },
  {
    // title: "Kontakt aktualisieren",
    data: "update_contact",
    translationKey: 'locationDashboard.automationShowPage.updateContactForm',
    type: "action",
    //description: "Änderungen an Kontaktinformationen vornehmen",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-user icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-user"></i>,
    getFormComponent: (props) => <UpdateContactForm contact={{}} {...props} />,
    schema: UpdateContactSchema,
  },
  {
    // title: "Webhook ausführen",
    data: "execute_webhook",
    translationKey: 'locationDashboard.automationShowPage.executeWebhookForm',
    type: "action",
    //description: "Kontaktinformationen an beliebigen Server schicken",
    inputs: [{}],
    outputs: [{ classes: ["port-success"] }, { classes: ["port-warning"] }],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-cloud-upload icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-cloud-upload"></i>,
    getFormComponent: (props) => <ExecuteWebhookForm {...props} />,
    schema: ExecuteWebhookSchema,
  },
  {
    // title: "Zu Automation hinzufügen",
    data: "add_to_automation",
    translationKey: 'locationDashboard.automationShowPage.addedToAutomationForm',
    type: "action",
    //description: "Kontakt zu einer Automation hinzufügen",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-forward icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-forward"></i>,
    getFormComponent: (props) => <AddToAutomationForm {...props} />,
    schema: AddToAutomationSchema,
  },
  {
    // title: "Aus Automation(en) entfernen",
    data: "cancel_automation",
    translationKey: 'locationDashboard.automationShowPage.cancelAutomationForm',
    type: "action",
    //description: "Kontakt aus einer speziellen oder allen laufenden Automationen entfernen",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-wrong icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-wrong"></i>,
    getFormComponent: (props) => (
      <CancelAutomationForm removeMultiple={false} {...props} />
    ),
    schema: CancelAutomationSchema,
  },
  {
    // title: "Anrufen",
    data: "send_call",
    translationKey: 'locationDashboard.automationShowPage.callForm',
    type: "action",
    //description: "Ihr Vertriebler wird mit Lead in dieser Automation verbunden",
    inputs: [{}],
    outputs: [{ classes: ["port-success"] }, { classes: ["port-warning"] }],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-microphone icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-microphone"></i>,
    getFormComponent: (props) => <SendCallForm {...props} />,
    schema: SendCallSchema,
  },
  {
    title: "Aufgabe erstellen",
    data: "add_task",
    translationKey: 'locationDashboard.automationShowPage.addTaskForm.title',
    type: "action",
    //description: "Eine Aufgabe erstellen und einem Mitarbeiter zuweisen",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-clipboard icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-clipboard"></i>,
    getFormComponent: (props) => <AddTaskForm {...props} />,
    schema: AddTaskSchema,
  },
  {
    // title: "Antwort enthält",
    data: "input_contains",
    translationKey: 'locationDashboard.automationShowPage.responseContainsForm',
    type: "condition",
    //description: "Prüfen, ob die Antwort eines Kontakts ein Stichwort enthält bzw. nicht enthält",
    inputs: [{}],
    outputs: [{ classes: ["port-success"] }, { classes: ["port-warning"] }],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-conversation icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-conversation"></i>,
    getFormComponent: (props) => (
      <ResponseContainsForm
        includeTermMatchOptions={{ any: true }}
        excludeTermMatchOptions={{ any: true }}
        {...props}
      />
    ),
    schema: ResponseContainsSchema,
  },
  {
    // title: "Warten",
    data: "wait",
    translationKey: 'locationDashboard.automationShowPage.waitForm',
    type: "condition",
    //description: "Pausiert die Durchführung der Automation",
    inputs: [{}],
    outputs: [{}],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-clock icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-hourglass"></i>,
    getFormComponent: (props) => <WaitForForm {...props} />,
    schema: WaitForSchema,
  },
  {
    // title: "Auf SMS-Antwort warten",
    data: "wait_sms",
    translationKey: 'locationDashboard.automationShowPage.waitForSmsForm',
    type: "condition",
    //description: "Reagiere auf eine (nicht erfolgte) SMS-Antwort des Kontakts",
    inputs: [{}],
    outputs: [
      {
        classes: ["port-success"],
      },
      { classes: ["port-warning"] },
    ],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-hourglass icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-hourglass"></i>,
    getFormComponent: (props) => <WaitForSmsForm {...props} />,
    schema: WaitForSmsSchema,
  },
  {
    // title: "Auf Email-Antwort warten",
    data: "wait_email",
    translationKey: 'locationDashboard.automationShowPage.waitForEmailForm',
    type: "condition",
    //description: "Reagiere auf eine (nicht erfolgte) Email-Antwort des Kontakts",
    inputs: [{}],
    outputs: [
      {
        classes: ["port-success"],
      },
      { classes: ["port-warning"] },
    ],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-hourglass icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-hourglass"></i>,
    getFormComponent: (props) => <WaitForEmailForm {...props} />,
    schema: WaitForEmailSchema,
  },
  {
    // title: "Kontakt hat Tag",
    data: "has_tag",
    translationKey: 'locationDashboard.automationShowPage.hasTagForm',
    type: "condition",
    //description: "Kontakt in dieser Automation hat einen bestimmten Tag",
    inputs: [{}],
    outputs: [
      {
        classes: ["port-success"],
      },
      { classes: ["port-warning"] },
    ],
    getElement: () => {
      return `<div class="title-box text-center"><i class="dripicons-tag icon"></i><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-tag"></i>,
    getFormComponent: (props) => <HasTagForm {...props} />,
    schema: HasTagSchema,
  },
  {
    // title: "Kommentar hinzufügen",
    data: "comment",
    translationKey: 'locationDashboard.automationShowPage.commentForm',
    type: "additional",
    //description: "Ziehen Sie einen Kommentar-Block auf den Canvas, um Mitarbeitern diese Automation besser zu beschreiben",
    inputs: [],
    outputs: [],
    getElement: () => {
      return `<div style="width: 250px" class="title-box"><span data-i18n="{{key}}">{{title}}</span></div>`;
    },
    cardIcon: () => <i className="dripicons-italic"></i>,
    getFormComponent: (props) => <CommentForm {...props} />,
    schema: CommentSchema,
  },
];
