import React from "react";
import { useTranslation } from "react-i18next";
import voiceServices from "../../../../../../../constants/voiceServices";
import { Form } from "react-bootstrap";

export default function VoiceServiceNameInput({
  name,
  handleChange,
  value,
  error,
}) {
  const { t } = useTranslation();
  return (
    <Form.Group controlId="formVoiceServiceName">
      <Form.Label>
        {" "}
        {t("locationDashboard.settingsPage.smsPage.form.smsService.label")}
      </Form.Label>
      <Form.Control
        name={name}
        as="select"
        value={value}
        onChange={handleChange}
        isInvalid={error}
      >
        {voiceServices.map((service) => (
          <option key={service} value={service}>
            {service}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
}
