import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import OpportunityForm from "../OpportunityForm";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import useFetchApi from "../../../../../hooks/useFetchApi";

const OpportunityEditModal = ({
  handleClose,
  callback,

  show,
  id,
  pipelineId,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const { data: opportunity } = useFetchApi(
    api.locations.opportunities.getById,
    { opportunityId: id, locationId },
    { eager: ["contact", "contact.[tags, source]"] },
    [id]
  );
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.opportunities.updateById(
        { locationId, opportunityId: id },
        values
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  const onDelete = async () => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.opportunities.deleteById({
        locationId,
        opportunityId: id,
      });
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.opportunitiesPage.editOpportunityModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <OpportunityForm
              {...opportunity}
              pipelineId={pipelineId}
              onSubmit={onSubmit}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OpportunityEditModal;
