import React from "react";
import { Switch } from "react-router-dom";
import AuthorizedRoute from "../AuthorizedRoute";
import * as ROUTES from "../../constants/routes";
import AgencyLeftSidebar from "./LeftSidebar";
import LocationLeftSidebar from "./LocationDashboard/LeftSidebar";
import LocationsPage from "./LocationsPage";
import HelpPage from "./HelpPage";
import SettingsPage from "./SettingsPage";
import AgencyTopbar from "./Topbar";
import LocationTopbar from "./LocationDashboard/Topbar";

import LocationDashboardPage from "./LocationDashboard";

function DashboardPage() {
  return (
    <div className="app">
      <div className="wrapper">
        <Switch>
          <AuthorizedRoute
            path={ROUTES.AGENCY_LOCATIONS_DASHBOARD.href}
            component={LocationLeftSidebar}
          />
          <AuthorizedRoute
            path={ROUTES.AGENCY.href}
            component={AgencyLeftSidebar}
          />
        </Switch>
        <div className="content-page">
          <div className="content">
            <Switch>
              <AuthorizedRoute
                path={ROUTES.AGENCY_LOCATIONS_DASHBOARD.href}
                component={LocationTopbar}
              />
              <AuthorizedRoute
                path={ROUTES.AGENCY.href}
                component={AgencyTopbar}
              />
            </Switch>
            <Switch>
              <AuthorizedRoute
                path={ROUTES.AGENCY.href}
                component={LocationsPage}
                exact
              />
              <AuthorizedRoute
                path={ROUTES.AGENCY_LOCATIONS.href}
                component={LocationsPage}
                exact
              />
              <AuthorizedRoute
                path={ROUTES.AGENCY_HELP.href}
                component={HelpPage}
              />
              <AuthorizedRoute
                path={ROUTES.AGENCY_SETTINGS.href}
                component={SettingsPage}
              />
              <AuthorizedRoute
                path={ROUTES.AGENCY_LOCATIONS_DASHBOARD.href}
                component={LocationDashboardPage}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
