import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import contactFields from "../../constants/fields";
import useImport from "../../hooks/useImport";

const schema = yup.object({
  firstName: yup.string().test({
    test: function (value) {
      const { lastName, fullName } = this.parent;
      if (!lastName && !fullName) return value != null;
      return true;
    },
    message: "validation.mapFieldsStep.firstName.required",
  }),
  lastName: yup.string().test({
    test: function (value) {
      const { firstName, fullName } = this.parent;
      if (!firstName && !fullName) return value != null;
      return true;
    },
    message: "validation.mapFieldsStep.lastName.required",
  }),
  fullName: yup.string().test({
    test: function (value) {
      const { lastName, firstName } = this.parent;
      if (!lastName && !firstName) return value != null;
      return true;
    },
    message: "validation.mapFieldsStep.fullName.required",
  }),
  email: yup.string().test({
    test: function (value) {
      const { phone } = this.parent;
      if (!phone) return value != null;
      return true;
    },
    message: "validation.mapFieldsStep.email.required",
  }),
  phone: yup.string().test({
    test: function (value) {
      const { email } = this.parent;
      if (!email) return value != null;
      return true;
    },
    message: "validation.mapFieldsStep.phone.required",
  }),
});

const MapFieldsForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  const { customFields } = useImport();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      {[
        ...contactFields,
        ...customFields.map((field) => ({ label: field.name, key: field.key })),
      ].map((f) => (
        <Form.Group as={Row}>
          <Col>
            <Form.Label>{f.label}</Form.Label>
          </Col>
          <Col>
            <Form.Control
              name={f.key}
              as="select"
              value={get(values, f.key) || ""}
              onChange={handleChange}
              isInvalid={get(errors, f.key)}
            >
              <option>Field</option>
              {values.fields.map((field) => (
                <option key={field} value={field}>
                  {field}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {t(get(errors, f.key))}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      ))}
      <Form.Group as={Row} controlId="formButton">
        <Col lg={4} md={4} sm={4} sx={12}></Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.contactsPage.importModal.step2.cancel")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.contactsPage.importModal.step2.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      omit(values, ["error", "onSubmit", "onCancel", "action", "fields"])
    );
  },
  validationSchema: schema,
})(MapFieldsForm);
