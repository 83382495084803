import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import SimpleBar from "simplebar-react";
import { Media } from "reactstrap";
import useChat from "../../../hooks/useChat";
import MessageModal from "../MessageModal/index";
import ModalButton from "../../ModalButton/index";


import { useTranslation } from "react-i18next";
import truncateText from "../../../common/functions/truncateText";
import getContactLetters from "../../../common/functions/getContactLetters";
import replaceHtml from "../../../common/functions/replaceHtml";

export default function ChatUsers() {
  const {
    chatFilters,
    setChatFilters,
    chatApiFilters,
    setChatApiFilters,
    selectedChat,
    setSelectedChat,
    chats,
    setNumberOfPages,
    numberOfPages,
    totalChats,
  } = useChat();
  const { t } = useTranslation();
  const groups = [
    {
      title: t("locationDashboard.conversationPage.chatList.unread"),
      isActive: chatFilters.status === "unread",
      onChange: () => {
        setChatFilters({ status: "unread" });
        setChatApiFilters({
          ...chatApiFilters,
          read: false,
          contactReplied: null,
        });
      },
    },
    {
      title: t("locationDashboard.conversationPage.chatList.recent"),
      isActive: chatFilters.status === "recent",
      onChange: () => {
        setChatFilters({ status: "recent" });
        setChatApiFilters({
          ...chatApiFilters,
          read: null,
          contactReplied: true,
        });
      },
    },
    {
      title: t("locationDashboard.conversationPage.chatList.all"),
      isActive: chatFilters.status === "all",
      onChange: () => {
        setChatFilters({ status: "all" });
        setChatApiFilters({
          ...chatApiFilters,
          read: null,
          contactReplied: null,
        });
      },
    },
  ];
  return (
    <Card className="p-0 m-0 shadow-none h-100">
      <Card.Body className="p-0">
        <ul className="nav nav-tabs nav-bordered">
          {groups.map((group) => (
            <li key={group.title} className="nav-item" onClick={group.onChange}>
              <Link
                className={`nav-link ${group.isActive ? "active" : ""}`}
                to={"#"}
              >
                {group.title}
              </Link>
            </li>
          ))}
        </ul>

        <div className="tab-content">
          <div className="tab-pane show active p-3">
            <Container>
              <Row>
                <Col>
                  <h3>
                    {t("locationDashboard.conversationPage.chatList.title")}
                  </h3>
                </Col>
                <Col className="text-left">
                  <ModalButton
                    className="m-1"
                    modal={<MessageModal />}
                    button={
                      <Button>
                        {t("locationDashboard.conversationPage.chatList.new")}
                      </Button>
                    }
                  />
                </Col>
              </Row>
            </Container>
            <div className="app-search">
              <div className="form-group position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t(
                    "locationDashboard.conversationPage.chatList.search"
                  )}
                  onChange={(e) =>
                    setChatFilters({
                      ...chatFilters,
                      searchFilter: e.target.value,
                    })
                  }
                />
                <span className="mdi mdi-magnify search-icon"></span>
              </div>
            </div>
            <SimpleBar
              style={{
                width: "100%",
                maxHeight: "calc(100vh - 360px)",
              }}
            >
              {chats.map((chat, index) => {
                return (
                  <Link
                    to="#"
                    key={index}
                    className="text-body"
                    onClick={() => {
                      setSelectedChat(chat);
                    }}
                  >
                    <Media
                      className={`mt-1 p-2 ${
                        chat.id === selectedChat.id ? "bg-light" : ""
                      }`}
                    >
                      {!chat.read && (
                        <div
                          className="circle"
                          style={{
                            marginTop: 20,
                            width: 8,
                            height: 8,
                            backgroundColor: "orange",
                          }}
                        />
                      )}

                      <div className="mr-2 ml-2 d-flex flex-column text-center align-items-center justify-content-center">
                        <div
                          style={{ backgroundColor: chat.contact.color }}
                          className="circle-sm"
                        >
                          <p className="circle-content-sm">
                            {getContactLetters(chat.contact)}
                          </p>
                        </div>
                      </div>

                      <Media body>
                        <h5 className="mt-0 mb-0 font-14">
                          <span className="float-right text-muted font-12">
                            {chat.lastMessage
                              ? format(
                                  parseISO(chat.lastMessage.sentAt),
                                  "HH:mm dd.MM.yyyy"
                                )
                              : ""}
                          </span>
                          {chat.contact.fullName}
                        </h5>
                        <p className="mt-1 mb-0 text-muted font-14">
                          <span className="w-25 float-right text-right">
                            {chat.totalUnread && (
                              <span className="badge badge-danger-lighten">
                                {chat.totalUnread}
                              </span>
                            )}
                          </span>
                          <span
                            className="w-75"
                            style={{
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              wordBreak: "break-word",
                            }}
                          >
                            {chat.lastMessage
                              ? truncateText(
                                  chat.lastMessage.type === "direct-mail"
                                    ? t(
                                        "locationDashboard.conversationPage.chatArea.message.pdfSent"
                                      )
                                    : replaceHtml(
                                        chat.lastMessage.content.text
                                      ),
                                  60
                                )
                              : ""}
                          </span>
                        </p>
                      </Media>
                    </Media>
                  </Link>
                );
              })}
            </SimpleBar>
          </div>
        </div>
        {totalChats > chats.length && (
          <Row>
            <Col className={"text-center"}>
              <Button
                onClick={() => {
                  setNumberOfPages(numberOfPages + 1);
                }}
                variant="light"
              >
                Load more
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
}
