import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik, Field } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import spacetime from "spacetime";

import { useCheckAuth } from "../../../../../react-check-auth";

import AutomationInput from "../../../../Form/AutomationInput";
import TimezonedDateTimeInput from "../../../../Form/TimezonedDateTimeInput";
import TagsInput from "../../../../Form/TagsInput";

const schema = yup.object({
  name: yup.string().required("validation.name.required"),
  automationId: yup.string().required("validation.automationId.required"),
  nextExecutionTime: yup.date().required("validation.scheduledDate.required"),
  tags: yup.array().required("validation.tags.required"),
});

const BulkRequestForm = ({
  onCancel,
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  // Get user's current active location timezone
  const { userInfo } = useCheckAuth();
  // console.log(JSON.stringify(userInfo.locations))
  
  const { locationId } = useParams();
  let tzName;

  if(locationId !== undefined) {
    const currLocation = userInfo.locations.find((l) => l.id === locationId)
    if(currLocation && currLocation.timezone) {
      tzName = currLocation.timezone
    }
  }
  if(!tzName) {
    tzName = 'Europe/Berlin'
  }

  const date = get(values, "nextExecutionTime") || new Date()
  const remoteDate = spacetime(date.getTime(), tzName);

  const remoteTz = remoteDate.timezone()

  const mins = remoteTz.current.offset * 60
  const hr = `${(mins / 60) ^ 0}:` + (mins % 60 === 0 ? "00" : Math.abs(mins % 60))
  const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${tzName}`

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>
          {t("locationDashboard.bulkRequestsPage.form.name.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.bulkRequestsPage.form.name.placeholder"
          )}
          value={get(values, "name") || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!get(errors, "name")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "name"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formAutomation">
        <Col>
          <AutomationInput
            label={t(
              "locationDashboard.bulkRequestsPage.form.automation.label"
            )}
            value={get(values, "automationId") || ""}
            onChange={handleChange}
            error={get(errors, "automationId") || ""}
          />
        </Col>
        <Col>
          <Form.Label>
            {t("locationDashboard.bulkRequestsPage.form.scheduledDate.label")}
          </Form.Label>
          <div
            className={
              "form-control " +
              (get(errors, "nextExecutionTime") || "" ? "is-invalid" : "")
            }
          >
            <Field
              name="nextExecutionTime"
              value={get(values, "nextExecutionTime") || ""}
              tzName={tzName}
              component={TimezonedDateTimeInput}
            />
            <Form.Control.Feedback type="invalid">
              {t(get(errors, "nextExecutionTime"))}
            </Form.Control.Feedback>
          </div>
          <div className="mt-1">{t("locationDashboard.bulkRequestsPage.form.scheduledDate.notice")} <b>{prefix}</b></div>
        </Col>
      </Form.Group>
      <Form.Group controlId="formTags">
        <Form.Label>
          {t("locationDashboard.bulkRequestsPage.form.tags.label")}
        </Form.Label>
        <TagsInput
          creatable={false}
          setFieldValue={setFieldValue}
          name={"tags"}
          value={get(values, "tags")}
          error={get(errors, "tags")}
        />
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.bulkRequestsPage.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.bulkRequestsPage.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(BulkRequestForm);
