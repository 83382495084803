import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";

export default function SmtpForm({ handleChange, errors, values, isValid }) {
  const { t } = useTranslation();
  return (
    <>
      <>
        <p className="font-weight-bold">
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.loginCredentials")}
        </p>
        <Form.Group controlId="formEmail">
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.settingsPage.emailPage.form.senderEmail.label"
            )}
          </Form.Label>
          <Form.Control
            type="email"
            placeholder={t(
              "locationDashboard.settingsPage.emailPage.form.senderEmail.placeholder"
            )}
            value={get(values, "config.email") || ""}
            name="config.email"
            onChange={handleChange}
            isInvalid={!!get(errors, "config.email")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "config.email"))}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label>
            {" "}
            {t("locationDashboard.settingsPage.emailPage.form.password.label")}
          </Form.Label>
          <Form.Control
            type="password"
            placeholder={t(
              "locationDashboard.settingsPage.emailPage.form.password.placeholder"
            )}
            value={get(values, "config.password") || ""}
            name="config.password"
            onChange={handleChange}
            isInvalid={!!get(errors, "config.password")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "config.password"))}
          </Form.Control.Feedback>
        </Form.Group>
      </>

      <>
        {" "}
        <p className="font-weight-bold">
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.imapHeader")}
        </p>
        <Form.Group controlId="formImapHost">
          <Form.Label>
            {" "}
            {t("locationDashboard.settingsPage.emailPage.form.imapHost.label")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.settingsPage.emailPage.form.imapHost.placeholder"
            )}
            value={get(values, "config.imapHost") || ""}
            name="config.imapHost"
            onChange={handleChange}
            isInvalid={!!get(errors, "config.imapHost")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "config.imapHost"))}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formImapPort">
          <Form.Label>
            {" "}
            {t("locationDashboard.settingsPage.emailPage.form.imapPort.label")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.settingsPage.emailPage.form.imapPort.placeholder"
            )}
            value={get(values, "config.imapPort") || ""}
            name="config.imapPort"
            onChange={handleChange}
            isInvalid={!!get(errors, "config.imapPort")}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "config.imapPort"))}
          </Form.Control.Feedback>
        </Form.Group>
      </>

      <p className="font-weight-bold">
        {" "}
        {t("locationDashboard.settingsPage.emailPage.form.smtpHeader")}
      </p>
      <Form.Group controlId="formSmtpHost">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.smtpHost.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.smtpHost.placeholder"
          )}
          value={get(values, "config.smtpHost") || ""}
          name="config.smtpHost"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.smtpHost")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.smtpHost"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSmtpPort">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.smtpPort.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.smtpPort.placeholder"
          )}
          value={get(values, "config.smtpPort") || ""}
          name="config.smtpPort"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.smtpPort")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.smtpPort"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="btn-block"
        variant="primary"
        type="submit"
        disabled={!isValid}
      >
        Submit
      </Button>
    </>
  );
}
