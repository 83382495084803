import pick from "lodash/pick";

const pickBulkRequest = (data) => {
  const request = pick(data, [
    "name",
    "automationId",
    "nextExecutionTime",
    "tags",
  ]);

  return request;
};

export default pickBulkRequest;
