import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";

export default function ({ handleChange, errors, values, isValid }) {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Form.Group controlId="formButton">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.smsPage.form.accountSID.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.smsPage.form.accountSID.placeholder"
          )}
          value={get(values, "config.apiKey") || ""}
          name="config.apiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.apiKey")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.apiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.smsPage.form.authToken.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.smsPage.form.authToken.placeholder"
          )}
          value={get(values, "config.authToken") || ""}
          name="config.authToken"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.authToken")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.authToken"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.smsPage.form.phone.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.smsPage.form.phone.placeholder"
          )}
          value={get(values, "config.phone") || ""}
          name="config.phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.phone")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="btn-block"
        variant="primary"
        type="submit"
        disabled={!isValid}
      >
        {t("locationDashboard.settingsPage.smsPage.form.submit")}
      </Button>
    </>
  );
}
