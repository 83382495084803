import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Wizard, Steps, Step } from "react-albus";
import ImportContext from "./contexts/ImportContext";
import Error from "../../../../../Error/index";
import Loading from "../../../../../Loading/index";
import UploadFileStep from "./UploadFileStep";
import MapFieldsStep from "./MapFieldsStep";
import FieldMappingPreviewStep from "./FieldMappingPreviewStep";
import DuplicateStrategyStep from "./DuplicateStrategyStep";
import duplicateStrategies from "./constants/duplicateStrategies";
import StartImportStep from "./StartImportStep";
import useFetchApi from "../../../../../../hooks/useFetchArrayApi";
import api from "../../../../../../common/api";

const ImportModal = ({ handleClose,   show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const [loading, ] = useState();
  const [error, ] = useState();
  const [contacts, setContacts] = useState(null);
  const [importedContacts, setImportedContacts] = useState(null);
  const [strategy, setStrategy] = useState(duplicateStrategies[0].key);
  const [mappings, setMappings] = useState({});
  const {
    data: customFields,
    loading: fetchCustomFieldsLoading
    ,
  } = useFetchApi(api.locations.customFields.get, {
    locationId,
  });
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.contactsPage.importModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || fetchCustomFieldsLoading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <ImportContext.Provider
              value={{
                contacts,
                setContacts,
                strategy,
                setStrategy,
                mappings,
                setMappings,
                importedContacts,
                setImportedContacts,
                customFields,
              }}
            >
              <Wizard>
                <Steps>
                  <Step
                    id="upload"
                    render={({ next, previous }) => (
                      <UploadFileStep
                        next={next}
                        handleClose={handleClose}
                        previous={previous}
                      />
                    )}
                  />
                  <Step
                    id="map"
                    render={({ next, previous }) => (
                      <MapFieldsStep
                        next={next}
                        handleClose={handleClose}
                        previous={previous}
                      />
                    )}
                  />
                  <Step
                    id="mapping-preview"
                    render={({ next, previous }) => (
                      <FieldMappingPreviewStep
                        next={next}
                        handleClose={handleClose}
                        previous={previous}
                      />
                    )}
                  />
                  <Step
                    id="strategy"
                    render={({ next, previous }) => (
                      <DuplicateStrategyStep
                        next={next}
                        handleClose={handleClose}
                        previous={previous}
                      />
                    )}
                  />
                  <Step
                    id="import"
                    render={({ next, previous }) => (
                      <StartImportStep
                        next={next}
                        handleClose={handleClose}
                        previous={previous}
                      />
                    )}
                  />
                </Steps>
              </Wizard>
            </ImportContext.Provider>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImportModal;
