import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import TagsInput from "../../../../../Form/TagsInput";

const schema = yup.object({
  tags: yup.array(),
});

const TagsForm = ({
  onCancel,
  handleSubmit,
  
  
  values,
  
  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formTags">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.tagForm.tags.label")}
        </Form.Label>
        <TagsInput
          setFieldValue={setFieldValue}
          name={"tags"}
          value={get(values, "tags")}
          creatable={false}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "tags")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.contactsPage.tagForm.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.contactsPage.tagForm.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(TagsForm);
