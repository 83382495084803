import React, { useState } from "react";
import { Container } from "react-bootstrap";
import EmailTemplateEditor from "../EmailTemplateEditor";
import EmailTemplateCreateModal from "../EmailTemplateCreateModal";

export default function EmailTemplateCreatePage() {
  const [show, setShow] = useState(false);
  const [config, setConfig] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Container fluid={true} className="mt-2">
      {show && (
        <EmailTemplateCreateModal
          config={config}
          handleClose={handleClose}
          show={show}
        />
      )}
      <EmailTemplateEditor setModal={handleShow} setConfig={setConfig} />
    </Container>
  );
}
