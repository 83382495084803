import React from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import logo from "../../../assets/images/logo-with-text.png";

const SideBarContent = () => {
  const { t } = useTranslation();
  return (
    <div className="left-side-menu">
      <Link to="/" className="logo text-center">
        <span className="logo-lg">
          <img src={logo} alt="logo" />
        </span>
      </Link>
      <div className="h-100" id="left-side-menu-container" data-simplebar>
        <ul className="metismenu side-nav" style={{ listStyleType: "none" }}>
          <li className="side-nav-item">
            <NavLink to={ROUTES.ADMIN_LOCATIONS.as()} className="side-nav-link">
              <i className="dripicons-location"></i>
              <span> {t("adminDashboard.leftSidebar.locations")} </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(SideBarContent);
