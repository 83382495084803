import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import MessageInput from "../../../../../../../Form/MessageInput";
import EmailTemplateInput from "../../../../../../../Form/EmailTemplateInput";

export const schema = yup.object({
  email: yup.string().nullable(),
  content: yup.object({
    text: yup.string().required("validation.sendEmail.message.required"),
    subject: yup.string().required("validation.sendEmail.subject.required"),
    fromName: yup.string(),
  }),
  emailTemplateId: yup.string().nullable(),
});

const SendEmailForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formTo">
        <Form.Label>
          {t("locationDashboard.automationShowPage.sendEmailForm.email.label")}
          <br />
          {t("locationDashboard.automationShowPage.sendEmailForm.email.hint")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendEmailForm.email.placeholder"
          )}
          value={get(values, "email") || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!get(errors, "email")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "email"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formFrom">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendEmailForm.fromName.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendEmailForm.fromName.placeholder"
          )}
          value={get(values, "content.fromName") || ""}
          name="content.fromName"
          onChange={handleChange}
          isInvalid={!!get(errors, "content.fromName")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "content.fromName"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formSubject">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.sendEmailForm.subject.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.sendEmailForm.subject.placeholder"
          )}
          value={get(values, "content.subject") || ""}
          name="content.subject"
          onChange={handleChange}
          isInvalid={!!get(errors, "content.subject")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "content.subject"))}
        </Form.Control.Feedback>
      </Form.Group>
      <EmailTemplateInput
        onChange={(e, template) => {
          handleChange(e);
          setFieldValue("content.text", template.config.fullHtml);
        }}
        value={get(values, "emailTemplateId")}
        errors={get(errors, "emailTemplateId")}
        label={t(
          "locationDashboard.automationShowPage.sendEmailForm.emailTemplateId.label"
        )}
      />
      <MessageInput
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        type={"email"}
      />
      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.sendEmailForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      omit(values, [
        "error",
        "onSubmit",
        "onCancel",
        "action",
        "emailTemplateId",
      ])
    );
  },
  validationSchema: schema,
})(SendEmailForm);
