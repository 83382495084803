import React  from "react";
import { withTranslation, useTranslation } from "react-i18next";
import get from "lodash/get";
import { useCheckAuth } from "../../../react-check-auth";
import { Link, NavLink } from "react-router-dom";
import OnboardingCard from "./OnboardingCard";
import * as ROUTES from "../../../constants/routes";
import logo from "../../../assets/images/logo-with-text.png";

const SideBarContent = () => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const locations = userInfo.locations;
  return (
    <div className="left-side-menu">
      <Link to="/" className="logo text-center">
        <span className="logo-lg">
          <img src={get(userInfo, "agency.photoUrl") || logo} alt="logo" />
        </span>
      </Link>
      <div className="h-100" id="left-side-menu-container" data-simplebar>
        <ul className="metismenu side-nav" style={{ listStyleType: "none" }}>
          <li className="side-nav-item">
            <NavLink
              to={ROUTES.AGENCY_LOCATIONS.as()}
              className="side-nav-link"
            >
              <i className="dripicons-location"></i>
              <span> {t("leftSidebar.accounts")} </span>
            </NavLink>
          </li>
          <li className="side-nav-item">
            <NavLink to={ROUTES.AGENCY_HELP.as()} className="side-nav-link">
              <i className="dripicons-help"></i>
              <span> {t("leftSidebar.help")} </span>
            </NavLink>
          </li>
          <li className="side-nav-item">
            <NavLink to={ROUTES.AGENCY_SETTINGS.as()} className="side-nav-link">
              <i className="dripicons-gear"></i>
              <span> {t("leftSidebar.settings")} </span>
            </NavLink>
          </li>
        </ul>
        {locations.length < 1 && <OnboardingCard />}
      </div>
    </div>
  );
};

export default withTranslation()(SideBarContent);
