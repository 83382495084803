import queryString from "query-string";
import api from "../../../../../api";

export default function get({ locationId, pipelineId }, query) {
  return api.get(
    `/locations/${locationId}/pipelines/${pipelineId}/stages${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
