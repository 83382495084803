import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import ModalButton from "../../../../../ModalButton";
import useTag from "../hooks/useTag";
import TagCreateModal from "../TagCreateModal";

const TagSearchBar = () => {
  const { reload } = useTag();
  const { t } = useTranslation();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.settingsPage.tagPage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            modal={<TagCreateModal />}
            button={
              <Button>
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.settingsPage.tagPage.addTag")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TagSearchBar;
