import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import SourceForm from "../SourceForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import pickSource from "../pickSource";

const SourceCreateModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.sources.create(
        { locationId },
        {
          ...pickSource(values),
        }
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("locationDashboard.settingsPage.sourcePage.createModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <SourceForm onSubmit={onSubmit} onCancel={onCancel} />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SourceCreateModal;
