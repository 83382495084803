import React from "react";
import get from "lodash/get";
import {    Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function ({
  handleChange,
  errors,
  values,
  
  isValid,
}) {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Form.Group controlId="formButton">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.smsPage.form.apiKey.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.smsPage.form.apiKey.placeholder"
          )}
          value={get(values, "config.apiKey") || ""}
          name="config.apiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.apiKey")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.apiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formButton">
        <Form.Label>
          {t("locationDashboard.settingsPage.smsPage.form.phone.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.smsPage.form.phone.placeholder"
          )}
          value={get(values, "config.phone") || ""}
          name="config.phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.phone")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        {" "}
        <Button
          className="btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.settingsPage.smsPage.form.submit")}
        </Button>
      </Form.Group>
    </>
  );
}
