import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../react-check-auth/index";
import api from "../../common/api/index";
import pickAccount from "../../common/pickers/pickAccount";
import Loading from "../Loading/index";
import PersonalDataForm from "./PersonalDataForm/index";
import Error from "../Error/index";
import useFirebase from "../../hooks/useFirebase";
import withFirebase from "../../hocs/withFirebase";

function PersonalDataEdit() {
  const firebase = useFirebase();
  const { userInfo, refreshAuth } = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async (values) => {
    setError(null);
    setLoading(true);
    try {
      let credentials;
      if (userInfo.provider === "password") {
        credentials = firebase.instance.auth.EmailAuthProvider.credential(
          userInfo.email,
          values.password
        );
        await firebase.auth.currentUser.reauthenticateWithCredential(
          credentials
        );
        await api.accounts.updateById(
          { accountId: userInfo.id },
          pickAccount(values)
        );
        const authResult = await firebase.doSignInWithEmailAndPassword(
          values.email,
          values.password
        );
        const idToken = await authResult.user.getIdToken();
        await api.session.start({ idToken });
        refreshAuth();
        setLoading(false);
      }

      refreshAuth();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) return <Loading />;
  return (
    <Container fluid={true}>
      {error && <Error error={error} />}
      <Row>
        <Col>
          <PersonalDataForm
            email={userInfo.email}
            name={userInfo.name}
            onSubmit={handleSubmit}
          />
        </Col>
      </Row>
    </Container>
  );
}
export default withFirebase(PersonalDataEdit);
