import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EmailServiceTable from "./EmailServiceTable";
import EmailServiceContext from "./contexts/EmailServiceContext";
import EmailServiceSearchBar from "./EmailServiceSearchBar";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import withLocationAdmin from "../../../../../hocs/withLocationAdmin";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import api from "../../../../../common/api";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";

const EmailSettingsPage = () => {
  const { locationId } = useParams();
  const {
    data: services,
    loading: fetchServicesLoading,
    error: fetchServicesError,
    getData,
  } = useFetchApi(
    api.locations.transports.get,
    { locationId },
    { type: "email" }
  );
  return (
    <EmailServiceContext.Provider value={{ services, reload: getData }}>
      <Container fluid={true}>
        <Row>
          <Col>
            <EmailServiceSearchBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {fetchServicesError && <Error error={fetchServicesError} />}
                {fetchServicesLoading ? (
                  <Loading />
                ) : (
                  services && <EmailServiceTable />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </EmailServiceContext.Provider>
  );
};
export default withSubscriptionCheck(withLocationAdmin(EmailSettingsPage));
