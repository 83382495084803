import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateTimeRange from "../DateTimeRangeDropdown";
import useDashboard from "../hooks/useDashboard";
import formatGermanCurrency from "../../../../../common/functions/formatGermanCurrency";

export default function PipelineValueCard() {
  const { opportunities } = useDashboard();
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Container>
          <Row>
            <Col>
              <h4>
                {" "}
                {t("locationDashboard.mainPage.pipelineValueCard.title")}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <DateTimeRange />
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {" "}
        <Container fluid={true}>
          {" "}
          <Row>
            <Col className="text-center">
              <p className="font-24">
                {formatGermanCurrency(
                  opportunities.reduce((acc, cur) => acc + cur.leadValue, 0)
                )}
              </p>
            </Col>
          </Row>
          <Row className="text-success">
            <Col className="text-left">
              {t("locationDashboard.mainPage.pipelineValueCard.closed")}
            </Col>
            <Col className="text-right ">
              {formatGermanCurrency(
                opportunities
                  .filter((o) => o.status === "won")
                  .reduce((acc, cur) => acc + cur.leadValue, 0)
              )}
            </Col>
          </Row>
          <Row className="text-primary">
            <Col className="text-left">
              {t("locationDashboard.mainPage.pipelineValueCard.open")}
            </Col>
            <Col className="text-right ">
              {formatGermanCurrency(
                opportunities
                  .filter((o) => o.status === "open")
                  .reduce((acc, cur) => acc + cur.leadValue, 0)
              )}
            </Col>
          </Row>
          <Row className="text-red">
            <Col className="text-left">
              {t("locationDashboard.mainPage.pipelineValueCard.lost")}
            </Col>
            <Col className="text-right">
              {formatGermanCurrency(
                opportunities
                  .filter((o) => o.status === "lost")
                  .reduce((acc, cur) => acc + cur.leadValue, 0)
              )}
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
