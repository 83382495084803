import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";

export const schema = yup.object({
  includeTerm: yup.string().nullable(),
  excludeTerm: yup.string().nullable(),
  allowMultiple: yup.boolean(),
});

const SmsReplyForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formMultiple">
        <Form.Label>
          {t(
            "locationDashboard.automationShowPage.smsReplyForm.multiple.label"
          )}
        </Form.Label>
        <Form.Check
          type="checkbox"
          value={get(values, "allowMultiple")}
          checked={get(values, "allowMultiple")}
          name="allowMultiple"
          onChange={handleChange}
          label={t(
            "locationDashboard.automationShowPage.smsReplyForm.multiple.placeholder"
          )}
        />
      </Form.Group>
      <Form.Group controlId="formContains">
        {" "}
        <Form.Label>
          {t(
            "locationDashboard.automationShowPage.smsReplyForm.messageContains.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.smsReplyForm.messageContains.placeholder"
          )}
          value={get(values, "includeTerm") || ""}
          name="includeTerm"
          onChange={handleChange}
          isInvalid={!!get(errors, "includeTerm")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "includeTerm")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDoesNotContains">
        {" "}
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.automationShowPage.smsReplyForm.doesNotContain.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.automationShowPage.smsReplyForm.doesNotContain.placeholder"
          )}
          value={get(values, "excludeTerm") || ""}
          name="excludeTerm"
          onChange={handleChange}
          isInvalid={!!get(errors, "excludeTerm")}
        />
        <Form.Control.Feedback type="invalid">
          {get(errors, "excludeTerm")}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("locationDashboard.automationShowPage.smsReplyForm.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(SmsReplyForm);
