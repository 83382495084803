import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";

export default function groupByDate(items, key = "createdAt") {
  const result = [];
  items.forEach((item) => {
    const dateObj = result.find((o) => isSameDay(o.date, parseISO(item[key])));
    if (dateObj) {
      dateObj.items.push(item);
    } else {
      result.push({ date: parseISO(item[key]), items: [item] });
    }
  });
  result.sort((a, b) => (a.date > b.date ? 1 : -1));
  result.forEach((item) => {
    item.items.sort((m1, m2) =>
      parseISO(m1[key]) > parseISO(m2[key]) ? 1 : -1
    );
  });
  return result;
}
