import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col,  Button } from "react-bootstrap";
import ModalButton from "../../../../ModalButton";
import useTriggerLink from "../hooks/useTriggerLink";
import TriggerLinkCreateModal from "../TriggerLinkCreateModal";

const TriggerLinkSearchBar = () => {
  const { t } = useTranslation();
  const { reload } = useTriggerLink();
  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={6} sx={12} sm={12}>
          <h3>{t("locationDashboard.triggerLinkPage.header")}</h3>
        </Col>
        <Col lg={6} md={6} sx={12} sm={12}>
          <ModalButton
            modal={<TriggerLinkCreateModal />}
            button={
              <Button>
                <i className="dripicons-plus"></i>{" "}
                {t("locationDashboard.triggerLinkPage.addTriggerLink")}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default TriggerLinkSearchBar;
