import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import classnames from "classnames";
import parseISO from "date-fns/parseISO";
import useChat from "../../../../../hooks/useChat";
import replaceHtml from "../../../../../common/functions/replaceHtml";
import truncateText from "../../../../../common/functions/truncateText";

const UserMessage = ({ message  }) => {
  const { setReplyMessage, replyMessage } = useChat();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const sender =
    message.senderType === "location" ? message.location : message.contact;
  const receiver =
    message.senderType === "location" ? message.contact : message.location;
  return (
    <li
      className={classnames("clearfix", {
        odd: message.senderType !== "contact",
      })}
    >
      <div className="chat-avatar">
        <i className={"user-message dripicons-mail"} />
        <i>{format(parseISO(message.sentAt), "H:mm")}</i>
      </div>
      <div className="conversation-text">
        <div className="ctext-wrap text-left">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <i>
              {`${
                message.senderType === "location"
                  ? sender.name
                  : sender.fullName
              } > ${
                message.senderType === "location"
                  ? receiver.fullName
                  : receiver.name
              }`}
            </i>
            {message.senderType === "contact" && (
              <Button
                variant={replyMessage === message ? "dark" : "light"}
                className="ml-2 font-10"
                style={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                onClick={() => {
                  if (replyMessage === message) {
                    setReplyMessage(null);
                  } else {
                    setReplyMessage(message);
                  }
                }}
              >
                {" "}
                {t("locationDashboard.conversationPage.chatArea.message.reply")}
              </Button>
            )}
          </div>

          <p>{message.content.subject}</p>
          {!expanded ? (
            <>
              <p>{truncateText(replaceHtml(message.content.text, 50, ""))}</p>
              <i
                onClick={() => {
                  setExpanded(!expanded);
                }}
                className="dripicons-dots-3 clickable"
              />
            </>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: message.content.text }} />
            </>
          )}
        </div>
      </div>
    </li>
  );
};

export default UserMessage;
