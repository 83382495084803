import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import ContactInput from "../../../Form/ContactCreatableInput/index";

import SmsForm from "./SmsForm";
import EmailForm from "./EmailForm";

const schema = yup.object({
  contact: yup.object(),
  content: yup.object({
    text: yup.string(),
    subject: yup.string(),
    fromName: yup.string(),
  }),
});

const MessageForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  values,
  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const groups = [
    {
      title: t(
        "locationDashboard.conversationPage.messageModal.messageForm.smsForm.title"
      ),
      value: "sms",
    },
    {
      title: t(
        "locationDashboard.conversationPage.messageModal.messageForm.emailForm.title"
      ),
      value: "email",
    },
  ];

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Container className="mt-3" fluid={true}>
        {" "}
        <ContactInput
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          values={values}
          errors={errors}
        />
        <Form.Group as={Row} controlId="formContact">
          <Col>
            {" "}
            <Form.Group controlId="form">
              <Form.Label>{t("formElements.email.label")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("formElements.email.placeholder")}
                value={get(values, "contact.email") || ""}
                name="contact.email"
                onChange={handleChange}
                isInvalid={!!get(errors, "contact.email")}
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, "contact.email")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            {" "}
            <Form.Group controlId="formPhone">
              <Form.Label> {t("formElements.phone.label")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("formElements.email.placeholder")}
                value={get(values, "contact.phone") || ""}
                name="contact.phone"
                onChange={handleChange}
                isInvalid={!!get(errors, "contact.phone")}
              />
              <Form.Control.Feedback type="invalid">
                {get(errors, "contact.phone")}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form.Group>
      </Container>
      <Row>
        <Col className="mb-2 text-right">
          <ul className="nav nav-tabs nav-bordered">
            {groups.map((group) => (
              <li
                key={group.title}
                className="nav-item"
                onClick={() => {
                  setFieldValue("type", group.value);
                }}
              >
                <Link
                  className={`nav-link ${
                    group.value === values.type ? "active" : ""
                  }`}
                  to={"#"}
                >
                  {group.title}
                </Link>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          {" "}
          {values.type === "sms" ? (
            <SmsForm
              handleChange={handleChange}
              errors={errors}
              values={values}
              setFieldValue={setFieldValue}
            />
          ) : values.type === "email" ? (
            <EmailForm
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              errors={errors}
              values={values}
            />
          ) : null}
        </Col>
      </Row>
      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t(
              "locationDashboard.conversationPage.messageModal.messageForm.cancel"
            )}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t(
              "locationDashboard.conversationPage.messageModal.messageForm.submit"
            )}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    const result = { ...values };
    if (result.contact) {
      const fullNameArray = result.contact.fullName.split(" ");
      result.contact.firstName = fullNameArray[0];
      result.contact.lastName = fullNameArray[1];
    }

    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(MessageForm);
