import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Form, Button } from "react-bootstrap";
// import Creatable from "react-select/creatable";
import Select, { components } from "react-select";

import * as ROUTES from "../../../constants/routes";
import api from "../../../common/api";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import Error from "../../Error";
import Loading from "../../Loading";

const MessageTemplateSelectModal = ({ handleClose, callback, show }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();

  const [selected, setSelected] = useState();

  const { data, loading, error } = useFetchApi(
    api.locations.messageTemplates.get,
    {
      locationId,
    },
    { sort: "createdAt" },
    [locationId]
  );

  const select = (data, event) => {
    // console.log(data);
    // callback(data.value);
    setSelected(data.value);
  };

  const options =
    data.map((c) => ({ value: c, label: c.name, description: c.message })) ||
    [];

  const formatOptionLabel = ({ value, label, description }) => (
    <div style={{ display: "flex" }} key={value.id}>
      <div className="mr-2">{label}</div>{" "}
      <div className="text-muted">{description.substring(0, 60)}</div>
    </div>
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("formElements.messageTemplateList.modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            {options.length > 0 ? (
              <Form.Group controlId="formMessageTemplate">
                <Form.Label>
                  {t("formElements.messageTemplateList.modal.label")}
                </Form.Label>

                <Select
                  // isLoading={loading}
                  value={options.find((o) =>
                    selected ? o.value.id === selected.id : null
                  )}
                  options={options}
                  formatOptionLabel={formatOptionLabel}
                  onChange={select}
                  placeholder={t(
                    "formElements.messageTemplateList.modal.label"
                  )}
                />
              </Form.Group>
            ) : (
              <span>
                {t("formElements.messageTemplateList.modal.empty")}{" "}
                <Link
                  to={ROUTES.AGENCY_LOCATIONS_SETTINGS_TEMPLATES.as({
                    locationId,
                  })}
                >
                  {t("formElements.messageTemplateList.modal.addRedirect")}
                </Link>
              </span>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          {t("formElements.messageTemplateList.modal.cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            callback(selected.message);
            handleClose();
          }}
        >
          {t("formElements.messageTemplateList.modal.confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageTemplateSelectModal;
