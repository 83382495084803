function searchContacts(contacts, contactFilter, length = 5) {
  const result = [];
  result.push(
    ...contacts.filter(
      (e) =>
        `${e.fullName ? e.fullName : ""}`
          .toLowerCase()
          .includes(contactFilter.toLowerCase()) ||
        `${e.phone ? e.phone : ""}`.includes(contactFilter.toLowerCase()) ||
        `${e.email ? e.email : ""}`.includes(contactFilter.toLowerCase())
    )
  );
  if (result.length < length) {
    result.push(
      ...contacts
        .filter((c) => !result.some((contact) => contact.id === c.id))
        .slice(0, length - result.length)
    );
  }
  return result;
}
export default searchContacts;
