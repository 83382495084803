import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import {Button, Form } from "react-bootstrap";
import MailgunHostInput from "../MailgunHostInput";

export default function MailgunForm({
  handleChange,
  errors,
  values,
  isValid,
}) {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Form.Group controlId="formApiKey">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.apiKey.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.apiKey.placeholder"
          )}
          value={get(values, "config.apiKey") || ""}
          name="config.apiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.apiKey")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.apiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.senderEmail.label")}
        </Form.Label>
        <Form.Control
          type="email"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.senderEmail.placeholder"
          )}
          value={get(values, "config.email") || ""}
          name="config.email"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.email")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.email"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formDomain">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.domain.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.domain.placeholder"
          )}
          value={get(values, "config.domain") || ""}
          name="config.domain"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.domain")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.domain"))}
        </Form.Control.Feedback>
      </Form.Group>
      <MailgunHostInput
        handleChange={handleChange}
        name="config.host"
        value={get(values, "config.host") || ""}
        error={get(errors, "config.host")}
      />
      <Button
        className="btn-block"
        variant="primary"
        type="submit"
        disabled={!isValid}
      >
        Submit
      </Button>
    </>
  );
}
