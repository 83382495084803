import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import * as ROUTES from "../../../../../../constants/routes";
import BootstrapTable from "react-bootstrap-table-next";

import useContact from "../hooks/useContact";


const ContactTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    contacts: data,
    
    selectId,
    selectIds,
    selectedColumns,
  } = useContact();

  const selectRowOptions = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row   ) => {
      selectId(row.id);
    },
    onSelectAll: (isSelect, rows) => {
      selectIds(rows.map((r) => r.id));
    },
  };
  const ActionColumn = (cell, row  ) => {
    return (
      <React.Fragment>
        <Link
          to={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.as({
            locationId,
            contactId: row.id,
          })}
          className="action-icon"
        >
          {" "}
          <i className="mdi mdi-eye"></i>
        </Link>
      </React.Fragment>
    );
  };
  const LastMessageAtColumn = (cell   ) => {
    return (
      <React.Fragment>
        {cell && format(parseISO(cell), "HH:mm dd.MM.YYY")}
      </React.Fragment>
    );
  };
  const CreatedAtColumn = (cell   ) => {
    return (
      <React.Fragment>
        {cell && format(parseISO(cell), "HH:mm dd.MM.YYY")}
      </React.Fragment>
    );
  };
  const UpdatedAtColumn = (cell   ) => {
    return (
      <React.Fragment>
        {cell && format(parseISO(cell), "HH:mm dd.MM.YYY")}
      </React.Fragment>
    );
  };
  const TagColumn = (cell, row  ) => {
    return (
      <React.Fragment>
        {row.tags ? row.tags.map((t) => t.name).join(", ") : ""}
      </React.Fragment>
    );
  };
  const columns = [
    {
      dataField: "firstName",
      text: t("locationDashboard.contactsPage.table.firstName"),
      sort: true,
    },
    {
      dataField: "lastName",
      text: t("locationDashboard.contactsPage.table.lastName"),
      sort: true,
    },
    {
      dataField: "email",
      text: t("locationDashboard.contactsPage.table.email"),
      sort: true,
    },
    {
      dataField: "phone",
      text: t("locationDashboard.contactsPage.table.phone"),
      sort: true,
    },
    {
      dataField: "lastActivityAt",
      text: t("locationDashboard.contactsPage.table.lastActivity"),
      sort: true,
      formatter: LastMessageAtColumn,
    },
    {
      dataField: "createdAt",
      text: t("locationDashboard.contactsPage.table.createdAt"),
      sort: true,
      formatter: CreatedAtColumn,
    },
    {
      dataField: "updatedAt",
      text: t("locationDashboard.contactsPage.table.updatedAt"),
      sort: true,
      formatter: UpdatedAtColumn,
    },
    {
      dataField: "tags",
      text: t("locationDashboard.contactsPage.table.tags"),
      formatter: TagColumn,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.contactsPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ].filter((c) => selectedColumns.some((column) => column.key === c.dataField));
  return (
    <BootstrapTable
      bordered={false}
      keyField="id"
      data={data}
      columns={columns}
      selectRow={selectRowOptions}
      headerClasses="thead-light"
      wrapperClasses="table-responsive"
    />
  );
};

export default ContactTable;
