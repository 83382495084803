import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalButton from "../../../../../ModalButton";
import roles from "../../../../../../constants/roles";
import useEmployee from "../hooks/useEmployee";
import EmployeeCreateModal from "../EmployeeCreateModal";

const EmployeeSearchBar = () => {
  const { t } = useTranslation();
  const {
    filters,
    setFilters,
    
    reload,
    nameFilter,
    setNameFilter,
  } = useEmployee();
  const handleChange = (e) => {
    if (e.target.name === "name") {
      setNameFilter(e.target.value);
      return;
    }
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={1} md={12} sx={12} sm={12}>
          <h3>
            {" "}
            {t("locationDashboard.settingsPage.teamManagementPage.header")}
          </h3>
        </Col>
        <Col lg={8} md={12} sx={12} sm={12}>
          <Form>
            <Form.Group as={Row}>
              <Col>
                <Form.Control
                  name="name"
                  onChange={handleChange}
                  value={nameFilter}
                  type="text"
                  placeholder={t(
                    "locationDashboard.settingsPage.teamManagementPage.search"
                  )}
                />
              </Col>
              <Col>
                <Form.Control
                  name="role"
                  as="select"
                  value={filters.role}
                  onChange={handleChange}
                >
                  <option>Role</option>
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Form>
        </Col>
        <Col lg={3} md={12} sx={12} sm={12}>
          <ModalButton
            modal={<EmployeeCreateModal />}
            button={
              <Button>
                <i className="dripicons-plus"></i>
                {t(
                  "locationDashboard.settingsPage.teamManagementPage.inviteButton"
                )}
              </Button>
            }
            callback={reload}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeSearchBar;
