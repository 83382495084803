import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useCheckAuth } from "../../react-check-auth";
import api from "../../common/api/index";
import Loading from "../Loading/index";
import ChangePasswordForm from "./ChangePasswordForm/index";
import Error from "../Error/index";
import useFirebase from "../../hooks/useFirebase";
import withFirebase from "../../hocs/withFirebase";

function ChangePassword() {
  const { userInfo, refreshAuth } = useCheckAuth();
  const firebase = useFirebase();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit = async (values) => {
    setError(null);
    setLoading(true);
    try {
      let credentials;
      if (userInfo.provider === "password") {
        credentials = firebase.instance.auth.EmailAuthProvider.credential(
          userInfo.email,
          values.oldPassword
        );
        await firebase.auth.currentUser.reauthenticateWithCredential(
          credentials
        );
        if (values.password) {
          await api.auth.resetPassword({
            password: values.password,
          });
          const authResult = await firebase.doSignInWithEmailAndPassword(
            userInfo.email,
            values.password
          );
          const idToken = await authResult.user.getIdToken();
          await api.session.start({ idToken });
        }
        refreshAuth();
        setLoading(false);
      }
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) return <Loading />;
  return (
    <Container fluid={true}>
      {error && <Error error={error} />}
      <Row>
        <Col>
          <ChangePasswordForm onSubmit={handleSubmit} />
        </Col>
      </Row>
    </Container>
  );
}

export default withFirebase(ChangePassword);
