import React, { useState } from "react";
// import { useParams } from "react-router-dom";
import { useParams, Link, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../../../../constants/routes";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import withLocationUser from "../../../../../hocs/withLocationUser";
import useFetchApi from "../../../../../hooks/useFetchApi";
import ContactForm from "./ContactForm";
import api from "../../../../../common/api";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import ChatMessagesArea from "../../../../Chat/ChatMessageArea";
import withChat from "../../../../../hocs/withChat";
import MarkAsReadButton from "../../../../Chat/MarkAsReadButton";
import MarkAsUnreadButton from "../../../../Chat/MarkAsUnreadButton";
import useChat from "../../../../../hooks/useChat";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";
import MessageModal from "./MessageModal";
import ModalButton from "../../../../ModalButton";
import AuthorizedRoute from "../../../../AuthorizedRoute";
import TasksPage from "./TasksPage";

function ContactsPage() {
  const { t } = useTranslation();
  const { selectedChat } = useChat();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { locationId, contactId } = useParams();
  const {
    data: contact,
    error: fetchError,
    loading: fetchLoading,
    getData,
  } = useFetchApi(
    api.locations.contacts.getById,
    {
      contactId,
      locationId,
    },
    { eager: ["tags", "opportunities", "source", "conversations"] }
  );

  const handleSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.contacts.updateById(
        { locationId, contactId },
        values
      );
      getData();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  return (
    <Container className="contact-view" fluid={true}>
      <Row className="">
        <Col lg={8} md={12} className="left-pane">
          <Card>
            <Card.Header>
              <Row>
                <Col className="text-left">
                  <Link
                    to={ROUTES.AGENCY_LOCATIONS_CONTACTS.as({ locationId })}
                  >
                    {" "}
                    <i className="font-20 uil-angle-left"></i>
                  </Link>

                  <span className="font-weight-bold">{contact.fullName}</span>
                </Col>
                <Col className="text-right">
                  {selectedChat.id && !selectedChat.read ? (
                    <MarkAsReadButton />
                  ) : (
                    <MarkAsUnreadButton />
                  )}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col lg={5} md={5} sm={5} sx={5}>
                  {error && <Error error={error} />}
                  {fetchLoading || loading ? (
                    <Loading />
                  ) : fetchError ? (
                    <Error error={fetchError} />
                  ) : (
                    <ContactForm {...contact} onSubmit={handleSubmit} />
                  )}
                </Col>
                <Col className="chat-area" lg={7} md={7} sm={7} sx={7}>
                  {contact.conversations && contact.conversations.length > 0 ? (
                    <ChatMessagesArea />
                  ) : (
                    <ModalButton
                      className="m-1"
                      modal={<MessageModal />}
                      callback={getData}
                      button={
                        <Button>
                          {" "}
                          {t(
                            "locationDashboard.contactShowPage.sendFirstMessage"
                          )}
                        </Button>
                      }
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} md={12} className="right-pane">
          <Card>
            <Card.Body className="m-0 p-1">
              <Container fluid={true}>
                <Row>
                  <Col xs={12} className=" mb-2">
                    <h5 className="d-inline-block">
                      {t("locationDashboard.contactShowPage.tasksPage.title")}
                    </h5>
                    <Switch>
                      <AuthorizedRoute
                        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.href}
                        component={() => (
                          <Link
                            to={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS_CREATE.as(
                              { contactId, locationId }
                            )}
                            className="float-right"
                          >
                            <Button variant={"light"}>
                              {" "}
                              {t(
                                "locationDashboard.contactShowPage.tasksPage.addNew"
                              )}
                            </Button>
                          </Link>
                        )}
                      />
                    </Switch>
                  </Col>
                </Row>
                <Row className="task-list">
                  <Col>
                    <Switch>
                      <AuthorizedRoute
                        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW.href}
                        component={TasksPage}
                      />
                      <AuthorizedRoute
                        path={ROUTES.AGENCY_LOCATIONS_CONTACTS_SHOW_TASKS.href}
                        component={TasksPage}
                      />
                    </Switch>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
export default withSubscriptionCheck(withLocationUser(withChat(ContactsPage)));
