import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/logo.png";

function ImagePreview({ url, file }) {
  const [fileUrl, setFileUrl] = useState("");
  useEffect(() => {
    if (file) setFileUrl(URL.createObjectURL(file));
  }, [file]);
  return (
    <img
      style={{ maxWidth: 200 }}
      className="avatar rounded"
      alt="preview"
      src={fileUrl ? fileUrl : url ? url : logo}
    ></img>
  );
}

export default ImagePreview;
