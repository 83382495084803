import React from "react";
import { useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import BulkRequestContext from "./contexts/BulkRequestContext";
import BulkRequestSearchBar from "./BulkRequestSearchBar";
import BulkRequestTable from "./BulkRequestTable/index";
import api from "../../../../common/api";
import useFetchApi from "../../../../hooks/useFetchArrayApi";
import Error from "../../../Error/index";
import Loading from "../../../Loading/index";
import withLocationUser from "../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../hocs/withSubscriptionCheck";

function BulkRequestsPage() {
  const { locationId } = useParams();
  const {
    data: requests,
    loading: fetchBulkRequestsLoading,
    error: fetchBulkRequestsError,
    getData,
  } = useFetchApi(
    api.locations.bulkRequests.get,
    {
      locationId: locationId,
    },
    { eager: ["tags"] }
  );

  return (
    <BulkRequestContext.Provider
      value={{
        requests,
        reload: () => {
          getData();
        },
      }}
    >
      <Container className="" fluid={true}>
        <Row>
          <Col>
            <BulkRequestSearchBar />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchBulkRequestsError && <Error error={fetchBulkRequestsError} />}
            {fetchBulkRequestsLoading ? (
              <Loading />
            ) : (
              requests && <BulkRequestTable />
            )}
          </Col>
        </Row>
      </Container>
    </BulkRequestContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(BulkRequestsPage))
);
