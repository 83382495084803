import queryString from "query-string";
import api from "../../../../../api";

export default function get({ locationId,conversationId }, query) {
  return api.get(
    `/locations/${locationId}/conversations/${conversationId}/messages${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
