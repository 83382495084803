import React from "react";
import { Form, Button, Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import ContactInput from "../../../../Form/ContactCreatableInput";
import SourceCreatableInput from "../../../../Form/SourceCreatableInput";
import TagsInput from "../../../../Form/TagsInput";
import PipelineInput from "../../../../Form/PipelineInput";
import StageInput from "../../../../Form/StageInput";
import opportunityStatuses from "../../../../../constants/opportunityStatuses";
import pickOpportunity from "../../../../../common/pickers/pickOpportunity";
import ConfirmationModal from "../../../../ConfirmationModal";

const schema = yup.object({
  fullName: yup.string(),
  leadValue: yup
    .number()
    .integer("validation.leadValue.integer")
    .required("validation.leadValue.required"),
  status: yup.string().required("validation.status.required"),
  pipelineId: yup.string().required("validation.pipelineId.required"),
  stageId: yup.string().required("validation.stageId.required"),
  contact: yup
    .object()
    .shape({
      source: yup.object().required("validation.sourceId.required"),
      sourceId: yup.string().when("source", {
        is: (s) => !s,
        then: yup.string().required("validation.sourceId.required"),
        otherwise: yup.string().nullable(),
      }),
    })
    .required("validation.contactId.required"),
});

const OpportunityForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors,
  setFieldValue,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Tabs defaultActiveKey="opportunity">
        <Tab eventKey="opportunity" title="Opportunity">
          <Container className="mt-3" fluid={true}>
            {" "}
            <ContactInput
              fetchSource={true}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
            <Form.Group as={Row} controlId="formContact">
              <Col>
                {" "}
                <Form.Group controlId="form">
                  <Form.Label>{t("formElements.email.label")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("formElements.email.placeholder")}
                    value={get(values, "contact.email") || ""}
                    name="contact.email"
                    onChange={handleChange}
                    isInvalid={!!get(errors, "contact.email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(get(errors, "contact.email"))}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                {" "}
                <Form.Group controlId="formPhone">
                  <Form.Label>{t("formElements.phone.label")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("formElements.phone.placeholder")}
                    value={get(values, "contact.phone") || ""}
                    name="contact.phone"
                    onChange={handleChange}
                    isInvalid={!!get(errors, "contact.phone")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(get(errors, "contact.phone"))}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Group>
            <Form.Group controlId="formTags">
              <Form.Label>{t("formElements.tags.label")}</Form.Label>
              <TagsInput
                setFieldValue={setFieldValue}
                name={"contact.tags"}
                value={get(values, "contact.tags")}
                error={t(get(errors, "contact.tags"))}
              />
            </Form.Group>
            <Form.Group as={Row} controlId="formContact">
              <Col>
                <PipelineInput
                  value={get(values, "pipelineId") || ""}
                  onChange={handleChange}
                  error={get(errors, "pipelineId")}
                />
              </Col>
              <Col>
                <StageInput
                  pipelineId={get(values, "pipelineId")}
                  value={get(values, "stageId") || ""}
                  onChange={handleChange}
                  error={get(errors, "stageId")}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formContact">
              <Col>
                <Form.Group>
                  <Form.Label>{t("formElements.status.label")}</Form.Label>
                  <Form.Control
                    name="status"
                    as="select"
                    value={get(values, "status")}
                    onChange={handleChange}
                    isInvalid={!!get(errors, "status")}
                  >
                    <option>{t("formElements.status.option")}</option>
                    {opportunityStatuses.map((status) => (
                      <option key={status} value={status}>
                        {status}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {t(get(errors, "status"))}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formLeadValue">
                  <Form.Label>{t("formElements.leadValue.label")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={t("formElements.leadValue.placeholder")}
                    value={get(values, "leadValue") || ""}
                    name="leadValue"
                    onChange={handleChange}
                    isInvalid={!!get(errors, "leadValue")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(get(errors, "leadValue"))}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Group>
            <SourceCreatableInput
              name="contact.source"
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              values={values}
              errors={errors}
            />
          </Container>
        </Tab>
      </Tabs>

      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.opportunitiesPage.opportunityForm.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.opportunitiesPage.opportunityForm.submit")}
          </Button>
        </Col>
      </Form.Group>
      <Form.Group controlId="formButton">
        <ConfirmationModal
          text={t("locationDashboard.opportunitiesPage.opportunityForm.deleteModal.text")}
          title={t("locationDashboard.opportunitiesPage.opportunityForm.deleteModal.title")}
          button={
            <Button
              className="btn-lg btn-block"
              variant="danger"
              disabled={!isValid}
            >
              {t("locationDashboard.opportunitiesPage.opportunityForm.delete")}
            </Button>
          }
          callback={onDelete}
        />
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    const result = { ...values };
    if (result.contact) {
      const fullNameArray = result.contact.fullName.split(" ");
      result.contact.firstName = fullNameArray[0];
      result.contact.lastName = fullNameArray[1];
      if (result.contact.tags) {
        result.contact.tags = result.contact.tags.map((t) =>
          t.id === t.name ? { name: t.name } : { id: t.id, name: t.name }
        );
      }
    }

    props.onSubmit(
      omit(pickOpportunity(values), [
        "error",
        "onSubmit",
        "onCancel",
        "action",
        "onDelete",
      ])
    );
  },
  validationSchema: schema,
})(OpportunityForm);
