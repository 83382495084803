import React, { useState } from "react";
import { Card, Accordion, Form } from "react-bootstrap";
import omit from "lodash/omit";
import useContact from "../../hooks/useContact";
import TagInput from "../../../../../../Form/TagInput";
import { useTranslation } from "react-i18next";

export default function TagFilter({ onClose }) {
  const { backendFilters: filters, setBackendFilters: setFilters } =
    useContact();
  const { t } = useTranslation();

  const [isEmptySelected, setIsEmptySelected] = useState(false);
  const [isNotEmptySelected, setIsNotEmptySelected] = useState(false);

  const options = [
    {
      key: "1",
      title: t("locationDashboard.contactsPage.filterBox.tagFilter.notEmpty"),
      onSelect: () => {
        // setFilters({ ...omitFilters(filters), isTagEmpty: false });
      },
    },
    {
      key: "2",
      title: t("locationDashboard.contactsPage.filterBox.tagFilter.empty"),
      onSelect: () => {
        // setFilters({ ...omitFilters(filters), isTagEmpty: true });
      },
    },
    {
      key: "3",
      title: t("locationDashboard.contactsPage.filterBox.tagFilter.is"),
      onSelect: () => {
        // setFilters({ ...omitFilters(filters) });
      },
    },
    {
      key: "4",
      title: t("locationDashboard.contactsPage.filterBox.tagFilter.not"),
      onSelect: () => {
        // setFilters({ ...omitFilters(filters) });
      },
    },
  ];

  const omitFilters = (filters) =>
    omit(filters, ["isTagEmpty", "tagIds", "notTagIds"]);

  const handleClose = () => {
    setFilters(omitFilters(filters));
    onClose();
  };

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey="7">
        {t("locationDashboard.contactsPage.filterBox.tagFilter.setTagFilter")}{" "}
        <i className="dripicons-cross" onClick={handleClose}></i>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="7">
        <Card.Body>
          {/* <br />
          {"isNotEmptySelected " + isNotEmptySelected}
          <br />
          {"isEmptySelected " + isEmptySelected}
          <br /> */}
          {options.map((option) => (
            <>
              {" "}
              {/* 
              
                TODO: Martin - Need more time to unhack this

               */}
              {option.key === "1" && (
                <Form.Check
                  type="checkbox"
                  label={option.title}
                  name="tag1"
                  checked={isNotEmptySelected}
                  id="formCheck1"
                  onChange={(e) => {
                    console.log(
                      `isNotEmptySelected onChange: ${e.target.checked} ~ ${!!e
                        .target.checked}`
                    );

                    // Set states of the checkboxes
                    setIsNotEmptySelected(!!e.target.checked);
                    if (!!e.target.checked) setIsEmptySelected(false);

                    setFilters({
                      ...filters,
                      isTagEmpty: !!e.target.checked
                        ? false
                        : isEmptySelected
                        ? true
                        : undefined,
                    });
                  }}
                />
              )}
              {option.key === "2" && (
                <Form.Check
                  type="checkbox"
                  label={option.title}
                  name="tag2"
                  checked={isEmptySelected}
                  id="formCheck2"
                  onChange={(e) => {
                    console.log(
                      `isEmptySelected onChange: ${e.target.checked} ~ ${!!e
                        .target.checked}`
                    );
                    // Set states of the checkboxes
                    setIsEmptySelected(!!e.target.checked);
                    if (!!e.target.checked) setIsNotEmptySelected(false);

                    setFilters({
                      ...filters,
                      isTagEmpty: !!e.target.checked
                        ? true
                        : isNotEmptySelected
                        ? false
                        : undefined,
                    });
                  }}
                />
              )}
              {option.key === "3" && (
                <TagInput
                  label={option.title}
                  value={filters.tagIds ? filters.tagIds[0] : undefined}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      tagIds:
                        e.target.value === "Tag" ? undefined : [e.target.value],
                    });
                  }}
                />
              )}
              {option.key === "4" && (
                <TagInput
                  label={option.title}
                  value={filters.notTagIds ? filters.notTagIds[0] : undefined}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      notTagIds:
                        e.target.value === "Tag" ? undefined : [e.target.value],
                    });
                  }}
                />
              )}
            </>
          ))}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}
