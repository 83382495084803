import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import {  Col, Button, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import * as ROUTES from "../../../../../constants/routes";
import { useCheckAuth } from "../../../../../react-check-auth";
import api from "../../../../../common/api";
import Error from "../../../../Error";
import Loading from "../../../../Loading";
import ConfirmationModal from "../../../../ConfirmationModal";
import useEmailTemplate from "../hooks/useEmailTemplate";

const EmailTemplateTable = () => {
  const { emailTemplates, reload } = useEmailTemplate();
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { userInfo } = useCheckAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (emailTemplateId) => {
    setLoading(true);
    try {
      await api.locations.emailTemplates.deleteById({
        emailTemplateId,
        locationId,
      });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };
  if (loading) {
    return <Loading />;
  }
  const ActionColumn = (cell, row  ) => {
    return (
      <Row>
        <Col>
          <ConfirmationModal
            text={t("locationDashboard.emailTemplatePage.deleteModal.text")}
            title={t("locationDashboard.emailTemplatePage.deleteModal.title")}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.emailTemplatePage.table.delete")}
              </Button>
            }
            callback={() => handleDelete(row.id)}
          />
        </Col>
        <Col>
          <Link
            to={ROUTES.AGENCY_LOCATIONS_EMAIL_TEMPLATES_EDIT.as({
              locationId,
              emailTemplateId: row.id,
            })}
          >
            {" "}
            <Button
              disabled={
                userInfo.role === "user" && userInfo.type === "location"
              }
            >
              {t("locationDashboard.emailTemplatePage.table.edit")}
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: t("locationDashboard.emailTemplatePage.table.name"),
      sort: true,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.emailTemplatePage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={emailTemplates}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
      />
    </>
  );
};

export default EmailTemplateTable;
