import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {  Col, Button, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import ConfirmationModal from "../../../../../ConfirmationModal";
import SourceEditModal from "../SourceEditModal";
import ModalButton from "../../../../../ModalButton";
import useSource from "../hooks/useSource";

const SourceTable = () => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { sources } = useSource();
  const { userInfo } = useCheckAuth();
  const { reload } = useSource();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleDelete = async (sourceId) => {
    setLoading(true);
    try {
      await api.locations.sources.deleteById({ sourceId, locationId });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };
  if (loading) {
    return <Loading />;
  }
  const selectRowOptions = {
    mode: "checkbox",
    onSelect: (   ) => {},
  };
  const ActionColumn = (cell, row  ) => {
    return (
      <Row>
        <Col>
          <ConfirmationModal
            text={t(
              "locationDashboard.settingsPage.sourcePage.deleteModal.text"
            )}
            title={t(
              "locationDashboard.settingsPage.sourcePage.deleteModal.title"
            )}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.settingsPage.sourcePage.table.delete")}
              </Button>
            }
            callback={() => handleDelete(row.id)}
          />
        </Col>
        <Col>
          {" "}
          <ModalButton
            modal={<SourceEditModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t("locationDashboard.settingsPage.sourcePage.table.edit")}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      dataField: "name",
      text: t("locationDashboard.settingsPage.sourcePage.table.name"),
      sort: true,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.settingsPage.sourcePage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={sources}
        columns={columns}
        selectRow={selectRowOptions}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
      />
    </>
  );
};

export default SourceTable;
