import queryString from "query-string";
import api from "../../../../api";

export default function getById(params,query) {
  return api.get(
    `/locations/${params.locationId}/trigger-links/${params.triggerLinkId}${
      query
        ? `?${queryString.stringify(query, {
            arrayFormat: "bracket",
          })}`
        : ""
    }`
  );
}
