import React from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";

import Error from "../../Error";
import equalTo from "../../../common/yup/equalTo";
import passwordPattern from "../../../common/yup/passwordPattern";

yup.addMethod(yup.string, "equalTo", equalTo);

const schema = yup.object({
  name: yup.string().required("validation.name.required"),
  email: yup
    .string()
    .email("validation.email.valid")
    .required("validation.email.required"),
  password: yup
    .string()
    .required("validation.password.required")
    .matches(passwordPattern, "validation.password.pattern"),
  confirmPassword: yup
    .string()
    .equalTo(yup.ref("password"), "validation.password.equal")
    .required("validation.confirmPassword.required"),
});

const SignUpForm = ({
  handleSubmit,
  handleChange,

  values,

  isValid,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      {values.error && <Error error={values.error} />}
      <Form.Group controlId="formName">
        <Form.Control
          type="text"
          placeholder={t("signUpInvite.form.name.placeholder")}
          value={values.name || ""}
          name="name"
          onChange={handleChange}
          isInvalid={!!errors.name}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.name)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Control
          type="email"
          placeholder={t("signUpInvite.form.email.placeholder")}
          value={values.email || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.email)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formPassword">
        <Form.Control
          type="password"
          placeholder={t("signUpInvite.form.password.placeholder")}
          value={values.password || ""}
          name="password"
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.password)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Control
          type="password"
          placeholder={t("signUpInvite.form.confirmPassword.placeholder")}
          value={values.confirmPassword || ""}
          name="confirmPassword"
          onChange={handleChange}
          isInvalid={!!errors.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.confirmPassword)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formBirthDate">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("signUpInvite.form.submit")}
        </Button>
      </Form.Group>
      <p className="text-center text-muted mb-0">
        {" "}
        {t("signUpInvite.agree")} <a href="#">{t("signUpInvite.terms")}</a>{" "}
        {t("signUpInvite.and")}{" "}
        <a href="#">{t("signUpInvite.privacyPolicy")}</a>.{" "}
      </p>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit"]));
  },
  validationSchema: schema,
})(SignUpForm);
