import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import get from "lodash/get";
import contactFields from "../constants/fields";
import StepHeader from "../StepHeader";
import useImport from "../hooks/useImport";

export default function FieldMappingPreviewStep({
  next,
  previous,
  handleClose,
}) {
  const { t } = useTranslation();
  const { contacts, customFields } = useImport();
  const handleNext = async () => {
    next();
  };
  const contact = get(contacts, "[0]") || {};
  return (
    <Container fluid={true} className="text-center">
      <Row>
        <Col>
          <StepHeader
            title={t("locationDashboard.contactsPage.importModal.step3.title")}
            number={3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>Field Mapping Preview</Card.Header>
            <Card.Body>
              {Object.keys({
                ...contact,
                ...customFields.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.key]: "",
                  }),
                  {}
                ),
              })
                .filter((key) => key !== "customFields")
                .map((key) => (
                  <Row>
                    <Col>
                      {
                        [
                          ...contactFields,
                          ...customFields.map((f) => ({
                            key: f.key,
                            label: f.name,
                          })),
                        ].find((c) => c.key === key).label
                      }
                    </Col>
                    <Col>
                      {contact[key] || get(contact, `customFields.${key}`)}
                    </Col>
                  </Row>
                ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={previous}
            className="btn-lg btn-block"
            variant="dark"
          >
            {t("locationDashboard.contactsPage.importModal.step3.previous")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleClose}
            className="btn-lg btn-block"
            variant="light"
          >
            {t("locationDashboard.contactsPage.importModal.step3.cancel")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleNext}
            className="btn-lg btn-block"
            variant="primary"
          >
            {t("locationDashboard.contactsPage.importModal.step3.submit")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
