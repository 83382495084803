import React from "react";
import { Form, Button } from "react-bootstrap";
import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import domainPattern from "../../../../../common/yup/domainPattern";

const schema = yup.object({
  url: yup
    .string()
    .matches(domainPattern, "validation.domain.pattern")
    .required("validation.domain.required"),
});

const DomainForm = ({
  
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <p> {t("settingsPage.agencySettingsPage.domainCard.description.0")}</p>
      <p> {t("settingsPage.agencySettingsPage.domainCard.description.1")}</p>
      <p> {t("settingsPage.agencySettingsPage.domainCard.description.2")}</p>
      <Form.Group controlId="formDomain">
        <Form.Control
          type="text"
          placeholder={t(
            "settingsPage.agencySettingsPage.domainCard.form.url.placeholder"
          )}
          value={get(values, "url") || ""}
          name="url"
          onChange={handleChange}
          isInvalid={!!get(errors, "url")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "url"))}
        </Form.Control.Feedback>
      </Form.Group>
      <p> {t("settingsPage.agencySettingsPage.domainCard.description.3")}</p>

      <Form.Group controlId="formButton">
        <Button
          className="btn-lg btn-block"
          variant="primary"
          type="submit"
          disabled={!isValid}
        >
          {t("settingsPage.agencySettingsPage.domainCard.form.submit")}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel"]));
  },
  validationSchema: schema,
})(DomainForm);
