import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import isArray from "lodash/isArray";
import pull from "lodash/pull";
import cloneDeep from "lodash/cloneDeep";

export default function removeEmptyStringsWithNull(obj) {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (isString(value) && isEmpty(value)) {
        current[key] = null;
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);

    return current;
  })(cloneDeep(obj));
}
