import React, {  useState } from "react";
import { useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import ContactContext from "./contexts/ContactContext";
import ContactSearchBar from "./ContactSearchBar/index";
import ContactPaginator from "./ContactPaginator/index";
import ContactTable from "./ContactTable/index";
import api from "../../../../../common/api/index";
import useFetchApi from "../../../../../hooks/useFetchArrayApi";
import Error from "../../../../Error/index";
import Loading from "../../../../Loading/index";
import withLocationUser from "../../../../../hocs/withLocationUser";
import withSubscriptionCheck from "../../../../../hocs/withSubscriptionCheck";
import searchContacts from "../../../../../common/search/searchContacts";
import FilterBox from "./FilterBox";

function clean(o) {
  const obj = { ...o };
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === "Columns"
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function ContactsPage() {
  const { t } = useTranslation();
  const [filterBox, setFilterBox] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tableError, setTableError] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const columns = [
    { key: "action", title: t("locationDashboard.contactsPage.table.action") },
    { key: "tags", title: t("locationDashboard.contactsPage.table.tags") },
    {
      key: "lastActivityAt",
      title: t("locationDashboard.contactsPage.table.lastActivity"),
    },
    { key: "phone", title: t("locationDashboard.contactsPage.table.phone") },
    { key: "email", title: t("locationDashboard.contactsPage.table.email") },
    {
      key: "createdAt",
      title: t("locationDashboard.contactsPage.table.createdAt"),
    },
    {
      key: "updatedAt",
      title: t("locationDashboard.contactsPage.table.updatedAt"),
    },
    {
      key: "lastName",
      title: t("locationDashboard.contactsPage.table.lastName"),
    },
    {
      key: "firstName",
      title: t("locationDashboard.contactsPage.table.firstName"),
    },
  ];
  const [selectedColumns, setSelectedColumns] = useState([
    { key: "action", title: "action" },
    { key: "tags", title: "tags" },
    { key: "lastActivityAt", title: "lastActivityAt" },
    { key: "phone", title: "phone" },
    { key: "email", title: "email" },
    { key: "lastName", title: "lastName" },
    { key: "firstName", title: "firstName" },
  ]);
  const [nameFilter, setNameFilter] = useState("");
  const { locationId } = useParams();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 20,
  });
  const [backendFilters, setBackendFilters] = useState({});
  const {
    data,
    loading: fetchContactsLoading,
    error: fetchContactsError,
    getData,
  } = useFetchApi(
    api.locations.contacts.get,
    {
      locationId: locationId,
    },
    {
      ...backendFilters,
      eager: ["tags", "conversations"],
      order: "desc",
      sort: "updatedAt",
    },
    [backendFilters]
  );
  const filterChange = (e) => {
    if (e.target.name === "name") {
      setNameFilter(e.target.value);
      return;
    }
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const selectId = (selectedId) => {
    if (selectedIds.find((id) => id === selectedId)) {
      setSelectedIds(selectedIds.filter((id) => id !== selectedId));
    } else {
      setSelectedIds([...selectedIds, selectedId]);
    }
  };
  const selectIds = (ids) => {
    const newSelectedIds = [];
    [...ids, ...selectedIds].forEach((id1, i, arr) => {
      if (arr.filter((id2) => id2 === id1).length === 1) {
        newSelectedIds.push(id1);
      }
    });
    setSelectedIds(newSelectedIds);
  };

  const contacts = searchContacts(data, nameFilter, 0).slice(
    (filters.page - 1) * filters.limit,
    filters.page * filters.limit
  );

  return (
    <ContactContext.Provider
      value={{
        contacts,
        allContacts: data,
        total: data.length,
        numberOfPages: Math.ceil(data.length / filters.limit),
        nameFilter,
        setNameFilter,
        filters,
        setFilters,
        filterChange,
        selectId,
        selectedIds,
        setTableError,
        setTableLoading,
        selectIds,
        selectedColumns,
        setSelectedColumns,
        columns,
        reload: () => {
          getData();
          setSelectedIds([]);
        },
        setFilterBox,
        filterBox,
        setBackendFilters,
        backendFilters,
      }}
    >
      <Container fluid={true}>
        <Row className="align-items-center">
          <Col>
            <h2>{t("locationDashboard.contactsPage.title")}</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ContactSearchBar />
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <ContactPaginator />
          </Col>
        </Row>
        <Row className="mt-1 mb-1">
          <Col>
            {fetchContactsError && <Error error={fetchContactsError} />}
            {tableError && <Error error={tableError} />}
            {fetchContactsLoading ? (
              <Loading />
            ) : tableLoading ? (
              <Loading />
            ) : (
              contacts && <ContactTable />
            )}
          </Col>
          {filterBox && (
            <Col lg={4} md={4} sm={12} xs={12}>
              <FilterBox />
            </Col>
          )}
        </Row>
      </Container>
    </ContactContext.Provider>
  );
}
export default withSubscriptionCheck(
  withLocationUser(withTranslation()(ContactsPage))
);
