import React from "react";
import { Form, Button } from "react-bootstrap";

import { withFormik } from "formik";
import * as yup from "yup";
import omit from "lodash/omit";


import equalTo from "../../../common/yup/equalTo";
import passwordPattern from "../../../common/yup/passwordPattern";

yup.addMethod(yup.string, "equalTo", equalTo);

const schema = yup.object({
  password: yup
    .string()
    .required("validation.password.required")
    .matches(passwordPattern, "validation.password.pattern"),
  confirmPassword: yup
    .string()
    .equalTo(yup.ref("password"), "validation.password.equal")
    .required("validation.confirmPassword.required"),
});

const EmailForm = ({
  t,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors
  ,
}) => {
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group controlId="formPassword">
        <Form.Control
          type="password"
          placeholder={"Password"}
          value={values.password || ""}
          name="password"
          onChange={handleChange}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.password)}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formConfirmPassword">
        <Form.Control
          type="password"
          placeholder={"Confirm Password"}
          value={values.confirmPassword || ""}
          name="confirmPassword"
          onChange={handleChange}
          isInvalid={!!errors.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          {t(errors.confirmPassword)}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="btn btn-lg btn-block"
        variant="primary"
        disabled={!isValid}
        type="submit"
      >
        Reset password
      </Button>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit"]));
  },
  validationSchema: schema,
})(EmailForm);
