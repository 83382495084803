import isAgencyUser from "./isAgencyUser";

export default function isLocationAdmin(userInfo, locationId) {
  return (
    (userInfo.type === "location" &&
      userInfo.role === "admin" &&
      userInfo.locations.some((l) => l.id === locationId)) ||
    isAgencyUser(userInfo)
  );
}
