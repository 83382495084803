import React from "react";
import {
  
  
  Card,
  CardBody,
  CardHeader,
  NavLink,
  UncontrolledCollapse,
} from "reactstrap";
import { Form } from "react-bootstrap";
import get from "lodash/get";
import { useCheckAuth } from "../../../../../../react-check-auth";
import roles from "../../../../../../constants/roles";
import types from "../../../../../../constants/types";
import LocationsInput from "./LocationsInput";
import limitedTypes from "../../../../../../constants/limitedTypes";
import limitedRoles from "../../../../../../constants/limitedRoles";
import { useTranslation } from "react-i18next";

const UsersRoles = ({
  handleChange,
  values,
  errors,
  
  setFieldValue,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useCheckAuth();
  const limited = userInfo.role === "user";
  return (
    <div id="accordion" className="mb-4">
      <Card className="mb-0">
        <CardHeader>
          <h5 className="m-0">
            <NavLink
              className="custom-accordion-title d-block pt-2 pb-2"
              id="groups2"
              href="#"
            >
              {t(
                "settingsPage.agencyTeamManagementPage.employeeForm.userRoles.title"
              )}
              <span className="float-right">
                <i className="mdi mdi-chevron-right font-18 accordion-arrow"></i>
              </span>
            </NavLink>
          </h5>
        </CardHeader>
        <UncontrolledCollapse toggler="#groups2">
          <CardBody>
            <Form.Group>
              <Form.Label>
                {t(
                  "settingsPage.agencyTeamManagementPage.employeeForm.userRoles.role.label"
                )}
              </Form.Label>
              <Form.Control
                name="role"
                as="select"
                value={get(values, "role")}
                onChange={handleChange}
                isInvalid={!!get(errors, "role")}
              >
                <option>Role</option>
                {(limited && get(values, "type") === "agency"
                  ? limitedRoles
                  : roles
                ).map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {t(get(errors, "role"))}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {" "}
                {t(
                  "settingsPage.agencyTeamManagementPage.employeeForm.userRoles.type.label"
                )}
              </Form.Label>
              <Form.Control
                name="type"
                as="select"
                value={get(values, "type")}
                onChange={handleChange}
                isInvalid={!!get(errors, "type")}
              >
                <option>Type</option>
                {(limited && get(values, "role") === "admin"
                  ? limitedTypes
                  : types
                ).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {t(get(errors, "type"))}
              </Form.Control.Feedback>
            </Form.Group>
            <LocationsInput
              value={get(values, "locations")}
              error={get(errors, "locations")}
              setFieldValue={setFieldValue}
            />
          </CardBody>
        </UncontrolledCollapse>
      </Card>
    </div>
  );
};

export default UsersRoles;
