import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import get from "lodash/get";

import { parse } from "json2csv";
import fileDownload from "js-file-download";
import Error from "../../../../../Error/index";
import Loading from "../../../../../Loading/index";
import useFetchApi from "../../../../../../hooks/useFetchArrayApi";
import api from "../../../../../../common/api";

const ExportModal = ({ handleClose,   show, contacts }) => {
  const { locationId } = useParams();
  const {
    data: customFields
    
    ,
  } = useFetchApi(api.locations.customFields.get, {
    locationId,
  });
  const { t } = useTranslation();

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const fields = [
        {
          label: "First Name",
          value: "firstName",
        },
        {
          label: "Last Name",
          value: "lastName",
        },
        {
          label: "Phone",
          value: "phone",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Title",
          value: "title",
        },
        {
          label: "Academic Title",
          value: "academicTitle",
        },
        {
          label: "Company",
          value: "company",
        },
        {
          label: "Created",
          value: (row) => format(parseISO(row.createdAt), "d.MM.y H:mm"),
        },
        {
          label: "Tags",
          value: (row) => row.tags.map((t) => t.name).join(", "),
        },
        ...customFields.map((field) => ({
          label: field.name,
          value: (row) => get(row, `customFields.${field.key}`) || "",
        })),
      ];
      const csv = await new Promise((resolve, reject) => {
        try {
          const opts = { fields };
          const data = parse(contacts, opts);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });

      fileDownload(csv, `contacts-${format(new Date(), "d-M-y")}.csv`);
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("locationDashboard.contactsPage.exportModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <p>
              {t("locationDashboard.contactsPage.exportModal.text", {
                length: contacts.length,
              })}
            </p>
            <Row>
              <Col>
                <Button
                  onClick={handleClose}
                  className="btn-lg btn-block"
                  variant="light"
                >
                  {" "}
                  {t("locationDashboard.contactsPage.exportModal.cancel")}
                </Button>
              </Col>
              <Col>
                {" "}
                <Button
                  onClick={onSubmit}
                  className="btn-lg btn-block"
                  variant="primary"
                >
                  {" "}
                  {t("locationDashboard.contactsPage.exportModal.submit")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExportModal;
