import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import SignInPage from "../SignInPage";
import InvitationSignUpPage from "../InvitationSignUpPage";
import SignUpPage from "../SignUpPage";
import AdminPage from "../AdminDashboard";
import AgencyPage from "../AgencyDashboard";
import ForgotPasswordPage from "../ForgotPasswordPage";
import ResetPasswordPage from "../ResetPasswordPage";

import * as ROUTES from "../../constants/routes";
import AuthorizedRoute from "../AuthorizedRoute";
import UnauthorizedRoute from "../UnauthorizedRoute";

import NotFoundPage from "../NotFound";

const App = () => (
  <Router>
    {" "}
    <Switch>
      <AuthorizedRoute path={"/"} component={AgencyPage} exact />
      <UnauthorizedRoute
        path={ROUTES.SIGN_IN.href}
        component={SignInPage}
        exact
      />
      <UnauthorizedRoute
        path={ROUTES.INVITATIONS_SIGN_UP.href}
        component={InvitationSignUpPage}
        exact
      />
      <UnauthorizedRoute
        path={ROUTES.SIGN_UP.href}
        component={SignUpPage}
        exact
      />
      <UnauthorizedRoute
        path={ROUTES.FORGOT_PASSWORD.href}
        component={ForgotPasswordPage}
        exact
      />
      <UnauthorizedRoute
        path={ROUTES.RESET_PASSWORD.href}
        component={ResetPasswordPage}
        exact
      />
      <AuthorizedRoute path={ROUTES.ADMIN.href} component={AdminPage} />
      <AuthorizedRoute path={ROUTES.AGENCY.href} component={AgencyPage} />

      <Route component={NotFoundPage} />
    </Switch>
  </Router>
);

export default App;
