import React from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const TagInput = ({ onChange, value = "", error: passedError, label }) => {
  const { locationId } = useParams();
  const {
    data,
    loading: fetchTagsLoading,
    error: fetchTagsError,
  } = useFetchApi(api.locations.tags.get, {
    locationId,
  });
  if (fetchTagsLoading) return <Loading />;
  return (
    <Form.Group controlId="forTagId">
      {fetchTagsError && <Error error={fetchTagsError} />}
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        name="tagId"
        as="select"
        value={value}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>Tag</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {passedError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TagInput;
