import axios from "axios";

export const apiDomainUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_DOMAIN : "";

export default axios.create({
  withCredentials: true,
  baseURL: `${apiDomainUrl}/v1`,
  responseType: "json",
});
