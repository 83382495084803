import React from "react";

import get from "lodash/get";
import { Editor } from "@tinymce/tinymce-react";
import { useTranslation } from "react-i18next";

const SmsForm = ({ values, setFieldValue }) => {
  useTranslation();
  return (
    <Editor
      value={get(values, "content.text")}
      outputFormat={"text"}
      apiKey={process.env.REACT_APP_TINYMCE_KEY}
      init={{
        height: 200,
        menubar: "",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        toolbar: false,
      }}
      onEditorChange={(content) => setFieldValue("content.text", content)}
    />
  );
};

export default SmsForm;
