import create from "./create";
import getLocations from "./getLocations";
import getAccounts from "./getAccounts";
import getById from "./getById";
import updatedSourceById from "./updateSourceById";
import updatePhoto from "./updatePhoto";
import updateById from "./updateById";
import upsertDomain from "./upsertDomain";

export default {
  create,
  getLocations,
  getById,
  updatedSourceById,
  updatePhoto,
  updateById,
  getAccounts,
  upsertDomain,
};
