import React, {  useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import Loading from "../../Loading/index";
import api from "../../../common/api/index";
import Error from "../../Error/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";

const PipelineInput = ({
  onChange,
  value = "",
  error: passedError,
  setInitialValue,
  name = "pipelineId",
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const {
    data,
    loading: fetchPipelinesLoading,
    error: fetchPipelinesError
    ,
  } = useFetchApi(
    api.locations.pipelines.get,
    {
      locationId,
    },
    { eager: ["stages"], sort: "createdAt" },
    [locationId]
  );
  useEffect(() => {
    if (setInitialValue && data.length > 0) {
      onChange({ target: { name: "pipelineId", value: data[0].id } });
    }
  }, [data, onChange, setInitialValue]);
  if (fetchPipelinesLoading) return <Loading />;
  return (
    <Form.Group controlId="forPipelineId">
      {fetchPipelinesError && <Error error={fetchPipelinesError} />}
      <Form.Control
        name={name}
        as="select"
        value={value||''}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>{t("formElements.pipeline.option")}</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {t(passedError)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default PipelineInput;
