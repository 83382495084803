import auth from "./auth";
import plans from "./plans";
import session from "./session";
import accounts from "./accounts";
import agencies from "./agencies";
import locations from "./locations";
import checkout from "./checkout";
import invites from "./invites";
import admin from "./admin";

export default {
  auth,
  plans,
  session,
  accounts,
  agencies,
  locations,
  checkout,
  invites,
  admin,
};
