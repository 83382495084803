import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  CustomInput,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useCheckAuth } from "../../../../../../react-check-auth/index";
import ModalButton from "../../../../../ModalButton/index";
import api from "../../../../../../common/api/index";
import useContact from "../hooks/useContact";
import ContactCreateModal from "../ContactCreateModal/index";
import ConfirmationModal from "../../../../../ConfirmationModal/index";
import AddTagsModal from "../AddTagsModal";
import RemoveTagsModal from "../RemoveTagsModal";
import ExportModal from "../ExportModal";
import ImportModal from "../ImportModal";

const ContactSearchBar = () => {
  const { locationId } = useParams();
  const { t } = useTranslation();
  const {
    
    
    reload,
    nameFilter,
    filterChange,
    selectedIds,
    setTableLoading,
    setTableError,
    
    allContacts,
    columns,
    selectedColumns,
    setSelectedColumns,
    setFilterBox
    ,
  } = useContact();
  const { userInfo } = useCheckAuth();
  const handleDeletion = async () => {
    setTableLoading(true);
    try {
      await api.locations.contacts.deleteContacts(
        { locationId },
        { ids: selectedIds }
      );
      reload();
    } catch (e) {
      setTableError(e);
    }
    setTableLoading(false);
  };

  // Don't allow upload/downlod to location users
  const isNotLocationUser = userInfo
    ? userInfo.type !== "location" && userInfo.role !== "user"
    : false;

  return (
    <Container fluid={true}>
      <Row>
        <Col lg={6} md={12} sx={12} sm={12}>
          <div className="btn-group">
            <ModalButton
              modal={<ContactCreateModal />}
              className="m-1"
              button={
                <Button>
                  <i className="dripicons-plus"></i>
                </Button>
              }
              callback={reload}
            />
            <ModalButton
              modal={<AddTagsModal />}
              className="m-1"
              modalProps={{ ids: selectedIds }}
              button={
                <Button className="m-1" disabled={selectedIds.length <= 0}>
                  <i className="dripicons-tags"></i>
                </Button>
              }
              callback={reload}
            />
            <ModalButton
              modal={<RemoveTagsModal />}
              modalProps={{ ids: selectedIds }}
              className="m-1"
              button={
                <Button className="m-1" disabled={selectedIds.length <= 0}>
                  <i className="dripicons-tag-delete"></i>
                </Button>
              }
              callback={reload}
            />

            <ConfirmationModal
              text={t("locationDashboard.contactsPage.deleteModal.text")}
              title={t("locationDashboard.contactsPage.deleteModal.title")}
              button={
                <Button
                  className="m-1"
                  disabled={selectedIds.length <= 0 || !isNotLocationUser}
                >
                  <i className="dripicons-document-delete"></i>
                </Button>
              }
              callback={handleDeletion}
            />
            <ModalButton
              modal={<ExportModal />}
              modalProps={{
                contacts: selectedIds.map((id) =>
                  allContacts.find((c) => c.id === id)
                ),
              }}
              className="m-1"
              button={
                <Button
                  className="m-1"
                  disabled={selectedIds.length <= 0 || !isNotLocationUser}
                >
                  <i className="dripicons-download"></i>
                </Button>
              }
            />
            <ModalButton
              modal={<ImportModal />}
              className="m-1"
              button={
                <Button className="m-1">
                  <i className="dripicons-upload"></i>
                </Button>
              }
            />
            <UncontrolledDropdown className="list-custom-class">
              <DropdownToggle className="m-1" color="primary" caret>
                Select
              </DropdownToggle>
              <DropdownMenu>
                {columns.map((column) => {
                  const selected = selectedColumns.find(
                    (c) => c.key === column.key
                  );
                  return (
                    <DropdownItem key={column.key} toggle={false}>
                      <CustomInput
                        type="checkbox"
                        id={`col-${column.key}`}
                        label={column.title}
                        checked={selected}
                        onClick={() => {
                          if (selected) {
                            setSelectedColumns(
                              selectedColumns.filter((c) => c !== selected)
                            );
                          } else {
                            setSelectedColumns([...selectedColumns, column]);
                          }
                        }}
                      />
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Col>
        <Col lg={6} md={12} sx={12} sm={12}>
          <Form>
            <Form.Group as={Row}>
              <Col></Col>
              <Col>
                <Form.Control
                  name="name"
                  onChange={filterChange}
                  value={nameFilter}
                  type="text"
                  placeholder={t("locationDashboard.contactsPage.search")}
                />
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setFilterBox(true);
                  }}
                >
                  {t("locationDashboard.contactsPage.moreFilters")}
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactSearchBar;
