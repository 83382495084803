import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import api from "../../../common/api";
import useChat from "../../../hooks/useChat";
import { useTranslation } from "react-i18next";

export default function MarkAsReadButton() {
  const { t } = useTranslation();
  const { locationId } = useParams();
  const { selectedChat } = useChat();
  const markAsRead = async () => {
    try {
      await api.locations.conversations.read({
        locationId,
        conversationId: selectedChat.id,
      });
    } catch (e) {}
  };
  return (
    <Button variant="light" onClick={markAsRead}>
        {t("locationDashboard.conversationPage.markAsRead")}
    </Button>
  );
}
