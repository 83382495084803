import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import {Button, Form } from "react-bootstrap";

export default function MailgunForm({
  handleChange,
  errors,
  values,
  isValid,
}) {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <Form.Group controlId="formApiKey">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.emailPage.form.publicApiKey.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.publicApiKey.placeholder"
          )}
          value={get(values, "config.publicApiKey") || ""}
          name="config.publicApiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.publicApiKey")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.publicApiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formApiKey">
        <Form.Label>
          {" "}
          {t(
            "locationDashboard.settingsPage.emailPage.form.privateApiKey.label"
          )}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.privateApiKey.placeholder"
          )}
          value={get(values, "config.privateApiKey") || ""}
          name="config.privateApiKey"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.privateApiKey")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.privateApiKey"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.form.senderEmail.label")}
        </Form.Label>
        <Form.Control
          type="email"
          placeholder={t(
            "locationDashboard.settingsPage.emailPage.form.senderEmail.placeholder"
          )}
          value={get(values, "config.email") || ""}
          name="config.email"
          onChange={handleChange}
          isInvalid={!!get(errors, "config.email")}
        ></Form.Control>
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "config.email"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        className="btn-block"
        variant="primary"
        type="submit"
        disabled={!isValid}
      >
        Submit
      </Button>
    </>
  );
}
