import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalButton from "../../ModalButton/index";
import api from "../../../common/api/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import AutomationInstanceCreateModal from "./AutomationInstanceCreateModal";
import ConfirmationModal from "../../ConfirmationModal";
import automationInstanceStatuses from "../../../constants/automationInstanceStatuses";
import Error from "../../Error";

export default function AutomationsListInput({ contactId: cId  }) {
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const { locationId, contactId } = useParams();
  const {
    data: automations,
    
    error: fetchError,
    getData,
  } = useFetchApi(
    api.locations.automationInstances.get,
    {
      locationId,
    },
    {
      contactId: contactId || cId,
      enabled: true,
      eager: ["automation"],
    },
    [contactId, cId]
  );

  const handleCancel = async (id) => {
    setError(null);
    try {
      await api.locations.automationInstances.deleteById({
        locationId,
        instanceId: id,
      });
      getData();
    } catch (e) {
      setError(true);
    }
  };
  const activeAutomations = automations.filter(
    (a) => a.status === automationInstanceStatuses.Runnable
  );
  const pastAutomations = automations.filter(
    (a) =>
      a.status === automationInstanceStatuses.Canceled ||
      a.status === automationInstanceStatuses.Errored ||
      a.status === automationInstanceStatuses.Complete
  );
  return (
    <>
      <p className="mt-3 mb-1">
        <strong> {t("formElements.pastAutomationInstanceList.label")}</strong>
      </p>
      {pastAutomations.map((a) => (
        <span className="bg-light p-1 m-1 d-inline-flex">
          {`${a.automation.name}`}
        </span>
      ))}
      <p className="mt-3 mb-1">
        <strong> {t("formElements.automationInstanceList.label")}</strong>
      </p>
      {error && <Error error={error} />}{" "}
      {fetchError && <Error error={fetchError} />}{" "}
      {activeAutomations.map((a) => (
        <span className="bg-light p-1 m-1 d-inline-flex">
          {`${a.automation.name}`}
          <ConfirmationModal
            title={t("formElements.automationInstanceList.cancelModal.title")}
            text={t("formElements.automationInstanceList.cancelModal.text")}
            button={<i className="dripicons-cross" />}
            callback={() => handleCancel(a.id)}
          />
        </span>
      ))}
      <ModalButton
        className="m-1"
        modal={<AutomationInstanceCreateModal contactId={contactId || cId} />}
        button={
          <Button variant="light">
            <i className="dripicons-plus"></i>
          </Button>
        }
        callback={() => {
          getData();
        }}
      />
    </>
  );
}
