import pick from "lodash/pick";

export default (values) => {
  const result = pick(values, [
    "automationId",
    "contactId",
    "nextExecutionTime",
  ]);
  return result;
};
