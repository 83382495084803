import React from "react";
import { Form, Button,  Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import omit from "lodash/omit";
import get from "lodash/get";
import SourceCreatableInput from "../../../../../Form/SourceCreatableInput";
import CountryInput from "../../../../../Form/CountryInput";
import TitleInput from "../../../../../Form/TitleInput";
import CustomFieldInput from "../../../../../Form/CustomFieldInput";

const schema = yup.object({
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  company: yup.string().nullable(),
  email: yup.string().email("validation.email.valid").nullable(),
  phone: yup.string().nullable(),
  source: yup.object().required("validation.sourceId.required"),
  sourceId: yup.string().when("source", {
    is: (s) => !s,
    then: yup.string().required("validation.sourceId.required"),
    otherwise: yup.string().nullable(),
  }),
  address: yup.object({
    street: yup.string().nullable(),
    city: yup.string().nullable(),
    postcode: yup.string().nullable(),
    country: yup.string().nullable(),
    state: yup.string().nullable(),
  }),
  notes: yup.string().nullable(),
  title: yup.string().nullable(),
});

const ContactForm = ({
  onCancel,
  handleSubmit,
  handleChange,
  
  values,
  
  isValid,
  errors,
  setFieldValue,
}) => {
  const { t } = useTranslation();
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <TitleInput
        handleChange={handleChange}
        name={"title"}
        error={get(errors, "title")}
        value={get(values, "title") || ""}
      />
      <Form.Group as={Row} controlId="formName">
        <Col>
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.contactsPage.createModal.form.firstName.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            zz
            placeholder={t(
              "locationDashboard.contactsPage.createModal.form.firstName.placeholder"
            )}
            value={get(values, "firstName") || ""}
            name="firstName"
            onChange={handleChange}
            isInvalid={!!get(errors, "firstName")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "firstName"))}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.contactsPage.createModal.form.lastName.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.contactsPage.createModal.form.lastName.placeholder"
            )}
            value={get(values, "lastName") || ""}
            name="lastName"
            onChange={handleChange}
            isInvalid={!!get(errors, "lastName")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "lastName"))}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group controlId="formEmail">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.email.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactsPage.createModal.form.email.placeholder"
          )}
          value={get(values, "email") || ""}
          name="email"
          onChange={handleChange}
          isInvalid={!!get(errors, "email")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "email"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formPhone">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.phone.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactsPage.createModal.form.phone.placeholder"
          )}
          value={get(values, "phone") || ""}
          name="phone"
          onChange={handleChange}
          isInvalid={!!get(errors, "phone")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "phone"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formCompany">
        {" "}
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.company.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactsPage.createModal.form.company.placeholder"
          )}
          value={get(values, "company") || ""}
          name="company"
          onChange={handleChange}
          isInvalid={!!get(errors, "company")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "company"))}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formStreet">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.street.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactsPage.createModal.form.street.placeholder"
          )}
          value={get(values, "address.street") || ""}
          name="address.street"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.street")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.street"))}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Row} controlId="formCity">
        <Col>
          <Form.Label>
            {t("locationDashboard.contactsPage.createModal.form.city.label")}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.contactsPage.createModal.form.city.placeholder"
            )}
            value={get(values, "address.city") || ""}
            name="address.city"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.city")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.city"))}
          </Form.Control.Feedback>
        </Col>
        <Col>
          <Form.Label>
            {" "}
            {t(
              "locationDashboard.contactsPage.createModal.form.postcode.label"
            )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t(
              "locationDashboard.contactsPage.createModal.form.postcode.placeholder"
            )}
            value={get(values, "address.postcode") || ""}
            name="address.postcode"
            onChange={handleChange}
            isInvalid={!!get(errors, "address.postcode")}
          />
          <Form.Control.Feedback type="invalid">
            {t(get(errors, "address.postcode"))}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group controlId="formState">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.state.label")}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder={t(
            "locationDashboard.contactsPage.createModal.form.state.placeholder"
          )}
          value={get(values, "address.state") || ""}
          name="address.state"
          onChange={handleChange}
          isInvalid={!!get(errors, "address.state")}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "address.state"))}
        </Form.Control.Feedback>
      </Form.Group>
      <CountryInput
        value={get(values, "address.country") || ""}
        name="address.country"
        handleChange={handleChange}
        error={!!get(errors, "address.country")}
        defaultOption={true}
      />
      <Form.Group controlId="formNotes">
        <Form.Label>
          {" "}
          {t("locationDashboard.contactsPage.createModal.form.notes.label")}
        </Form.Label>
        <Form.Control
          type="text"
          name="notes"
          as="textarea"
          value={get(values, "notes")}
          style={{ minHeight: 80 }}
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">
          {t(get(errors, "notes"))}
        </Form.Control.Feedback>
      </Form.Group>
      <CustomFieldInput
        value={get(values, "customFields")}
        onChange={handleChange}
      />
      <SourceCreatableInput
        name="source"
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        values={values}
        errors={errors}
      />

      <Form.Group as={Row} controlId="formButton">
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="light"
            disabled={!isValid}
            onClick={onCancel}
          >
            {t("locationDashboard.contactsPage.createModal.form.cancel")}
          </Button>
        </Col>
        <Col lg={6} md={6} sm={6} sx={12}>
          <Button
            className="btn-lg btn-block"
            variant="primary"
            type="submit"
            disabled={!isValid}
          >
            {t("locationDashboard.contactsPage.createModal.form.submit")}
          </Button>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: (values) => {
    return values;
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(omit(values, ["error", "onSubmit", "onCancel", "action"]));
  },
  validationSchema: schema,
})(ContactForm);
