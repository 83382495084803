import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {  Col, Button, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import ConfirmationModal from "../../../../../ConfirmationModal";
import ModalButton from "../../../../../ModalButton";
import EmailServiceEditModal from "../EmailServiceEditModal";
import useEmailService from "../hooks/useEmailService";
import useLocation from "../../../hooks/useLocation";
import getEmailWebhook from "../../../../../../common/functions/getEmailWebhook";

const EmailServiceTable = () => {
  const { t } = useTranslation();
  const { location } = useLocation();
  const { userInfo, refreshAuth } = useCheckAuth();
  const { locationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { reload, services } = useEmailService();

  const handleDelete = async (transportId) => {
    setLoading(true);
    try {
      await api.locations.transports.deleteById({ transportId, locationId });
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    reload();
  };

  const ActionColumn = (cell, row  ) => {
    return (
      <Row>
        <Col>
          <ConfirmationModal
            text={t(
              "locationDashboard.settingsPage.emailPage.deleteModal.text"
            )}
            title={t(
              "locationDashboard.settingsPage.emailPage.deleteModal.title"
            )}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
                variant="danger"
              >
                {t("locationDashboard.settingsPage.emailPage.table.delete")}
              </Button>
            }
            callback={() => handleDelete(row.id)}
          />
        </Col>
        <Col>
          {" "}
          <ModalButton
            modal={<EmailServiceEditModal />}
            button={
              <Button
                disabled={
                  userInfo.role === "user" && userInfo.type === "location"
                }
              >
                {t("locationDashboard.settingsPage.emailPage.table.edit")}
              </Button>
            }
            callback={reload}
            id={row.id}
          />
        </Col>
      </Row>
    );
  };
  const WebhookColumn = (cell, row  ) => {
    return getEmailWebhook(row.name, row.locationId);
  };
  const SetDefaultColumn = (cell, row  ) => {
    return (
      <Row>
        <Col>
          {location.defaultEmailTransportId === row.id ? (
            t("locationDashboard.settingsPage.emailPage.table.default")
          ) : (
            <Button
              onClick={async () => {
                setError(null);
                setLoading(true);
                try {
                  await api.locations.transports.makeEmailDefault({
                    locationId,
                    transportId: row.id,
                  });
                } catch (e) {
                  setError(e);
                }
                setLoading(false);
                refreshAuth();
              }}
            >
              {t("locationDashboard.settingsPage.emailPage.table.makeDefault")}
            </Button>
          )}
        </Col>
      </Row>
    );
  };
  const columns = [
    {
      dataField: "default",
      isDummyColumn: true,
      text: t("locationDashboard.settingsPage.emailPage.table.default"),
      sort: false,
      classes: "table-action",
      formatter: SetDefaultColumn,
    },
    {
      dataField: "name",
      text: t("locationDashboard.settingsPage.emailPage.table.name"),
      sort: true,
    },
    {
      dataField: "webhook",
      isDummyColumn: true,
      text: t("locationDashboard.settingsPage.emailPage.table.webhook"),
      sort: false,
      classes: "table-action",
      formatter: WebhookColumn,
    },
    {
      dataField: "action",
      isDummyColumn: true,
      text: t("locationDashboard.settingsPage.emailPage.table.action"),
      sort: false,
      classes: "table-action",
      formatter: ActionColumn,
    },
  ];
  if (loading) return <Loading />;
  return (
    <>
      {error && <Error error={error} />}
      <BootstrapTable
        bordered={false}
        keyField="id"
        data={services}
        columns={columns}
        headerClasses="thead-light"
        wrapperClasses="table-responsive"
      />
    </>
  );
};

export default EmailServiceTable;
