import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Collapse } from "reactstrap";
import TaskCard from "../TaskCard";

const TaskSection = ({ title, tasks, reload }) => {
  const [collapse, setCollapse] = useState(true);
  const toggle = () => setCollapse(!collapse);

  return (
    <React.Fragment>
      <Link className="text-dark" to="#" onClick={toggle}>
        <h5 className="m-0 pb-2">
          <i
            className={`uil font-18 ${
              collapse ? "uil-angle-down" : "uil-angle-right"
            }`}
          ></i>
          {title} <span className="text-muted">({tasks.length})</span>
        </h5>
      </Link>

      <Collapse isOpen={collapse}>
        <Card className="mb-0">
          <CardBody className="pb-1 pt-2">
            {tasks.map((task) => (
              <TaskCard reload={reload} task={task} key={task.id} />
            ))}
          </CardBody>
        </Card>
      </Collapse>
    </React.Fragment>
  );
};

export default TaskSection;
