import React from "react";
import { Card, Row, Col, Container, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateTimeRange from "../DateTimeRangeDropdown";
import useDashboard from "../hooks/useDashboard";
import formatGermanCurrency from "../../../../../common/functions/formatGermanCurrency";

export default function ContactsCard() {
  const { events, sources, opportunities } = useDashboard();
  const { t } = useTranslation();
  const filteredSources = sources.filter((s) =>
    events.some((e) => e.sourceId === s.id)
  );
  const data = filteredSources.map((source) => {
    const leads = opportunities.filter((o) => o.contact.sourceId === source.id);
    const totalLeads = leads.length;
    const totalValue = leads.reduce((acc, cur) => acc + cur.leadValue, 0);
    const won = leads.filter((o) => o.status === "won").length;
    const open = leads.filter((o) => o.status === "open").length;
    const lost = leads.filter((o) => o.status === "lost").length;
    const winPercent = (won / totalLeads) * 100;
    return { source, totalLeads, totalValue, open, lost, winPercent, won };
  });
  return (
    <Card>
      <Card.Header>
        <Container>
          <Row>
            <Col>
              <h4>
                {t("locationDashboard.mainPage.leadSourceReportCard.title")}
              </h4>
            </Col>
            <Col>
              <DateTimeRange />
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        {" "}
        <Container fluid={true}>
          {" "}
          <Row>
            <Col>
              <Table>
                <thead className="thead-light">
                  <tr>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.source"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.totalLeads"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.totalValue"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.open"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.closed"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.lost"
                      )}
                    </th>
                    <th>
                      {t(
                        "locationDashboard.mainPage.leadSourceReportCard.table.winPercent"
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((record) => (
                    <tr>
                      <td>{record.source.name}</td>
                      <td>{record.totalLeads}</td>
                      <td>{formatGermanCurrency(record.totalValue)}</td>
                      <td>{record.open}</td>
                      <td>{record.won}</td>
                      <td>{record.lost}</td>
                      <td>{record.winPercent.toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
