import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalButton from "../../ModalButton/index";
import api from "../../../common/api/index";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import OpportunityShortCreateModal from "./OpportunityShortCreateModal/index";
import OpportunityEditModal from "../../AgencyDashboard/LocationDashboard/OpportunitiesPage/OpportunityEditModal";

export default function OpportunitiesListInput({ contactId: cId }) {
  const { t } = useTranslation();
  const { locationId, contactId } = useParams();
  const { data: opportunities,   getData } = useFetchApi(
    api.locations.opportunities.get,
    {
      locationId,
    },
    { contactId: contactId || cId, eager: ["stage.pipeline"] },
    [contactId, cId]
  );
  return (
    <>
      {" "}
      <p className="mt-3 mb-1">
        <strong> {t("formElements.opportunityList.label")}</strong>
      </p>{" "}
      {opportunities.map((o) => (
        <span className="bg-light p-1 m-1 d-inline-flex">
          {`${o.stage.name} (${o.stage.pipeline.name})`}{" "}
          <ModalButton
            className="clickable dripicons-export"
            modal={<OpportunityEditModal />}
            modalProps={{ pipelineId: o.stage.pipelineId }}
            id={o.id}
            button={<i></i>}
            callback={getData}
          />
        </span>
      ))}
      <ModalButton
        className="m-1"
        modal={<OpportunityShortCreateModal contactId={contactId || cId} />}
        button={
          <Button variant="light">
            <i className="dripicons-plus"></i>
          </Button>
        }
        callback={() => {
          getData();
        }}
      />
    </>
  );
}
