import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../../../../../react-check-auth";
import api from "../../../../../../common/api";
import EmailServiceForm from "../EmailServiceForm";
import Error from "../../../../../Error";
import Loading from "../../../../../Loading";
import useFetchApi from "../../../../../../hooks/useFetchApi";

import pickEditEmailService from "../../../../../../common/pickers/pickEditEmailService";
import pickEmailService from "../../../../../../common/pickers/pickEmailService";

const EmailServiceEditModal = ({ handleClose, callback, show, id }) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const {
    data,
    error: fetchError,
    loading: fetchLoading,
  } = useFetchApi(api.locations.transports.getById, {
    transportId: id,
    locationId,
  });

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      if (values.name === "smtp") {
        await api.locations.transports.deleteById({
          transportId: id,
          locationId: locationId,
        });
        await api.locations.transports.create(
          { locationId: locationId },
          { ...pickEmailService(values) }
        );
      } else {
        await api.locations.transports.updateById(
          { transportId: id, locationId: locationId },
          { ...pickEditEmailService(values) }
        );
      }
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("locationDashboard.settingsPage.emailPage.editModal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading || fetchLoading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            {fetchError && <Error error={fetchError} />}
            <EmailServiceForm
              {...data}
              action={"edit"}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmailServiceEditModal;
