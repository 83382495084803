import React from "react";
import { useCheckAuth } from "../react-check-auth";
import UnauthorizedPage from "../components/UnauthorizedPage";
import isAgencyUser from "../common/functions/isAgencyUser";

export default (WrappedPage) => {
  const WithAgencyUser = ({ ...props }) => {
    const { userInfo } = useCheckAuth();
    if (isAgencyUser(userInfo)) {
      return <WrappedPage {...props} />;
    } else {
      return <UnauthorizedPage />;
    }
  };
  return WithAgencyUser;
};
