import React from "react";
import { Form } from "react-bootstrap";
import Loading from "../../Loading/index";
import api from "../../../common/api";
import Error from "../../Error";
import useFetchApi from "../../../hooks/useFetchArrayApi";
import { useCheckAuth } from "../../../react-check-auth";

const LocationInput = ({ onChange, value = "", error: passedError }) => {
  const { userInfo } = useCheckAuth();
  const { data, loading, error } = useFetchApi(api.agencies.getLocations, {
    agencyId: userInfo.agency.id,
  });
  if (loading) return <Loading />;
  return (
    <Form.Group controlId="forLocationId">
      {error && <Error error={error} />}
      <Form.Control
        name="locationId"
        as="select"
        value={value}
        onChange={onChange}
        isInvalid={!!passedError}
      >
        <option>In Account</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        {passedError}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default LocationInput;
