import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useCheckAuth } from "../../../../react-check-auth/index";
import api from "../../../../common/api/index";
import OpportunityForm from "../OpportunityShortForm/index";
import Error from "../../../Error/index";
import Loading from "../../../Loading/index";
import pickOpportunity from "../../../../common/pickers/pickOpportunity";

const OpportunityCreateModal = ({
  handleClose,
  callback,

  show,
  contactId,
}) => {
  const { t } = useTranslation();
  const { locationId } = useParams();
  useCheckAuth();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const onCancel = () => {
    handleClose();
  };
  const onSubmit = async (values) => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.opportunities.create(
        { locationId },
        pickOpportunity(values)
      );
      if (callback) callback();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {t("formElements.opportunityList.modal.title")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            {error && <Error error={error} />}
            <OpportunityForm
              contactId={contactId}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default OpportunityCreateModal;
