import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TagsInput from "../../../../../../Form/TagsInput";
import StepHeader from "../StepHeader";
import useImport from "../hooks/useImport";
import api from "../../../../../../../common/api";
import Error from "../../../../../../Error";
import Loading from "../../../../../../Loading";
import useContact from "../../hooks/useContact";
import removeEmptyStrings from "../../../../../../../common/functions/removeEmptyStrings";

export default function StartImportStep({  handleClose, previous }) {
  const { locationId } = useParams();
  const { reload } = useContact();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { contacts, strategy } = useImport();
  const [tags, setTags] = useState([]);
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await api.locations.contacts.importContacts(
        { locationId },
        {
          strategy,
          contacts: contacts.map((c) => removeEmptyStrings({ ...c, tags })),
        }
      );
      reload();
      handleClose();
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <Container fluid={true} className="text-center">
      {error && <Error error={error} />}
      <Row>
        <Col>
          <StepHeader
            title={t("locationDashboard.contactsPage.importModal.step5.title")}
            number={5}
          />
        </Col>
      </Row>
      <Row className="mt-1 mb-1">
        <Col>
          <Form.Group controlId="formTags">
            <Form.Label>
              {" "}
              {t("locationDashboard.contactsPage.importModal.step5.tags.label")}
            </Form.Label>
            <TagsInput
              setFieldValue={(name, value) => setTags(value)}
              name={"tags"}
              value={tags}
              creatable={false}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={previous}
            className="btn-lg btn-block"
            variant="dark"
          >
            {t("locationDashboard.contactsPage.importModal.step5.previous")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleClose}
            className="btn-lg btn-block"
            variant="light"
          >
            {t("locationDashboard.contactsPage.importModal.step5.cancel")}
          </Button>
        </Col>
        <Col lg={4} md={4} sm={4} sx={12}>
          <Button
            onClick={handleSubmit}
            disabled={!(contacts && strategy)}
            className="btn-lg btn-block"
            variant="primary"
          >
            {t("locationDashboard.contactsPage.importModal.step5.submit")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
